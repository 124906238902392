import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { EditPenIcon, EnvolepUploadIcon, PlusIcon, ThreeDotIcon, TimeLineIcon } from 'assets/svgs'
import { ADMIN_CONTACT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IProfileData } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'
import TableImage from 'components/common/Table/TableImage'

import ActivityModal from './modal/ActivityModal'
import AddMember from './modal/AddMember'
import { editUserContact, getBillingContact, getContactProfile, getFacilityContact, sendInvitation } from './api'
import { type IAddMemberFacility, type IFacilityContactColumn } from './type'

const FacilityContacts = () => {
  const param = useParams()
  const [hideActivityBtn, setHideActivityBtn] = useState<boolean>(false)
  const [billingListData, setBillingListData] = useState([])
  const [openActivityModal, setActivityModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [editId, setEditId] = useState<string>()
  const [openModal, setModalOpen] = useState<boolean>(false)
  const [contactDetails, setContactDetails] = useState<IProfileData | any>(null)
  const [contactListData, setContactListData] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(contactListData?.length)?.fill(false))
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 5,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [sortBillingTable, setSortBillingTable] = useState({
    order: '',
    field: '',
  })
  const [isOpenDirectly, setOpenDirectly] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (param.id) {
      void handleGetBillingContact(sortBillingTable?.order, sortBillingTable?.field)
      void handleGetContacts(sortTable?.order, sortTable?.field, search ? 0 : paginationInfo.offset, paginationInfo.limit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openModal, sortTable, sortBillingTable, search, paginationInfo.limit, paginationInfo.limit, param.id])

  const handleGetBillingContact = async (order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const res = await getBillingContact(String(param.id), search, order, field)
    if (res?.status === 200) {
      setBillingListData(res?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetContacts = async (order: string, field: string, offset: number, limit: number) => {
    dispatch(setGlobalLoader(true))
    const res = await getFacilityContact(String(param.id), search, order, field, offset, limit)
    if (res?.status === 200) {
      setContactListData(res?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: res?.data?.total,
        limit: res?.data?.limit,
      }))
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditUserData = async (id: string, values: IAddMemberFacility) => {
    dispatch(setGlobalLoader(true))
    const response = await editUserContact(id, values)
    if (response?.data.statusCode === 1) {
      void handleGetBillingContact(sortBillingTable?.order, sortBillingTable?.field)
      void handleGetContacts(sortTable?.order, sortTable.field, paginationInfo.offset, paginationInfo.limit)
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetContactProfile = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getContactProfile(id)
    if (response?.data?.statusCode === 1) {
      setContactDetails(response?.data?.data)
      setModalOpen(true)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setPaginationInfo((prev) => ({
      ...prev,
      current: 1,
    }))
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const showAddEditModal = () => {
    setContactDetails(null)
    setEditId('')
    setModalOpen(true)
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleSendInvitation = async (data: { email: string }) => {
    dispatch(setGlobalLoader(true))
    const response = await sendInvitation(data)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleChangePage = (page: number, pageSize?: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    void handleGetBillingContact(sortBillingTable?.order, sortBillingTable?.field)
    void handleGetContacts(sortTable?.order, sortTable.field, newOffset, paginationInfo.limit)
  }

  const handleEdit = (id: string) => {
    setEditId(id)
    void handleGetContactProfile(id)
  }

  const onSwitchChange = (checked: boolean, data: any, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    void handleEditUserData(id, newData)
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (
            <div
              className="option-wrapper w-full p-4"
              key="1"
              onClick={() => {
                handleEdit(record.id)
                if (record.invitation_status === 'invited') {
                  setHideActivityBtn(true)
                }
              }}
            >
              <p className="flex items-center gap-2">
                <EditPenIcon /> <span className="para-p1 font-normal text-neutral-800">{t('button.edit')}</span>
              </p>
            </div>
          ),
        },
        {
          label: (
            <div
              className="option-wrapper w-full p-4"
              key="2"
              onClick={() => {
                if (record?.invitation_status !== 'invited') {
                  setActivityModalOpen(true)
                  setHideActivityBtn(false)
                } else {
                  void handleSendInvitation({ email: record?.email })
                }
              }}
            >
              <p className="flex items-center gap-2">
                {record.invitation_status === 'invited' ? <EnvolepUploadIcon /> : <TimeLineIcon />}
                <span className="para-p1 font-normal text-neutral-800">{record.invitation_status === 'invited' ? t('button.sendInvitation') : t('button.viewActivity')}</span>
              </p>
            </div>
          ),
          key: 2,
        },
        {
          label: (
            <div className="option-wrapper w-full p-4" key="3">
              <p className="flex items-center justify-between w-full gap-2">
                <span className="para-p1 font-normal text-neutral-800">{t('label.active')}</span>
                <Switch
                  checked={record.status === 'active'}
                  onChange={(checked) => {
                    onSwitchChange(checked, record, record?.id)
                  }}
                />
              </p>
            </div>
          ),
          key: 3,
        },
      ],
    }
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    if (sorter.field === 'facility_id') {
      return
    }
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleBillingTableChange = (_pagination: any, _filters: any, sorter: any) => {
    if (sorter.field === 'facility_id') {
      return
    }
    setSortBillingTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType<IFacilityContactColumn> = [
    {
      title: t('table.facilityContact.teamMember'),
      dataIndex: 'first_name',
      key: 'first_name',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 300,
      render: (_value, record) => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const name = record.first_name + ' ' + record.last_name
        return (
          <div className="w-full min-w-300">
            <TableImage imageClass="rounded-50" name={name} baseUrl={record?.base_url as string} image={record?.image as string} />
          </div>
        )
      },
    },
    {
      title: t('table.facilityContact.email'),
      dataIndex: 'email',
      key: 'email',
      align: 'start',
      width: 300,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <div className="w-full min-w-300">
            <HeadingText text={record?.email} classString="text-neutral-800 para-p3" />
          </div>
        )
      },
    },
    {
      title: t('table.facilityContact.status'),
      dataIndex: 'status',
      key: 'status_contact',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 200,
      className: 'center-align',
      render: (_value, record) => {
        return (
          <div className="w-full min-w-200">
            <HeadingText
              classString={`${record?.status === ADMIN_CONTACT_STATUS.INVITED ? 'invited' : record?.status === ADMIN_CONTACT_STATUS.ACTIVE ? 'active' : 'inactive'} `}
              text={record?.status === 'active' ? 'Active' : 'Inactive'}
            />
          </div>
        )
      },
    },
    {
      title: t('table.facilityContact.invitationStatus'),
      dataIndex: 'invitation_status',
      key: 'invitation_status',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="w-full min-w-200">
            <HeadingText classString={`${record?.invitation_status === ADMIN_CONTACT_STATUS.INVITED ? 'invited' : 'active'} `} text={formatTitle(record?.invitation_status)} />
          </div>
        )
      },
    },
    {
      title: t('table.facilityContact.facilityAccess'),
      dataIndex: 'facility_id',
      key: 'facility_id',
      align: 'center',
      width: 200,
      sorter: (a, b) => {
        const aLength = Array.isArray(a.facility_id) ? a.facility_id.length : 0
        const bLength = Array.isArray(b.facility_id) ? b.facility_id.length : 0
        return aLength - bLength
      },
      sortDirections: ['ascend', 'descend'],
      className: 'center-align',
      render: (_value, record) => {
        const facilityIdLength = Array.isArray(record.facility_id) ? record.facility_id.length : 0
        return (
          <div className="w-full min-w-200">
            <HeadingText classString="text-neutral-800 para-p3" text={facilityIdLength.toString()} />
          </div>
        )
      },
    },
    {
      title: t('table.facilityContact.lastModified'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 200,
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      render: (_value, record) => {
        const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY, hh:mm A')
        return (
          <div className="w-full min-w-200">
            <HeadingText classString="text-neutral-800 para-p3 font-normal" text={formattedDate} />
          </div>
        )
      },
    },
    {
      title: t('table.facilityContact.actions'),
      dataIndex: '',
      // hidden: !permits.edit,
      key: 'action_contact',
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <Dropdown
              rootClassName="table-action-dropdown"
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
              trigger={['click']}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const tableButton = <CustomBtn isIcon svgIcon={<PlusIcon />} text={t('button.addUser')} onClick={showAddEditModal} type="primary" className="rounded-3 border-0" />
  return (
    <div className="flex flex-col gap-6 relative">
      <div className="flex flex-col items-start gap-6 w-full pt-123">
        <HeadingText text={t('heading.billingContacts')} classString="mobile-heading-h4 font-medium text-neutral-800" />
        <CustomTable
          column={columns}
          data={billingListData}
          total={undefined}
          pagination={false}
          onChange={handleBillingTableChange}
          isSearch
          tableButton={tableButton}
          isWithoutTable
          onSearchChange={(e: { target: { value: string } }) => {
            handleChange(e.target.value)
          }}
          onSearch={onSearch}
          searchPlaceholder={t('placeholder.search')}
        />
      </div>
      <div className="flex flex-col items-start gap-2 w-full">
        <HeadingText text={t('heading.contacts')} classString="mobile-heading-h4 font-medium text-neutral-800" />
        <CustomTable
          name={t('label.member')}
          column={columns}
          data={contactListData}
          total={paginationInfo.total}
          limit={paginationInfo.limit}
          current={paginationInfo.current}
          search={search}
          onChange={handleTableChange}
          onChangePage={handleChangePage}
        />
      </div>
      {openModal && (
        <CustomModal
          width={915}
          open={openModal}
          title={editId ? t('heading.editContact') : t('heading.addContact')}
          footer={null}
          classStrings="hide-closeicon-modal"
          onCancel={() => {
            setContactDetails(null)
            setModalOpen(false)
          }}
          content={
            <AddMember
              openModal={openModal}
              setOpenModal={setModalOpen}
              editId={editId}
              contactDetails={contactDetails}
              setContactDetails={setContactDetails}
              setSortTable={setSortTable}
              facilityId={String(param.id)}
            />
          }
        />
      )}
      {openActivityModal && (
        <ActivityModal isModalOpen={openActivityModal} setIsModalOpen={setActivityModalOpen} isOpenDirectly={isOpenDirectly} setOpenDirectly={setOpenDirectly} />
      )}
    </div>
  )
}

export default FacilityContacts
