import React from 'react'

const DropDownIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width ?? '20'} height={props.height ?? '21'} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0832 8.16797C13.7498 7.83464 13.2498 7.83464 12.9165 8.16797L9.99984 11.0846L7.08317 8.16797C6.74984 7.83464 6.24984 7.83464 5.9165 8.16797C5.58317 8.5013 5.58317 9.0013 5.9165 9.33464L9.4165 12.8346C9.58317 13.0013 9.74984 13.0846 9.99984 13.0846C10.2498 13.0846 10.4165 13.0013 10.5832 12.8346L14.0832 9.33464C14.4165 9.0013 14.4165 8.5013 14.0832 8.16797Z"
        fill={props.className ?? '#494A4C'}
      />
    </svg>
  )
}

export default DropDownIcon
