import { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, message } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_REJECTED_TIMECARD_REASONS } from 'constants/Constant'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IShiftCancelReason } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import TimeCardReject from './Modal/TimeCardReject'
import { deleteTimeCardCancelReason, getAllTimeRejectReason } from './api'

const TimeAndAttendance = () => {
  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [isAddNewReason, setIAddNewReason] = useState<boolean>(false)
  const [editId, setEditId] = useState<string>('')
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [timeCancelData, setTimeCancelData] = useState({
    reason: '',
    description: '',
    status: '',
  })

  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.timeAndAttendance'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleTimeCancelData(paginationInfo.limit, search ? 0 : paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.offset, isAddNewReason, sortTable, search])

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    void handleTimeCancelData(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
  }

  const handleTimeCancelData = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllTimeRejectReason(limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetData = async (record: IShiftCancelReason) => {
    const { id, reason, description, status } = record
    setEditId(id ?? '')
    setTimeCancelData({ reason, description, status })
    setIAddNewReason(true)
  }

  const handleDeleteTimeRejectReason = async (id: string) => {
    const response = await deleteTimeCardCancelReason(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleTimeCancelData(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setPaginationInfo((prev) => ({
      ...prev,
      current: 1,
    }))
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }
  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            void handleGetData(record)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id ?? '')
            setDeleteName(record.reason)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.shiftCancelReason.reason'),
      dataIndex: 'reason',
      key: 'reason',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 460,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record.reason} />
      },
    },
    {
      title: t('table.shiftCancelReason.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 460,
    },
    {
      title: t('table.shiftCancelReason.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 460,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.shiftCancelReason.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const addRejectBtn = (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={t('button.addReason')}
      type="primary"
      className="rounded-3 border-0"
      onClick={() => {
        setEditId('')
        setIAddNewReason(true)
      }}
    />
  )

  return (
    <div className="time-and-attendance-wrapper">
      <CustomTable
        name={t('label.member')}
        column={columns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        onChange={handleTableChange}
        isSearch
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        data={listData}
        tableButton={addRejectBtn}
        searchPlaceholder={t('placeholder.search')}
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
      />
      {isAddNewReason && (
        <CustomModal
          open={isAddNewReason}
          centered
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIAddNewReason(false)
          }}
          width={600}
          content={<TimeCardReject setIsModalOpen={setIAddNewReason} editId={editId} data={timeCancelData} setSortTable={setSortTable} />}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_REJECTED_TIMECARD_REASONS.CANCEL}
          okClick={() => {
            void handleDeleteTimeRejectReason(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_REJECTED_TIMECARD_REASONS.DELETE_DESC}
          mainHead={DELETE_REJECTED_TIMECARD_REASONS.DELETE_HEAD_LOB}
          okText={DELETE_REJECTED_TIMECARD_REASONS.DELETE}
        />
      )}
    </div>
  )
}

export default TimeAndAttendance
