import { useState } from 'react'
import { Form, Radio } from 'antd'
import { settingTab, verifyFacility } from 'constants/Constant'
import { selectOptionValidation } from 'constants/Validation'
import { t } from 'i18next'

import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

interface IGeneralSetting {
  mainClass?: string
}

const customData = [
  {
    label: 'Custom settings',
    value: 'Custom settings',
  },
]

const GeneralSettings = ({ mainClass }: IGeneralSetting) => {
  const [selectedRadioValue, setSelectedRadioValue] = useState(settingTab.SYSTEM_DEFAULT)

  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <div className={`general-settings-container flex p-10 flex-col items-center gap-6 rounded-4 border-stroke ${mainClass} `}>
      <Form className="general-setting-wrapper flex justify-between items-start aline-self gap-4">
        <div className="order-type-wrapper flex w-full flex-col items-start gap-4">
          <p className="text-neutral-800 activity-text font-medium">{verifyFacility.DEFAULT_ORDER_TYPE}</p>
          <div className={`cursor-pointer w-full MB-6`}>
            <Form.Item name="facility" className="w-full" rules={selectOptionValidation}>
              <Radio.Group
                onChange={(e) => {
                  setSelectedRadioValue(e.target.value)
                }}
                defaultValue={settingTab.SYSTEM_DEFAULT}
                rootClassName="setting-general-radio flex flex-col items-start gap-4 w-full aline-self"
              >
                <Radio value={settingTab.SYSTEM_DEFAULT}>{settingTab.SYSTEM_DEFAULT}</Radio>
                <Radio value={settingTab.PER_DIEM}>{settingTab.PER_DIEM}</Radio>
                <Radio value={settingTab.LONG_TERM_CONT}>{settingTab.LONG_TERM_CONT}</Radio>
                <Radio value={settingTab.TRAVEL_ASSIGN}>{settingTab.TRAVEL_ASSIGN}</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          {/* {error?.facility && <p className={` error-container ${error?.facility ? 'mb-6' : ''}`}>{error.facility}</p>} */}
        </div>
        <div className="order-type-wrapper w-full">
          <p className="text-neutral-800 activity-text font-medium">{settingTab.OTHER_SETTINGS}</p>
          <div className="flex flex-col justify-center items-start gap-2 aline-self w-full">
            <p className="text-neutral-800 para-p2 font-normal">{settingTab.WORK_COME_CODE}</p>
            <div className="flex items-start gap-4 aline-self w-full ">
              <div className="flex flex-col w-full">
                <FloatingLabelSelect label={t('placeholder.facilityType')} options={customData} name="custom" labelClass="facility-float" />
              </div>
              <p className="text-neutral-800 para-p3 font-normal pt-22">{settingTab.OR}</p>
              <div className="flex flex-col w-full">
                <InputField placeholder={t('placeholder.enterFloorName')} name="no_of_beds" wrapperClass="w-full" />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default GeneralSettings
