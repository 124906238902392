const Filter = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M4.1665 8.83464C3.6665 8.83464 3.33317 9.16797 3.33317 9.66797V18.0013C3.33317 18.5013 3.6665 18.8346 4.1665 18.8346C4.6665 18.8346 4.99984 18.5013 4.99984 18.0013V9.66797C4.99984 9.16797 4.6665 8.83464 4.1665 8.83464ZM5.83317 5.5013H4.99984V3.0013C4.99984 2.5013 4.6665 2.16797 4.1665 2.16797C3.6665 2.16797 3.33317 2.5013 3.33317 3.0013V5.5013H2.49984C1.99984 5.5013 1.6665 5.83464 1.6665 6.33464C1.6665 6.83464 1.99984 7.16797 2.49984 7.16797H5.83317C6.33317 7.16797 6.6665 6.83464 6.6665 6.33464C6.6665 5.83464 6.33317 5.5013 5.83317 5.5013ZM9.99984 15.5013C9.49984 15.5013 9.1665 15.8346 9.1665 16.3346V18.0013C9.1665 18.5013 9.49984 18.8346 9.99984 18.8346C10.4998 18.8346 10.8332 18.5013 10.8332 18.0013V16.3346C10.8332 15.8346 10.4998 15.5013 9.99984 15.5013ZM17.4998 8.83464H16.6665V3.0013C16.6665 2.5013 16.3332 2.16797 15.8332 2.16797C15.3332 2.16797 14.9998 2.5013 14.9998 3.0013V8.83464H14.1665C13.6665 8.83464 13.3332 9.16797 13.3332 9.66797C13.3332 10.168 13.6665 10.5013 14.1665 10.5013H17.4998C17.9998 10.5013 18.3332 10.168 18.3332 9.66797C18.3332 9.16797 17.9998 8.83464 17.4998 8.83464ZM15.8332 12.168C15.3332 12.168 14.9998 12.5013 14.9998 13.0013V18.0013C14.9998 18.5013 15.3332 18.8346 15.8332 18.8346C16.3332 18.8346 16.6665 18.5013 16.6665 18.0013V13.0013C16.6665 12.5013 16.3332 12.168 15.8332 12.168ZM11.6665 12.168H10.8332V3.0013C10.8332 2.5013 10.4998 2.16797 9.99984 2.16797C9.49984 2.16797 9.1665 2.5013 9.1665 3.0013V12.168H8.33317C7.83317 12.168 7.49984 12.5013 7.49984 13.0013C7.49984 13.5013 7.83317 13.8346 8.33317 13.8346H11.6665C12.1665 13.8346 12.4998 13.5013 12.4998 13.0013C12.4998 12.5013 12.1665 12.168 11.6665 12.168Z"
        fill={props.className ?? '#494A4C'}
      />
    </svg>
  )
}

export default Filter
