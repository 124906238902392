import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { selectCommon } from 'store/slice/CommonSlice'
import { type ICustomBtn } from 'types'

const CustomBtn = ({ isIcon, text, svgIcon, onClick, type, className, htmlType = 'button', disabled, ...props }: ICustomBtn) => {
  const { globalLoader } = useSelector(selectCommon)

  return (
    <>
      {isIcon ? (
        <Button
          size="large"
          icon={svgIcon}
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          className={`${type !== 'default' ?? 'custom-icon-btn'} btn-padding flex items-center justify-center ${className}`}
          onClick={onClick}
          type={type}
          disabled={globalLoader || (disabled ?? false)}
          htmlType={htmlType}
          {...props}
        >
          {text}
        </Button>
      ) : (
        <Button
          size="large"
          className={`${className ?? ''} btn-padding flex items-center justify-center`}
          loading={htmlType === 'submit' ? globalLoader : false}
          onClick={onClick}
          type={type}
          disabled={globalLoader || (disabled ?? false)}
          htmlType={htmlType}
          {...props}
        >
          {text}
        </Button>
      )}
    </>
  )
}

export default CustomBtn
