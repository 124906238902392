// import React, { type MouseEvent } from 'react'
// import { Table } from 'antd'
// import { type ICustomTableProps, type IDataRow } from 'types'
// import { paginationDetails } from 'utils/utils'

// import PaginationComponent from './PaginationComponent'

// const CustomTable = <T extends Record<string, unknown>>({
//   column,
//   data,
//   total,
//   current,
//   limit,
//   onChangePage,
//   setModalOpen,
//   name,
//   onChange,
//   rowSelected,
// }: ICustomTableProps<T>): JSX.Element => {
//   const columns = column as any[]

//   const handleRowClick = (_record: any, event: MouseEvent<HTMLTableRowElement>) => {
//     if (event.target && (event.target as HTMLElement).closest('.action-cell')) {
//       return
//     }
//     if (setModalOpen) {
//       setModalOpen(_record.id)
//     }
//   }

//   const onRowProps = (record: any) => ({
//     onClick: (event: MouseEvent<HTMLTableRowElement>) => {
//       handleRowClick(record, event)
//     },
//   })

//   const modifiedColumns = columns?.map((col, index) => {
//     if (index === columns.length - 1) {
//       return {
//         ...col,
//         onCell: (record: IDataRow) => ({
//           onClick: (event: MouseEvent<HTMLTableRowElement>) => {
//             event.stopPropagation()
//           },
//         }),
//       }
//     }
//     return col
//   })

//   return (
//     <div className="common-table-wrapper">
//       <Table
//         onChange={onChange}
//         columns={modifiedColumns}
//         dataSource={data}
//         className="common-table"
//         pagination={false}
//         rowKey="id"
//         onRow={onRowProps}
//         rowSelection={rowSelected}
//       />
//       <div className="flex justify-between items-center p-4 table-footer">
//         <p className="text-pagination">
//           {paginationDetails(limit, current, total)} {name}
//         </p>
//         <PaginationComponent total={total} pageSize={limit} current={current} onChangePage={onChangePage} />
//       </div>
//     </div>
//   )
// }

// export default CustomTable

import { type ChangeEventHandler, type MouseEvent, useEffect, useState } from 'react'
import { Drawer, Form, Input, Select, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DropDownIcon, FilterIcon, SearchIcon } from 'assets/svgs'
import { t } from 'i18next'
import { paginationDetails } from 'utils/utils'

import CustomBtn from '../CustomBtn'

import PaginationComponent from './PaginationComponent'

import './Table.scss'

interface ISelectLabel {
  label: string
  value: string
}

interface CustomTableProps<T extends Record<string, unknown>> {
  column: ColumnsType<T>
  data: T[] | undefined
  total?: number
  current?: number
  limit?: number
  search?: string
  onChangePage?: (page: number, pageSize?: number) => void
  setModalOpen?: (editId: string) => void
  onChange?: (pagination: any, filters: any, sorter: any) => void
  name?: string
  className?: string
  pagination?: boolean
  rowSelected?: any
  isSearch?: boolean
  filterOption?: any
  onSearchChange?: ChangeEventHandler<HTMLInputElement>
  searchPlaceholder?: string
  tableButton?: any
  isWithoutTable?: boolean
  onSearch?: (value: string) => void
  isFilter?: boolean
  isTab?: boolean
  option?: ISelectLabel[]
  setSelectedValue?: any
  selectedValue?: string | any
}

interface IDataRow {
  id: string | number
}

const { Search } = Input

const CustomTable = <T extends Record<string, unknown>>({
  name,
  column,
  data,
  total,
  current,
  option,
  limit,
  onChangePage,
  onChange,
  setModalOpen,
  rowSelected,
  className,
  onSearch,
  pagination = true,
  isSearch = false,
  onSearchChange,
  searchPlaceholder,
  filterOption,
  isTab = false,
  tableButton,
  setSelectedValue,
  isWithoutTable = false,
  selectedValue,
  isFilter = false,
}: CustomTableProps<T>): JSX.Element => {
  const columns = column as any[]
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (isTab && option && option.length > 0) {
      setSelectedValue(option[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRowClick = (_record: any, event: MouseEvent<HTMLTableRowElement>) => {
    if (event.target && (event.target as HTMLElement).closest('.action-cell')) {
      return
    }
    if (setModalOpen) setModalOpen(_record.id)
  }

  const onRowProps = (record: any) => ({
    onClick: (event: MouseEvent<HTMLTableRowElement>) => {
      handleRowClick(record, event)
    },
  })

  // Modify the last column to prevent row selection when clicked
  const modifiedColumns = columns.map((col, index) => {
    if (index === columns.length - 1) {
      // Assuming the last column is the "action" column
      return {
        ...col,
        onCell: (record: IDataRow) => ({
          onClick: (event: MouseEvent<HTMLTableRowElement>) => {
            // Prevent row selection event when the last column is clicked
            event.stopPropagation()
          },
        }),
      }
    }
    return col
  })

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleSelectChange = (value: { label: string; value: string }) => {
    setSelectedValue(value?.value)
  }

  return (
    <div className={`common-table-wrapper ${isSearch ? 'border-stroke' : ''} ${isWithoutTable ? 'border-0 ' : ''}`}>
      {isSearch && (
        <div className={`filter-wrapper ${isWithoutTable ? 'without-table border-0 ' : ''}`}>
          <div className="search-container">
            <Search
              placeholder={searchPlaceholder}
              prefix={<SearchIcon />}
              onSearch={onSearch}
              allowClear
              className="custom-searchbar table-searchBar-width"
              onChange={onSearchChange}
            />
            {isFilter && (
              <>
                <CustomBtn
                  isIcon
                  svgIcon={<FilterIcon />}
                  onClick={showDrawer}
                  type="default"
                  className="flex flex-col justify-center items-center self-stretch rounded-3 bg-neutral-50 box-shadow filter-btn"
                />
                <Drawer title={t('button.filter')} onClose={onClose} open={open} className="table-filter-drawer">
                  <Form className="flex flex-col items-start gap-2 self-stretch h-full justify-between">
                    <div className="filter-content-wrapper w-full">{filterOption}</div>
                    <div className="flex gap-4 w-full">
                      <CustomBtn text={t('button.reset')} type="default" className="w-full" htmlType="reset" />
                      <CustomBtn text={t('button.apply')} type="primary" className="w-full" htmlType="submit" />
                    </div>
                  </Form>
                </Drawer>
              </>
            )}
            {isTab && <Select labelInValue onChange={handleSelectChange} options={option} className="select-table" suffixIcon={<DropDownIcon />} value={selectedValue} />}
          </div>
          {tableButton}
        </div>
      )}
      <Table
        onChange={onChange}
        columns={modifiedColumns}
        dataSource={data}
        className={`common-table ${!isSearch ? 'non-filter-table' : ''} ${isWithoutTable ? 'non-filter-table' : ''} ${className ?? ''}`}
        rowKey="id"
        onRow={onRowProps}
        rowSelection={rowSelected}
        pagination={false} // Disable default pagination
      />
      {pagination && (
        <div className="flex justify-between items-center p-4 border-t-stroke table-footer">
          <p className="text-pagination">
            {paginationDetails(limit, current, total)} {name}
          </p>
          <PaginationComponent total={total} pageSize={limit} current={current} onChangePage={onChangePage} />
        </div>
      )}
    </div>
  )
}

export default CustomTable
