import React from 'react'

const ModalClose = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.1751 9.9982L16.4251 4.75653C16.582 4.59961 16.6702 4.38679 16.6702 4.16487C16.6702 3.94295 16.582 3.73012 16.4251 3.5732C16.2682 3.41628 16.0554 3.32812 15.8334 3.32812C15.6115 3.32812 15.3987 3.41628 15.2418 3.5732L10.0001 8.8232L4.75845 3.5732C4.60153 3.41628 4.3887 3.32813 4.16678 3.32813C3.94486 3.32813 3.73203 3.41628 3.57511 3.5732C3.41819 3.73012 3.33004 3.94295 3.33004 4.16487C3.33004 4.38679 3.41819 4.59961 3.57511 4.75653L8.82511 9.9982L3.57511 15.2399C3.497 15.3173 3.43501 15.4095 3.3927 15.5111C3.3504 15.6126 3.32861 15.7215 3.32861 15.8315C3.32861 15.9415 3.3504 16.0505 3.3927 16.152C3.43501 16.2536 3.497 16.3457 3.57511 16.4232C3.65258 16.5013 3.74475 16.5633 3.8463 16.6056C3.94785 16.6479 4.05677 16.6697 4.16678 16.6697C4.27679 16.6697 4.38571 16.6479 4.48726 16.6056C4.58881 16.5633 4.68098 16.5013 4.75845 16.4232L10.0001 11.1732L15.2418 16.4232C15.3192 16.5013 15.4114 16.5633 15.513 16.6056C15.6145 16.6479 15.7234 16.6697 15.8334 16.6697C15.9435 16.6697 16.0524 16.6479 16.1539 16.6056C16.2555 16.5633 16.3476 16.5013 16.4251 16.4232C16.5032 16.3457 16.5652 16.2536 16.6075 16.152C16.6498 16.0505 16.6716 15.9415 16.6716 15.8315C16.6716 15.7215 16.6498 15.6126 16.6075 15.5111C16.5652 15.4095 16.5032 15.3173 16.4251 15.2399L11.1751 9.9982Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default ModalClose
