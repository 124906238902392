import { useEffect, useRef, useState } from 'react'
import { Form, Input } from 'antd'
import { PasswordInvisibleIcon, PasswordVisibleIcon } from 'assets/svgs'

import './PasswordField.scss'

interface IPasswordField {
  placeholder: string
  value?: string
  rules?: any
  name: string
  inputClass?: string
  error?: string | boolean
  wrapperClass?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PasswordField = ({ name, rules, placeholder, inputClass, error, value, wrapperClass, onChange }: IPasswordField) => {
  const [focused, setFocused] = useState(false)
  const [visible, setVisible] = useState(false)
  const [manuallyFocused, setManuallyFocused] = useState(false)
  const inputRef: any = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleAutofillCheck = () => {
      const isAutoFilled = inputRef.current?.input?.matches(':-webkit-autofill')
      const hasValue = inputRef.current?.input?.value !== ''
      if (!manuallyFocused) {
        setFocused(hasValue || isAutoFilled)
      }
    }

    const timeout = setTimeout(handleAutofillCheck, 900)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputRef, manuallyFocused])

  const toggleVisibility = () => {
    setVisible(!visible)
    if (inputRef.current) {
      const cursorPosition = inputRef.current.input.selectionStart
      setTimeout(() => {
        inputRef.current?.input?.setSelectionRange(cursorPosition, cursorPosition)
      }, 0)
    }
  }

  const handleFocus = () => {
    setFocused(true)
    setManuallyFocused(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(e.target.value !== '')
    setManuallyFocused(false)
  }

  return (
    <>
      <div className={`${wrapperClass as string} password-field-wrapper ${!error ? 'mb-6' : ''}`}>
        <div className={`floatingLabelPassword ${inputClass as string}`}>
          <label htmlFor={name} className={`label-float ${focused ? 'actives' : ''} ${value ? 'actives' : ''}`}>
            {placeholder}
          </label>
          <Form.Item name={name} rules={rules}>
            <Input
              value={value}
              ref={inputRef}
              size="small"
              type={visible ? 'text' : 'password'}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={onChange}
              className="common-input"
              suffix={<span onClick={toggleVisibility}>{visible ? <PasswordVisibleIcon /> : <PasswordInvisibleIcon />}</span>}
            />
          </Form.Item>
        </div>
      </div>
      {error && <p className="error-container">{error}</p>}
    </>
  )
}

export default PasswordField
