import { useEffect, useRef, useState } from 'react'
import { Form, Input } from 'antd'

import './InputField.scss'

interface IRules {
  required?: boolean
  message?: string
}

interface IInputField {
  name: string
  disabled?: boolean
  type?: string
  rules?: IRules[]
  value?: string
  placeholder: string
  inputClass?: string
  labelClass?: string
  defaultValue?: string
  textClass?: string
  wrapperClass?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string | boolean
  delay?: number
}

const InputField = ({
  name,
  disabled = false,
  value,
  error,
  type = 'text',
  rules,
  placeholder,
  inputClass,
  labelClass,
  defaultValue,
  textClass,
  wrapperClass,
  onChange,
  delay,
  ...props
}: IInputField) => {
  const [focused, setFocused] = useState(false)
  const [manuallyFocused, setManuallyFocused] = useState(false)
  const inputRef = useRef<any>(null)

  useEffect(() => {
    const handleAutofillCheck = () => {
      const isAutoFilled = inputRef.current?.input?.matches(':-webkit-autofill')
      const hasValue = inputRef.current?.input?.value !== ''
      if (!manuallyFocused) {
        setFocused(hasValue || isAutoFilled)
      }
    }

    const timeout = setTimeout(handleAutofillCheck, delay ?? 900)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputRef, manuallyFocused])

  const handleFocus = () => {
    setFocused(true)
    setManuallyFocused(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(e.target.value !== '')
    setManuallyFocused(false)
    if (props.onBlur) props.onBlur(e)
  }

  return (
    <>
      <div className={`${wrapperClass as string} input-field-wrapper ${!error ? 'mb-6' : ''}`}>
        <div className={`floatingLabelInput ${inputClass as string}`}>
          <label
            htmlFor={name}
            className={`label-float ${focused ? 'actives' : ''} ${value ? 'actives' : ''} ${disabled ? 'actives text-neutral-300' : ''} ${labelClass as string}`}
          >
            {placeholder}
          </label>
          <Form.Item name={name} rules={rules} initialValue={value}>
            <Input
              id={name}
              size="small"
              autoComplete={name}
              value={value}
              ref={inputRef}
              defaultValue={defaultValue}
              type={type}
              className={`common-input ${textClass as string} `}
              disabled={disabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={props.onKeyDown}
              onClick={props.onClick}
              onChange={onChange}
              {...props}
            />
          </Form.Item>
        </div>
      </div>
      {error && <p className="error-container">{error}</p>}
    </>
  )
}

export default InputField
