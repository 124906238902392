import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import type { SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { DeleteIcon, DuplicateIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_MODAL_ROLE_MANAGEMENT_TEXT } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setFormData } from 'store/slice/AddRoleSlice'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import type { IPermission, IRoleTable } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import { deleteRoles, duplicateRoles, editRoles, getRoles, getSingleRole } from './api'

const RoleManagement = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>('')
  const [roleData, setRoleData] = useState<IRoleTable[]>()
  const [editId, setEditId] = useState<string>()
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(roleData?.length).fill(false))
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.roleManage'),
        isBack: true,
      })
    )
  }, [dispatch, t])

  useEffect(() => {
    void handleGetRoles(paginationInfo.limit, search ? 0 : paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sortTable])

  useEffect(() => {
    if (editId) {
      const getSingleRoleData = async (editId: string) => {
        dispatch(setGlobalLoader(true))
        const response = await getSingleRole(editId)
        if (response?.data.statusCode === 1) {
          const { name, description, permissions } = response.data.data ?? {}
          const sections =
            permissions?.map((section: { id: string; permissions: any[] }) => ({
              section_id: section.id,
              sub_sections:
                section.permissions?.map((permission: { id: string; permission: IPermission[] }) => ({
                  sub_section_id: permission.id,
                  permissions:
                    permission.permission?.map((per: IPermission) => ({
                      permission_id: per.id,
                      has_access: per.has_access,
                    })) || [],
                })) || [],
            })) || []
          dispatch(setFormData({ name, description, sections }))
        }
        dispatch(setGlobalLoader(false))
        localStorage.setItem('editRoleId', editId)
        navigate(`/settings/user/role-management/edit-role/${editId}`)
      }
      void getSingleRoleData(editId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId])

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setPaginationInfo((prev) => ({
      ...prev,
      current: 1,
    }))
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handleGetRoles = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getRoles(limit, offset, search, order, field)
    if (response?.status === 200) {
      setRoleData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    }
    dispatch(setGlobalLoader(false))
  }

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    void handleGetRoles(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
  }

  const handleDelete = (roleId: string) => {
    void handleDeleteRole(roleId)
  }

  const handleDeleteRole = async (roleId: string) => {
    const response = await deleteRoles(roleId)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void handleGetRoles(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    }
  }

  const handleEdit = (roleId: string) => {
    setEditId(roleId)
  }

  const handleDuplicate = async (id: string) => {
    if (id) {
      dispatch(setGlobalLoader(true))
      const response = await duplicateRoles(id)
      if (response?.data.statusCode === 1) {
        void message.success(response?.data?.message)
        void handleGetRoles(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
      } else {
        void message.error(response?.data?.message)
      }
      dispatch(setGlobalLoader(false))
    }
  }

  const onSwitchChange = async (checked: boolean, data: any, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    void handleEditRoleData(id, newData)
  }

  const handleEditRoleData = async (id: string, values: any) => {
    dispatch(setGlobalLoader(true))
    const response = await editRoles(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetRoles(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            handleEdit(record.id)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon width={24} height={24} className="#2A2A2B" />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          onClick: () => {
            void handleDuplicate(record.id)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DuplicateIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.duplicate')}</span>
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div className="option-wrapper">
              <div className=" cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      void onSwitchChange(checked, record, record?.id)
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '4',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id)
            setDeleteName(record.name)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<IRoleTable> = [
    {
      title: t('table.roleManage.role'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 440,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return <HeadingText classString="font-medium para-p2" text={record?.name} />
      },
    },
    {
      title: t('table.roleManage.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      width: 440,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: t('table.roleManage.userCount'),
      dataIndex: 'user_count',
      key: 'user_count',
      align: 'center',
      width: 440,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
    },
    {
      title: t('table.common.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            {record?.id !== '27a879f5-c044-4074-96c0-34ee3d209f17' && (
              <Dropdown
                rootClassName="table-action-dropdown"
                trigger={['click']}
                menu={getMenu(record, _value.id)}
                placement="bottomRight"
                open={actionMenuOpen[_value.id]}
                onOpenChange={(open) => {
                  handleOpenChange(_value.id, open)
                }}
              >
                <div className="action-cell flex items-center justify-center three-dot-icon">
                  <ThreeDotIcon className="cursor-pointer icon" />
                </div>
              </Dropdown>
            )}
          </div>
        )
      },
    },
  ]

  const tableButton = (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={t('button.addNewRole')}
      onClick={() => {
        dispatch(setFormData({ name: '', description: '', sections: [] }))
        navigate('/settings/user/role-management/create-role')
      }}
      type="primary"
    />
  )

  return (
    <div>
      <CustomTable<IRoleTable>
        column={columns}
        onChangePage={handlePageChange}
        data={roleData}
        isSearch
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        onSearch={onSearch}
        name={t('label.roles')}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        searchPlaceholder={t('placeholder.search')}
        tableButton={tableButton}
        onChange={handleTableChange}
      />

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_ROLE_MANAGEMENT_TEXT.CANCEL}
          okClick={() => {
            handleDelete(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_MODAL_ROLE_MANAGEMENT_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_ROLE_MANAGEMENT_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_ROLE_MANAGEMENT_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default RoleManagement
