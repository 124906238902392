import React, { type ChangeEventHandler, useEffect, useState } from 'react'
import { Drawer, Form, Input } from 'antd'
import { FilterIcon, SearchIcon } from 'assets/svgs'

import CustomBtn from '../CustomBtn'

import '../Table/Table.scss'

const { Search } = Input

interface ICustomTableProps {
  isSearch?: boolean
  search?: string
  tableButton?: any
  isWithoutTable?: boolean
  onSearch?: (value: string) => void
  isFilter?: boolean
  handleApplyFilter?: () => void
  handleResetFilter?: () => void
  filterSelected?: Record<string, string | number | boolean | null | undefined | any[]> | any
  filterOption?: any
  onSearchChange?: ChangeEventHandler<HTMLInputElement>
  searchPlaceholder?: string
}

const CustomTableHeader = ({
  isSearch,
  isWithoutTable,
  searchPlaceholder,
  onSearch,
  onSearchChange,
  isFilter,
  filterOption,
  handleResetFilter,
  handleApplyFilter,
  tableButton,
  filterSelected,
}: ICustomTableProps) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const maskElement = document.getElementsByClassName('ant-drawer-mask')[0]
    if (maskElement) {
      const handleMaskClick = () => {
        handleResetFilter?.()
      }
      maskElement.addEventListener('click', handleMaskClick)
      return () => {
        maskElement.removeEventListener('click', handleMaskClick)
      }
    }
  }, [open])

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const hasValue = filterSelected
    ? Object.values(filterSelected).some((value) => {
        if (Array.isArray(value)) {
          return value.length > 0
        }
        return value !== undefined && value !== null && value !== ''
      })
    : false

  return (
    <div className={`common-table-wrapper table-header-border ${isSearch ? 'border-stroke' : ''}`}>
      {isSearch && (
        <div className={`filter-wrapper ${isWithoutTable ? 'without-table border-0 ' : ''}`}>
          <div className="search-container">
            <Search
              placeholder={searchPlaceholder}
              prefix={<SearchIcon />}
              onSearch={onSearch}
              allowClear
              className="custom-searchbar table-searchBar-width"
              onChange={onSearchChange}
            />
            {isFilter && (
              <>
                <CustomBtn
                  isIcon
                  svgIcon={<FilterIcon hideRedDot={hasValue} />}
                  onClick={showDrawer}
                  type="default"
                  className="flex flex-col justify-center items-center self-stretch rounded-3 bg-neutral-50 box-shadow filter-btn"
                />
                <Drawer title="Filter" onClose={onClose} open={open} className="table-filter-drawer" destroyOnClose>
                  <Form className="flex flex-col items-start gap-2 self-stretch h-full justify-between">
                    <div className="filter-content-wrapper w-full">{filterOption}</div>
                    <div className="flex gap-4 w-full">
                      <CustomBtn
                        text="Reset"
                        type="default"
                        className="w-full"
                        htmlType="reset"
                        onClick={() => {
                          handleResetFilter?.()
                          setOpen(false)
                        }}
                      />
                      <CustomBtn
                        text="Apply"
                        type="primary"
                        className="w-full"
                        htmlType="submit"
                        onClick={() => {
                          handleApplyFilter?.()
                          setOpen(false)
                        }}
                      />
                    </div>
                  </Form>
                </Drawer>
              </>
            )}
          </div>
          {tableButton}
        </div>
      )}
    </div>
  )
}

export default CustomTableHeader
