import { message } from 'antd'
import http from 'services/Service'
import type { IAddProfile } from 'types'

export const getAllContacts = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/admin/all-contacts?limit=${limit}&offset=${offset}${order && field && `&order[${field}]=${order}`}${search && `&search=${search}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getProfileById = async (id: string) => {
  try {
    const response = http.get(`/admin/profile/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addContact = async (payload: IAddProfile) => {
  try {
    const response = http.post('/admin/add-contact', payload)
    return await response
  } catch (err: any) {
    console.log(err)
    void message.error(err?.response?.data?.message)
  }
}

export const editContact = async (id: string, payload: IAddProfile) => {
  try {
    const response = http.patch(`/admin/edit-contact/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message[0])
  }
}

export const sendInvitation = async (payload: { email: string }) => {
  try {
    const response = http.patch('/auth/send-invitation/admin', payload)
    return await response
  } catch (error: any) {
    void message.error(error?.response?.data?.message)
  }
}
