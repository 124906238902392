import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, message, Radio } from 'antd'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type ICompetencyTestForm, type IcompetencyTestOption, type ICompetencyTestQue } from 'types'
import { addCompentencyDeletedData, validateQuestionsForCompentancy } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

import { createCompetencyTest, getFindOneTest, updateCompetencyTest } from '../api'

const PreviewTest = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()

  const [data, setData] = useState({
    name: '',
    required_score: '',
    duration: '',
    delete_question: [],
    delete_option: [],
    competency_test_question: [],
    editId: '',
    global_test_setting: {
      expires_in: 0,
      expiration_duration_type: '',
      total_attempts: 0,
      reassignment_duration: 0,
      reassignment_duration_type: '',
    },
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: location.state ? t('pageTitle.preview') : t('pageTitle.livePreview'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    getLocalStorageData()
  }, [])

  useEffect(() => {
    if (location.state) {
      if (localStorage.getItem('compentancyState')) {
        getLocalStorageData()
        return
      }
      const getUrlData = location?.state
      if (getUrlData?.id) void handleFindSingleTestData(getUrlData?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const getLocalStorageData = () => {
    const stateString = localStorage.getItem('compentancyState')
    const getData = stateString ? JSON.parse(stateString) : {}
    getData.competency_test_question = getData?.competency_test_question?.sort((a: ICompetencyTestQue, b: ICompetencyTestQue) => a.order - b.order)
    setData(getData)
  }

  const handleFindSingleTestData = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getFindOneTest(id)
    if (response?.status === 200) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, required_score, duration, competency_test_question, test_setting } = response.data.data
      const sortedQuestions = competency_test_question?.sort((a: ICompetencyTestQue, b: ICompetencyTestQue) => a.order - b.order)
      const setting = {
        expires_in: test_setting?.expires_in ?? 1,
        expiration_duration_type: test_setting?.expiration_duration_type ?? 'year',
        total_attempts: test_setting?.total_attempts ?? 10,
        reassignment_duration: test_setting?.reassignment_duration ?? 365,
        reassignment_duration_type: test_setting?.reassignment_duration_type ?? 'day',
      }
      const previewData = {
        name: name ?? '',
        required_score: required_score ?? '',
        duration: duration ?? '',
        delete_question: [],
        delete_option: [],
        competency_test_question: sortedQuestions ?? [],
        editId: '',
        global_test_setting: setting,
      }
      setData(previewData)
      localStorage.setItem('compentancyState', JSON.stringify(previewData))
    } else {
      void message.error(response?.data?.message)
      return null
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = () => {
    const validation = validateQuestionsForCompentancy(data.competency_test_question)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const updatedValues = {
      name: data.name,
      required_score: String(data.required_score),
      duration: data.duration,
      competency_test_question: data.competency_test_question,
      global_test_setting: data.global_test_setting,
    }
    if (data.editId) {
      const newData = addCompentencyDeletedData(updatedValues, data.delete_question, data.delete_option)
      void handleUpdate(data.editId, newData)
    } else {
      void handleSubmit(updatedValues)
    }
  }

  const handleSubmit = async (data: ICompetencyTestForm) => {
    dispatch(setGlobalLoader(true))
    const response = await createCompetencyTest(data)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/compentency-test')
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleUpdate = async (editId: string, data: ICompetencyTestForm) => {
    dispatch(setGlobalLoader(true))
    const response = await updateCompetencyTest(editId, data)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/compentency-test')
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Form form={form} onFinish={onFinish} className="flex flex-col gap-6 items-center w-full">
      <div className="flex items-center bg-white w-full competency-preview" style={{ paddingTop: location.state ? '25px' : '', paddingBottom: location.state ? '25px' : '' }}>
        <div className="flex flex-wrap gap-8 items-center justify-center preview-deatils">
          <HeadingText classString="text-neutral-800 mobile-heading-h6 font-medium" text={`Questions: ${data?.competency_test_question?.length ?? 0}`} />
          <HeadingText classString="text-neutral-800 mobile-heading-h6 font-medium" text={`Pass Score: ${data.required_score ?? 0}%`} />
          <HeadingText classString="text-neutral-800 mobile-heading-h6 font-medium" text={`Total Time (Mins): ${data.duration ?? 0}`} />
        </div>
        <div className="flex items-center justify-end gap-4 flex-1">
          {!location.state && <CustomBtn text={data.editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" />}
        </div>
      </div>

      <div className="flex flex-col w-915 gap-6 mobile-version">
        <div className="flex flex-col gap-6">
          {/* question */}
          {data.competency_test_question?.map((question: ICompetencyTestQue, questionIndex: number) => {
            return (
              <div key={questionIndex} className="bg-white rounded-3 question-container p-8">
                <div className={`flex items-center`}>
                  <div className="w-full mobile-heading-h5 font-medium text-neutral-800 break-all">{question.question}</div>
                </div>
                <div className="flex flex-col mt-6">
                  <div className="flex flex-col gap-6 w-full">
                    {question?.competency_test_option.map((optionTxt: IcompetencyTestOption, index: number) => {
                      return (
                        <div key={index} className="radio-option-container flex items-center w-full">
                          <Radio key={index} value={optionTxt.option} checked={!!optionTxt.is_answer} className="add-option activity-text">
                            <div className="flex items-center justify-between gap-6 w-full">
                              <HeadingText classString="text-neutral-800 mobile-heading-h6 font-medium break-all w-full" text={optionTxt.option} />
                            </div>
                          </Radio>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Form>
  )
}

export default PreviewTest
