import { message } from 'antd'
import http from 'services/Service'
import type { IListOfBusiness } from 'types'

export const getLineOfBusiness = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/line-of-business?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addLineOfBusiness = async (payload: IListOfBusiness) => {
  try {
    const response = http.post('/line-of-business', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const deleteLineOfBusiness = async (id: string) => {
  try {
    const response = http.delete(`/line-of-business/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const editLineOfBusiness = async (id: string, payload: IListOfBusiness) => {
  try {
    const response = http.patch(`/line-of-business/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
