import { useEffect, useState } from 'react'
import { Tabs, type TabsProps } from 'antd'
import { ChatIcon, ThreeDotIcon } from 'assets/svgs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

import CustomBtn from 'components/common/CustomBtn'

import Detail from './components/Detail'
import Settings from './components/settings/Settings'

const FacilityProfile = () => {
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState('1')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: 'Rocky Mtn. Care of Heber',
        isBack: true,
      })
    )
  }, [dispatch])

  const onChange = (key: string) => {
    setActiveTab(key)
    console.log(key)
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Details',
      children: <Detail />,
    },
    {
      key: '2',
      label: 'Schedule',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Shifts',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '4',
      label: 'Providers',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '5',
      label: 'Docs',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '6',
      label: 'Evaluations',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '7',
      label: 'Accounting',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '8',
      label: 'Contacts',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '9',
      label: 'Notes',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '10',
      label: 'Settings',
      children: <Settings />,
    },
  ]

  return (
    <div className="facility-profile-container relative flex flex-col items-start gap-6 aline-self">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} rootClassName="facility-profile-tab flex p-0 w-full" />
      <div className="flex items-center gap-6 profile-tab-btn">
        {activeTab !== '10' ? (
          <>
            <CustomBtn isIcon svgIcon={<ChatIcon className="svg" />} text={t('button.chats')} type="default" className="user-Icon" />
            <div className="action-cell flex items-center justify-center three-dot-icon">
              <ThreeDotIcon className="cursor-pointer " />
            </div>
          </>
        ) : (
          <CustomBtn text={t('button.saveSettings')} type="primary" className="user-Icon w-160" />
        )}
      </div>
    </div>
  )
}

export default FacilityProfile
