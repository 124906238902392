import { useState } from 'react'
import { Checkbox, Dropdown, type MenuProps } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { settingTab } from 'constants/Constant'
import { t } from 'i18next'
import AddFloor from 'pages/facilities/Modal/AddFloor'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import CustomTable from 'components/common/Table/CustomTable'

const tableData = [
  {
    id: 1,
    floorName: 'C-HALL',
    billToClient: '-',
    phone: '-',
    costCenter: '-',
    defaultOrder: '-',
    poNumber: '-',
    speciality: 'LTC',
  },
]

const Floors = () => {
  const [isAddFloor, setIsAddFloor] = useState(false)
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(tableData?.length).fill(false))
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleAddFloorClick = () => {
    setIsAddFloor(true)
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.evaluation.floorName'),
      dataIndex: 'floorName',
      key: 'floorName',
      align: 'center',
      width: 180,
      render(value, record) {
        return <p className="text-neutral-800 para-p2 font-normal">{record.floorName}</p>
      },
    },
    {
      title: t('table.evaluation.billToClient'),
      dataIndex: 'billToClient',
      key: 'billToClient',
      align: 'start',
      width: 194,
    },
    {
      title: t('table.evaluation.phone'),
      dataIndex: 'phone',
      key: 'phone',
      align: 'start',
      width: 139,
    },
    {
      title: t('table.evaluation.costCenter'),
      dataIndex: 'costCenter',
      key: 'costCenter',
      align: 'start',
      width: 140,
    },
    {
      title: t('table.evaluation.defaultOrder'),
      dataIndex: 'defaultOrder',
      key: 'defaultOrder',
      align: 'start',
      width: 204,
    },
    {
      title: t('table.evaluation.poNumber'),
      dataIndex: 'poNumber',
      key: 'poNumber',
      align: 'start',
      width: 106,
    },
    {
      title: t('table.evaluation.speciality'),
      dataIndex: 'speciality',
      key: 'speciality',
      align: 'start',
      width: 107,
    },
    {
      title: t('table.evaluation.action'),
      dataIndex: '',
      key: 'floorName',
      align: 'center',
      width: 74,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className="floors-container flex flex-col items-start gap-6 w-full">
      <div className="flex items-start aline-self rounded-4 border-stroke ">
        <div className=" border-r-stroke max-w-400 flex p-4 justify-start items-center gap-2 aline-self">
          <p className="text-neutral-800 activity-text font-medium">{settingTab.CLIENT_SPECIAL_FLOOR}</p>
        </div>
        <div className="flex p-4 justify-start items-center gap-2 aline-self ">
          <div className="flex items-start gap-2 align-self">
            <Checkbox />
            <div className="flex flex-col items-start gap-2">
              <p className="text-neutral-800 activity-text font-semi-bold">{settingTab.ASSOCIATE_WITH_SPEC}</p>
              <p className="text-neutral-600 para-p3 font-normal">{settingTab.ASSOCIATE_WITH_SPEC_SUB}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end gap-4 aline-self">
        <CustomBtn type="default" text={t('button.addFloor')} isIcon svgIcon={<PlusIcon className="svg" />} className="plus-icon" onClick={handleAddFloorClick} />
        <CustomTable
          onChangePage={handlePageChange}
          column={columns}
          data={tableData}
          total={paginationInfo.total}
          limit={paginationInfo.limit}
          current={paginationInfo.current}
          name={t('label.evaluations')}
        />
      </div>
      {isAddFloor && (
        <CustomModal
          open={isAddFloor}
          centered
          footer={null}
          onCancel={() => {
            setIsAddFloor(false)
          }}
          closable={false}
          title={t('label.addFloor')}
          classStrings="add-certificate-modal"
          content={<AddFloor isModalOpen={setIsAddFloor} />}
          width={915}
        />
      )}
    </div>
  )
}

export default Floors
