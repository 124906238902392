const MultipleChoiceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <rect x="0.5" width="24" height="24" rx="12" fill="#494A4C" />
      <circle cx="12.5" cy="12" r="6" fill="white" />
    </svg>
  )
}

export default MultipleChoiceIcon
