import { useState } from 'react'
import { Button, Checkbox, Form, Select, Switch } from 'antd'
import { DropDownIcon, MinusIcon, PlusIcon } from 'assets/svgs'
import { allowedEntryType, settingTab } from 'constants/Constant'
import { commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'

import CommonTextArea from 'components/common/TextArea/CommonTextArea'

const customData = [
  {
    label: 'Custom settings',
    value: 'Custom settings',
  },
]
const TimeAndApproval = () => {
  const [counter, setCounter] = useState(4)
  const [radius, setRadius] = useState(10)

  const handleMinusClick = () => {
    setCounter(counter > 0 ? counter - 1 : 0)
  }

  const handlePlusClick = () => {
    setCounter(counter + 1)
  }
  const handleRadiusMinusClick = () => {
    setRadius(radius > 0 ? radius - 1 : 0)
  }

  const handleRadiusPlusClick = () => {
    setRadius(radius + 1)
  }
  return (
    <Form className="time-and-approval-container flex flex-col items-start flex-grow rounded-4 border-stroke  ">
      <div className=" flex items-start aline-self flex-grow border-b-stroke w-full">
        <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30">
          <p className="text-neutral-800 activity-text font-medium">{settingTab.INVOICE_SHOULD_BE_PAID}</p>
        </div>
        <div className="flex flex-col  w-70">
          <div className="flex border-b-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow aline-self justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.MINUTE_ROUNDING}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex flex-col items-center justify-start w-ful">
                <div className="flex gap-4 items-center h-60 w-full">
                  <div className="flex flex-col w-full">
                    <div className={`cursor-pointer w-full `}>
                      <Form.Item name="user_system_increment" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                        <Select suffixIcon={<DropDownIcon />} placeholder={t('placeholder.useSystemSettings')} options={customData} className="setting-custom-dropDown" />
                      </Form.Item>
                    </div>
                    {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                  </div>
                  <p className="text-neutral-800 para-p3 font-small">{settingTab.INCREMENT}</p>
                </div>
                <div className="flex gap-4 items-center justify-start h-60 w-full">
                  <div className="flex flex-col w-full">
                    <div className={`cursor-pointer w-full `}>
                      <Form.Item name="userSystem_direction" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                        <Select suffixIcon={<DropDownIcon />} placeholder={t('placeholder.useSystemSettings')} options={customData} className="setting-custom-dropDown" />
                      </Form.Item>
                    </div>
                    {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                  </div>
                  <p className="text-neutral-800 para-p3 font-small">{settingTab.DIRECTION}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex ">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.MINUTE_ROUNDING}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start ">
              <div className="counter-box">
                <Button icon={<MinusIcon />} onClick={handleMinusClick} className="counter-btn-min" />
                <div className="counter-text-wrapper">
                  <p className="counter-text">{counter}</p>
                </div>
                <Button icon={<PlusIcon className="plus-icon-solid " />} onClick={handlePlusClick} className="counter-btn-plus" />
              </div>
              <p className="text-neutral-800 para-p3 font-small">{settingTab.DAYS}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start aline-self flex-grow border-b-stroke w-full ">
        <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30 ">
          <p className="text-neutral-800 activity-text font-medium">{settingTab.WORKFORCE_PORTAL}</p>
        </div>
        <div className="flex flex-col  w-70">
          <div className="flex border-b-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow aline-self justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.CLIENT_CLOCKING_DATA}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex gap-4 items-center h-60">
                <div className="flex flex-col w-full">
                  <div className={`cursor-pointer w-full `}>
                    <Form.Item name="work_force_portal" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                      <Select suffixIcon={<DropDownIcon />} placeholder={t('placeholder.workForcePortal')} options={customData} className="setting-custom-dropDown" />
                    </Form.Item>
                  </div>
                  {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                </div>
                <p className="text-neutral-800 para-p3 font-small">{settingTab.SOURCE}</p>
              </div>
            </div>
          </div>
          <div className="flex border-b-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.TIME_APPROVAL}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex gap-4 items-center h-60">
                <div className="flex flex-col w-full">
                  <div className={`cursor-pointer w-full `}>
                    <Form.Item name="e-signature" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                      <Select suffixIcon={<DropDownIcon />} placeholder={t('placeholder.esignature')} options={customData} className="setting-custom-dropDown" />
                    </Form.Item>
                  </div>
                  {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex border-b-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.ALLOWED_ENTRY_TYPE}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex flex-col item-start gap-2">
                {allowedEntryType.map((data) => {
                  return (
                    <div className="flex items-start justify-start gap-2" key={data.name}>
                      <Checkbox checked={data.checked} />
                      <p className="text-neutral-700 para-p3 font-normal">{data.name}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.CHECKED_MISSED_MEAL_FOR}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <Switch checked={true} />
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.CLOCK_IN}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <Switch checked={true} />
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.GEOFENCE_TIME_APPROVAL}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <Switch checked={false} />
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.DEFAULT_RADIUS}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="counter-box">
                <Button icon={<MinusIcon />} onClick={handleRadiusMinusClick} className="counter-btn-min" />
                <div className="counter-text-wrapper">
                  <p className="counter-text">{radius}</p>
                </div>
                <Button icon={<PlusIcon className="plus-icon-solid " />} onClick={handleRadiusPlusClick} className="counter-btn-plus" />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-normal">{settingTab.MAP_LOCATION}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <CommonTextArea name="location" placeholder={t('placeholder.clientLocation')} rows={2} wrapperClass="m-0" />
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default TimeAndApproval
