import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, message, Switch } from 'antd'
import { ModalClose } from 'assets/svgs'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFacilityVerificationColumn, IFieldData, IModalFacilityVerifi } from 'types'
import { handleFieldsChange, removeSorting } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { addFacilityRejectReason, updateFacilityRejectReason } from '../api'

const AddFacilityVerifi = ({ setIsModalOpen, editId, data, setSortTable }: IModalFacilityVerifi) => {
  const [error, setError] = useState({
    reason: '',
  })
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    if (data && editId) {
      const checked = data.status === 'active'
      setStatusCheck(checked)
      form.setFieldsValue(data)
    }
  }, [data, editId, form])

  const onFinish = (values: IFacilityVerificationColumn) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const updatedValues = { ...values, status: statusValue }
    if (editId && data) {
      void handleEditFacilityVerifi(editId, updatedValues)
    } else {
      void handleAddFacilityVerifi(updatedValues)
    }
  }

  const handleAddFacilityVerifi = async (values: IFacilityVerificationColumn) => {
    dispatch(setGlobalLoader(true))
    const response = await addFacilityRejectReason(values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      setSortTable && removeSorting(setSortTable)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditFacilityVerifi = async (id: string, values: IFacilityVerificationColumn) => {
    dispatch(setGlobalLoader(true))
    const response = await updateFacilityRejectReason(id, values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      setSortTable && removeSorting(setSortTable)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (checked: boolean) => {
    setStatusCheck(checked)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        {editId && data ? (
          <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={t('label.editProviderVerifyReason')} />
        ) : (
          <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={t('label.createNewVerifyReason')} />
        )}
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        className="provider-descri"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <InputField
          error={error.reason}
          placeholder={t('placeholder.reason')}
          rules={commonInputFieldValidation(VALIDATION_STRINGS.REASON)}
          name="reason"
          value={getValue('reason')}
        />
        <CommonTextArea name="description" placeholder={t('placeholder.desc')} rows={4} value={getValue('description')} />
        <div className="mt-6 flex items-center justify-between hide-label">
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.active')}</span> <Switch checked={statusCheck} onChange={onChange} />
          </div>
          <CustomBtn text={editId ? t('button.updateBtn') : t('button.add')} htmlType="submit" type="primary" className="w-120" />
        </div>
      </Form>
    </div>
  )
}

export default AddFacilityVerifi
