import { Popover } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, ThreeDotIcon } from 'assets/svgs'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { t } from 'i18next'
import type { IPaginationInfo } from 'types'
import { formatTitle } from 'utils/Helper'

import HeadingText from 'components/common/HeadingText'

export interface IColumns {
  actionMenuOpen: any
  setActionMenuOpen: any
  paginationInfo: IPaginationInfo
  setPaginationInfo: React.Dispatch<React.SetStateAction<IPaginationInfo>>
}

export const useColumn = ({ actionMenuOpen, setActionMenuOpen, setPaginationInfo, paginationInfo }: IColumns) => {
  const getMenu = (record: any, index: number) => {
    return (
      <div className="dropDown-action w-full">
        <div className="p-4 cursor-pointer w-full">
          <p className="flex items-center gap-2">
            <EditPenIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{t('button.edit')}</span>
          </p>
        </div>
        <div className="p-4 cursor-pointer">
          <p className="flex items-center gap-2">
            <DeleteIcon className="red-flag" />
            <span className="mobile-heading-h6 font-normal text-error-500">{t('button.delete')}</span>
          </p>
        </div>
      </div>
    )
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen: any) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const dnrColumns: ColumnsType = [
    {
      title: t('table.dnrReason.reason'),
      dataIndex: 'reason',
      key: 'reason',
      align: 'start',
      width: 460,
      render(value, record) {
        return <HeadingText classString="text-neutral-800 para-p1 font-medium" text={record.reason} />
      },
    },
    {
      title: t('table.dnrReason.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      width: 460,
    },
    {
      title: t('table.dnrReason.reasonType'),
      dataIndex: 'reasonType',
      key: 'reasonType',
      align: 'center',
      width: 230,
    },
    {
      title: t('table.dnrReason.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 230,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.dnrReason.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <div className="action-cell flex items-center justify-center three-dot-icon">
              <Popover
                content={getMenu(record, _value.id)}
                placement="bottomRight"
                open={actionMenuOpen[_value.id]}
                onOpenChange={(open) => {
                  handleOpenChange(_value.id, open)
                }}
              >
                <ThreeDotIcon className="cursor-pointer icon" />
              </Popover>
            </div>
          </div>
        )
      },
    },
  ]

  const flagColumns: ColumnsType = [
    {
      title: t('table.providerStatus.statusName'),
      dataIndex: 'statusName',
      key: 'statusName',
      align: 'start',
      width: 345,
      render(value, record) {
        return <HeadingText classString="text-neutral-800 para-p1 font-medium" text={record.statusName} />
      },
    },
    {
      title: t('table.providerStatus.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      width: 345,
    },
    {
      title: t('table.providerStatus.flagColor'),
      dataIndex: 'flagColor',
      key: 'flagColor',
      align: 'center',
      width: 345,
    },
    {
      title: t('table.providerStatus.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 345,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.providerStatus.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <div className="action-cell flex items-center justify-center three-dot-icon">
              <Popover
                content={getMenu(record, _value.id)}
                placement="bottomRight"
                open={actionMenuOpen[_value.id]}
                onOpenChange={(open) => {
                  handleOpenChange(_value.id, open)
                }}
              >
                <ThreeDotIcon className="cursor-pointer icon" />
              </Popover>
            </div>
          </div>
        )
      },
    },
  ]

  const providerColumns: ColumnsType = [
    {
      title: t('table.providerStatus.statusName'),
      dataIndex: 'statusName',
      key: 'statusName',
      align: 'start',
      width: 345,
      render(value, record) {
        return <HeadingText classString="text-neutral-800 para-p1 font-medium" text={record.statusName} />
      },
    },
    {
      title: t('table.providerStatus.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      width: 345,
    },
    {
      title: t('table.providerStatus.badgeColor'),
      dataIndex: 'badgeColor',
      key: 'badgeColor',
      align: 'center',
      width: 345,
      render(value, record) {
        return (
          <HeadingText
            classString={`provider-status-wrapper ${record.color === 'success' ? 'green-badge' : record.color === 'neutral' ? 'neutral-badge' : record.color === 'warning' ? 'orange-badge' : 'red-badge'}`}
            text={record.badgeColor}
          />
        )
      },
    },
    {
      title: t('table.providerStatus.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 345,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.providerStatus.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <div className="action-cell flex items-center justify-center three-dot-icon">
              <Popover
                content={getMenu(record, _value.id)}
                placement="bottomRight"
                open={actionMenuOpen[_value.id]}
                onOpenChange={(open) => {
                  handleOpenChange(_value.id, open)
                }}
              >
                <ThreeDotIcon className="cursor-pointer icon" />
              </Popover>
            </div>
          </div>
        )
      },
    },
  ]
  return { dnrColumns, flagColumns, providerColumns, handlePageChange }
}
