import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Form, message } from 'antd'
import { LogoIcon } from 'assets/svgs'
import { UsaIcon } from 'assets/svgs/index'
import { country } from 'constants/Country'
import { passwordValidations } from 'constants/Validation'
import { useAppDispatch } from 'hooks/reduxHooks'
import { getProfileAdminUser, updateProfileAdminUser } from 'services/StaticTokenServices'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import InputField from 'components/common/InputField/InputField'
import PasswordField from 'components/common/PasswordField/PasswordField'

const usa = country.find((item) => item.code === 'US')

const AdminUserRegister = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCountryCode, setSelectedCountryCode] = useState(country[1])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [formData, setFormData] = useState<any>()
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    user_name: '',
    email: '',
    mobile_no: '',
    password: '',
  })
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') ?? ''
  const [form] = Form.useForm()

  useEffect(() => {
    void getProfileData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldsValue(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formData])

  const onFinish = (value: any) => {
    const payload = {
      first_name: formData?.first_name,
      user_name: `${new Date().getTime()}`,
      email: formData?.email,
      last_name: formData?.last_name,
      country_code: usa?.dial_code,
      mobile_no: formData?.mobile_no,
      password: value?.password,
    }
    void updateProfileData(payload)
  }

  const getProfileData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getProfileAdminUser(id)
    console.log(response)
    if (response?.data?.statusCode === 1) {
      setFormData(response?.data?.data)
    } else {
      navigate('/link-expiry')
    }
    dispatch(setGlobalLoader(false))
  }

  const updateProfileData = async (data: any) => {
    dispatch(setGlobalLoader(true))
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    const response: any = await updateProfileAdminUser(data, id)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/facility-user-login')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <div className="bg-neutral-50">
      <div className="bg-neutral-50 mx-auto max-w-400 w-full min-h-screen py-10 flex flex-col gap-10 justify-center items-center">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <div className="flex flex-col items-center gap-1">
            <p className="mobile-heading-h2 font-medium">
              {t('common.welcome2')} {formData?.first_name}!
            </p>
            <p className="text-neutral-500 text-center para-p2">{t('common.signupSub')}</p>
          </div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          layout="vertical"
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
        >
          <div>
            <div className="w-full">
              <div className={`flex edit-name gap-6 h-64 mb-6`}>
                <div className="flex ">
                  <InputField
                    placeholder={t('label.profileForm.fname')}
                    // rules={nameValidations}
                    // value={formData?.first_name}
                    disabled
                    name="first_name"
                  />
                </div>
                <div className="flex ">
                  <InputField
                    placeholder={t('label.profileForm.lname')}
                    // value={formData?.last_name}
                    // rules={nameValidations}
                    disabled
                    name="last_name"
                  />
                </div>
              </div>

              {(error.last_name || error.first_name) && (
                <div className="flex gap-6 edit-name">
                  <div className="flex items-center w-50">
                    <p className="error-container mb-6">{error.first_name}</p>
                  </div>
                  <div className="flex items-center w-50">
                    <p className="error-container mb-6">{error.last_name}</p>
                  </div>
                </div>
              )}
            </div>

            <InputField disabled name="email" placeholder={t('placeholder.email')} error={error.email} />

            <div className="flex gap-6">
              <div className="country-code-wrapper w-120 h-64">
                <div className="country-flag">
                  <UsaIcon />
                </div>
                <p className="para-p2 font-normal text-neutral-800 ">{usa?.dial_code}</p>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex flex-col flex-grow-1">
                  <InputField name="mobile_no" error={error.mobile_no} wrapperClass="w-full flex-grow-1" disabled placeholder={t('placeholder.mobileNumber')} />
                </div>
              </div>
            </div>

            <PasswordField name="password" rules={passwordValidations} placeholder={t('label.password')} inputClass={' password-input'} error={error.password} />

            <Form.Item className="mt-6">
              <Button type="primary" htmlType="submit" className="common-button">
                {t('button.login')}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AdminUserRegister
