import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message, Tabs, type TabsProps } from 'antd'
import { ChatIcon, ThreeDotIcon } from 'assets/svgs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFacilityPortalSetting, type IShiftTime, type ITimeEntrySetting, type updateSettingApiData } from 'types'

import CustomBtn from 'components/common/CustomBtn'

import { getAllfacilitySettingShiftTimes, getFacilitySettings, updateGeneralSettings } from '../api'

import Detail from './components/Detail'
import FacilityContacts from './components/facilityContact/FacilityContacts'
import Providers from './components/Provider'
import Settings from './components/settings/Settings'
import Shift from './components/shift/Shift'

const FacilityProfile = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { id } = location?.state ?? ''
  const [activeTab, setActiveTab] = useState<string>('1')
  const [settingTab, setSettingTab] = useState<string>('')
  const [settingData, setSettingData] = useState<updateSettingApiData>()
  const [timeEntrySetting, setTimeEntrySetting] = useState<ITimeEntrySetting>({
    timecard_rounding: Number(''),
    timecard_rounding_direction: 'standard',
    default_lunch_duration: Number(''),
    clocking_source: 'workforce',
    time_approval_method: 'esignature',
    allowed_entries: [],
    check_missed_meal_break: false,
    enforce_geo_fence: false,
    geo_fence_for_approval: false,
    geo_fence_radius: Number(''),
    location: '',
  })
  const [clientSelected, setClientSelected] = useState<IShiftTime[]>([])
  const [systemOptionData, setSystemOptionData] = useState<IShiftTime[]>([])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [defaultOrderType, setDefaultOrderType] = useState<string>('')
  const [workCompCode, setWorkCompCode] = useState<string>('')
  const [facilityPortalSetting, setFacilityPortalSetting] = useState<IFacilityPortalSetting>({
    allow_cancellation: false,
    cancellation_advance: 10,
    display_provider_request: false,
    accept_provider_request: false,
    scheduling_warnings: [],
    client_confirmation: false,
    display_bill_rate: false,
  })
  const [invoiceCancellationDays, setInvoiceCancellationDays] = useState<number>(0)
  const selectedFacilityName = useAppSelector((state) => state?.facilities?.selectedFacility?.name) ?? localStorage?.getItem('facilityName')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: selectedFacilityName ?? '',
        isBack: true,
      })
    )
    // eslint-disable-next-line
  }, [dispatch])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Details',
      children: <Detail />,
    },
    {
      key: '2',
      label: 'Schedule',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Shifts',
      children: <Shift />,
    },
    {
      key: '4',
      label: 'Providers',
      children: <Providers />,
    },
    {
      key: '5',
      label: 'Docs',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '6',
      label: 'Evaluations',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '7',
      label: 'Accounting',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '8',
      label: 'Contacts',
      children: <FacilityContacts />,
    },
    {
      key: '9',
      label: 'Notes',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '10',
      label: 'Settings',
      children: (
        <Settings
          setSettingTab={setSettingTab}
          facilityId={id}
          settingData={settingData}
          timeEntrySetting={timeEntrySetting}
          setTimeEntrySetting={setTimeEntrySetting}
          clientSelected={clientSelected}
          setClientSelected={setClientSelected}
          systemOptionData={systemOptionData}
          setSystemOptionData={setSystemOptionData}
          setDefaultOrderType={setDefaultOrderType}
          workCompCode={workCompCode}
          setWorkCompCode={setWorkCompCode}
          facilityPortalSetting={facilityPortalSetting}
          setFacilityPortalSetting={setFacilityPortalSetting}
          setInvoiceCancellationDays={setInvoiceCancellationDays}
        />
      ),
    },
  ]

  useEffect(() => {
    if (settingData) {
      setTimeEntrySetting({
        timecard_rounding: settingData?.time_entry_setting?.timecard_rounding,
        timecard_rounding_direction: settingData?.time_entry_setting?.timecard_rounding_direction,
        default_lunch_duration: settingData?.time_entry_setting?.default_lunch_duration,
        clocking_source: settingData?.time_entry_setting?.clocking_source,
        time_approval_method: settingData?.time_entry_setting?.time_approval_method,
        allowed_entries: settingData?.time_entry_setting?.allowed_entries ?? [],
        check_missed_meal_break: settingData?.time_entry_setting?.check_missed_meal_break,
        enforce_geo_fence: settingData?.time_entry_setting?.enforce_geo_fence,
        geo_fence_for_approval: settingData?.time_entry_setting?.geo_fence_for_approval,
        geo_fence_radius: settingData?.time_entry_setting?.geo_fence_radius,
        location: settingData?.time_entry_setting?.location,
      })
      setFacilityPortalSetting({
        allow_cancellation: settingData?.facility_portal_setting?.allow_cancellation,
        cancellation_advance: settingData?.facility_portal_setting?.cancellation_advance,
        display_provider_request: settingData?.facility_portal_setting?.display_provider_request,
        accept_provider_request: settingData?.facility_portal_setting?.accept_provider_request,
        scheduling_warnings: settingData?.facility_portal_setting?.scheduling_warnings ?? [],
        client_confirmation: settingData?.facility_portal_setting?.client_confirmation,
        display_bill_rate: settingData?.facility_portal_setting?.display_bill_rate,
      })
      setWorkCompCode(settingData?.work_comp_code)
    }
  }, [settingData])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: 'Rocky Mtn. Care of Heber',
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void getfacilitySettings(id)
  }, [])

  useEffect(() => {
    setSelectedIds(clientSelected.map((item) => item?.id))
  }, [clientSelected])

  useEffect(() => {
    void getAllShiftTimes()
  }, [settingData])

  const onChange = (key: string) => {
    setActiveTab(key)
  }

  const getAllShiftTimes = async () => {
    try {
      const response = await getAllfacilitySettingShiftTimes()
      if (response?.data?.statusCode === 1) {
        const shiftTimes = response?.data?.data
        const selectedShiftIds = settingData?.shift_setting
        const filteredShiftTimes = shiftTimes.filter((shift: any) => !selectedShiftIds?.includes(shift?.id))
        const formattedData = filteredShiftTimes.map((shift: any) => ({
          id: shift.id,
          time: shift.name,
        }))
        setSystemOptionData(formattedData)
        const selectedShifts = shiftTimes.filter((shift: any) => selectedShiftIds?.includes(shift.id))
        setClientSelected(
          selectedShifts.map((shift: any) => ({
            id: shift.id,
            time: shift.name,
          }))
        )
      }
    } catch (error) {
      console.error('Error fetching shift times:', error)
    }
  }

  const getfacilitySettings = async (id: number) => {
    const response = await getFacilitySettings(id)
    if (response?.data?.statusCode === 1) {
      setSettingData(response?.data?.data)
    }
  }

  const handleSaveSettting = async () => {
    const updateSettingData = {
      default_order_type: defaultOrderType,
      work_comp_code: workCompCode,
      shift_setting: selectedIds,
      time_entry_setting: timeEntrySetting,
      facility_portal_setting: facilityPortalSetting,
      invoice_cancellation_days: invoiceCancellationDays,
    }
    dispatch(setGlobalLoader(true))
    const response = await updateGeneralSettings(id, updateSettingData)
    if (response?.status === 200) {
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <div className="facility-profile-container relative flex flex-col items-start aline-self items-center">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} rootClassName="facility-profile-tab flex p-0 w-full" />
      <div className="flex items-center gap-6 profile-tab-btn">
        {activeTab !== '10' ? (
          <>
            <CustomBtn isIcon svgIcon={<ChatIcon className="svg" />} text={t('button.chats')} type="default" className="user-Icon" />
            <div className="action-cell flex items-center justify-center three-dot-icon">
              <ThreeDotIcon className="cursor-pointer " />
            </div>
          </>
        ) : (
          settingTab !== '2' && (
            <CustomBtn
              text={t('button.saveSettings')}
              onClick={() => {
                void handleSaveSettting()
              }}
              type="primary"
              className="user-Icon w-160"
            />
          )
        )}
      </div>
    </div>
  )
}

export default FacilityProfile
