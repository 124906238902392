import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Popover } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, DropDownIcon, EditPenIcon, Filter, PlusIcon } from 'assets/svgs'
import { credientialData, credientialRequirement, DELETE_MODAL_COMPLIANCE_MANAGER_TEXT, filterOption, filterSubMenu } from 'constants/Constant'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle } from 'store/slice/CommonSlice'
import { type ICredentialData, type ICredentialRequirmentTableData, type ICredentialTableData, type IFilterOption } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddDocument from './modal/AddDocument'
import AddNewCategory from './modal/AddNewCategory'
import AddRequirement from './modal/AddRequirement'

import '../../style/pages/ComplianceManager.scss'

const ComplianceManager = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [activeTab, setActiveTab] = useState('credentialsManager')
  const [visibleTables, setVisibleTables] = useState<boolean[]>(credientialData.map(() => false))
  const [visibleRequirementTables, setVisibleRequirementTables] = useState<boolean[]>(credientialRequirement.map(() => false))
  const [categoryModalOpen, setCategoryModalOpen] = useState<boolean>(false)
  const [categoryEditModalOpen, setCategoryEditModalOpen] = useState<number | null>(null)
  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false)
  const [editDocumentId, setEditDocumentId] = useState<number | string>('')
  const [editDocumentData, setEditDocumentData] = useState<ICredentialTableData>()
  const [requirementModalOpen, setRequirementModalOpen] = useState<boolean>(false)
  const [filterOptionOpen, setFilterOptionOpen] = useState<boolean>(false)
  const [selectedFilters, setSelectedFilters] = useState('')
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<Record<string, string[]>>({})
  const [editRequirementId, setEditRequirementId] = useState('')
  const [editRequirementData, setEditRequirementData] = useState<ICredentialRequirmentTableData | undefined>()
  const [selectedOptions, setSelectedOptions] = useState<Record<string, string[]>>({})
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string | number>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [isDeleteDocOrCategory, setIsDeleteDocOrCategory] = useState<string>('')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.complianceManager'),
        isBack: true,
      })
    )
  }, [dispatch, t])

  useEffect(() => {
    console.log('selectedOptions', selectedOptions)
  }, [selectedOptions])

  const handleTabClick = (tab: string) => {
    if (filterOptionOpen) setFilterOptionOpen(false)
    setActiveTab(tab)
  }

  const handleToggle = (index: number) => {
    setVisibleTables((prevState) => {
      const newState = new Array(prevState.length).fill(false)
      newState[index] = !prevState[index] ? true : !prevState[index]
      return newState
    })
  }

  const handleToggleRequirement = (index: number) => {
    setVisibleRequirementTables((prevState) => {
      const newState = new Array(prevState.length).fill(false)
      newState[index] = !prevState[index] ? true : !prevState[index]
      return newState
    })
  }

  const handleCategoryPopoverToggle = (index: number) => {
    setCategoryEditModalOpen((prevState) => (prevState === index ? null : index))
  }

  const handleCheckboxChange = (filterName: string, option: string) => {
    setSelectedCheckboxes((prevSelected) => {
      const currentSelections = prevSelected[filterName] || []
      const newSelections = currentSelections.includes(option) ? currentSelections.filter((item) => item !== option) : [...currentSelections, option]

      console.log('Selected Option:', option)
      console.log('Current Selections:', newSelections)

      return {
        ...prevSelected,
        [filterName]: newSelections,
      }
    })
  }

  const handleOptionSelect = (filterName: string, option: string) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [filterName]: [option],
    }))
  }

  const handleDocumentPopoverToggle = (index?: number | string, data?: ICredentialTableData) => {
    if (documentModalOpen) {
      setDocumentModalOpen(false)
    } else {
      setDocumentModalOpen(true)
    }
    if (index && data) {
      setEditDocumentId(index)
      setEditDocumentData(data)
    }
  }

  const handleDeleteCategory = (categoryId: string | number) => {
    const updatedQuestions = [...credientialData]
    const categoryIndex = updatedQuestions.findIndex((category) => category.id === categoryId)

    if (categoryIndex !== -1) {
      updatedQuestions.splice(categoryIndex, 1)
      setIsDeleteModalOpen(false)
    }
  }

  const handleDeleteDocument = (categoryId: string | number) => {
    setIsDeleteModalOpen(false)
  }

  const handleDeleteRequirement = (requirementId: string | number) => {
    setIsDeleteModalOpen(false)
  }

  const handleDeleteRequirementCategory = (requirementId: number | string) => {
    setIsDeleteModalOpen(false)
  }

  const columns: ColumnsType<ICredentialTableData> = [
    {
      title: t('table.credentialsManager.credential'),
      dataIndex: 'credential',
      key: 'credential',
      align: 'start',
      width: 485,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 font-normal activity-text" text={record?.credential} />
      },
    },
    {
      title: t('table.credentialsManager.certification'),
      dataIndex: 'certification',
      key: 'certification',
      align: 'center',
      width: 380,
    },
    {
      title: t('table.credentialsManager.essesential'),
      dataIndex: 'essesential',
      key: 'essesential',
      align: 'center',
      width: 380,
    },
    {
      title: t('table.credentialsManager.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 163,
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <CustomBtn
              isIcon
              svgIcon={<DeleteIcon className="svg" />}
              text=""
              className="custom-action-button delete-icon"
              onClick={() => {
                if (typeof record.id === 'string') {
                  setIsDeleteDocOrCategory('document')
                  setIsDeleteModalOpen(true)
                  setDeleteId(record.id)
                  setDeleteName(record.name)
                }
              }}
            />
            <CustomBtn
              isIcon
              svgIcon={<EditPenIcon className="svg" />}
              text=""
              className="custom-action-button user-Icon"
              onClick={() => {
                if (record?.id) handleDocumentPopoverToggle(record?.id, record)
              }}
            />
          </div>
        )
      },
    },
  ]

  const requirementColumns: ColumnsType<ICredentialRequirmentTableData> = [
    {
      title: t('table.credentialsRequirement.certification'),
      dataIndex: 'certification',
      key: 'certification',
      align: 'start',
      width: 219,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 font-normal activity-text" text={record?.certification} />
      },
    },
    {
      title: t('table.credentialsRequirement.credential'),
      dataIndex: 'credential',
      key: 'credential',
      align: 'start',
      width: 222,
    },
    {
      title: t('table.credentialsRequirement.state'),
      dataIndex: 'state',
      key: 'state',
      align: 'start',
      width: 74,
    },
    {
      title: t('table.credentialsRequirement.employement'),
      dataIndex: 'employement',
      key: 'employement',
      align: 'start',
      width: 199,
      render: (_value, record) => {
        return (
          <div className="flex flex-col gap-2">
            <HeadingText classString="text-neutral-800 font-small para-p3" text={String(record.employement)} />
            <div>
              <HeadingText classString="text-neutral-800 font-medium para-p3" text={`${t('heading.employmentType')}: `} />
              <HeadingText classString="text-neutral-800 font-small para-p3" text={record.employmentType} />
            </div>
            <HeadingText classString="text-neutral-800 font-medium para-p3" text={t('heading.validateUpon')} />
            <div className="flex justify-between">
              <HeadingText classString="text-neutral-800 font-small para-p3" text={t('heading.submission')} />
              <HeadingText classString="text-neutral-800 font-small para-p3" text={String(record.submission)} />
            </div>
            <div className="flex justify-between">
              <HeadingText classString="text-neutral-800 font-small para-p3" text={t('heading.pdOrder')} />
              <HeadingText classString="text-neutral-800 font-small para-p3" text={String(record.pdOrder)} />
            </div>
            <div className="flex justify-between">
              <HeadingText classString="text-neutral-800 font-small para-p3" text={t('heading.ltOrder')} />
              <HeadingText classString="text-neutral-800 font-small para-p3" text={String(record.ltOrder)} />
            </div>
          </div>
        )
      },
    },
    {
      title: t('table.credentialsRequirement.autoAssign'),
      dataIndex: 'autoAssign',
      key: 'autoAssign',
      align: 'center',
      width: 199,
    },
    {
      title: t('table.credentialsRequirement.workForcePortal'),
      dataIndex: 'workForcePortal',
      key: 'workForcePortal',
      align: 'start',
      width: 199,
      render: (_value, record) => {
        return (
          <div className="flex gap-1">
            <HeadingText classString="text-neutral-800 font-medium para-p3" text={`${t('heading.enforcement')}:`} />
            <HeadingText classString="text-neutral-800 para-p3" text={record.workForcePortal} />
          </div>
        )
      },
    },
    {
      title: t('table.credentialsRequirement.create'),
      dataIndex: 'create',
      key: 'create',
      align: 'start',
      width: 199,
      render: (_value, record) => {
        const updateformattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY, hh:mm A')
        const createformattedDate = dayjs(record?.created_at).format('MM/DD/YYYY, hh:mm A')
        return (
          <div className="flex flex-col gap-1rem">
            <div>
              <HeadingText classString="text-neutral-800 para-p3" text={`Created on ${createformattedDate} by angelacouch`} />
            </div>
            <div>
              <HeadingText classString="text-neutral-800 para-p3" text={`Modified on ${updateformattedDate} by davidbice`} />
            </div>
          </div>
        )
      },
    },
    {
      title: t('table.credentialsRequirement.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 100,
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <CustomBtn
              isIcon
              svgIcon={<DeleteIcon className="svg" />}
              text=""
              className="custom-action-button delete-icon"
              onClick={() => {
                if (typeof record.id === 'string') {
                  setIsDeleteDocOrCategory('requirement')
                  setIsDeleteModalOpen(true)
                  setDeleteId(record.id)
                  setDeleteName(record.certification)
                }
              }}
            />
            <CustomBtn
              isIcon
              svgIcon={<EditPenIcon className="svg" />}
              text=""
              className="custom-action-button user-Icon"
              onClick={() => {
                if (record?.id) {
                  setEditRequirementId(record.id)
                  setRequirementModalOpen(true)
                  setEditRequirementData(record)
                }
              }}
            />
          </div>
        )
      },
    },
  ]

  return (
    <div className="compliance-container flex flex-col justify-start shadow p-10 gap-1rem">
      {/* tab with button */}
      <div className="flex justify-between w-full mobile-tab">
        <div className="flex gap-2 items-center mobile-tab-text">
          <div
            className={`${activeTab === 'credentialsManager' ? 'border-b-stroke-primary' : ''} p-2_5`}
            onClick={() => {
              handleTabClick('credentialsManager')
            }}
          >
            <span className={`${activeTab === 'credentialsManager' ? 'text-primary-500' : ' text-neutral-600'} para-p2 font-medium`}>{t('heading.credentialsManager')}</span>
          </div>
          <div
            className={`${activeTab === 'credentialsRequirement' ? 'border-b-stroke-primary' : ''} p-2_5`}
            onClick={() => {
              handleTabClick('credentialsRequirement')
            }}
          >
            <span className={`${activeTab === 'credentialsRequirement' ? 'text-primary-500' : ' text-neutral-600'} para-p2 font-medium`}>{t('heading.credentialRequirement')}</span>
          </div>
        </div>
        {activeTab === 'credentialsManager' && (
          <Popover
            trigger={['click']}
            content={<AddNewCategory setActionMenu={setCategoryModalOpen} />}
            placement="bottomRight"
            open={categoryModalOpen}
            onOpenChange={(open) => {
              if (!open) {
                setCategoryModalOpen(false)
              }
            }}
            rootClassName="general-setting-popover"
          >
            <CustomBtn
              isIcon
              svgIcon={<PlusIcon />}
              text={t('button.addNewCategory')}
              type="primary"
              className="rounded-3"
              onClick={() => {
                setCategoryModalOpen(true)
              }}
            />
          </Popover>
        )}
        {activeTab === 'credentialsRequirement' && (
          <div className="flex gap-1rem mobile-requirement-btn">
            <CustomBtn
              isIcon
              svgIcon={<Filter className="svg" />}
              text={filterOptionOpen ? t('button.hide') : t('button.filter')}
              className="user-Icon rounded-3 w-150"
              onClick={() => {
                if (!filterOptionOpen) {
                  setFilterOptionOpen(true)
                } else {
                  setFilterOptionOpen(false)
                }
              }}
            />
            <CustomBtn
              isIcon
              svgIcon={<PlusIcon />}
              text={t('button.addNewRequirement')}
              type="primary"
              className="rounded-3"
              onClick={() => {
                setEditRequirementId('')
                setRequirementModalOpen(true)
              }}
            />
          </div>
        )}
      </div>

      {/* filter */}
      {filterOptionOpen && activeTab === 'credentialsRequirement' && (
        <div className="flex gap-6 flex-wrap mb-4">
          {filterOption.map((filter: IFilterOption, index: number) => {
            return (
              <div key={index} className="flex flex-col relative">
                <div
                  className={`${selectedFilters === filter.filterName || selectedOptions[filter.filterName]?.includes(filter.filterName) ? 'border-primary' : 'border-neutral-200'} flex flex-col items-center justify-center bg-white rounded-100 py-2 px-6`}
                  onClick={() => {
                    setSelectedFilters(filter.filterName)
                    if (filter.filterName === 'Certifications' || filter.filterName === 'Specialties') handleOptionSelect(filter.filterName, filter.filterName)
                  }}
                >
                  <span
                    className={`${selectedFilters === filter.filterName || selectedOptions[filter.filterName]?.includes(filter.filterName) ? 'text-primary-500 ' : 'text-neutral-600'} para-p2 font-medium`}
                  >
                    {filter.filterName}
                  </span>
                </div>
                {filterSubMenu[filter.filterName] && (
                  <div
                    className="bg-neutral-50 rounded-3 absolute w-full filter-submenu"
                    onMouseLeave={() => {
                      setSelectedFilters('')
                    }}
                  >
                    {selectedFilters === filter.filterName &&
                      filterSubMenu[selectedFilters].name.map((option, subIndex: number) => (
                        <div key={subIndex} className="flex flex-col gap-2">
                          {selectedFilters === 'Auto Assign' ? (
                            <Checkbox
                              className="py-2 px-4"
                              checked={selectedCheckboxes[filter.filterName]?.includes(option) || false}
                              onChange={() => {
                                handleCheckboxChange(filter.filterName, option)
                              }}
                            >
                              {option}
                            </Checkbox>
                          ) : (
                            <span
                              onClick={() => {
                                handleOptionSelect(filter.filterName, option)
                              }}
                              className={`${selectedOptions[filter.filterName]?.includes(option) ? 'bg-primary-500 rounded-3 text-white' : 'bg-neutral-50 rounded-3'} menu-options py-2 px-4`}
                            >
                              {option}
                            </span>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}

      {/* credentials manager tab content */}
      {activeTab === 'credentialsManager' && (
        <>
          {credientialData.map((data: ICredentialData, index: number) => {
            return (
              <div key={index} className="flex flex-col gap-2 items-center w-full p-4 bg-neutral-50 rounded-4 mobile-action-icon">
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-2 items-center">
                    <div
                      onClick={() => {
                        handleToggle(index)
                      }}
                      style={{ transform: visibleTables[index] ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                    >
                      <DropDownIcon width={'28'} height={'28'} className="#2A2A2B" />
                    </div>
                    <HeadingText text={data.name} classString="mobile-heading-h5 text-neutral-800 font-medium mobile-heading-text" />
                  </div>
                  <div className="flex gap-6 items-center icon-neutral-500">
                    <div>
                      <DeleteIcon
                        width={'24'}
                        height={'24'}
                        className="svg"
                        onClick={() => {
                          if (typeof data.id === 'string') {
                            setIsDeleteDocOrCategory('')
                            setIsDeleteModalOpen(true)
                            setDeleteId(data.id)
                            setDeleteName(data.name)
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Popover
                        trigger={['click']}
                        content={<AddNewCategory setIsModalOpen={setCategoryEditModalOpen} editId={data.id} data={data} />}
                        placement="bottomRight"
                        open={categoryEditModalOpen === index}
                        onOpenChange={(open) => {
                          if (!open) {
                            setCategoryEditModalOpen(null)
                          }
                        }}
                        rootClassName="general-setting-popover"
                      >
                        <div
                          onClick={() => {
                            handleCategoryPopoverToggle(index)
                          }}
                        >
                          <EditPenIcon width={'24'} height={'24'} className="svg" />
                        </div>
                      </Popover>
                    </div>
                    {visibleTables[index] && (
                      <div
                        onClick={() => {
                          setEditDocumentId('')
                          handleDocumentPopoverToggle()
                        }}
                      >
                        <PlusIcon width={'24'} height={'24'} className="svg" />
                      </div>
                    )}
                  </div>
                </div>
                {visibleTables[index] && (
                  <div className="w-full">
                    <CustomTable
                      column={columns}
                      // onChangePage={handlePageChange}
                      data={data.table}
                      name={data.name}
                      className={'table-border-wrapper'}
                      // total={listData.length}
                      // limit={10}
                      // current={1}
                      // search={search}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </>
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.CANCEL}
          okClick={() => {
            if (isDeleteDocOrCategory === 'document') {
              handleDeleteDocument(deleteId)
            }
            if (isDeleteDocOrCategory === 'requirement') {
              handleDeleteRequirement(deleteId)
            }
            if (activeTab === 'credentialsManager' && isDeleteDocOrCategory === '') {
              handleDeleteCategory(deleteId)
            }
            if (activeTab === 'credentialsRequirement' && isDeleteDocOrCategory === '') {
              handleDeleteRequirementCategory(deleteId)
            }
          }}
          deleteName={deleteName}
          subHead={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE}
        />
      )}

      {/* credentials requirement tab content */}
      {activeTab === 'credentialsRequirement' && (
        <>
          {credientialRequirement.map((data: any, index: number) => {
            return (
              <div key={index} className="flex flex-col gap-2 items-center w-full p-4 bg-neutral-50 rounded-4">
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-2 items-center">
                    <div
                      onClick={() => {
                        handleToggleRequirement(index)
                      }}
                      style={{ transform: visibleRequirementTables[index] ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                    >
                      <DropDownIcon width={'28'} height={'28'} className="#2A2A2B" />
                    </div>
                    <HeadingText text={data.name} classString="mobile-heading-h5 text-neutral-800 font-medium mobile-heading-text" />
                  </div>
                  <div className="flex gap-6 items-center icon-neutral-500">
                    <div>
                      <DeleteIcon
                        width={'24'}
                        height={'24'}
                        className="svg"
                        onClick={() => {
                          if (typeof data.id === 'string') {
                            setIsDeleteDocOrCategory('')
                            setIsDeleteModalOpen(true)
                            setDeleteId(index)
                            setDeleteName(data.name)
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {visibleRequirementTables[index] && (
                  <div className="w-full">
                    <CustomTable
                      column={requirementColumns}
                      // onChangePage={handlePageChange}
                      data={data.table}
                      name={data.name}
                      className={'cell-align-top table-border-wrapper'}
                      // total={listData.length}
                      // limit={10}
                      // current={1}
                      // search={search}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </>
      )}

      {/* add requirement modal */}
      {requirementModalOpen && (
        <CustomModal
          open={requirementModalOpen}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setRequirementModalOpen(false)
          }}
          content={<AddRequirement setIsModalOpen={setRequirementModalOpen} editId={editRequirementId} data={editRequirementData} />}
          width={915}
        />
      )}

      {/* add document modal */}
      {documentModalOpen && (
        <CustomModal
          open={documentModalOpen}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setDocumentModalOpen(false)
          }}
          content={<AddDocument setIsModalOpen={setDocumentModalOpen} editId={editDocumentId} data={editDocumentData} />}
        />
      )}
    </div>
  )
}

export default ComplianceManager
