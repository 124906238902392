import { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_MODAL_SPECIALTIES_TEXT } from 'constants/Constant'
import { SPECIALITIES_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import usePermissionsBySectionName from 'hooks/usePermission'
import { t } from 'i18next'
import ActivityModal from 'pages/adminContacts/Modal/ActivityModal'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddNewSpecialities, type ISpecialtiesTable } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddNewSpecialities from './Modal/AddNewSpecialities'
import { deleteSpecialties, getSpecialties, updateSpecialties } from './api'

const Specialties = () => {
  const dispatch = useAppDispatch()

  const [search, setSearch] = useState<string>('')
  const [isAddNewSpecialty, setIsAddNewSpecialty] = useState<boolean>(false)
  const [isActivityModalOpen, setIsActivityModalOpen] = useState<boolean>(false)
  const [tableData, setTableData] = useState<ISpecialtiesTable[]>([])
  const [editId, setEditId] = useState<string>('')
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(tableData?.length).fill(false))
  const permit = usePermissionsBySectionName('master_admin_setting') // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.specialties'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetSpecialties(paginationInfo.limit, search ? 0 : paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.offset, sortTable, isAddNewSpecialty, search])

  const handleGetSpecialties = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getSpecialties(limit, offset, order, search, field)
    if (response?.status === 200) {
      setTableData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    void handleGetSpecialties(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleDelete = (specialtiesId: string) => {
    void handleDeleteSpecialties(specialtiesId)
  }

  const handleDeleteSpecialties = async (specialtiesId: string) => {
    const response = await deleteSpecialties(specialtiesId)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleGetSpecialties(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleGetData = async (record: IAddNewSpecialities) => {
    const { id } = record
    setEditId(id ?? '')
    setIsAddNewSpecialty(true)
  }

  const onSwitchChange = (checked: boolean, data: IAddNewSpecialities, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    void handleEditUserData(id, newData)
  }

  const handleEditUserData = async (id: string, values: IAddNewSpecialities) => {
    dispatch(setGlobalLoader(true))
    const response = await updateSpecialties(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetSpecialties(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            void handleGetData(record)
            handleActionMenuClick(index)
          },
          label: permit?.specialty?.edit && (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <div className="cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>{' '}
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      onSwitchChange(checked, record, record?.id)
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '3',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id)
            setDeleteName(record.name)
            handleActionMenuClick(index)
          },
          label: permit?.specialty?.delete && (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<ISpecialtiesTable> = [
    {
      title: t('table.specialties.number'),
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      width: 100,
      render: (_value, record, index) => {
        const pageNumber = paginationInfo.current
        const itemsPerPage = paginationInfo.limit
        const calculatedNumber = (pageNumber - 1) * itemsPerPage + index + 1
        return (
          <HeadingText text={search ? (index + 1 + (pageNumber - 1) * itemsPerPage).toString() : calculatedNumber.toString()} classString="para-p3 text-neutral-800 font-small" />
        )
      },
    },
    {
      title: t('table.specialties.speciality'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 326,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return <HeadingText text={record?.name} classString={'text-neutral-800 activity-text font-medium'} />
      },
    },
    {
      title: t('table.specialties.abbreviation'),
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 326,
    },
    {
      title: t('table.specialties.licenses'),
      dataIndex: 'certificate_count',
      key: 'certificate_count',
      align: 'start',
      width: 326,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        const certificates = record?.certificates?.map((item: { name: string }) => item.name).join(', ')
        return <HeadingText classString="text-neutral-800 para-p3 font-semibold" text={certificates} />
      },
    },
    {
      title: t('table.specialties.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 326,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === SPECIALITIES_STATUS.INVITED ? 'invited' : record.status === SPECIALITIES_STATUS.ACTIVE ? 'active' : 'inactive'}`}
            text={record.status === 'active' ? 'Active' : 'Inactive'}
          />
        )
      },
    },
    {
      title: t('table.specialties.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const addSpecialityBtn = permit?.specialty?.add && (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={t('button.addSpeciality')}
      onClick={() => {
        setEditId('')
        setIsAddNewSpecialty(true)
      }}
      type="primary"
    />
  )

  return (
    <div>
      <CustomTable<ISpecialtiesTable>
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        column={columns}
        data={tableData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        name={t('label.speciality')}
        isSearch
        onSearch={onSearch}
        searchPlaceholder={t('placeholder.search')}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        tableButton={addSpecialityBtn}
      />
      {isAddNewSpecialty && (
        <CustomModal
          open={isAddNewSpecialty}
          centered
          footer={null}
          closable={false}
          classStrings="add-speciality-modal"
          onCancel={() => {
            setIsAddNewSpecialty(false)
          }}
          content={<AddNewSpecialities setIsModalOpen={setIsAddNewSpecialty} editId={editId} />}
          width={600}
        />
      )}
      {isActivityModalOpen && <ActivityModal isModalOpen={isActivityModalOpen} setIsModalOpen={setIsActivityModalOpen} />}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_SPECIALTIES_TEXT.CANCEL}
          okClick={() => {
            handleDelete(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_MODAL_SPECIALTIES_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_SPECIALTIES_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_SPECIALTIES_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default Specialties
