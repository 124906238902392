import { message } from 'antd'
import http from 'services/Service'
import { type IShiftType } from 'types'

export const getAllSpecialityTypes = async () => {
  try {
    const response = http.get('/shift-type')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getShiftTypeById = async (id: string) => {
  try {
    const response = http.get(`/shift-type/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const deleteShiftType = async (id: string) => {
  try {
    const response = http.delete(`/shift-type/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addShiftType = async (payload: IShiftType) => {
  try {
    const response = http.post('/shift-type', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const editShiftType = async (id: string, payload: IShiftType) => {
  try {
    const response = http.patch(`/shift-type/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
