import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, message } from 'antd'
import { CirclePlus, CloseIcon, DeleteIcon, DraggableDots, DropDownIcon, DuplicateIcon, MultipleChoiceIcon, SelectFieldIcon, TextAreaIcon } from 'assets/svgs'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IPreviewReferanceFormQue, type IReferanceForm, type IReferanceFormFieldOption, type IReferanceFormQue } from 'types'
import { handleFieldsChange, referanceFormDeletedData, validateReferanceFormJSON } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { createReferenceForm, editReferenceForm, getReferanceFormDetail } from './api'

import '../../style/pages/ReferanceForm.scss'

const fieldOption = [
  {
    value: 'multiple_choice',
    label: 'Multiple Choice',
    icon: <MultipleChoiceIcon />,
  },
  {
    value: 'dropdown',
    label: 'Dropdown',
    icon: <SelectFieldIcon />,
  },
  {
    value: 'textarea',
    label: 'Text Area',
    icon: <TextAreaIcon />,
  },
]

const AddEditReferanceForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { editId } = useParams()
  const inputOptionRef = useRef<HTMLInputElement>(null)

  const [fieldList, setField] = useState<IReferanceFormQue[]>([])
  const [visibleDetails, setVisibleDetails] = useState<any[]>(fieldList?.map(() => false))
  const [deleteQueIds, setDeleteQueIds] = useState<string[]>([])
  const [deleteOptionIds, setDeleteOptionIds] = useState<string[]>([])
  const [errors, setError] = useState({
    name: '',
  })
  const [inputErrors, setInputErrors] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.referanceForm'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('referanceForm')) {
      getLocalStorageData()
      return
    }
    if (editId) void handleFindSingleReferanceData(editId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, form])

  useEffect(() => {
    if (localStorage.getItem('referanceForm')) {
      getLocalStorageData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLocalStorageData = () => {
    const getPrevData = localStorage.getItem('referanceForm')
    const getData = getPrevData ? JSON.parse(getPrevData) : {}
    setDeleteQueIds(getData?.delete_question)
    setDeleteOptionIds(getData?.delete_option)
    form.setFieldValue(`name`, getData?.name)
    const sortedQuestions = getData?.reference_form_option?.sort((a: IReferanceFormQue, b: IReferanceFormQue) => a.order - b.order)
    sortedQuestions?.forEach((eachField: IReferanceFormQue, index: number) => {
      form.setFieldValue(`name-${index}`, eachField.name)
      form.setFieldValue(`field-${index}`, eachField.option_type)
      eachField?.options?.sort((a: IReferanceFormFieldOption, b: IReferanceFormFieldOption) => a.order - b.order)
    })
    setField(sortedQuestions)
  }

  const handleFindSingleReferanceData = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getReferanceFormDetail(id)
    if (response?.status === 200) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, reference_form_design } = response.data.data
      form.setFieldValue('name', name)

      const sortedQuestions = reference_form_design?.sort((a: IPreviewReferanceFormQue, b: IPreviewReferanceFormQue) => a.order - b.order)

      const transformedQuestions = sortedQuestions?.map((eachField: IPreviewReferanceFormQue, index: number) => {
        const sortedOptions = eachField?.reference_form_option?.sort((a: IReferanceFormFieldOption, b: IReferanceFormFieldOption) => a.order - b.order)
        form.setFieldValue(`name-${index}`, eachField.name)
        form.setFieldValue(`field-${index}`, eachField.option_type)

        return {
          id: eachField.id ? eachField.id : '',
          name: eachField.name ?? '',
          option_type: eachField.option_type ?? 'multiple_choice',
          order: eachField.order ?? 0,
          options: sortedOptions ?? [],
        }
      })

      setField(transformedQuestions ?? [])
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
      return null
    }
    dispatch(setGlobalLoader(false))
  }

  const addQuestionAns = () => {
    form.setFieldValue(`add-option`, '')
    setVisibleDetails(fieldList ? fieldList.map(() => false) : [])
    const data = {
      name: '',
      option_type: 'multiple_choice',
      order: (fieldList ? fieldList.length : 0) + 1,
      options: [],
    }

    setVisibleDetails((prevState) => {
      if (!prevState) prevState = []
      const newState = [...prevState]
      newState[fieldList ? fieldList.length : 0] = !newState[fieldList ? fieldList.length : 0]
      return newState
    })
    setField((prev) => (prev ? [...prev, data] : [data]))
    form.setFieldValue(`name-${fieldList ? fieldList.length : 0}`, '')
  }

  const handleAddOptionBlur = async (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>, id: number) => {
    e.preventDefault()
    const target = e.target as HTMLInputElement

    // Check if the input is empty
    if (target.value.trim() === '') {
      // Set the error for this field index on both blur and Enter key
      setInputErrors((prevErrors) => {
        const updatedErrors = [...prevErrors]
        updatedErrors[id] = 'Option cannot be empty'
        return updatedErrors
      })

      return // Stop here, do not add the empty option
    } else {
      // Clear the error if a valid option is provided
      setInputErrors((prevErrors) => {
        const updatedErrors = [...prevErrors]
        updatedErrors[id] = ''
        return updatedErrors
      })
    }

    // Proceed to add the valid option if input is not empty
    await new Promise<void>((resolve) => {
      setField((prevFieldList) => {
        const updatedQuestions = prevFieldList.map((field, qIndex) => {
          if (qIndex === id) {
            const fieldOptions = Array.isArray(field.options) ? field.options : []
            const updatedOptions = [...fieldOptions, { name: target.value, order: fieldOptions.length }]
            return { ...field, options: updatedOptions }
          }
          return field
        })
        resolve()
        return updatedQuestions
      })
    })

    setInputValue('') // Reset the input value after adding a valid option
  }

  const onOptionTextChange = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>, questionIndex: number, optionIndex?: number) => {
    const isEnterKey = 'key' in e && e.key === 'Enter'
    const target = e.target as HTMLInputElement
    setField((prevfieldList) => {
      const updatedFields = prevfieldList.map((field, qIndex) => {
        if (qIndex === questionIndex) {
          const updatedOptions = field?.options?.map((option: IReferanceFormFieldOption, oIndex: number) => {
            if (oIndex === optionIndex) {
              return { ...option, name: target?.value }
            }
            return option
          })
          return { ...field, options: updatedOptions }
        }
        return field
      })
      return updatedFields
    })
    if (isEnterKey) {
      e.preventDefault()
      const target = e.target as HTMLInputElement
      const currentOptionIndex = Array.from(document.querySelectorAll('input.radio-option-input')).indexOf(target)
      const nextOption = document.querySelectorAll('input.radio-option-input')[currentOptionIndex + 1] as HTMLInputElement | null
      if (nextOption) {
        nextOption.focus()
      }
    }
  }

  const deleteField = async (fieldIndex: number, queId?: string) => {
    setVisibleDetails(fieldList?.map(() => false))
    const updatedQuestions = [...fieldList]
    form.setFieldValue(`name-${fieldIndex}`, '')
    updatedQuestions.splice(fieldIndex, 1)
    updatedQuestions.forEach((field: IReferanceFormQue, index: number) => {
      form.setFieldValue(`question-${index}`, field.name)
      form.setFieldValue(`field-${index}`, field.option_type)
      field?.options?.forEach((eachOption, optionIndex: number) => {
        form.setFieldValue(`option-${index}-${optionIndex}`, eachOption)
      })
    })
    setField(updatedQuestions)

    if (editId && queId) {
      setDeleteQueIds((prev) => [...prev, queId])
    }
  }

  const deleteOption = async (fieldIndex: number, index: number, optId?: string) => {
    const updatedFields = [...fieldList]
    form.setFieldValue(`option-${fieldIndex}-${index}`, '')
    updatedFields[fieldIndex]?.options?.splice(index, 1)
    setField(updatedFields)
    if (editId && optId) {
      setDeleteOptionIds((prev) => [...prev, optId])
    }
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, fieldIndex: number, index: number) => {
    e.dataTransfer.setData('fieldIndex', fieldIndex.toString())
    e.dataTransfer.setData('index', index.toString())
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
    const questionIndex = parseInt(e.dataTransfer.getData('fieldIndex'), 10)
    const optionIndex = parseInt(e.dataTransfer.getData('index'), 10)

    if (isNaN(questionIndex) || isNaN(optionIndex) || optionIndex === dropIndex) return

    const updatedList = [...fieldList]
    const listData = updatedList[questionIndex]

    if (listData?.options) {
      const dragOption = listData.options[optionIndex]

      if (dragOption) {
        listData.options.splice(optionIndex, 1)
        listData.options.splice(dropIndex, 0, dragOption)
        listData.options.forEach((option: { order: number }, index: number) => {
          option.order = index + 1
        })
        setField(updatedList)
      }
    }
  }

  const handleFieldDragStart = (e: React.DragEvent<HTMLDivElement>, fieldIndex: number) => {
    e.dataTransfer.setData('fieldIndex', fieldIndex.toString())
  }

  const handleFieldDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleFieldDrop = (e: React.DragEvent<HTMLDivElement>, targetFieldIndex: number) => {
    e.preventDefault()
    const sourceQuestionIndex = parseInt(e.dataTransfer.getData('fieldIndex'), 10)

    if (isNaN(sourceQuestionIndex) || sourceQuestionIndex === targetFieldIndex) return

    const updatedList = [...fieldList]
    const [draggedQuestion] = updatedList.splice(sourceQuestionIndex, 1)
    updatedList.splice(targetFieldIndex, 0, draggedQuestion)

    const reorderedQuestions = updatedList?.map((question, index) => {
      form.setFieldValue(`name-${index}`, question.name)
      form.setFieldValue(`field-${index}`, question.option_type)
      question?.options?.forEach((eachOption, optionIndex: number) => {
        form.setFieldValue(`option-${index}-${optionIndex}`, eachOption)
      })
      return { ...question, order: index + 1 }
    })

    setField(reorderedQuestions)
    setVisibleDetails(fieldList?.map(() => false))
  }

  const onFinish = (values: { name: string }) => {
    const validation = validateReferanceFormJSON(fieldList)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const data: IReferanceForm = {
      name: values?.name ?? '',
      reference_form: fieldList ?? [],
      ...(editId ? {} : { status: 'active' }),
    }
    if (editId) {
      const newData = referanceFormDeletedData(data, deleteQueIds, deleteOptionIds)
      void handleUpdate(editId, newData)
    } else {
      void handleSubmit(data)
    }
  }

  const handleSubmit = async (data: IReferanceForm) => {
    dispatch(setGlobalLoader(true))
    const response = await createReferenceForm(data)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/referance-form-setting')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleUpdate = async (editId: string, data: IReferanceForm) => {
    dispatch(setGlobalLoader(true))
    const response = await editReferenceForm(editId, data)
    if (response?.data?.statusCode === 1) {
      setDeleteOptionIds([])
      setDeleteQueIds([])
      void message.success(response?.data?.message)
      navigate('/settings/referance-form-setting')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleFieldNameBlur = (e: React.FocusEvent<HTMLInputElement>, fieldIndex: number) => {
    const newFieldText = e.target.value
    setField((prevfieldList) => {
      const updatedFieldName = prevfieldList.map((field, fIndex) => {
        if (fIndex === fieldIndex) {
          return { ...field, name: newFieldText }
        } else {
          return field
        }
      })
      return updatedFieldName
    })
  }

  const toggleDetails = (index: number) => {
    setVisibleDetails((prevState) => {
      const newState = new Array(prevState.length).fill(false)
      newState[index] = !prevState[index] ? true : !prevState[index]
      return newState
    })
  }

  const handlePreviewTest = () => {
    const validation = validateReferanceFormJSON(fieldList)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const state = {
      name: form.getFieldValue('name') ?? '',
      reference_form_option: fieldList,
      delete_question: deleteQueIds,
      delete_option: deleteOptionIds,
      editId,
    }
    localStorage.setItem('referanceForm', JSON.stringify(state))
    if (editId) {
      navigate('/settings/referance-form-setting/edit-form/referance-form-preview')
    } else {
      navigate('/settings/referance-form-setting/add-form/referance-form-preview')
    }
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  const handleFieldsOptionTypeChange = (selectedValue: string, fieldIndex: number) => {
    setField((prevFieldList) => {
      const updatedFieldOptionType = prevFieldList.map((field, fIndex) => {
        if (fIndex === fieldIndex) {
          if (selectedValue === 'textarea') {
            const { options, ...rest } = field
            return { ...rest, option_type: selectedValue }
          } else {
            return { ...field, option_type: selectedValue }
          }
        } else {
          return field
        }
      })
      return updatedFieldOptionType
    })
  }

  const handleDuplicateField = (index: number) => {
    setVisibleDetails(fieldList?.map(() => false))
    setField((prevFieldList) => {
      if (index < 0 || index >= prevFieldList.length) {
        void message.error('Index out of bounds')
        return prevFieldList
      }
      const fieldToClone = { ...prevFieldList[index] }
      delete fieldToClone.id
      fieldToClone.order = Number(fieldToClone?.order) + 1
      const updatedFieldList = [...prevFieldList.slice(0, index + 1), fieldToClone, ...prevFieldList.slice(index + 1)]
      for (let i = index + 2; i < updatedFieldList.length; i++) {
        if (i > index + 1) {
          updatedFieldList[i].order = Number(updatedFieldList[i]?.order) + 1
        }
      }
      updatedFieldList?.forEach((list, index) => {
        form.setFieldValue(`name-${index}`, list.name)
        form.setFieldValue(`field-${index}`, list.option_type)
        list?.options?.forEach((eachOption, optionIndex: number) => {
          form.setFieldValue(`option-${index}-${optionIndex}`, eachOption)
        })
      })
      return updatedFieldList
    })
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
        className="flex flex-col gap-6 items-center w-full"
      >
        <div className="flex items-center bg-white competency-addtest w-full">
          <div className="flex items-center gap-4">
            <CustomBtn
              text={t('button.preview')}
              className="rounded-3 w-100"
              onClick={() => {
                handlePreviewTest()
              }}
              disabled={fieldList?.length === 0 || !form.getFieldValue('name')}
            />
            <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" />
          </div>
        </div>
        <div className="flex flex-col w-915 gap-6 mobile-version">
          {/* button */}
          <div className="flex justify-end items-center">
            <CustomBtn isIcon svgIcon={<CirclePlus className="svg" />} text={t('button.newQue')} className="user-Icon rounded-3" onClick={addQuestionAns} />
          </div>

          <div className="bg-white p-8 rounded-3">
            <div className="w-full">
              <InputField
                wrapperClass="m-0"
                placeholder={t('label.formName')}
                rules={commonInputFieldValidation(VALIDATION_STRINGS.FORM_NAME)}
                name="name"
                error={errors?.name}
                value={getValue('name')}
                delay={100}
              />
            </div>
          </div>

          {/* question */}
          <div className="flex flex-col gap-6">
            {fieldList?.map((fields: IReferanceFormQue, fieldIndex: number) => {
              const isVisible = visibleDetails[fieldIndex]
              console.log(visibleDetails[fieldIndex], 'Field Index', fieldIndex, fields)
              return (
                <div
                  key={fieldIndex}
                  className="bg-white rounded-3 question-container"
                  draggable
                  onDragStart={(e) => {
                    handleFieldDragStart(e, fieldIndex)
                  }}
                  onDragOver={handleFieldDragOver}
                  onDrop={(e) => {
                    handleFieldDrop(e, fieldIndex)
                  }}
                >
                  <div className="ques-move-icon">
                    <DraggableDots />
                  </div>
                  <div className={`flex w-full gap-1rem items-center px-8 pt-8 ${!isVisible ? 'pb-8' : ''} field-row`}>
                    {fields.name === '' || isVisible ? (
                      <div className="w-70 field-name flex justify-between">
                        <InputField
                          wrapperClass="m-0"
                          placeholder={t('placeholder.fieldName')}
                          name={`name-${fieldIndex}`}
                          onBlur={(e) => {
                            handleFieldNameBlur(e, fieldIndex)
                          }}
                          delay={100}
                        />
                      </div>
                    ) : (
                      <div
                        className="w-full flex justify-between"
                        onClick={() => {
                          toggleDetails(fieldIndex)
                        }}
                      >
                        <span className="mobile-heading-h5 font-medium text-neutral-800 break-all">{fields.name}</span>

                        <span style={{ transform: isVisible ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}>
                          <DropDownIcon />
                        </span>
                      </div>
                    )}
                    {(isVisible || fields.name === '') && (
                      <div className="flex items-center justify-end w-30 field-type">
                        <FloatingLabelSelect
                          wrapperClass="m-0"
                          label={t('placeholder.field')}
                          options={fieldOption}
                          name={`field-${fieldIndex}`}
                          value={getValue(`field-${fieldIndex}`) || 'multiple_choice'}
                          onChange={(e) => {
                            handleFieldsOptionTypeChange(e, fieldIndex)
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {fields.option_type !== 'textarea' ? (
                    <div className="flex-col mt-6 pr-8 relative" style={{ display: isVisible || fields.name === '' ? 'flex' : 'none' }}>
                      <div className="flex flex-col gap-6 w-full mb-4">
                        {fields?.options?.map((optionTxt: IReferanceFormFieldOption, index: number) => {
                          return (
                            <div
                              key={index}
                              draggable
                              onDragStart={(e) => {
                                handleDragStart(e, fieldIndex, index)
                              }}
                              onDragOver={handleDragOver}
                              onDrop={(e) => {
                                handleDrop(e, index)
                              }}
                              className="radio-option-container flex items-center w-full"
                            >
                              <div className="radio-move-icon flex items-center">
                                <DraggableDots />
                              </div>

                              <div key={index} className="add-option activity-text w-60">
                                <div className="flex items-center justify-between gap-6 w-full">
                                  <div className="option-text">
                                    <input
                                      name={`option-${fieldIndex}-${index}`}
                                      type="text"
                                      value={optionTxt.name}
                                      onChange={(e) => {
                                        onOptionTextChange(e, fieldIndex, index)
                                      }}
                                      onKeyDown={(e) => {
                                        onOptionTextChange(e, fieldIndex, index)
                                      }}
                                      className="radio-option-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-6 w-full items-center justify-end">
                                <span
                                  className="option-delete flex items-center justify-end gap-6"
                                  onClick={() => {
                                    void deleteOption(fieldIndex, index, optionTxt?.id)
                                  }}
                                >
                                  <CloseIcon />
                                </span>
                              </div>
                            </div>
                          )
                        })}
                        <div className="pl-8 add-option activity-text w-full add-option-mobile">
                          <div className="flex items-center justify-between w-full">
                            <input
                              type="text"
                              name="add-option"
                              ref={inputOptionRef}
                              placeholder="Add Option"
                              className="radio-option-input"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  void handleAddOptionBlur(e, fieldIndex) // Handle Enter key
                                }
                              }}
                              onBlur={(e) => {
                                void handleAddOptionBlur(e, fieldIndex) // Validate input on blur
                                if (e.target.value.trim() === '') {
                                  // Clear error when user types valid input
                                  setInputErrors((prevErrors) => {
                                    const updatedErrors = [...prevErrors]
                                    updatedErrors[fieldIndex] = ''
                                    return updatedErrors
                                  })
                                }
                              }}
                              value={inputValue}
                              onChange={(e) => {
                                setInputValue(e.target.value)
                                if (e.target.value.trim() !== '') {
                                  // Clear error when user types valid input
                                  setInputErrors((prevErrors) => {
                                    const updatedErrors = [...prevErrors]
                                    updatedErrors[fieldIndex] = ''
                                    return updatedErrors
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                        {inputErrors[fieldIndex] && <span className="error-container pl-8">{inputErrors[fieldIndex]}</span>}
                      </div>
                      <div className="flex gap-6 justify-end pb-8">
                        <DuplicateIcon
                          fill="#7D7E82"
                          onClick={() => {
                            handleDuplicateField(fieldIndex)
                          }}
                        />
                        <DeleteIcon
                          className="#d1293d"
                          onClick={() => {
                            void deleteField(fieldIndex, fields?.id)
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex-col mt-6 pr-8 relative" style={{ display: isVisible || fields.name === '' ? 'flex' : 'none' }}>
                      <div className="flex flex-col gap-6 w-full mb-4">
                        <div className="pl-8 add-option activity-text w-full add-option-mobile">
                          <div className="flex items-center justify-between w-full">
                            <CommonTextArea wrapperClass="m-0" name={`textarea-${fieldIndex}`} placeholder={t('placeholder.description')} rows={4} disabled />
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-6 justify-end pb-8">
                        <DuplicateIcon
                          fill="#7D7E82"
                          onClick={() => {
                            handleDuplicateField(fieldIndex)
                          }}
                        />
                        <DeleteIcon
                          className="#d1293d"
                          onClick={() => {
                            void deleteField(fieldIndex, fields?.id)
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Form>
    </>
  )
}

export default AddEditReferanceForm
