import { type ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, message, Switch } from 'antd'
import { CirclePlus, DeleteIcon, DraggableDots, DropDownIcon, EditPenIcon, PlusIcon } from 'assets/svgs'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_ERROR, VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setCurrentNameData, setCurrentSkillData, setGlobalLoader, setPreviousNameData, setPreviousSkillData, setType } from 'store/slice/CommonSlice'
import { type ICreateChecklistData, type IFieldData, type ISkillChecklistModule, type ISkillChecklistQuestion, type ISkillChecklistSubModule, type ISubModData } from 'types'
import { addSkillChecklistDeletedData, handleFieldsChange, validateJSON } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { addChecklist, editChecklist, getFindChecklistById } from '../api'
import AddSubModuleQue from '../modal/SubModuleQue'

import '../../../style/pages/SkillChecklist.scss'

const AddEditCheckList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { editId } = useParams()

  const [error, setError] = useState({
    name: '',
  })
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [allQues, setAllQues] = useState<ISkillChecklistModule[]>([])
  const [visibleDetails, setVisibleDetails] = useState<boolean[]>(allQues?.map(() => false))
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalName, setModalName] = useState<string>('')
  const [modalSubModData, setSubModData] = useState<ISubModData>()
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [deletedSectionIds, setDeletedSectionIds] = useState<string[]>([])
  const [deletedSubModuleIds, setDeletedSubModuleIds] = useState<string[]>([])
  const [deletedSubModuleQueIds, setDeletedSubModuleQueIds] = useState<string[]>([])
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const previousAllQuesLength = useRef<number>(allQues.length)

  console.log({ statusCheck })
  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: editId ? t('pageTitle.editChecklist') : t('pageTitle.newChecklist'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('skillState')) {
      getLocalStorageData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (localStorage.getItem('skillState')) {
      getLocalStorageData()
      return
    }
    if (editId) void handleFindSingleTestData(editId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, form])

  useEffect(() => {
    if (allQues.length > 0) {
      dispatch(setType('skill'))
      dispatch(setCurrentSkillData(allQues))
      if (firstLoad) {
        setFirstLoad(false)
        previousAllQuesLength.current = allQues.length
        return
      }
      if (allQues.length > previousAllQuesLength.current) {
        const container = document.querySelector('.question-block')
        const lastQuestion = container?.lastElementChild

        if (lastQuestion) {
          lastQuestion.scrollIntoView({
            block: 'end',
            behavior: 'smooth',
          })
        }
      }
      previousAllQuesLength.current = allQues.length
    }
  }, [allQues, dispatch])

  useEffect(() => {
    dispatch(setType('skill'))
    const nameValue = form.getFieldValue('name')
    if (nameValue) {
      dispatch(setCurrentNameData({ name: nameValue, status: statusCheck ? 'active' : 'in_active' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('name'), form, dispatch])

  const handleFindSingleTestData = async (id: string) => {
    try {
      dispatch(setGlobalLoader(true))
      const response = await getFindChecklistById(id)
      if (response?.status === 200) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { name, skill_checklist_module, status } = response.data.data
        const data = {
          name,
        }
        const sortedSkillChecklistModule = skill_checklist_module?.sort((a: ISkillChecklistModule, b: ISkillChecklistModule) => a.order - b.order)

        sortedSkillChecklistModule?.forEach((question: ISkillChecklistModule, index: number) => {
          question.skill_checklist_sub_module.forEach((subModData: ISkillChecklistSubModule, subModIndex: number) => {
            form.setFieldValue(`option_name_${index}_${subModIndex}`, subModData.name)
          })
        })

        dispatch(setPreviousNameData({ name, status }))
        setStatusCheck(status === 'active')
        setAllQues(sortedSkillChecklistModule)
        dispatch(setPreviousSkillData(sortedSkillChecklistModule ?? []))
        form.setFieldsValue(data)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error) {
      console.error('Error fetching checklist data:', error)
    } finally {
      dispatch(setGlobalLoader(false))
    }
  }

  const getLocalStorageData = () => {
    const stateString = localStorage.getItem('skillState')
    const data = stateString ? JSON.parse(stateString) : {}
    const sortedSkillChecklistModule = data.skill_checklist_module?.sort((a: ISkillChecklistModule, b: ISkillChecklistModule) => a.order - b.order)

    sortedSkillChecklistModule?.forEach((question: ISkillChecklistModule, index: number) => {
      question.skill_checklist_sub_module.forEach((subModData: ISkillChecklistSubModule, subModIndex: number) => {
        form.setFieldValue(`option_name_${index}_${subModIndex}`, subModData.name)
      })
    })
    setAllQues(sortedSkillChecklistModule ?? [])
    setStatusCheck(data.status)
    form.setFieldsValue({ name: data?.name })
  }

  const onChange = (checked: boolean) => {
    setStatusCheck(checked)
  }

  const handleQueDragStart = (e: React.DragEvent<HTMLDivElement>, questionIndex: number) => {
    e.dataTransfer.setData('questionIndex', questionIndex.toString())
  }

  const handleQueDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleQueDrop = (e: React.DragEvent<HTMLDivElement>, targetQuestionIndex: number) => {
    e.preventDefault()
    setEditingIndex(null)
    const sourceQuestionIndex = parseInt(e.dataTransfer.getData('questionIndex'), 10)
    if (isNaN(sourceQuestionIndex) || sourceQuestionIndex === targetQuestionIndex) return
    const updatedQuestions = [...allQues]
    const [dragQuestion] = updatedQuestions.splice(sourceQuestionIndex, 1)
    updatedQuestions.splice(targetQuestionIndex, 0, dragQuestion)
    const reorderedQuestions = updatedQuestions.map((question, index) => {
      question.skill_checklist_sub_module.forEach((subModData: ISkillChecklistSubModule, subModIndex: number) => {
        form.setFieldValue(`option_name_${index}_${subModIndex}`, subModData.name)
      })
      return {
        ...question,
        order: index + 1,
      }
    })
    setAllQues(reorderedQuestions)
    setVisibleDetails(reorderedQuestions.map(() => false))
  }

  const skillChecklistModules = allQues.map((data: ISkillChecklistModule) => {
    return {
      ...(data.id && { id: data.id }),
      name: data.name,
      order: data.order,
      skill_checklist_sub_module: data.skill_checklist_sub_module,
    }
  })

  const onFinish = (values: { name: string }) => {
    if (allQues.length === 0) {
      void message.error(VALIDATION_ERROR.COMMON.ENTERONEQUE)
      return
    }
    const validation = validateJSON(allQues)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const name = values.name
    const statusValue = statusCheck ? 'active' : 'in_active'
    const updatedValues = { name, status: statusValue, skill_checklist_module: skillChecklistModules }
    if (editId) {
      const newData = addSkillChecklistDeletedData(updatedValues, deletedSectionIds, deletedSubModuleQueIds, deletedSubModuleIds)
      void handleEditCheckList(editId, newData)
    } else {
      void handleAddCheckList(updatedValues)
    }
  }

  const handleAddCheckList = async (payload: ICreateChecklistData) => {
    dispatch(setGlobalLoader(true))
    const response = await addChecklist(payload)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/skill-checklist')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditCheckList = async (id: string, values: ICreateChecklistData) => {
    dispatch(setGlobalLoader(true))
    const response = await editChecklist(id, values)
    if (response?.status === 200) {
      void message.success(response?.data?.message)
      navigate('/settings/skill-checklist')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleAddModule = () => {
    setVisibleDetails(allQues?.map(() => false))
    form.setFieldValue(`question-${allQues.length + 1}`, '')
    form.setFieldValue(`option_name_${allQues.length}_0`, '')

    const data = {
      name: '',
      order: allQues.length + 1,
      skill_checklist_sub_module: [
        {
          name: '',
          skill_checklist_question: [],
        },
      ],
    }
    setVisibleDetails((prevState) => {
      const newState = [...prevState]
      newState[allQues.length] = !newState[allQues.length]
      return newState
    })
    setEditingIndex(allQues.length)
    setAllQues((prev) => [...prev, data])
  }

  const toggleDetails = (index: number) => {
    setVisibleDetails((prevState) => {
      const newState = new Array(prevState.length).fill(false)
      newState[index] = !prevState[index] ? true : !prevState[index]
      return newState
    })
  }

  const openEditModal = (moduleIndex: number, subModuleIndex: number, name: string, subModule: ISkillChecklistSubModule[]) => {
    setModalName(name)
    const data = {
      moduleIndex,
      subModuleIndex,
      subModule,
    }
    setSubModData(data)
    setIsModalVisible(true)
  }

  const cancelOpenEditModal = () => {
    setIsModalVisible(false)
  }

  const handleAddQuestionInput = (index: number) => {
    const updatedAllQues = [...allQues]
    const module = updatedAllQues[index]
    if (module?.skill_checklist_sub_module) {
      const newSubModule = {
        name: '',
        skill_checklist_question: [],
      }
      module.skill_checklist_sub_module.push(newSubModule)
      form.setFieldValue(`option_name_${index}_${module.skill_checklist_sub_module.length - 1}`, '')
    }
    setAllQues(updatedAllQues)
  }

  const handleRemoveSubSection = (index: number, subModuleIndex: number, subModQueId?: string) => {
    void handleDeleteSubModule(index, subModuleIndex, subModQueId)
  }

  const handleDeleteSubModule = async (index: number, subModuleIndex: number, subModQueId?: string) => {
    form.setFieldValue(`option_name_${index}_${subModuleIndex}`, '')
    setAllQues((prevAllQues) => {
      const updatedAllQues = prevAllQues.map((module, moduleIndex) => {
        if (moduleIndex !== index) return module
        const updatedSubModules = module.skill_checklist_sub_module.filter((_: ISkillChecklistSubModule, subIndex: number) => subIndex !== subModuleIndex)
        updatedSubModules.forEach((question: ISkillChecklistSubModule, subModIndex: number) => {
          form.setFieldValue(`option_name_${index}_${subModIndex}`, question.name)
        })
        return { ...module, skill_checklist_sub_module: updatedSubModules }
      })
      return updatedAllQues
    })
    if (editId && subModQueId) {
      setDeletedSubModuleIds((prev) => [...prev, subModQueId])
    }
  }

  const handleEditClick = (index: number, queName?: string) => {
    if (queName) {
      form.setFieldValue(`question-${index}`, queName)
    }
    setEditingIndex(index)
  }

  const handleInputChange = (index: number, subModuleIndex: number, event: ChangeEvent<HTMLInputElement>) => {
    setAllQues((prevAllQues) => {
      const newModules = prevAllQues.map((module, moduleIndex) => {
        if (moduleIndex !== index) return module

        const newSubModules = module.skill_checklist_sub_module.map((subModule: ISkillChecklistSubModule, subIndex: number) => {
          if (subIndex !== subModuleIndex) return subModule

          return { ...subModule, name: event.target.value }
        })

        return { ...module, skill_checklist_sub_module: newSubModules }
      })

      return newModules
    })
  }

  const handleMainSectionDelete = (index: number, queId?: string) => {
    void deleteMainSection(index, queId)
  }

  const deleteMainSection = async (questionIndex: number, queId?: string) => {
    setVisibleDetails(allQues?.map(() => false))
    const updatedQuestions = [...allQues]
    form.setFieldValue(`question-${questionIndex}`, '')
    updatedQuestions.splice(questionIndex, 1)
    updatedQuestions.forEach((question, index) => {
      form.setFieldValue(`question-${index}`, question.name)
      question.skill_checklist_sub_module.forEach((subModule: ISkillChecklistSubModule, subIndex: number) => {
        form.setFieldValue(`option_name_${index}_${subIndex}`, subModule.name)
      })
    })
    setAllQues(updatedQuestions)

    if (editId && queId) {
      setDeletedSectionIds((prev) => [...prev, queId])
    }
  }

  const handleSaveQuestions = (moduleIndex: number, subModuleIndex: number, questions: ISkillChecklistQuestion[]) => {
    const updatedModules = [...allQues]
    if (moduleIndex < updatedModules.length) {
      const subModules = updatedModules[moduleIndex].skill_checklist_sub_module
      if (subModuleIndex < subModules.length) {
        subModules[subModuleIndex].skill_checklist_question = questions
      }
    }
    setAllQues(updatedModules)
  }

  const handleQuestionBlur = (e: React.FocusEvent<HTMLInputElement>, questionIndex: number) => {
    const newQuestionText = e.target.value
    setAllQues((prevAllQues) => {
      const updatedQuestions = prevAllQues.map((question, qIndex) => {
        if (qIndex === questionIndex) {
          return { ...question, name: newQuestionText }
        } else {
          return question
        }
      })
      return updatedQuestions
    })
  }

  const handlePreviewModals = () => {
    const validation = validateJSON(allQues)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const state = {
      skill_checklist_module: allQues,
      name: form.getFieldValue('name') ?? '',
      status: statusCheck,
      delete_module: deletedSectionIds,
      delete_sub_module: deletedSubModuleIds,
      delete_question: deletedSubModuleQueIds,
      editId,
    }
    localStorage.setItem('skillState', JSON.stringify(state))
    if (editId) {
      navigate('/settings/skill-checklist/edit/preview')
    } else {
      navigate('/settings/skill-checklist/add/preview')
    }
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="flex flex-col gap-6 items-center w-full addedit-checklist"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="flex items-center justify-center bg-white content-header-spacing w-full rounded-4">
          <div className="flex items-center justify-between gap-4 w-full px-10 btn-header">
            <div className="flex gap-2 toggle-switch">
              <span>{t('placeholder.userStatus')}</span> <Switch checked={statusCheck} onChange={onChange} />
            </div>
            <div className="flex gap-1rem skill-action">
              <CustomBtn
                text={t('button.preview')}
                className="rounded-3 w-100"
                onClick={() => {
                  handlePreviewModals()
                }}
                disabled={allQues.length === 0 || !form.getFieldValue('name')}
              />
              <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-955 px-10 w-full gap-6 question-block">
          {/* test form */}
          <div className="bg-white pt-8 px-8 py-2 rounded-3">
            <div className="w-full">
              <InputField
                placeholder={t('label.checklistName')}
                rules={commonInputFieldValidation(VALIDATION_STRINGS.CHECKLIST_NAME)}
                name="name"
                error={error.name}
                value={getValue('name')}
                delay={100}
              />
            </div>
          </div>
          <div className="flex flex-col gap-6">
            {allQues?.map((question, index) => {
              const isVisible = visibleDetails[index]
              return (
                <div
                  key={question.order}
                  className="bg-white rounded-3 question-container"
                  draggable
                  onDragStart={(e) => {
                    handleQueDragStart(e, index)
                  }}
                  onDragOver={handleQueDragOver}
                  onDrop={(e) => {
                    handleQueDrop(e, index)
                  }}
                >
                  <div className="ques-move-icon">
                    <DraggableDots />
                  </div>
                  <div className="flex flex-col gap-6 p-8 bg-white rounded-3">
                    <div className="flex items-center">
                      {question?.name === '' || editingIndex === index ? (
                        <div className="flex items-center justify-between w-full gap-2">
                          <div className="w-full">
                            <Form.Item name={`question-${index}`} className="p-0 m-0">
                              <Input
                                variant="borderless"
                                placeholder={t('placeholder.addSection')}
                                className="mobile-heading-h5 font-medium text-neutral-800 p-0 m-0 w-full"
                                onBlur={(e) => {
                                  handleQuestionBlur(e, index)
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ) : (
                        <HeadingText
                          classString="w-full mobile-heading-h5 font-medium p-0 m-0"
                          text={question.name}
                          onClick={() => {
                            handleEditClick(index, question?.name)
                          }}
                        />
                      )}
                      <div
                        className="flex items-center justify-end"
                        onClick={() => {
                          toggleDetails(index)
                        }}
                        style={{ transform: isVisible ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                      >
                        <DropDownIcon />
                      </div>
                    </div>
                    <div style={{ display: isVisible ? 'block' : 'none' }}>
                      <div className="grid grid-cols-2 gap-6 items-center sub-module">
                        {question.skill_checklist_sub_module.map((data: ISkillChecklistSubModule, subModuleIndex: number) => {
                          return (
                            <div key={`subModule-${index}-${subModuleIndex}`} className="item-container flex items-center justify-between p-2 rounded-2 border-stroke gap-8">
                              <Form.Item rootClassName="question-list p-0 m-0 w-75" name={`option_name_${index}_${subModuleIndex}`}>
                                <Input
                                  className="p-0 m-0"
                                  onChange={(event) => {
                                    handleInputChange(index, subModuleIndex, event)
                                  }}
                                  // placeholder={t('placeholder.addSubSection')}
                                />
                              </Form.Item>
                              <div className="data-number para-p4 text-primary-500 rounded-2 item-center count-bg">{data.skill_checklist_question.length ?? 0}</div>
                              <div className="items-edit-icon">
                                <div
                                  onClick={() => {
                                    handleRemoveSubSection(index, subModuleIndex, data?.id)
                                  }}
                                >
                                  <DeleteIcon className="#919195" />
                                </div>
                                <div
                                  onClick={() => {
                                    if (data.name === '') {
                                      void message.error(VALIDATION_ERROR.SUBMODULE_NAME)
                                      return
                                    }
                                    openEditModal(index, subModuleIndex, data.name, question.skill_checklist_sub_module)
                                  }}
                                >
                                  {data.skill_checklist_question?.length === 0 ? <PlusIcon className="#919195" /> : <EditPenIcon className="#919195" />}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        <CustomBtn
                          isIcon
                          svgIcon={<CirclePlus className="svg" width={25} height={25} />}
                          className="sub-module-btn rounded-2 user-Icon"
                          onClick={() => {
                            handleAddQuestionInput(index)
                          }}
                        />
                      </div>
                      <div className="flex justify-end pt-4">
                        <DeleteIcon
                          className="#d1293d"
                          onClick={() => {
                            handleMainSectionDelete(index, question?.id)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {/* button */}
          <div className="flex justify-end">
            <CustomBtn isIcon svgIcon={<CirclePlus />} text={t('button.newSection')} className="new-que-btn p-2 user-Icon rounded-3" onClick={handleAddModule} />
          </div>
        </div>
      </Form>
      {isModalVisible && (
        <AddSubModuleQue
          isModalOpen={isModalVisible}
          setIsModalOpen={cancelOpenEditModal}
          modalName={modalName}
          subModData={modalSubModData}
          onSave={handleSaveQuestions}
          setDeletedQueId={setDeletedSubModuleQueIds}
        />
      )}
    </>
  )
}

export default AddEditCheckList
