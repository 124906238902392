import React, { useEffect, useState } from 'react'
import { Button, message, Switch } from 'antd'
import { MinusIcon, PlusIcon } from 'assets/svgs'
import { useAppDispatch } from 'hooks/reduxHooks'
import useDebounce from 'hooks/useDebounce'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { formatToTitleCase } from 'utils/utils'

import { editTimeEntryApproval, getTimeEntryApproval } from './api'

interface data {
  id: string
  key: string
  value: string
}

const TimeEntryApproval = () => {
  const [data, setData] = useState<data[]>([])
  const [pendingUpdates, setPendingUpdates] = useState<Array<{ id: string; value: string }>>([])
  const [originalValue, setOriginalValue] = useState<Record<string, string>>({})
  const debouncedUpdates = useDebounce(pendingUpdates, 1000)
  const MIN_LIMIT = 1
  const MAX_LIMIT = 100

  useEffect(() => {
    // eslint-disable-next-line
    const updatePromises = debouncedUpdates.map(({ id, value }) => {
      return handleUpdateTimeEntryApproval(id, value, false)
    })

    if (updatePromises.length > 0) {
      Promise.all(updatePromises)
        .then(() => {
          void handleGetTimeEntryApproval()
          setPendingUpdates([])
        })
        .catch((error) => {
          console.error('Error updating time entry approvals:', error)
        })
    }
    // eslint-disable-next-line
  }, [debouncedUpdates])

  const handleChangeValue = (id: string, newValue: string) => {
    setData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item)))
    setPendingUpdates((prev) => [...prev.filter((item) => item.id !== id), { id, value: newValue }])
  }

  const handleChangeInputValue = (id: string, newValue: string) => {
    setData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item)))
  }

  const handleGetTimeEntryApproval = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getTimeEntryApproval()
    if (response?.data?.statusCode === 1) {
      setData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleUpdateTimeEntryApproval = async (id: string, value: string, update = true) => {
    dispatch(setGlobalLoader(true))
    const response = await editTimeEntryApproval(id, { value })
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      if (update) {
        void handleGetTimeEntryApproval()
      }
    }
    dispatch(setGlobalLoader(false))
  }

  useEffect(() => {
    void handleGetTimeEntryApproval()
    // eslint-disable-next-line
  }, [])

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.timeEntryApproval'),
        isBack: true,
      })
    )
  }, [dispatch])

  return (
    <div className="max-w-1200 mx-auto  rounded-4  bg-white">
      {data?.map((item, index) => (
        <div
          key={item?.id}
          className={`flex gap-2 w-full items-center ${['active', 'in_active']?.includes(item?.value) ? 'p-4' : 'p-2 pl-4'} ${data?.length === index + 1 ? '' : 'border-b-stroke'}`}
        >
          <p className="para-p2 font-medium text-neutral-800 w-70">{formatToTitleCase(item?.key)}</p>
          <div className="w-30 flex justify-center items-center">
            {['active', 'in_active']?.includes(item?.value) ? (
              <Switch
                defaultChecked={item?.value === 'active'}
                onChange={(checked) => {
                  void handleUpdateTimeEntryApproval(item?.id, checked ? 'active' : 'in_active', true)
                }}
              />
            ) : (
              <div className="w-full flex items-center gap-2 justify-center aline-self flex-grow justify-start ">
                <div className="counter-box">
                  <Button
                    icon={<MinusIcon />}
                    disabled={Number(item?.value) === MIN_LIMIT}
                    onClick={() => {
                      const newValue = Number(item?.value) - 1
                      handleChangeValue(item?.id, newValue?.toString())
                    }}
                    className="counter-btn-min"
                  />
                  <div className="counter-text-wrapper">
                    <input
                      className="counter-text"
                      value={item?.value}
                      onFocus={() => {
                        setOriginalValue({ ...originalValue, [item.id]: item?.value })
                      }}
                      onChange={(e) => {
                        const inputValue = e?.target?.value
                        if (!isNaN(Number(inputValue))) {
                          handleChangeInputValue(item?.id, inputValue)
                        }
                      }}
                      onBlur={(e) => {
                        const newValue = Math.floor(Number(e?.target?.value))
                        if (newValue >= MIN_LIMIT && newValue <= MAX_LIMIT && e?.target?.value !== originalValue[item?.id]) {
                          handleChangeValue(item?.id, newValue?.toString())
                        } else {
                          handleChangeInputValue(item?.id, originalValue[item.id] ?? MIN_LIMIT.toString())
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e?.key === 'e' || e?.key === 'E') {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  <Button
                    icon={<PlusIcon className="plus-icon-solid " />}
                    disabled={Number(item?.value) === MAX_LIMIT}
                    onClick={() => {
                      const newValue = Number(item?.value) + 1
                      handleChangeValue(item?.id, newValue?.toString())
                    }}
                    className="counter-btn-plus"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default TimeEntryApproval
