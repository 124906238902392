import { useState } from 'react'
import { Form } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type IFieldData, type IModalProps } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

const AddNewCategory = ({ setIsModalOpen }: IModalProps) => {
  const [form] = Form.useForm()
  const [error, setError] = useState({
    categoryName: '',
  })

  const onFinish = (values: string) => {
    console.log(values)
    setIsModalOpen(false)
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={t('label.addNewCategory')} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start gap-5  w-full">
            <div className="flex flex-col w-full">
              <div className="h-64 aline-self">
                <InputField rules={commonInputFieldValidation(VALIDATION_STRINGS.CATEGORY)} placeholder={t('placeholder.categoryName')} name="categoryName" inputClass={''} />
              </div>
              {error?.categoryName && <p className="error-container">{error.categoryName}</p>}
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  setIsModalOpen(false)
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddNewCategory
