import { useCallback, useEffect, useState } from 'react'
import { Button, Form, message, Switch } from 'antd'
import { MinusIcon, PlusIcon } from 'assets/svgs'
import { ScheduleAndRequestText } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { debounce } from 'lodash'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IScheduleOrShiftItem } from 'types'

import { getScheduleAndRequest, updateScheduleAndRequest } from './api/index'

import './ScheduleAndRequest.scss'

const ScheduleAndRequest = () => {
  const dispatch = useAppDispatch()
  const [hours, setHours] = useState<number>(0)
  const [shiftRequestCount, setShiftRequestCount] = useState<number>(0)
  const [scheduleAndRequestData, setScheduleAndRequestData] = useState<IScheduleOrShiftItem[]>([])
  const [prevDefaultValues, setPrevDefaultValues] = useState<{
    hours: number
    shiftRequestCount: number
  }>({
    hours: 0,
    shiftRequestCount: 0,
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.scheduleAndRequest'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetscheduleAndRequestSettings()
  }, [])

  useEffect(() => {
    if (scheduleAndRequestData) {
      const initialMinutes = Number(
        scheduleAndRequestData?.filter((it: IScheduleOrShiftItem) => it?.setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST)?.[0]?.value
      )
      const initialShiftRequestCount = scheduleAndRequestData?.filter((it: IScheduleOrShiftItem) => it?.setting === ScheduleAndRequestText.HOW_MANY_TEMPS_CAN_REQUEST_SHIFT)?.[0]
        ?.value

      setHours(initialMinutes / 60)
      setShiftRequestCount(Number(initialShiftRequestCount))
    }
  }, [scheduleAndRequestData])

  const debouncedUpdateStatus = useCallback(
    debounce(async (id: string, data: { value: string }) => {
      dispatch(setGlobalLoader(true))
      const response = await updateScheduleAndRequest(id, data)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        await handleGetscheduleAndRequestSettings()
      }
      dispatch(setGlobalLoader(false))
    }, 800),
    []
  )

  const formatFieldName = (fieldName: string) => {
    const result = fieldName.replace(/_/g, ' ')
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const handleGetscheduleAndRequestSettings = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getScheduleAndRequest()
    if (response?.status === 200) {
      setScheduleAndRequestData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleHoursMinusClick = (text: string) => {
    const shiftSettingData = getClickedDataId(text, 'shift')

    if (hours >= 0.5) {
      setHours(hours - 0.5)
      const data = {
        value: ((hours - 0.5) * 60)?.toString(),
      }
      void debouncedUpdateStatus(shiftSettingData?.[0]?.id, data)
    }
  }

  const handleHoursPlusClick = (text: string) => {
    const shiftSettingData = getClickedDataId(text, 'shift')

    const updatedhours = hours + 0.5
    setHours(updatedhours)
    const data = {
      value: (updatedhours * 60)?.toString(),
    }
    void debouncedUpdateStatus(shiftSettingData?.[0]?.id, data)
  }

  const handleTempMinusClick = (text: string) => {
    const shiftSettingData = getClickedDataId(text, 'shift')
    if (shiftRequestCount > 0) {
      setScheduleAndRequestData((prevData) => prevData.map((item) => (item.setting === text ? { ...item, value: (shiftRequestCount - 1)?.toString() } : item)))
      setShiftRequestCount(shiftRequestCount - 1)
      const data = {
        value: (shiftRequestCount - 1)?.toString(),
      }
      void debouncedUpdateStatus(shiftSettingData?.[0]?.id, data)
    }
  }

  const handleTempPlusClick = (text: string) => {
    setScheduleAndRequestData((prevData) => prevData.map((item) => (item.setting === text ? { ...item, value: (Number(shiftRequestCount) + 1)?.toString() } : item)))
    const shiftSettingData = getClickedDataId(text, 'shift')
    setShiftRequestCount(Number(shiftRequestCount) + 1)

    const data = {
      value: (Number(shiftRequestCount) + 1)?.toString(),
    }
    void debouncedUpdateStatus(shiftSettingData?.[0]?.id, data)
  }

  const handleToggle = (text: string, type: 'shift' | 'schedule') => {
    const clickedData = getClickedDataId(text, type)
    const id = clickedData?.[0]?.id
    const data = {
      value: clickedData?.[0]?.value === 'active' ? 'in_active' : 'active',
    }
    void handleUpdateStatus(id, data)
  }

  const getClickedDataId = (text: string, type: 'shift' | 'schedule') => {
    const filteredData = scheduleAndRequestData?.filter((item: IScheduleOrShiftItem) => item.type === type)
    const clickedData = filteredData?.filter((it: IScheduleOrShiftItem) => it?.setting === text)
    return clickedData ?? [{ id: '', value: '' }]
  }

  const handleUpdateStatus = async (id: string, data: { value: string }) => {
    dispatch(setGlobalLoader(true))
    const response = await updateScheduleAndRequest(id, data)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetscheduleAndRequestSettings()
    }
    dispatch(setGlobalLoader(false))
  }

  const handleChangeInputValue = (id: string, setting: string, newValue: string) => {
    if (setting !== ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST) {
      setScheduleAndRequestData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item)))
    } else {
      setHours(parseFloat(newValue))
    }
  }

  const isActiveOrInactive = (text: string, type: 'schedule' | 'shift') => {
    const filteredData = scheduleAndRequestData?.filter((item: IScheduleOrShiftItem) => item.type === type)
    const data = filteredData?.find((it: IScheduleOrShiftItem) => it.setting === text)?.value
    return data === 'active'
  }

  const handleChangeValue = (id: string, setting: string, newValue: string) => {
    if (setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST && hours !== prevDefaultValues.hours) {
      setScheduleAndRequestData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: (Number(newValue) * 60)?.toString() } : item)))
      void debouncedUpdateStatus(id, { value: (Number(newValue) * 60)?.toString() })
      setPrevDefaultValues({ ...prevDefaultValues, hours: Number(newValue) })
    } else if (setting !== ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST && shiftRequestCount !== prevDefaultValues.shiftRequestCount) {
      setScheduleAndRequestData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item)))
      void debouncedUpdateStatus(id, { value: newValue })
      setPrevDefaultValues({ ...prevDefaultValues, shiftRequestCount: Number(newValue) })
    }
  }

  return (
    <Form className="schedule-and-request-container flex flex-col items-start flex-grow rounded-4 border-stroke">
      {/* Schedule Section */}
      <div className="flex items-start flex-grow border-b-stroke w-full">
        <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30">
          <p className="text-neutral-800 activity-text font-medium">{ScheduleAndRequestText.SCHEDULE}</p>
        </div>
        <div className="flex flex-col w-70">
          {scheduleAndRequestData
            ?.filter((item) => item.type === 'schedule')
            .map((item: IScheduleOrShiftItem) => (
              <div key={item.id} className="flex border-b-stroke">
                <div className="w-full flex p-4 items-center gap-2 justify-start flex-grow">
                  <p className="text-neutral-800 para-p2 font-medium">{formatFieldName(item.setting)}</p>
                </div>
                <div className="w-full flex p-4 items-center gap-2 justify-center flex-grow">
                  {item.setting !== ScheduleAndRequestText.HOW_MANY_TEMPS_CAN_REQUEST_SHIFT && item.setting !== ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST ? (
                    <Switch
                      checked={isActiveOrInactive(item.setting, 'schedule')}
                      onChange={() => {
                        handleToggle(item.setting, 'schedule')
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Shift Section */}
      <div className="flex items-start flex-grow border-b-stroke w-full">
        <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30">
          <p className="text-neutral-800 activity-text font-medium">{ScheduleAndRequestText.SHIFTS}</p>
        </div>
        <div className="flex flex-col w-70">
          {scheduleAndRequestData
            ?.filter((item) => item.type === 'shift')
            .map((item: IScheduleOrShiftItem) => (
              <div key={item.id} className="flex border-b-stroke">
                <div className="w-full flex p-4 items-center gap-2 justify-start flex-grow">
                  <p className="text-neutral-800 para-p2 font-medium">{formatFieldName(item.setting)}</p>
                </div>
                <div className="w-full flex p-4 items-center gap-2 justify-center flex-grow">
                  {item.setting !== ScheduleAndRequestText.HOW_MANY_TEMPS_CAN_REQUEST_SHIFT && item.setting !== ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST ? (
                    <Switch
                      checked={isActiveOrInactive(item.setting, 'shift')}
                      onChange={() => {
                        handleToggle(item.setting, 'shift')
                      }}
                    />
                  ) : (
                    <div className="flex">
                      <div className="w-full flex p-4 items-center gap-2 justify-center flex-grow">
                        <div className="counter-box">
                          <Button
                            icon={<MinusIcon />}
                            onClick={() => {
                              item.setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST
                                ? handleHoursMinusClick(item.setting)
                                : handleTempMinusClick(item.setting)
                            }}
                            className="counter-btn-min"
                          />
                          <div className="counter-text-wrapper">
                            <input
                              type="number"
                              min="0"
                              className="counter-text"
                              value={item.setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST ? hours : shiftRequestCount}
                              onFocus={() => {
                                setPrevDefaultValues({
                                  hours,
                                  shiftRequestCount,
                                })
                              }}
                              onKeyDown={(e) => {
                                if (item.setting !== ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST) {
                                  if (e.key === '.' || e.key === ',') {
                                    e.preventDefault()
                                  }
                                }
                              }}
                              onChange={(e) => {
                                const inputValue = e.target.value
                                handleChangeInputValue(item.id, item.setting, inputValue)
                              }}
                              onBlur={(e) => {
                                const newValue = e.target.value
                                handleChangeValue(item.id, item.setting, newValue.toString())
                              }}
                            />
                          </div>
                          <Button
                            icon={<PlusIcon className="plus-icon-solid" />}
                            onClick={() => {
                              item.setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST
                                ? handleHoursPlusClick(item.setting)
                                : handleTempPlusClick(item.setting)
                            }}
                            className="counter-btn-plus"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Form>
  )
}

export default ScheduleAndRequest
