import { message } from 'antd'
import http from 'services/Service'
import { type IChangePwdData, type IProfileFormDataType } from 'types'

export const getProfileData = async () => {
  try {
    const response = http.get('/admin/my-profile')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const editProfileData = async (payload: IProfileFormDataType) => {
  try {
    const response = http.patch('/admin/edit-profile', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const changePassword = async (payload: IChangePwdData) => {
  try {
    const response = http.patch('/auth/change-password/admin', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
