import { useEffect } from 'react'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, ThreeDotIcon } from 'assets/svgs'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IGetStatusOptionColumn, IPaginationInfo, ISorting } from 'types'
import { formatTitle } from 'utils/Helper'

import HeadingText from 'components/common/HeadingText'

import { deleteStatusOption, getAllStatusOptions, updateStatusOption } from '../api'

interface IColumns {
  setEditId: React.Dispatch<React.SetStateAction<string>>
  setIsAddNewStatus: React.Dispatch<React.SetStateAction<boolean>>
  setData: React.Dispatch<React.SetStateAction<Partial<IGetStatusOptionColumn>>> | any
  paginationInfo: IPaginationInfo
  setPaginationInfo: React.Dispatch<React.SetStateAction<IPaginationInfo>>
  sortProviderTable: ISorting
  sortFacilityTable: ISorting
  search: string
  setListData: any
  actionMenuOpen: any
  setActionMenuOpen: any
  selectedValue: string | undefined
  isAddNewStatus: boolean
  setDeleteStatusId: React.Dispatch<React.SetStateAction<string>>
  setDeleteStatusName: React.Dispatch<React.SetStateAction<string>>
  setIsDeleteStatusModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const useColumn = ({
  setEditId,
  setIsAddNewStatus,
  setData,
  paginationInfo,
  setPaginationInfo,
  sortProviderTable,
  sortFacilityTable,
  search,
  setListData,
  actionMenuOpen,
  setActionMenuOpen,
  selectedValue,
  isAddNewStatus,
  setDeleteStatusId,
  setDeleteStatusName,
  setIsDeleteStatusModalOpen,
}: IColumns) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedValue === 'provider' && sortProviderTable) {
      void handleGetAllStatusData(selectedValue, paginationInfo.limit, search ? 0 : paginationInfo.offset, sortProviderTable?.order, sortProviderTable?.field)
    } else {
      if (selectedValue === 'facility' && sortFacilityTable)
        void handleGetAllStatusData(selectedValue, paginationInfo.limit, search ? 0 : paginationInfo.offset, sortFacilityTable?.order, sortFacilityTable?.field)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.offset, isAddNewStatus, sortProviderTable, sortFacilityTable, search, selectedValue, isAddNewStatus])

  const getMenu = (record: IGetStatusOptionColumn, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            handleEditStatusOption(record)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <div className=" cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      void onSwitchChange(checked, record, record?.id)
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '3',
          onClick: () => {
            setIsDeleteStatusModalOpen(true)
            setDeleteStatusId(record.id ?? '')
            setDeleteStatusName(record.name)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const handleEditStatusOption = (record: IGetStatusOptionColumn) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, name, description, text_color, background_color, status, status_for } = record
    setEditId(id ?? '')
    setData({
      id: '',
      name,
      description,
      text_color,
      background_color,
      status,
      status_for,
    })
    setIsAddNewStatus(true)
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen: any) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleGetAllStatusData = async (status: string, limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllStatusOptions(status, limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditRoleData = async (id: string, values: any) => {
    dispatch(setGlobalLoader(true))
    const response = await updateStatusOption(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetAllStatusData(selectedValue ?? '', paginationInfo.limit, paginationInfo.offset, sortProviderTable?.order, sortProviderTable?.field)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSwitchChange = async (checked: boolean, data: any, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    void handleEditRoleData(id, newData)
  }

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen: any) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleDeleteCancelReason = async (id: string) => {
    const response = await deleteStatusOption(id)
    if (response?.status === 200) {
      setIsDeleteStatusModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleGetAllStatusData(selectedValue ?? '', paginationInfo.limit, newOffset, sortProviderTable?.order, sortProviderTable?.field)
      }
    }
  }

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    if (selectedValue === 'provider' && sortProviderTable) {
      void handleGetAllStatusData(selectedValue, paginationInfo.limit, newOffset, sortProviderTable?.order, sortProviderTable?.field)
    } else {
      if (selectedValue === 'facility' && sortFacilityTable)
        void handleGetAllStatusData(selectedValue, paginationInfo.limit, newOffset, sortFacilityTable?.order, sortFacilityTable?.field)
    }
  }

  const statusOptionsColumn: ColumnsType<IGetStatusOptionColumn> = [
    {
      title: t('table.statusOption.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 351,
      render: (_value, record) => {
        return <HeadingText classString={`para-p1-regular text-neutral-800`} text={formatTitle(record.name ? record.name : '-')} />
      },
    },
    {
      title: t('table.statusOption.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 351,
      render: (_value, record) => {
        return <HeadingText classString={` `} text={formatTitle(record.description ? record.description : '-')} />
      },
    },
    {
      title: t('table.statusOption.badgeColor'),
      dataIndex: 'text_color',
      key: 'text_color',
      align: 'center',
      className: 'center-align-sortedfield',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 351,
      render: (_value, record) => {
        return record?.text_color && record?.background_color ? (
          <div className="flex flex-col justify-center items-center">
            <div
              className="rounded-2 py-1 px-2_5 w-max"
              style={{
                backgroundColor: record?.background_color,
                color: record?.text_color,
              }}
            >
              {t('label.badgeLabel')}
            </div>
          </div>
        ) : (
          '-'
        )
      },
    },
    {
      title: t('table.statusOption.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 351,
      className: 'center-align-sortedfield',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.statusOption.actions'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return { statusOptionsColumn, handlePageChange, handleDeleteCancelReason }
}
