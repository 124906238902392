import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import dayjs from 'dayjs'
import type { ICompetencyTestQue, IFieldData, IQuizTest, IReferanceFormQue, ISkillChecklistQuestion } from 'types'

export const formatTitle = (name: string): string => name?.replace(/_/g, ' ')?.replace(/\b\w/g, (char) => char?.toUpperCase())

export const handleFieldsChange = (allFields: IFieldData[], setError: React.Dispatch<React.SetStateAction<any>>) => {
  allFields.forEach((field: IFieldData) => {
    const fieldName = field.name[0]
    const fieldErrors = field.errors ?? []
    setError((prev: any) => ({
      ...prev,
      [fieldName]: fieldErrors.length > 0 ? fieldErrors[0] : '',
    }))
  })
}

// validate skill checklist json before submit
export const validateJSON = (data: any[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(data) || data.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.NOBLANKDATA }
  }

  for (const module of data) {
    if (!module.name || typeof module.name !== 'string' || module.name.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_WITH_SUBMODULE }
    }
    if (typeof module.order !== 'number') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_WITH_SUBMODULE }
    }
    if (!Array.isArray(module.skill_checklist_sub_module) || module.skill_checklist_sub_module.length === 0) {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_SUBMODULE }
    }

    for (const subModule of module.skill_checklist_sub_module) {
      if (!subModule.name || typeof subModule.name !== 'string' || subModule.name.trim() === '') {
        return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
      }
      if (!Array.isArray(subModule.skill_checklist_question) || subModule.skill_checklist_question.length === 0) {
        return { isValid: false, error: VALIDATION_ERROR.NO_QUE_SUBMODULE }
      }

      for (const question of subModule.skill_checklist_question) {
        if (!question.question || typeof question.question !== 'string' || question.question.trim() === '') {
          return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
        }
        if (typeof question.order !== 'number') {
          return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
        }
      }
    }
  }

  return { isValid: true, error: null }
}

// validate compentency json before submit
export const validateQuestionsForCompentancy = (questions: ICompetencyTestQue[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
  }
  for (const question of questions) {
    if (
      !question.question ||
      !question.order ||
      !Array.isArray(question.competency_test_option) ||
      question.competency_test_option.length === 1 ||
      question.competency_test_option.length === 0
    ) {
      return { isValid: false, error: VALIDATION_ERROR.BLANK_QUE_OPT }
    }
    let hasAnswer = false
    for (const option of question.competency_test_option) {
      if (!option.option || !option.order) {
        return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
      }
      if (option.is_answer) {
        hasAnswer = true
      }
    }
    if (!hasAnswer) {
      return { isValid: false, error: VALIDATION_ERROR.OPT_SELECTED }
    }
  }
  return { isValid: true, error: null }
}

export const validateQuestionsForCompentancyPreview = (questions: ICompetencyTestQue[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
  }

  for (const question of questions) {
    if (!question.question || !question.order || !Array.isArray(question.competency_test_option) || question.competency_test_option.length === 0) {
      return { isValid: false, error: VALIDATION_ERROR.BLANK_QUE_OPT_ONE }
    }

    for (const option of question.competency_test_option) {
      if (!option.option || !option.order) {
        return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
      }
    }
  }

  return { isValid: true, error: null }
}

// validate faq json before submit
export const validateQuestionsForFaq = (questions: IQuizTest[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions)) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.NOBLANKDATA }
  }
  for (const q of questions) {
    if (!q.question || typeof q.question !== 'string' || q.question.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_ANS }
    }
    if (!q.answer || typeof q.answer !== 'string' || q.answer.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_ANS }
    }
    if (typeof q.order !== 'number') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_ANS }
    }
  }
  return { isValid: true, error: null }
}

// add deleted id state for compentency
export const addCompentencyDeletedData = (updatedValues: any, deleteQueIds: string[], deleteOptionIds: string[]) => {
  const newData = { ...updatedValues }
  if (deleteQueIds && deleteQueIds.length > 0) {
    newData.delete_question = deleteQueIds
  }
  if (deleteOptionIds && deleteOptionIds.length > 0) {
    newData.delete_option = deleteOptionIds
  }
  return newData
}

// validation for sub module question
export const validateQuestionsForSubModule = (questions: ISkillChecklistQuestion[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
  }
  for (const q of questions) {
    if (!q.question || typeof q.question !== 'string' || q.question.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
    }
  }
  return { isValid: true, error: null }
}

// add deleted id state for skill check list
export const addSkillChecklistDeletedData = (updatedValues: any, deleteModule: string[], deleteQuestion: string[], deleteSubModule: string[]) => {
  const newData = { ...updatedValues }

  if (deleteModule && deleteModule.length > 0) {
    newData.delete_module = deleteModule
  }
  if (deleteSubModule && deleteSubModule.length > 0) {
    newData.delete_sub_module = deleteSubModule
  }
  if (deleteQuestion && deleteQuestion.length > 0) {
    newData.delete_question = deleteQuestion
  }
  return newData
}

// helper function for set dynamic string and id for breadcrumb label
export const resolveBreadcrumbLabel = (url: string, location: any, breadcrumbMap: Record<string, string>): { url: string; label: string } => {
  let label = breadcrumbMap[url]

  if (url === '/settings/skill-checklist/edit') {
    const getData = JSON.parse(localStorage.getItem('skillState') ?? 'null') as { editId?: string } | null
    const editId = getData?.editId ?? ''
    return { url: `${url}/${editId}`, label }
  }
  if (url?.includes('/facilities/')) {
    if (location?.state?.facilityName) localStorage.setItem('facilityName', location?.state?.facilityName ?? 'Facility Profile')
    const name = location?.state?.facilityName ?? localStorage.getItem('facilityName') ?? 'Facility Profile'
    label = `${formatTitle(name)}`
    return { url, label }
  }
  // if (url?.includes('/settings/referance-form-setting/edit-form/referance-form-preview')) {
  //   return { url, label: 'Preview Form' }
  // }
  // if (url?.includes('/settings/referance-form-setting/edit-form/')) {
  //   return { url, label: 'Edit Form' }
  // }

  if (url === '/settings/skill-checklist/preview' || url === '/settings/skill-checklist/edit/preview' || url === '/settings/skill-checklist/add/preview') {
    const getData = JSON.parse(localStorage.getItem('skillState') ?? 'null') as { name?: string } | null
    const name = location.state?.record?.name ?? getData?.name ?? ''
    label = ` ${String(name)}`
  }

  if (
    url === '/settings/skill-checklist/preview/subtopic' ||
    url === '/settings/skill-checklist/edit/preview/subtopic' ||
    url === '/settings/skill-checklist/add/preview/subtopic'
  ) {
    const name = location.state?.subModuleName ?? ''
    label = ` ${String(name)}`
  }

  if (url === '/settings/compentency-test/add-test/preview' || url === '/settings/compentency-test/edit-test/preview') {
    const getData = JSON.parse(localStorage.getItem('compentancyState') ?? 'null') as { name?: string } | null
    const name = location.state?.record?.name ?? getData?.name ?? ''
    label = ` ${String(name)}`
  }

  if (url === '/settings/compentency-test/edit-test') {
    const getData = JSON.parse(localStorage.getItem('compentancyState') ?? 'null') as { editId?: string } | null
    const editId = getData?.editId ?? ''
    url += `/${editId}`
  }

  if (url === '/settings/user/role-management/edit-role') {
    const getData = localStorage.getItem('editRoleId') ?? ''
    url += `/${getData}`
  }

  if (url === '/settings/referance-form-setting/add-form/referance-form-preview' || url === '/settings/referance-form-setting/edit-form/referance-form-preview') {
    const getData = JSON.parse(localStorage.getItem('referanceForm') ?? 'null') as { name?: string } | null
    const name = location.state?.record?.name ?? getData?.name ?? ''
    label = ` ${String(name)}`
  }

  if (url === '/settings/referance-form-setting/edit-form') {
    const getData = JSON.parse(localStorage.getItem('referanceForm') ?? 'null') as { editId?: string } | null
    const editId = getData?.editId ?? ''
    url += `/${editId}`
  }

  return { url, label }
}

// add deleted id for referance form
export const referanceFormDeletedData = (updatedValues: any, deleteQueIds: string[], deleteOptionIds: string[]) => {
  const newData = { ...updatedValues }
  if (deleteQueIds && deleteQueIds.length > 0) {
    newData.delete_question = deleteQueIds
  }
  if (deleteOptionIds && deleteOptionIds.length > 0) {
    newData.delete_option = deleteOptionIds
  }
  return newData
}

// validate json for referance form
export const validateReferanceFormJSON = (data: any[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(data) || data.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.NOBLANK_FORM_DATA }
  }

  for (const module of data) {
    if (!module.name || typeof module.name !== 'string' || module.name.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.FORM_FIELD_NAME }
    }

    if (!module.option_type || typeof module.option_type !== 'string' || module.option_type.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.FORM_OPTION_TYPE }
    }

    if (module.option_type === 'multiple_choice' || module.option_type === 'dropdown') {
      if (!Array.isArray(module.options) || module.options.length === 0) {
        return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
      }

      if (module.option_type === 'multiple_choice' && module.options.length < 2) {
        return { isValid: false, error: VALIDATION_ERROR.MINIMUM_OPTIONS_MULTICHOICE }
      }
      if (module.option_type === 'dropdown' && module.options.length < 2) {
        return { isValid: false, error: VALIDATION_ERROR.MINIMUM_OPTIONS_DROPDOWN }
      }
    }

    if (module.options && Array.isArray(module.options)) {
      for (const option of module.options) {
        if (!option.name || typeof option.name !== 'string' || option.name.trim() === '') {
          return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
        }
      }
    }
  }

  return { isValid: true, error: null }
}

export const transformOptionGroup = (response: any): any[] => {
  const transformedOption: any[] = []
  for (const [key, value] of Object.entries(response.data)) {
    const options: any[] = (value as any[]).map((item) => ({
      label: item?.name,
      value: item?.id,
    }))
    transformedOption.push({
      label: formatTitle(key),
      title: key,
      options,
    })
  }
  return transformedOption
}

export const formatTime = (time?: string): string => {
  if (!time) return '-'

  // If the time string doesn't include a date, append a default date
  const dateTime = `1970-01-01T${time}`
  return dayjs(dateTime).format('h:mm A')
}
// export const isEqualData = (arr1: IReferanceFormQue[], arr2: IReferanceFormQue[]): boolean => {
//   if (arr1.length !== arr2.length) {
//     return false
//   }

//   for (let i = 0; i < arr1.length; i++) {
//     const obj1 = arr1[i]
//     const obj2 = arr2[i]

//     // Compare the top-level properties of IReferanceFormQue
//     if (obj1.id !== obj2.id || obj1.name !== obj2.name || obj1.option_type !== obj2.option_type || obj1.order !== obj2.order) {
//       return false
//     }

//     // Compare the options arrays, if they exist
//     if ((obj1.options && !obj2.options) ?? (!obj1.options && obj2.options)) {
//       return false
//     }

//     if (obj1.options && obj2.options) {
//       if (obj1.options.length !== obj2.options.length) {
//         return false
//       }

//       for (let j = 0; j < obj1.options.length; j++) {
//         const opt1 = obj1.options[j]
//         const opt2 = obj2.options[j]

//         if (opt1.id !== opt2.id || opt1.name !== opt2.name || opt1.order !== opt2.order) {
//           return false
//         }
//       }
//     }
//   }

//   return true
// }

export const removeSorting = (setSortTable: React.Dispatch<React.SetStateAction<{ order: string; field: string }>>) => {
  const sortIcons = document.querySelectorAll('.anticon-caret-down, .anticon-caret-up')
  const sortBg = document.querySelectorAll('.ant-table-cell, .ant-table-column-has-sorters')
  sortBg.forEach((bg) => {
    if (bg.classList.contains('ant-table-column-sort')) {
      bg.classList.remove('ant-table-column-sort')
    }
  })
  sortIcons.forEach((icon) => {
    if (icon.classList.contains('active')) {
      icon.classList.remove('active')
    }
  })
  setSortTable({
    order: '',
    field: '',
  })
}

// Combined comparison function
export const isEqualData = (arr1: IReferanceFormQue[] | any, arr2: IReferanceFormQue[] | any, type: string): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  if (type === 'test') {
    // Test type comparison (using logic from deepCompareQuestions)
    for (let i = 0; i < arr1.length; i++) {
      const question1 = arr1[i]
      const question2 = arr2[i]

      if (question1.id !== question2.id || question1.created_at !== question2.created_at || question1.question !== question2.question || question1.order !== question2.order) {
        return false
      }

      if (question1.competency_test_option.length !== question2.competency_test_option.length) {
        return false
      }

      for (let j = 0; j < question1.competency_test_option.length; j++) {
        const option1 = question1.competency_test_option[j]
        const option2 = question2.competency_test_option[j]

        if (
          option1.id !== option2.id ||
          option1.created_at !== option2.created_at ||
          option1.option !== option2.option ||
          option1.order !== option2.order ||
          option1.is_answer !== option2.is_answer
        ) {
          return false
        }
      }
    }
  } else if (type === 'refForm') {
    // Default type comparison (IReferanceFormQue logic)
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i] as IReferanceFormQue
      const obj2 = arr2[i] as IReferanceFormQue

      if (obj1.id !== obj2.id || obj1.name !== obj2.name || obj1.option_type !== obj2.option_type || obj1.order !== obj2.order) {
        return false
      }

      // Compare the options arrays, if they exist
      if ((obj1.options && !obj2.options) ?? (!obj1.options && obj2.options)) {
        return false
      }

      if (obj1.options && obj2.options) {
        if (obj1.options.length !== obj2.options.length) {
          return false
        }

        for (let j = 0; j < obj1.options.length; j++) {
          const opt1 = obj1.options[j]
          const opt2 = obj2.options[j]

          if (opt1.id !== opt2.id || opt1.name !== opt2.name || opt1.order !== opt2.order) {
            return false
          }
        }
      }
    }
  }
  //  else if (type === 'skill') {
  //   // New 'skill' type comparison
  //   for (let i = 0; i < arr1.length; i++) {
  //     const skill1 = arr1[i]
  //     const skill2 = arr2[i]

  //     if (skill1.id !== skill2.id || skill1.created_at !== skill2.created_at || skill1.name !== skill2.name || skill1.order !== skill2.order) {
  //       console.log('first')
  //       return false
  //     }

  //     if (skill1.skill_checklist_sub_module.length !== skill2.skill_checklist_sub_module.length) {
  //       console.log(' dsd first')
  //       return false
  //     }

  //     for (let j = 0; j < skill1.skill_checklist_sub_module.length; j++) {
  //       const subModule1 = skill1.skill_checklist_sub_module[j]
  //       const subModule2 = skill2.skill_checklist_sub_module[j]

  //       if (subModule1.id !== subModule2.id || subModule1.created_at !== subModule2.created_at || subModule1.name !== subModule2.name) {
  //         return false
  //       }

  //       if (subModule1.skill_checklist_question.length !== subModule2.skill_checklist_question.length) {
  //         return false
  //       }

  //       for (let k = 0; k < subModule1.skill_checklist_question.length; k++) {
  //         const question1 = subModule1.skill_checklist_question[k]
  //         const question2 = subModule2.skill_checklist_question[k]

  //         if (question1.id !== question2.id || question1.created_at !== question2.created_at || question1.question !== question2.question || question1.order !== question2.order) {
  //           return false
  //         }
  //       }
  //     }
  //   }
  // }

  return true
}

export const deepMatch = (obj1: any, obj2: any, type: string): boolean => {
  const isEmptyObj = (obj: any) => {
    return Object.keys(obj).length === 0
  }

  if (type === 'refForm') {
    if (isEmptyObj(obj1) && isEmptyObj(obj2)) {
      return true
    }

    if (!obj1?.name || !obj2?.name) {
      return false
    }
    if (obj1.name !== obj2.name) {
      return false
    }
  } else if (type === 'test') {
    if (isEmptyObj(obj1) && isEmptyObj(obj2)) {
      return true
    }
    // Check if both objects have the same set of keys
    const obj1Keys = Object.keys(obj1)
    const obj2Keys = Object.keys(obj2)

    if (obj1Keys.length !== obj2Keys.length) {
      return false // If they don't have the same number of keys, they can't be equal
    }

    // Compare each key-value pair
    for (const key of obj1Keys) {
      if (obj1[key] !== obj2[key]) {
        return false // Return false if any key's value does not match
      }
    }
  } else if (type === 'skill') {
    if (isEmptyObj(obj1) && isEmptyObj(obj2)) {
      return true
    }

    if (!obj1?.name || !obj2?.name) {
      return false
    }
    if (obj1.name !== obj2.name) {
      return false
    }
  }

  return true
}

export const deepCompareArrays = (arr1: any, arr2: any): boolean => {
  if (arr1.length !== arr2.length) return false

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i]
    const obj2 = arr2[i]

    if (!deepCompareObjects(obj1, obj2)) return false
  }

  return true
}

// Recursive helper function to compare objects
const deepCompareObjects = (obj1: any, obj2: any): boolean => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (!deepCompareObjects(obj1[key], obj2[key])) return false
  }

  return true
}
