import { useEffect, useState } from 'react'
import { type ColumnsType } from 'antd/es/table'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import CustomTable from 'components/common/Table/CustomTable'

import RejectFacility from '../Modal/RejectFacility'
import VerifyFacility from '../Modal/VerifyFacility'

const listData = [
  {
    id: '1',
    facility: 'Autumn Lane Health and Rehabilitation',
    phone: '+1 123 456 7890',
    address: '5050 Kimball Bridge RoadJohns Creek, GA, 30005',
    type: 'Assisted Living',
    email: 'care@rocky.com',
  },
]

const VerificationRequest = () => {
  const [isRejectModalOpen, setOpenRejectModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.verificationRequest'),
        isBack: true,
      })
    )
  }, [dispatch])

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleModalClick = () => {
    setIsModalOpen(true)
  }

  const columns: ColumnsType = [
    {
      title: t('table.facility.facility'),
      dataIndex: 'facility',
      key: 'facility',
      align: 'start',
      width: 470,
      render(value, record, index) {
        return <p className="w-156">{record.facility}</p>
      },
    },
    {
      title: t('table.facility.address'),
      dataIndex: 'address',
      key: 'address',
      align: 'start',
      width: 366,
      render(value, record, index) {
        return <p className="w-156">{record.address}</p>
      },
    },
    {
      title: t('table.facility.mobile_no'),
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 177,
      render(value, record, index) {
        return <p className="w-156">{record.phone}</p>
      },
    },
    {
      title: t('table.facility.email'),
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 177,
    },
    {
      title: t('table.facility.type'),
      dataIndex: 'type',
      key: 'type',
      align: 'start',
      width: 177,
      render(value, record, index) {
        return <p className="w-156">{record.type}</p>
      },
    },
    {
      title: t('table.facility.action'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <CustomBtn text={t('button.viewInformation')} type="default" onClick={handleModalClick} />
          </div>
        )
      },
    },
  ]

  return (
    <div className="flex flex-col gap-6 items-start aline-self">
      <CustomTable
        className="verification-table"
        column={columns}
        data={listData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        name={t('label.entries')}
      />
      {isModalOpen && (
        <CustomModal
          open={isModalOpen}
          centered
          footer={null}
          title={t('label.verifyFacility')}
          closable
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsModalOpen(false)
          }}
          content={<VerifyFacility setModalOpen={setIsModalOpen} openRejectModal={setOpenRejectModal} />}
          width={790}
        />
      )}
      {isRejectModalOpen && (
        <CustomModal
          open={isRejectModalOpen}
          centered
          footer={null}
          title={t('label.rejectFacility')}
          closable
          classStrings="add-certificate-modal"
          onCancel={() => {
            setOpenRejectModal(false)
          }}
          content={<RejectFacility isModalOpen={setIsModalOpen} openRejectModal={setOpenRejectModal} />}
          width={600}
        />
      )}
    </div>
  )
}

export default VerificationRequest
