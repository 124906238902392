export const VALIDATION_ERROR = {
  COMMON: {
    PASSWORD: 'Please input your password!',
    EMAIL: 'Enter Email Address',
    EMAIL2: 'Enter valid email address',
    ROLESUBMIT: 'Please enter role name.',
    RADIOSELECT: 'Please select an option',
    ENTERONEQUE: 'Please enter questions',
    NOBLANKDATA: 'Data should not be empty',
    FLOOR: 'Select any Floor',
    ANSWER_BLANK: 'Answer cannot be left blank',
  },
  IMAGE: {
    ONLYIMG: 'Only image files are allowed for upload.',
  },
  PASSWORD: {
    EMPTY_CURRENT_PASSWORD: 'Please input your current password!',
    EMPTY_CONFIRM_PASSWORD: 'Please input your confirm password!',
    EMPTY_NEW_PASSWORD: 'Please input your new password!',
    NOT_MATCH_PASSWORD: 'The passwords that you entered do not match.',
    MINIMUM_LENGTH: 'Password minimum 8 characters long',
    INVALID_PASSWORD: 'Include at least one number, one special character, one uppercase letter, and one lowercase letter.',
  },
  ENTER_QUE_ANS: 'Questions and answer can not be blank',
  BLANK_QUE_OPT: 'Questions cannot be left blank and It must be at least two options.',
  BLANK_QUE_OPT_ONE: 'Questions cannot be left blank and It must be at least one options.',
  BLANK_OPT: 'Options can not be blank',
  OPT_SELECTED: 'Each question must have at least one correct answer',
  ENTER_QUE_WITH_SUBMODULE: 'Questions and sub module option can not be blank',
  ENTER_SUBMODULE: 'Sub module option can not be blank',
  NO_QUE_SUBMODULE: 'Please check submodule. Question is missing',
  SUBMODULE_NAME: 'Please enter a sub module name',
  FORM_FIELD_NAME: 'Field name can not be empty',
  FORM_OPTION_TYPE: 'Please select field type',
  NOBLANK_FORM_DATA: 'Please enter question',
  QUIZ_NEW_QUES: 'Please Fill the last question answer before add new question',
  QUIZ_EDIT_QUES: 'Please Fill the last question answer before edit question',
  COMP_NEW_QUES: 'Please Fill the last question before add new question',
  SELECT_ONE: 'Select any one',
  SINGLE_SELECT: 'Please select any',
  SOMETHING: 'Something Went Wrong',
  MINIMUM_OPTIONS_MULTICHOICE: 'Multiple choice must have at least 2 options.',
  MINIMUM_OPTIONS_DROPDOWN: 'Dropdown must have at least 2 options.',
  DATE_RANGE: 'Please select a valid date range',
  DATE: 'Please Select Date',
  SHIFT_ID: 'Please enter shift id',
  VALID_SHIFT_ID: 'Please enter valid shift id',
}

export const VALIDATION_STRINGS = {
  NUMBER_OF_BED: 'number of beds',
  FACILITY_NAME: 'facility name',
  CHECKLIST_NAME: 'checklist name',
  INPUT_FIELD: 'Please enter',
  SINGLE_SELECT: 'Please select',
  MULTI_SELECT: 'Please select at least one',
  NAME2: 'can only contain letters, hyphens, apostrophes, and spaces',
  LICENCE: 'licence name',
  ABBREVIATION: 'abbreviation',
  SPECIALITY: 'speciality name',
  LINE_OF_BUSINESS: 'line of business',
  SHIFT_TYPE: 'shift type',
  REASON: 'reason',
  CATEGORY: 'category name',
  SELECT_LICENCE: 'licence',
  SELECT_CRED: 'credentials',
  SLECT_CERTI_OR_SPECIALITY: 'certificate or speciality!',
  SELECT_STATE: 'state',
  REQUIRED_FIELD: 'required field',
  SELECT_WORKFORCE: 'workforce',
  FIRST_NAME: 'first name',
  LAST_NAME: 'last name',
  MOBILE: 'mobile',
  EMAIL: 'email',
  SELECT_ROLE: 'role',
  FORM_NAME: 'form name',
  BUSINESS_NAME: 'business name',
  ROLE: 'role',
  START_TIME: 'start time',
  END_TIME: 'end time',
  SOURCE_NAME: 'source name',
  TEST_NAME: 'test name',
  PASSING_SCORE: 'passing score',
  TEST_TIME: 'duration',
  ASSOSIATE_MASTER: 'associate with master client',
  STREET_ADDRESS: 'street address',
  ZIP_CODE: 'zip code',
  CITY: 'city name',
  TEST_EXPIRATION_TIME: 'test expiration time',
  REASSIGNMENT_DURATION: 'eassignment duration',
  NUMBER_OF_ATTEMP: 'number of attempt',
  FLOOR_NAME: 'floor name',
  BEDS: 'beds',
  PO: 'po',
  COST_CENTER: 'cost Center',
  DOC_NAME: 'document name',
  MAX_NUMBER: 'Cannot exceed 14 characters',
  MIN_NUMBER: 'Must be at least 8 characters',
  MOBILE_REQ: 'Enter mobile number',
  MOBILE2: 'Mobile can only contain number',
  PASSWORD: 'password',
  STATUS_NAME: 'status name',
  BADGE_COLOR: 'badge color',
  STATUS_FOR: 'status for Provider or Client',
  ADD_CONTACT: 'Please Add Contact!',
  FLAG_NAME: 'Status Name',
  DNR_REASON: 'Reason',
}
