import React, { useEffect, useState } from 'react'
import type { SearchProps } from 'antd/es/input'
import { PlusIcon } from 'assets/svgs'
import { BUTTON } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import CustomTable from 'components/common/Table/CustomTable'

import { useColumn } from './hooks/useColumn'
import AddDnrReason from './Modal/AddDnrReason'
import AddFlagStatus from './Modal/AddFlagStatus'
import AddProviderStatus from './Modal/AddProviderStatus'

const ProviderSettings = () => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>('provider_status')
  const [paginationInfo, setPaginationInfo] = useState({
    total: 5,
    offset: 0,
    limit: 5,
    current: 1,
  })
  const [isProviderStatusOpen, setIsProviderStatusOpen] = useState<boolean>()
  const [isFlagStatusOpen, setIsFlagStatusOpen] = useState<boolean>()
  const [isAddReasonOpen, setIsAddReasonOpen] = useState<boolean>()
  const [listData, setListData] = useState([])
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [search, setSearch] = useState<string>('')
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const { dnrColumns, flagColumns, providerColumns, handlePageChange } = useColumn({ actionMenuOpen, setActionMenuOpen, setPaginationInfo, paginationInfo })

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.provideSettings'),
        isBack: true,
      })
    )
  }, [dispatch])

  const tabData = [
    { value: 'provider_status', label: 'Provider Status' },
    { value: 'flag_status', label: 'Flag Status' },
    { value: 'dnr_reason', label: 'DNR Reason' },
  ]

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const showAddModal = () => {
    if (selectedValue === 'provider_status') {
      setIsProviderStatusOpen(true)
    } else if (selectedValue === 'flag_status') {
      setIsFlagStatusOpen(true)
    } else {
      setIsAddReasonOpen(true)
    }
  }

  const TableButton = (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={selectedValue === 'provider_status' || selectedValue === 'flag_status' ? BUTTON.CREATE_STATUS : BUTTON.ADD_REASON}
      type="primary"
      className="rounded-3 border-0"
      onClick={showAddModal}
    />
  )

  return (
    <div className="flex gap-6 w-full relative">
      <CustomTable
        name={t('label.shiftTiming')}
        column={selectedValue === 'provider_status' ? providerColumns : selectedValue === 'flag_status' ? flagColumns : dnrColumns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        data={listData}
        isSearch
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        searchPlaceholder={t('placeholder.search')}
        tableButton={TableButton}
        isTab
        option={tabData}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
      />

      {isProviderStatusOpen && (
        <CustomModal
          open={isProviderStatusOpen}
          centered
          title={t('label.createNewStatus')}
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsProviderStatusOpen(false)
          }}
          width={600}
          content={<AddProviderStatus />}
        />
      )}

      {isFlagStatusOpen && (
        <CustomModal
          open={isFlagStatusOpen}
          centered
          title={t('label.createNewStatus')}
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsFlagStatusOpen(false)
          }}
          width={600}
          content={<AddFlagStatus />}
        />
      )}

      {isAddReasonOpen && (
        <CustomModal
          open={isAddReasonOpen}
          centered
          title={t('label.createNewStatus')}
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsAddReasonOpen(false)
          }}
          width={600}
          content={<AddDnrReason />}
        />
      )}
    </div>
  )
}

export default ProviderSettings
