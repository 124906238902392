import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { Form, message, Radio, Switch } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { addDnrReason, editDnrReason } from 'services/SettingServices/FacilityProviderService'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddDnrReasonPayload, type IFieldData } from 'types'
import { handleFieldsChange, removeSorting } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

const badgeData = [
  {
    value: 'clinical',
    label: 'Clinical',
  },
  {
    value: 'professional',
    label: 'Professional',
  },
]

export interface IModal {
  setIsAddReasonOpen: Dispatch<SetStateAction<boolean>>
  setRefreshData: Dispatch<SetStateAction<boolean>>
  setSortTable?: React.Dispatch<React.SetStateAction<{ order: string; field: string }>>
  setEditData: Dispatch<SetStateAction<any>>
  editData: any
}

const AddDnrReason = ({ setIsAddReasonOpen, setRefreshData, setSortTable, setEditData, editData }: IModal) => {
  const [form] = Form.useForm()
  const [status, setStatus] = useState(true)
  const dispatch = useAppDispatch()
  const [error, setError] = useState({
    reason: '',
  })

  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({ ...editData })
      setStatus(editData?.status === 'active')
    }
    // eslint-disable-next-line
  }, [editData])

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          ...values,
          status: status ? 'active' : 'in_active',
        }
        void handleCreateDnrReason(payload)
      })
      .catch((errorInfo) => {
        console.log('Validate Failed:', errorInfo)
      })
  }

  const handleCreateDnrReason = async (values: IAddDnrReasonPayload) => {
    try {
      dispatch(setGlobalLoader(true))
      const response = editData?.id ? await editDnrReason(editData?.id, values) : await addDnrReason(values)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        setIsAddReasonOpen(false)
        setEditData(null)
        form.resetFields()
        if (!editData?.id) {
          setSortTable && removeSorting(setSortTable)
        } else {
          setRefreshData(true)
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setGlobalLoader(false))
    }
  }

  return (
    <Form
      className="add-provider-status-wrapper"
      form={form}
      initialValues={{ reason_type: 'clinical' }}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <InputField
        error={error.reason}
        placeholder={t('placeholder.reason')}
        name="reason"
        wrapperClass="w-full"
        rules={commonInputFieldValidation(VALIDATION_STRINGS.DNR_REASON)}
        delay={100}
      />
      <div className="badge-color-wrapper mb-6">
        <p className="text-neutral-800 para-p2 font-medium">{t('label.reasonType')}</p>
        <div className="badge-wrapper">
          <Form.Item name="reason_type">
            <Radio.Group options={badgeData} rootClassName="dnr-radio-button" />
          </Form.Item>
        </div>
      </div>
      <CommonTextArea name="description" placeholder={t('placeholder.desc')} rows={4} formItemClass="mb-0" />
      <div className="add-provider-footer">
        <div className="flex gap-2 toggle-switch items-center">
          <span>{t('label.active')}</span>
          <Switch
            checked={status}
            onChange={(checked) => {
              setStatus(checked)
            }}
          />
        </div>
        <CustomBtn text={editData?.id ? t('button.edit') : t('button.add')} htmlType="submit" type="primary" className="w-120" onClick={handleSubmit} />
      </div>
    </Form>
  )
}

export default AddDnrReason
