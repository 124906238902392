import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { LinkExpiryIcon, LogoIcon } from 'assets/svgs'
import { LINK_EXPIRY } from 'constants/Constant'

const LinkExpiry = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-neutral-50">
      <div className="link-wrapper screen-height">
        <div className="link-sub-wrapper">
          <LogoIcon />
          <p className="desktop-heading-h3 mt-6 mb-2 text-neutral-800 font-medium">{LINK_EXPIRY.LINK_EXPIRE}</p>
          <p className="para-p2 text-neutral-500 font-medium mb-10">{LINK_EXPIRY.SORRY_TEXT}</p>

          <LinkExpiryIcon />

          <Button
            type="primary"
            htmlType="submit"
            className="mt-10 common-button"
            onClick={() => {
              navigate('/login')
            }}
          >
            {LINK_EXPIRY.LOGIN}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LinkExpiry
