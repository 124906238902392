// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-content {
  padding-inline: 0 !important;
  padding-top: 0 !important;
}

.delete-modal {
  max-width: 600px;
  width: 600px;
  height: 271px;
  padding: 0 !important;
}

.common-btn {
  height: 49px;
  width: 160px;
  border-radius: 12px;
}

.delete-heading {
  font-size: 24px;
}

.cancel-button {
  color: #494a4c;
  border: 1px solid #c4c5c7 !important;
}

.cancel-button:hover {
  color: #494a4c !important;
  border: 1px solid #494a4c !important;
}

.delete-btn {
  background-color: #d1293d;
}

.delete-btn:hover {
  background-color: #d1293d !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/DeleteCancelModal/DeleteCancelModal.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;EACA,oCAAA;AACF;;AAEA;EACE,yBAAA;EACA,oCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,oCAAA;AACF","sourcesContent":[".ant-modal-content {\n  padding-inline: 0 !important;\n  padding-top: 0 !important;\n}\n\n.delete-modal {\n  max-width: 600px;\n  width: 600px;\n  height: 271px;\n  padding: 0 !important;\n}\n\n.common-btn {\n  height: 49px;\n  width: 160px;\n  border-radius: 12px;\n}\n\n.delete-heading {\n  font-size: 24px;\n}\n\n.cancel-button {\n  color: #494a4c;\n  border: 1px solid #c4c5c7 !important;\n}\n\n.cancel-button:hover {\n  color: #494a4c !important;\n  border: 1px solid #494a4c !important;\n}\n\n.delete-btn {\n  background-color: #d1293d;\n}\n\n.delete-btn:hover {\n  background-color: #d1293d !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
