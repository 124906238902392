import { message } from 'antd'
import axios, { type AxiosError } from 'axios'
import type { ILoginForm } from 'types'

import http, { type ApiErrorData } from './Service'

const config = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${String(process.env.REACT_APP_STATIC_TOKEN)}`,
  },
  timeout: 5000,
}

export const login = async (payload: ILoginForm) => {
  try {
    const response = axios.post('/auth/admin/login', payload, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
    return undefined
  }
}

export const getProfileAdminUser = async (id: string) => {
  try {
    const response = axios.get(`/auth/get-profile/admin?id=${id}`, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      console.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
  }
}

export const updateProfileAdminUser = async (payload: any, id: string) => {
  try {
    const response = axios.patch(`/auth/accept-invitation?table=admin&id=${id}`, payload, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
  }
}

export const logOut = async () => {
  try {
    const response = http.post('/auth/logout')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const forgotPassword = async (payload: { email: string }) => {
  try {
    const response = axios.patch('/auth/forgot-password/admin', payload, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
    return undefined
  }
}
