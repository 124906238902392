import React from 'react'
import { Pagination } from 'antd'
import { type PaginationProps } from 'antd/lib/pagination'
import { PaginationLeft, PaginationRight } from 'assets/svgs'

interface PaginationComProps extends PaginationProps {
  onChangePage?: (page: number, pageSize?: number) => void // Adding an optional onChangePage callback
}

const PaginationComponent: React.FC<PaginationComProps> = (props) => {
  const { onChangePage } = props

  // if (Number(props.total) < 10) {
  //   return null
  // }

  const itemRender: PaginationProps['itemRender'] = (current, type, originalElement) => {
    if (type === 'prev') {
      return <PaginationLeft />
    }
    if (type === 'next') {
      return <PaginationRight />
    }
    return originalElement
  }

  const handlePageChange = (page: number, pageSize?: number) => {
    if (onChangePage) {
      onChangePage(page, pageSize)
    }
  }

  return <Pagination {...props} itemRender={itemRender} onChange={handlePageChange} />
}

export default PaginationComponent
