import React, { type ReactNode, useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { DropDownIcon } from 'assets/svgs'

import './Select.scss'

const { Option } = Select

interface FloatingLabelSelectProps {
  label: string
  options: Array<{ value: string; label: string; icon?: ReactNode }>
  value?: string
  onChange?: (value: string) => void
  name: string
  prefixIcon?: React.ReactNode
  disabled?: boolean
  isIcon?: boolean
  error?: string
  rules?: any
  initialValue?: string
  labelClass?: string
  mainClass?: string
  wrapperClass?: string
}

const FloatingLabelSelect: React.FC<FloatingLabelSelectProps> = ({
  disabled,
  mainClass,
  label,
  name,
  prefixIcon,
  options,
  value,
  onChange,
  isIcon,
  error,
  rules,
  labelClass,
  wrapperClass,
  ...props
}) => {
  const [focused, setFocused] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value ?? '')

  useEffect(() => {
    const hasValue = selectedValue ? selectedValue !== '' : false
    setFocused(hasValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    if (selectedValue !== '') {
      setFocused(true)
    } else {
      setFocused(false)
    }
  }

  const handleChange = (newValue: string) => {
    setSelectedValue(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <div className={`${mainClass as string} custom-select w-full`}>
      <Form.Item className={`floating-label-select ${wrapperClass as string} ${error ? '' : 'mb-6'} `} name={name} rules={rules} initialValue={selectedValue}>
        <Select
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          suffixIcon={<DropDownIcon />}
          className={`select ${isIcon ? '' : 'no-icon'}`}
          placeholder=""
          {...props}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              <div className="flex gap-3">
                {option.icon ? option.icon : ''}
                {option.label}
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <label className={`${focused || value ? 'focused filled' : ''} `}>
        {isIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
        {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
        <span className={`floating-label ${isIcon ? '' : 'floating-icon-label'} ${labelClass} `}>{label}</span>
      </label>
      {error && <p className="error-container mb-6">{error}</p>}
    </div>
  )
}

export default FloatingLabelSelect
