import { useEffect, useState } from 'react'
import { Checkbox, Form, message } from 'antd'
import { ModalClose } from 'assets/svgs'
import { COMMON, documentEssentialSub } from 'constants/Constant'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddDocumentAdmin, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { createNewDocument, editDocument } from '../api'

const AddNewDocument = ({
  setIsModalOpen,
  setEditModalOpen,
  categoryId,
  editId,
  data,
}: {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<number | null>>
  setEditModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  categoryId?: string
  editId?: string
  data?: any
}) => {
  const dispatch = useAppDispatch()
  const [isEssential, setIsEssential] = useState(false)
  const [form] = Form.useForm()
  const [error, setError] = useState({
    name: '',
  })

  useEffect(() => {
    if (editId && data) {
      form.setFieldValue('name', data.name)
      setIsEssential(data.is_required)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, data])

  const onFinish = (values: IAddDocumentAdmin) => {
    if (!categoryId) return
    const essential = !!isEssential
    const updatedValues = { ...values, is_required: essential, facility_document_category: categoryId }
    void handleAddEditDocument(updatedValues, editId ?? '')
  }

  const handleAddEditDocument = async (values: IAddDocumentAdmin, editId: string) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await editDocument(editId, values)
    } else {
      response = await createNewDocument(values)
    }
    if (response?.data.statusCode === 1) {
      if (setIsModalOpen) setIsModalOpen(null)
      if (setEditModalOpen) setEditModalOpen(false)
      void message.success(response?.data?.message)
      form.resetFields()
      setIsEssential(false)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = () => {
    setIsEssential(!isEssential)
  }

  const handleCancel = () => {
    if (setIsModalOpen) setIsModalOpen(null)
    if (setEditModalOpen) setEditModalOpen(false)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editDocument') : t('label.addNewDocument')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-8">
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="form-certi-wrapper w-full"
        >
          <>
            <div className="flex flex-col items-start gap-5 w-full">
              <div className="flex flex-col w-full">
                <div className={`h-64 aline-self ${error?.name ? 'mb-6' : ''}`}>
                  <InputField
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.DOC_NAME)}
                    placeholder={t('placeholder.documentName')}
                    name="name"
                    error={error?.name}
                    delay={100}
                  />
                </div>
              </div>
              <div className="flex items-start gap-2 aline-self mb-4">
                <Checkbox name="is_required" checked={isEssential} onChange={onChange} />
                <div className="flex flex-col justify-center items-start gap-1">
                  <p className="text-neutral-800 para-p2 font-normal">{t('label.essentialDocument')}</p>
                  <p className="text-neutral-500 para-p3 font-normal">{documentEssentialSub}</p>
                </div>
              </div>
            </div>
            <div className="flex items-start gap-4 w-full mt-4">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  handleCancel()
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={editId ? COMMON.UPDATE : t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </>
        </Form>
      </div>
    </div>
  )
}

export default AddNewDocument
