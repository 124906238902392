import { message } from 'antd'
import http from 'services/Service'
import { type IGlobalSetting } from 'types'

export const getFindAllTests = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/competency-test-setting?${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}&limit=${limit}&offset=${offset}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const removeCompetencyTestSetting = async (id: string) => {
  try {
    const response = http.delete(`/competency-test-setting/setting/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getFindOneTest = async (id: string) => {
  try {
    const response = http.get(`/competency-test-setting/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const updateCompetencyTest = async (id: string, payload: any) => {
  try {
    const response = http.patch(`/competency-test-setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getCertificate = async () => {
  try {
    const response = http.get('/dropdown/certificate')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const createCompetencyTest = async (payload: any) => {
  try {
    const response = http.post('/competency-test-setting', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const removeCompetencyTestOption = async (id: string) => {
  try {
    const response = http.delete(`/competency-test-setting/option/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const removeCompetencyTestQuestion = async (id: string) => {
  try {
    const response = http.delete(`/competency-test-setting/question/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getAllTestFaqs = async () => {
  try {
    const response = http.get('/test-faqs')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const createTestFaqs = async (payload: any) => {
  try {
    const response = http.post('/test-faqs', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const createGlobalSetting = async (payload: IGlobalSetting) => {
  try {
    const response = http.post('/competency-test-setting/global', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const updateIndividualSetting = async (payload: IGlobalSetting, id: string) => {
  try {
    const response = http.patch(`/competency-test-setting/setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getGlobalSetting = async () => {
  try {
    const response = http.get('/competency-test-setting/global-setting')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
