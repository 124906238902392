import React, { useState } from 'react'
import { Button, Checkbox, Switch } from 'antd'
import { MinusIcon, PlusIcon } from 'assets/svgs'
import { schedulingWarning, settingTab } from 'constants/Constant'

const FacilityPortalSetting = () => {
  const [counter, setCounter] = useState(12)

  const handleMinusClick = () => {
    setCounter(counter > 0 ? counter - 1 : 0)
  }

  const handlePlusClick = () => {
    setCounter(counter + 1)
  }
  return (
    <div className="flex items-start w-full rounded-4 border-stroke flex-col">
      <div className=" border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-normal">{settingTab.INVOICE_SHOULD_BE_PAID}</p>
        </div>
        <div className="flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch checked={true} />
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-normal">{settingTab.CANCELATION_ADVA}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <div className="counter-box">
            <Button icon={<MinusIcon />} onClick={handleMinusClick} className="counter-btn-min" />
            <div className="counter-text-wrapper">
              <p className="counter-text">{counter}</p>
            </div>
            <Button icon={<PlusIcon className="plus-icon-solid " />} onClick={handlePlusClick} className="counter-btn-plus" />
          </div>
          <p className="text-neutral-800 para-p3 font-small">{settingTab.HOURS}</p>
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-normal">{settingTab.DISPLAY_PROVIDER_REQUEST}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch checked={true} />
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-normal">{settingTab.ACCEPT_PROVIDER_REQUEST}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch checked={true} />
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-normal">{settingTab.ALLOWS_SCHEDULING_WARNING}</p>
        </div>
        <div className="flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <div className="flex flex-col item-start gap-2">
            {schedulingWarning.map((data) => {
              return (
                <div className="flex items-start justify-start gap-2" key={data.name}>
                  <Checkbox checked={data.checked} />
                  <p className="text-neutral-700 para-p3 font-normal">{data.name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-normal">{settingTab.SET_CLIENT_CONFO}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch checked={true} />
        </div>
      </div>{' '}
      <div className="flex p-4 items-center gap-2 aline-self flex-grow-1-wrapper">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-normal">{settingTab.DISPLAY_BILL_RATE}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch checked={false} />
        </div>
      </div>
    </div>
  )
}

export default FacilityPortalSetting
