import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, message, Switch } from 'antd'
import { CloseIcon, ModalClose } from 'assets/svgs'
import { commonSingleSelectValidation, commonInputFieldValidation } from 'constants/Validation'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { ICreateCertificate, IFieldData, IModalProps } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import DropDown from 'components/common/Select/DropDown'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { addCertificate, editCertificate, getCertificate, getLineOfBusinessDetails, getSpeciality } from '../api'
import { VALIDATION_ERROR, VALIDATION_STRINGS } from 'constants/ValidationStrings'

const AddNewLicense = ({ setIsModalOpen, editId }: IModalProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const [error, setError] = useState({
    name: '',
    abbreviation: '',
    line_of_business: '',
  })
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [displayWorkforce, setDisplayWorkforce] = useState<boolean>(true)
  const [lineOfBusinessOption, setLineOfBusinessOption] = useState<any[]>([])
  const [specialityOption, setSpecialityOption] = useState<any[]>([])
  const [specialitiesTag, setSpecialitiesTag] = useState<any[]>([])

  useEffect(() => {
    if (editId) {
      void getSingleLicenseData(editId)
    }
  }, [editId, form])

  useEffect(() => {
    void getLineOfBusinessOptions()
    void getSpecialityOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLineOfBusinessOptions = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getLineOfBusinessDetails()
    if (response?.data.statusCode === 1) {
      const businessOption = response?.data?.data
      const modifiedLineOfBusiness = businessOption.map((item: { id: string; name: string }) => ({
        ...item,
        value: item.id,
        label: item?.name,
      }))
      setLineOfBusinessOption(modifiedLineOfBusiness)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getSpecialityOptions = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getSpeciality()
    if (response?.data.statusCode === 1) {
      const specialityOption = response?.data?.data
      const modifiedSpeciality = specialityOption.map((item: { id: string; name: string }) => ({
        ...item,
        id: item.id,
        name: item?.name,
      }))
      setSpecialityOption(modifiedSpeciality)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getSingleLicenseData = async (editId: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getCertificate(editId)
    if (response?.data.statusCode === 1) {
      const data = response.data.data
      const checked = data.status === 'active'
      setStatusCheck(checked)
      const specialitiesIds: string[] = data?.specialities?.map((specialities: { id: string }) => specialities.id)
      setSpecialitiesTag(specialitiesIds)
      form.setFieldsValue({
        name: data.name,
        abbreviation: data.abbreviation,
        line_of_business: data.line_of_business_id,
        workforce_portal_alias: data.workforce_portal_alias,
      })
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = (values: ICreateCertificate) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const updatedValues = {
      ...values,
      specialities: specialitiesTag ?? [],
      status: statusValue,
      display: displayWorkforce,
      workforce_portal_alias: values.workforce_portal_alias ?? '',
    }
    if (editId) {
      void handleEditCertificate(editId, updatedValues)
    } else {
      void handleAddCertificate(updatedValues)
    }
  }

  const handleAddCertificate = async (values: ICreateCertificate) => {
    dispatch(setGlobalLoader(true))
    const response = await addCertificate(values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditCertificate = async (id: string, values: ICreateCertificate) => {
    dispatch(setGlobalLoader(true))
    const response = await editCertificate(id, values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (checked: boolean) => {
    setStatusCheck(checked)
  }

  const onDisplayWorkForceChange = (checked: boolean) => {
    setDisplayWorkforce(checked)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleSpecialityChange = (selectedValue: any[]) => {
    setSpecialitiesTag(selectedValue)
  }

  const handleDeleteTag = (id: number) => {
    const updatedSpecialitiesTag = [...specialitiesTag]
    updatedSpecialitiesTag.splice(id, 1)
    setSpecialitiesTag(updatedSpecialitiesTag)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editCerti') : t('label.addNewCerti')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        className="form-certi-wrapper "
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="add-certificate">
          <InputField
            error={error.name}
            placeholder={t('placeholder.certiName')}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.LICENCE)}
            name="name"
            value={getValue('name')}
          />
          <InputField
            error={error.abbreviation}
            placeholder={t('placeholder.certiAbb')}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.ABBREVIATION)}
            name="abbreviation"
            value={getValue('abbreviation')}
          />
          <FloatingLabelSelect
            name="line_of_business"
            options={lineOfBusinessOption}
            label={t('label.lineOfBusiness')}
            rules={commonSingleSelectValidation(VALIDATION_STRINGS.LINE_OF_BUSINESS)}
            error={error.line_of_business}
            value={getValue('line_of_business')}
          />
          <div className="w-full mb-6">
            <DropDown
              name="specialities"
              handleChange={handleSpecialityChange}
              Options={specialityOption}
              placeholder={t('placeholder.speciality')}
              updatedTag={specialitiesTag}
              value={getValue('specialities')}
            />
          </div>
          {/* show selected option as tag */}
          {specialitiesTag?.length > 0 && (
            <div className="flex flex-col gap-4 w-full mb-6">
              {specialitiesTag?.length > 0 && <HeadingText text={t('placeholder.speciality')} classString="para-p3 text-neutral-400" />}
              <div className="flex gap-4 w-full flex-wrap">
                {specialitiesTag?.map((tagId: string, index: number) => {
                  const option = specialityOption.find((item) => item.id === tagId)
                  return (
                    <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                      {option?.name}
                      <span
                        className="flex items-center cursor-pointer"
                        onClick={() => {
                          handleDeleteTag(index)
                        }}
                      >
                        {<CloseIcon />}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div className="flex gap-2 toggle-switch mb-6">
            <span className="para-p3 text-neutral-600">{t('heading.displayWorkforce')}</span> <Switch checked={displayWorkforce} onChange={onDisplayWorkForceChange} />
          </div>
          <InputField placeholder={t('placeholder.workforcePortalAlias')} name="workforce_portal_alias" value={getValue('workforce_portal_alias')} />
        </div>
        <div className="flex items-center justify-between hide-label">
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.active')}</span> <Switch checked={statusCheck} onChange={onChange} />
          </div>
          <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="w-120" />
        </div>
      </Form>
    </div>
  )
}

export default AddNewLicense
