import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AutoComplete, Dropdown, type MenuProps, message } from 'antd'
import { type SearchProps } from 'antd/es/input/Search'
import { type ColumnsType } from 'antd/es/table'
import { CalenderIcon, ChatIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import DropDown from 'components/common/Select/DropDown'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CustomTable from 'components/common/Table/CustomTable'

import { getFacility } from './api/index'
import AddFacility from './components/AddFacility'

const statusOption = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'in_active',
    name: 'Inactive',
  },
  {
    id: 'on_hold',
    name: 'On Hold',
  },
]

const Facility = () => {
  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [isAddFacility, setIsAddFacility] = useState(false)
  const [statusTag, setStatusTag] = useState<any[]>([])
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.facility'),
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleFacility(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field, search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.offset, sortTable, search])

  const handleFacility = async (limit: number, offset: number, order: string, field: string, search: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getFacility(limit, offset, field, order, search)
    if (response?.status === 200) {
      if (response?.data?.data?.length === 0) {
        void message.error(response?.data?.message)
      }
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }
  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    void handleFacility(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field, search)
  }

  const handleModalOpen = () => {
    navigate('/facilities/facility-profile')
  }

  const handleVerificationClick = () => {
    navigate('/facilities/verification-request')
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleAddFacilityClick = () => {
    setIsAddFacility(true)
  }

  const handleStatusChange = (selectedValue: any[]) => {
    setStatusTag(selectedValue)
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <ChatIcon className="chat-Icon" />
              <span className="activity-text font-normal text-neutral-800">{t('button.chats')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.facility.facility'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 470,
      render: (_value, record) => {
        return (
          <div className="flex justify-between items-center min-w-300 ">
            <HeadingText text={formatTitle(record.name)} classString="text-neutral-800 para-p3 font-small text-decoration-line" />
            <div className="action-cell flex items-center justify-center user-Icon">
              <CalenderIcon className="cursor-pointer " />
            </div>
          </div>
        )
      },
    },
    {
      title: t('table.facility.mobile_no'),
      dataIndex: 'mobile_no',
      key: 'mobile_no',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 177,
      render: (_value, record) => {
        return (
          <div className="flex items-center gap-1 justify-center w-200">
            <HeadingText text={record.country_code} classString="text-neutral-800 para-p3 font-small " />
            <HeadingText text={record.mobile_no} classString="text-neutral-800 para-p3 font-small " />
          </div>
        )
      },
    },
    {
      title: t('table.facility.address'),
      dataIndex: 'address',
      key: 'id',
      align: 'start',
      width: 366,
      render: (_value, record) => {
        const addressParts = [record.house_no, record.street_address, record?.city?.name, record?.state?.name, record?.zip_code].filter(Boolean)

        return <p className="text-neutral-800 flex items-center gap-2 w-300">{addressParts.length > 0 ? addressParts.join(', ') : '-'}</p>
      },
    },
    {
      title: t('table.certification.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 130,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={record.status ? formatTitle(record.status) : '-'}
          />
        )
      },
    },
    {
      title: t('table.facility.type'),
      dataIndex: 'facility_type',
      key: 'facility_type',
      align: 'center',
      width: 177,
      render: (_value, record) => {
        return (
          <div className="w-200">
            <HeadingText text={record.facility_type ? record.facility_type : '-'} classString="text-neutral-800 para-p3 font-small" />
          </div>
        )
      },
    },
    {
      title: t('table.facility.modified'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 127,
      render: (_value, record) => {
        const updatedAt = dayjs(record.updated_at)
        return (
          <div className="flex flex-col gap-1 justify-center items-center w-full">
            <HeadingText text={`${updatedAt.format('MM/DD/YYYY')}`} classString="text-neutral-800 para-p3" />
            <HeadingText text={`${updatedAt.format('HH:mm:ss')}`} classString="text-neutral-600 para-p3 " />
          </div>
        )
      },
    },
    {
      title: t('table.certification.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 74,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const facilityBtn = (
    <div className="flex gap-6">
      <CustomBtn text={t('button.verificationRequest')} type="default" onClick={handleVerificationClick} />
      <CustomBtn isIcon svgIcon={<PlusIcon />} text={t('button.addFacility')} type="primary" onClick={handleAddFacilityClick} />
    </div>
  )

  const filterOptions = (
    <div className="w-full flex flex-col gap-8">
      <div className="flex flex-col items-start gap-6px self-stretch">
        <p className="text-neutral-700 para-p2 font-medium">{t('label.status')}</p>
        <DropDown
          placeholder={t('placeholder.selectStatus')}
          Options={statusOption}
          name="status"
          mainClass="w-full h-46 filter-multi-select"
          handleChange={handleStatusChange}
          updatedTag={statusTag}
        />
      </div>
      <div className="flex flex-col items-start gap-6px self-stretch">
        <p className="text-neutral-700 para-p2 font-medium">{t('label.type')}</p>
        <FloatingLabelSelect label={t('placeholder.selectType')} options={[]} name="facility_type" mainClass="filter-floating-select " />
      </div>
      <div className="flex flex-col items-start gap-6px self-stretch">
        <p className="text-neutral-700 para-p2 font-medium">{t('heading.location')}</p>
        <AutoComplete placeholder={t('placeholder.search')} className="w-full h-46" />
      </div>
      <div className="flex flex-col items-start gap-6px self-stretch">
        <p className="text-neutral-700 para-p2 font-medium">{t('placeholder.columns')}</p>
        <DropDown placeholder={t('placeholder.hideShow')} mainClass="w-full h-46 filter-multi-select" />
      </div>
    </div>
  )
  return (
    <div className="flex flex-col items-start gap-10 flex-grow-1 aline-self">
      {!isAddFacility ? (
        <CustomTable
          className="facility-table"
          column={columns}
          data={listData}
          total={paginationInfo.total}
          limit={paginationInfo.limit}
          current={paginationInfo.current}
          onChangePage={handlePageChange}
          onChange={handleTableChange}
          name={t('label.entries')}
          setModalOpen={handleModalOpen}
          isSearch
          onSearch={onSearch}
          searchPlaceholder={t('placeholder.search')}
          onSearchChange={(e) => {
            handleChange(e.target.value)
          }}
          tableButton={facilityBtn}
          isFilter
          filterOption={filterOptions}
        />
      ) : (
        <AddFacility setIsOpen={setIsAddFacility} />
      )}
    </div>
  )
}

export default Facility
