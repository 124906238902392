import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Divider, Form, Select } from 'antd'
import { DropDownIcon } from 'assets/svgs'

const DropDown = ({ Options, placeholder, handleChange, value, rules, name, updatedTag, isIcon, icon, mainClass }: any) => {
  const [open, setOpen] = useState(false)
  useTranslation()
  const selectRef = useRef<HTMLDivElement>(null)
  const [selected, setSelected] = useState<any[]>(value || [])
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectRef])

  useEffect(() => {
    if (updatedTag) {
      setSelected(updatedTag)
    }
  }, [updatedTag])

  useEffect(() => {
    setSelected(value || [])
  }, [value])

  function handleClickOutside(event: { target: any }) {
    const { current: selectRefCurrent } = selectRef

    if (selectRefCurrent && !selectRefCurrent.contains(event.target)) {
      const isElementClick = (classNames: string[]) => classNames.some((className) => event.target.classList.contains(className))
      const isSvg = event.target.className?.baseVal === ''

      const shouldCloseDropdown = ![
        'ant-select-item-option-content',
        'ant-select-item',
        'ant-image-img',
        'para-p4-medium',
        'para-p2-regular',
        'flex',
        'ant-checkbox-input',
        'ant-checkbox-wrapper',
      ].some((className) => isElementClick([className]))

      if (shouldCloseDropdown && !isSvg) {
        setOpen(false)
      }
    }
  }

  const handleSelectChange = (selectedValues: any) => {
    // Update the state to reflect selected values
    setSelected(selectedValues)
    handleChange(selectedValues)
  }

  return (
    <div ref={selectRef} className={`dropdown-menu ${mainClass as string}`}>
      <Form.Item rules={selected.length === 0 ? rules : []} name={name}>
        <Select
          showSearch={false}
          placeholder={
            <span className="flex g-10 items-center text-neutral-800 font-normal items-center placeholder show-placeholder para-p2">
              {isIcon && icon} {placeholder}
            </span>
          }
          rootClassName={`dropdown-option-checkbox`}
          suffixIcon={<DropDownIcon />}
          maxTagCount={0}
          menuItemSelectedIcon={null}
          mode="multiple"
          variant="borderless"
          placement="bottomRight"
          dropdownStyle={{ border: 'none', padding: '0px' }}
          style={{ height: '64px', border: '1px solid #C4C5C7', fontSize: '16px', fontWeight: 400 }}
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          className={`text-neutral-800 w-full para-p3 text-neutral-700 rounded-3  ${Options?.some((e: { value: any }) => value?.includes(e?.value)) && 'active h-16'} `}
          onChange={handleSelectChange}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setOpen(false)
            }
          }}
          value={selected}
          open={open}
          onClick={() => {
            setOpen(true)
          }}
          dropdownRender={(menu) => {
            return (
              <div className="flex flex-col dropdown-wrapper">
                <Divider style={{ margin: 0 }} />
                {menu}
              </div>
            )
          }}
        >
          {open &&
            Options?.map((item: { id: string; name: string }) => {
              return (
                <Select.Option key={item?.id} value={item?.id} className="">
                  <div className="flex w-full gap-3">
                    <span className="flex justify-start gap-2 items-center">
                      <Checkbox checked={selected?.includes(item?.id)} />
                      <p className="para-p2-regular text-hidden dropdown-p-text">{item?.name}</p>
                    </span>
                  </div>
                </Select.Option>
              )
            })}
        </Select>
        {placeholder && (
          <span
            className="flex g-10 items-center text-neutral-800 font-normal items-center placeholder show-placeholder para-p2"
            onClick={() => {
              setOpen(true)
            }}
          >
            {isIcon && icon} {placeholder}
          </span>
        )}
      </Form.Item>
    </div>
  )
}

export default DropDown
