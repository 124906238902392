import { useEffect, useState } from 'react'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { PlusIcon } from 'assets/svgs'
import { DELETE_STATUS_OPTION_CLIENT, DELETE_STATUS_OPTION_PROVIDER } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'
import type { IGetStatusOptionColumn } from 'types'
import { removeSorting } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import CustomTable from 'components/common/Table/CustomTable'

import { useColumn } from './hooks/useColumn'
import AddStatusOption from './Modal/AddStatusOptions'

import '../../style/pages/StatusOptions.scss'

const StatusOption = () => {
  const dispatch = useAppDispatch()

  const [, setModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [isAddNewStatus, setIsAddNewStatus] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string | undefined>('provider')
  const [editId, setEditId] = useState<string>('')
  const [getData, setData] = useState<IGetStatusOptionColumn>({
    id: '',
    name: '',
    description: '',
    text_color: '',
    background_color: '',
    status: '',
    status_for: '',
  })
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortProviderTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [sortFacilityTable, setSortFacilityReasonTable] = useState({
    order: '',
    field: '',
  })
  const [isDeleteStatusModalOpen, setIsDeleteStatusModalOpen] = useState<boolean>(false)
  const [deleteStatusId, setDeleteStatusId] = useState<string>('')
  const [deleteStatusName, setDeleteStatusName] = useState<string>('')
  const { statusOptionsColumn, handlePageChange, handleDeleteCancelReason } = useColumn({
    setEditId,
    setIsAddNewStatus,
    setData,
    paginationInfo,
    setPaginationInfo,
    sortProviderTable,
    sortFacilityTable,
    search,
    setListData,
    actionMenuOpen,
    setActionMenuOpen,
    selectedValue,
    isAddNewStatus,
    setDeleteStatusId,
    setDeleteStatusName,
    setIsDeleteStatusModalOpen,
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.statusOption'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    setSearch('')
    removeSorting(setSortTable)
    removeSorting(setSortFacilityReasonTable)
    setPaginationInfo({
      total: 0,
      offset: 0,
      limit: 10,
      current: 1,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  const showModal = () => {
    setModalOpen(true)
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleFacilityTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortFacilityReasonTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setPaginationInfo((prev) => ({
      ...prev,
      current: 1,
    }))
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const TableButton = (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={t('button.createStatus')}
      type="primary"
      className="rounded-3 border-0"
      onClick={() => {
        setEditId('')
        setData({
          id: '',
          name: '',
          description: '',
          text_color: '',
          background_color: '',
          status: '',
          status_for: '',
        })
        setIsAddNewStatus(true)
      }}
    />
  )

  const tabData = [
    { value: 'provider', label: 'Provider' },
    { value: 'facility', label: 'Client' },
  ]

  return (
    <div className="status-option-wrapper">
      <CustomTable
        name={selectedValue === 'provider' ? t('label.provider') : t('label.client')}
        column={statusOptionsColumn as ColumnsType<Record<string, unknown>>}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        onChange={selectedValue === 'provider' ? handleTableChange : handleFacilityTableChange}
        data={listData}
        isSearch
        setModalOpen={showModal}
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        searchPlaceholder={t('placeholder.search')}
        tableButton={TableButton}
        isTab
        option={tabData}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
      />
      {isAddNewStatus && (
        <CustomModal
          open={isAddNewStatus}
          centered
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsAddNewStatus(false)
          }}
          content={<AddStatusOption setIsModalOpen={setIsAddNewStatus} editId={editId} data={getData} setSortTable={setSortTable} selectedValue={selectedValue ?? 'provider'} />}
          width={600}
        />
      )}
      {isDeleteStatusModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteStatusModalOpen}
          setIsModalOpen={setIsDeleteStatusModalOpen}
          cancelText={selectedValue === 'provider' ? DELETE_STATUS_OPTION_PROVIDER.CANCEL : DELETE_STATUS_OPTION_CLIENT.CANCEL}
          okClick={() => {
            void handleDeleteCancelReason(deleteStatusId)
          }}
          deleteName={deleteStatusName}
          subHead={selectedValue === 'provider' ? DELETE_STATUS_OPTION_PROVIDER.DELETE_DESC : DELETE_STATUS_OPTION_CLIENT.DELETE_DESC}
          mainHead={selectedValue === 'provider' ? DELETE_STATUS_OPTION_PROVIDER.DELETE_HEAD_LOB : DELETE_STATUS_OPTION_CLIENT.DELETE_HEAD_LOB}
          okText={selectedValue === 'provider' ? DELETE_STATUS_OPTION_PROVIDER.DELETE : DELETE_STATUS_OPTION_CLIENT.DELETE}
        />
      )}
    </div>
  )
}

export default StatusOption
