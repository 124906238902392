import { message } from 'antd'
import http from 'services/Service'

export const getRoleDropdown = async () => {
  try {
    const response = http.get(`/dropdown/roles`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
