import React from 'react'

const GreenFlag = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" {...props}>
      <path
        d="M13.5585 1.9C13.3683 1.76293 13.1484 1.67263 12.9167 1.63643C12.6851 1.60023 12.4482 1.61916 12.2252 1.69167C11.637 1.85707 11.0278 1.93568 10.4168 1.925C9.514 1.87599 8.62877 1.65539 7.80851 1.275C6.79519 0.80636 5.69912 0.542963 4.58351 0.5C2.17518 0.5 1.25018 1.33333 1.08351 1.45C1.00377 1.52816 0.940545 1.62155 0.897599 1.72462C0.854653 1.82769 0.832858 1.93834 0.83351 2.05V14.6667C0.83351 14.8877 0.921308 15.0996 1.07759 15.2559C1.23387 15.4122 1.44583 15.5 1.66684 15.5C1.88786 15.5 2.09982 15.4122 2.2561 15.2559C2.41238 15.0996 2.50018 14.8877 2.50018 14.6667V11.0833C3.1642 10.8291 3.87307 10.7128 4.58351 10.7417C5.48635 10.7907 6.37158 11.0113 7.19184 11.3917C8.20516 11.8603 9.30123 12.1237 10.4168 12.1667C11.4214 12.2046 12.4208 12.0047 13.3335 11.5833C13.5795 11.4678 13.7881 11.2854 13.9354 11.057C14.0828 10.8286 14.163 10.5634 14.1668 10.2917V3.09167C14.1665 2.85915 14.1113 2.62999 14.0056 2.42289C13.8998 2.21579 13.7467 2.03662 13.5585 1.9ZM12.5002 10.1583C11.8359 10.4117 11.1272 10.5279 10.4168 10.5C9.51289 10.4521 8.62693 10.2285 7.80851 9.84167C6.79326 9.37979 5.698 9.11942 4.58351 9.075C3.88143 9.0629 3.18085 9.14417 2.50018 9.31667V2.51667C3.16265 2.25611 3.87226 2.1369 4.58351 2.16667C5.48747 2.21457 6.37343 2.43818 7.19184 2.825C8.20709 3.28687 9.30236 3.54725 10.4168 3.59167C11.1194 3.60204 11.8201 3.51795 12.5002 3.34167V10.1583Z"
        fill={props.className ?? '#359766'}
      />
    </svg>
  )
}

export default GreenFlag
