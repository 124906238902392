import { useEffect, useState } from 'react'
import { Form, message, Switch } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { addShiftCancelReason, editShiftCancelReason } from 'pages/shiftSetting/api'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IModalProps, type IShiftCancelReason } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

const AddReason = ({ setIsModalOpen, editId, data }: IModalProps) => {
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [error, setError] = useState({
    reason: '',
  })

  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data && editId) {
      const checked = data.status === 'active'
      setStatusCheck(checked)
      form.setFieldsValue(data)
    }
  }, [data, editId, form])

  const onFinish = (values: any) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const userStatus = t('label.shiftProvider')
    const updatedValues = { ...values, status: statusValue, user_type: userStatus }
    if (editId && data) {
      void handleEditReason(editId, updatedValues)
    } else {
      void handleAddReason(updatedValues)
    }
  }

  const handleAddReason = async (values: IShiftCancelReason) => {
    dispatch(setGlobalLoader(true))
    const response = await addShiftCancelReason(values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditReason = async (id: string, values: IShiftCancelReason) => {
    dispatch(setGlobalLoader(true))
    const response = await editShiftCancelReason(id, values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (checked: boolean) => {
    setStatusCheck(checked)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        {editId && data ? (
          <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={t('label.editShiftReason')} />
        ) : (
          <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={t('label.createNewReason')} />
        )}
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        className="add-time-content-wrapper"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <InputField
          name="reason"
          rules={commonInputFieldValidation(VALIDATION_STRINGS.REASON)}
          placeholder={t('placeholder.reason')}
          wrapperClass="w-full"
          error={error.reason}
          value={getValue('reason')}
        />
        <CommonTextArea name="description" placeholder={t('placeholder.desc')} rows={4} value={getValue('description')} />
        <div className="flex items-center justify-between hide-label w-full">
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.active')}</span> <Switch checked={statusCheck} onChange={onChange} />
          </div>
          <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="w-120" />
        </div>
      </Form>
    </div>
  )
}

export default AddReason
