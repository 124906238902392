import { message } from 'antd'
import http from 'services/Service'
import { type IAddNewSpecialities } from 'types'

export const getSpecialties = async (limit: number, offset: number, order: string, search: string, field: string) => {
  try {
    const response = http.get(`/speciality?limit=${limit}&offset=${offset}${order && field && `&order[${field}]=${order}`}${search && `&search=${search}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addSpecialities = async (payload: IAddNewSpecialities) => {
  try {
    const response = http.post(`/speciality`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const deleteSpecialties = async (id: string) => {
  try {
    const response = http.delete(`/speciality/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const updateSpecialties = async (id: string, payload: IAddNewSpecialities) => {
  try {
    const response = http.patch(`/speciality/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getSpecialtyById = async (id: string) => {
  try {
    const response = http.get(`/speciality/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getCertificate = async () => {
  try {
    const response = http.get(`/dropdown/certificate`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
