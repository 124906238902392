import React, { useState } from 'react'
import { Tabs } from 'antd'
import { useWindowSize } from 'hooks/useWindowSize'

import FacilityPortalSetting from './components/FacilityPortalSetting'
import Floors from './components/Floors'
import GeneralSettings from './components/GeneralSettings'
import InvoiceSettings from './components/InvoiceSettings'
import ShiftOptions from './components/ShiftOptions'
import TimeAndApproval from './components/TimeAndApproval'

const items = [
  {
    key: '1',
    label: 'General Settings',
    children: <GeneralSettings />,
  },
  {
    key: '2',
    label: 'Floors',
    children: <Floors />,
  },
  {
    key: '3',
    label: 'Shift Options',
    children: <ShiftOptions />,
  },
  {
    key: '4',
    label: 'Time Entry & Approval',
    children: <TimeAndApproval />,
  },
  {
    key: '5',
    label: 'Facility Portal Settings',
    children: <FacilityPortalSetting />,
  },
  {
    key: '6',
    label: 'Invoice Settings',
    children: <InvoiceSettings />,
  },
]

const Settings = () => {
  const [selectedTabId, setSelectedTabId] = useState('1')
  const size = useWindowSize()
  return (
    <div className="facility-setting-wrapper flex p-10 items-start gap-6 w-full rounded-4 bg-white">
      <Tabs
        rootClassName="facility-setting-tab w-full p-0"
        defaultActiveKey="1"
        tabPosition={size.width > 1230 ? 'left' : 'top'}
        items={items}
        onChange={(key) => {
          setSelectedTabId(key)
        }}
      />
    </div>
  )
}

export default Settings
