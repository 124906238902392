import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Form, message } from 'antd'
import { LogoIcon } from 'assets/svgs'
import { commonInputFieldValidation, emailValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import DeviceDetector from 'device-detector-js'
import { useAppDispatch } from 'hooks/reduxHooks'
import { forgotPassword, login } from 'services/StaticTokenServices'
import { setAuthLogin } from 'store/slice/AuthSlice'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFieldData, ILogin, ILoginForm } from 'types'
import { handleFieldsChange } from 'utils/Helper'
import { getsessionUUID } from 'utils/utils'

import PasswordField from 'components/common//PasswordField/PasswordField'
import InputField from 'components/common/InputField/InputField'

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  })
  const [error, setError] = useState({
    email: '',
    password: '',
  })
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const deviceDetector = new DeviceDetector()
  const userAgent = navigator.userAgent
  const device = deviceDetector.parse(userAgent)
  const dispatch = useAppDispatch()

  const onFinish = (values: ILogin) => {
    getsessionUUID()
      .then((uuid) => {
        const authData = {
          email: loginData.email?.toLowerCase(),
          password: loginData?.password,
          device_id: uuid,
          device_name: `${device?.device?.brand ?? 'UnknownBrand'}${device?.device?.model ?? 'UnknownModel'} Web`,
          device_type: 'web',
        }
        void handleLogin(authData)
      })
      .catch((err) => {
        console.error('Error getting session UUID:', err)
      })
  }

  const handleLogin = async (data: ILoginForm) => {
    dispatch(setGlobalLoader(true))
    const response = await login(data)
    if (response?.data?.statusCode === 1) {
      const authData = {
        accessToken: response?.data?.data?.jwt,
        permission: response?.data?.data?.permissions,
        role: { id: response?.data?.data?.role?.id, name: response?.data?.data?.role?.name },
        profileDetails: {
          email: response?.data?.data?.email,
          first_name: response?.data?.data?.first_name,
          base_url: response?.data?.data?.base_url,
          last_name: response?.data?.data?.last_name,
          id: response?.data?.data?.id,
          image: response?.data?.data?.image,
          mobile_no: response?.data?.data?.mobile_no,
          country_code: response?.data?.data?.country_code,
        },
      }
      navigate('/')
      void message.success(response?.data?.message)
      dispatch(setAuthLogin(authData))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const forgotPass = async (payload: { email: string }) => {
    dispatch(setGlobalLoader(true))
    const response = await forgotPassword(payload)
    if (response?.data?.statusCode === 1) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      navigate(`/forgot-password?email=${payload.email}`)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleForgotPass = () => {
    form
      .validateFields(['email'])
      .then(() => {
        const email = form.getFieldValue('email').toLowerCase()
        const payload = {
          email,
        }

        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        void forgotPass(payload)
      })
      .catch((errorInfo) => {
        setError((prevError) => ({
          ...prevError,
          email: 'Please enter a valid email address to reset your password.',
        }))
      })
  }

  return (
    <div className="bg-neutral-50 min-w-full min-h-screen justify-center ">
      <div className="mx-auto max-w-400 w-full py-10 min-h-screen flex flex-col gap-10 justify-center items-center">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <p className="mobile-heading-h2 font-medium">{t('common.welcome')}</p>
        </div>

        <div className="w-full min-w-full">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="min-w-full"
            requiredMark={false}
            onFieldsChange={(allFields: IFieldData[]) => {
              handleFieldsChange(allFields, setError)
            }}
          >
            <InputField
              rules={emailValidation}
              error={error.email}
              name="email"
              value={loginData?.email}
              placeholder={t('label.profileForm.email')}
              onChange={(e) => {
                setLoginData({
                  ...loginData,
                  email: e.target.value,
                })
              }}
            />
            <PasswordField
              error={error.password}
              value={loginData?.password}
              placeholder={t('label.profileForm.password')}
              rules={commonInputFieldValidation(VALIDATION_STRINGS.PASSWORD)}
              name="password"
              onChange={(e) => {
                setLoginData({
                  ...loginData,
                  password: e.target.value,
                })
              }}
            />
            <div className="flex justify-end cursor-pointer text-primary-500 para-p2" onClick={handleForgotPass}>
              {t('label.login.forgot')}
            </div>

            <Form.Item className="mt-6">
              <Button type="primary" htmlType="submit" className="common-button">
                {t('button.login')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
