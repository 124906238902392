import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout } from 'antd'
import { HeaderBack, NotificationIcon, SearchIcon, SupportRequest } from 'assets/svgs'
import { pathsWithSpecialStyles } from 'constants/Constant'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectCommon } from 'store/slice/CommonSlice'
import type { ILayoutProps } from 'types'

import Breadcrumbs from 'components/common/Breadcrumb'

import NotificationDrawer from './NotificationDrawer'
import Sidebar from './Sidebar'

const { Header, Sider, Content } = Layout

const AppLayout = ({ children }: ILayoutProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const common = useAppSelector(selectCommon)
  const location = useLocation()
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0)
    }
  }, [location.pathname])

  const handleBack = () => {
    navigate(-1)
  }

  const handleNotificationDrawerOpen = () => {
    setIsNotificationDrawerOpen(true)
  }

  const isSpecialPath = (path: string) => {
    return pathsWithSpecialStyles.some((basePath) => path === basePath || path.startsWith(`${basePath}/`))
  }
  const isAddTestPath = isSpecialPath(location.pathname)

  return (
    <>
      <Layout className="wrapper-layout">
        <Sider trigger={null} collapsible collapsed={collapsed} className={`${collapsed ? 'collapsed-sidebar' : 'sider-layout'}  bg-white`}>
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        </Sider>
        <Layout className="ml-6">
          <Header className="header-wrapper flex justify-between items-center">
            <div className="header-content-div flex items-center gap-6">
              {common?.isBack && (
                <div onClick={handleBack} className="div-handle-back">
                  <HeaderBack />
                </div>
              )}
              <p className="desktop-heading-h6 text-neutral-800 font-semi-bold header-heading-text">{common?.pageTitle}</p>
            </div>

            <div className="flex gap-4 justify-end">
              <div className="flex gap-6 items-center">
                <div className="header-icon">
                  <SearchIcon />
                </div>
                <div className="header-icon">
                  <SupportRequest />
                </div>
                <div className="header-icon">
                  <NotificationIcon onClick={handleNotificationDrawerOpen} />
                </div>
              </div>
            </div>
          </Header>
          {/* <Content ref={contentRef} className={isAddTestPath ? 'special-class' : 'wrapper-content overflow-y-auto'}>
            {children}
          </Content> */}

          <Content>
            {location?.pathname !== '/' && <Breadcrumbs />}
            <div
              ref={contentRef}
              /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
              className={` ${location?.pathname === '/' && 'mt-6'} ${isAddTestPath ? 'special-class' : 'wrapper-content overflow-y-auto'}`}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>

      {isNotificationDrawerOpen && <NotificationDrawer isNotificationDrawerOpen={isNotificationDrawerOpen} setIsNotificationDrawerOpen={setIsNotificationDrawerOpen} />}
    </>
  )
}

export default AppLayout
