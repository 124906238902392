import { Form, Radio, Switch } from 'antd'
import { t } from 'i18next'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

const badgeData = [
  {
    value: 'success',
    label: '',
    style: { backgroundColor: '#28714D' },
  },
  {
    value: 'primary',
    label: '',
    style: { backgroundColor: '#220EB1' },
  },
  {
    value: 'warning',
    label: '',
    style: { backgroundColor: '#FB8600' },
  },
  {
    value: 'error',
    label: '',
    style: { backgroundColor: '#D1293D' },
  },
  {
    value: 'neutral',
    label: '',
    style: { backgroundColor: '#68696C' },
  },
  {
    value: 'secondary',
    label: '',
    style: { backgroundColor: '#6111B9' },
  },
  {
    value: 'tertiary',
    label: '',
    style: { backgroundColor: '#0C90B6' },
  },
  {
    value: 'pink',
    label: '',
    style: { backgroundColor: '#D11575' },
  },
  {
    value: 'orange',
    label: '',
    style: { backgroundColor: '#FF6C2D' },
  },
  {
    value: 'purple',
    label: '',
    style: { backgroundColor: '#B710D2' },
  },
]

const AddFlagStatus = () => {
  // const [value, setValue] = useState(1)

  // const onChange = (e: RadioChangeEvent) => {
  //   console.log('radio checked', e.target.value)
  //   setValue(e.target.value)
  // }
  return (
    <Form className="add-provider-status-wrapper">
      <InputField placeholder={t('placeholder.statusName')} name="status_name" wrapperClass="w-full" />
      <CommonTextArea name="description" placeholder={t('placeholder.desc')} rows={4} />
      <div className="badge-color-wrapper">
        <p className="text-neutral-800 para-p2 font-medium">Badge Color</p>
        <div className="badge-wrapper">
          <Form.Item name="repeat-btn" valuePropName="checked">
            <Radio.Group options={badgeData} rootClassName="badge-radio-button" />
          </Form.Item>
        </div>
      </div>
      <div className="add-provider-footer mt-6">
        <div className="flex gap-2 toggle-switch">
          <span>{t('label.active')}</span> <Switch checked={true} />
        </div>
        <CustomBtn text={t('button.add')} htmlType="submit" type="primary" className="w-120" />
      </div>
    </Form>
  )
}

export default AddFlagStatus
