import React from 'react'

const DownloadIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11H13.37C13.2728 2.058 13.1683 2.02092 13.06 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM13.29 14.29L13 14.59V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V14.59L10.71 14.29C10.5217 14.1017 10.2663 13.9959 10 13.9959C9.7337 13.9959 9.4783 14.1017 9.29 14.29C9.1017 14.4783 8.99591 14.7337 8.99591 15C8.99591 15.2663 9.1017 15.5217 9.29 15.71L11.29 17.71C11.3851 17.801 11.4972 17.8724 11.62 17.92C11.7397 17.9729 11.8691 18.0002 12 18.0002C12.1309 18.0002 12.2603 17.9729 12.38 17.92C12.5028 17.8724 12.6149 17.801 12.71 17.71L14.71 15.71C14.8983 15.5217 15.0041 15.2663 15.0041 15C15.0041 14.7337 14.8983 14.4783 14.71 14.29C14.5217 14.1017 14.2663 13.9959 14 13.9959C13.7337 13.9959 13.4783 14.1017 13.29 14.29Z"
        fill="#2A2A2B"
      />
    </svg>
  )
}

export default DownloadIcon
