import { facilityProfile } from 'assets/imgs'
import { CheckIcon } from 'assets/svgs'
import { verifyFacility } from 'constants/Constant'
import { t } from 'i18next'

import CustomBtn from 'components/common/CustomBtn'

const VerifyFacility = ({ setModalOpen, openRejectModal }: any) => {
  const handleRejectModalClick = () => {
    openRejectModal(true)
    setModalOpen(false)
  }

  return (
    <div className="flex p-10 flex-col item-center gap-10">
      <div className="flex flex-col items-center gap-4">
        <img src={facilityProfile} height={100} width={100} alt="profile" className="w-100 h-100 rounded-full" />
        <p className="text-neutral-800 mobile-heading-h5 font-medium">Rocky Mtn. Care of Heber</p>
      </div>
      <div className="flex flex-col items-start gap-6 aline-self">
        <div className="grid grid-cols-2 gap-6 w-full">
          <div className="flex flex-col items-start gap-2 w-full">
            <p className="text-neutral-500 para-p2 font-normal">{verifyFacility.TYPE}</p>
            <p className="text-neutral-800 activity-text font-normal">Nursing Home</p>
          </div>
          <div className="flex flex-col items-start gap-2 w-full">
            <p className="text-neutral-500 para-p2 font-normal">{verifyFacility.BEDS}</p>
            <p className="text-neutral-800 activity-text font-normal">128</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6 w-full">
          <div className="flex flex-col items-start gap-2 w-full">
            <p className="text-neutral-500 para-p2 font-normal">{verifyFacility.EMAIL}</p>
            <p className="text-neutral-800 activity-text font-normal flex gap-2 items-center">
              catherine@gmail.com <CheckIcon />
            </p>
          </div>
          <div className="flex flex-col items-start gap-2 w-full">
            <p className="text-neutral-500 para-p2 font-normal">{verifyFacility.PHONE}</p>
            <p className="text-neutral-800 activity-text font-normal flex gap-2 items-center">
              +1 123 456 7890 <CheckIcon />
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-2 w-full">
          <p className="text-neutral-500 para-p2 font-normal">{verifyFacility.ADDRESS}</p>
          <p className="text-neutral-800 activity-text font-normal">3825 Brownley DrMacon, GA 31206</p>
        </div>
      </div>
      <div className="flex w-full justify-end items-center gap-4">
        <CustomBtn text={t('button.reject')} type="default" className="w-160" onClick={handleRejectModalClick} />
        <CustomBtn text={t('button.approve')} type="primary" className="w-160" />
      </div>
    </div>
  )
}

export default VerifyFacility
