import React from 'react'

const DrawerClose = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.20001 10.9001C5.20001 9.07756 6.67748 7.6001 8.50001 7.6001H23.5C25.3226 7.6001 26.8 9.07756 26.8 10.9001V21.1001C26.8 22.9227 25.3226 24.4001 23.5 24.4001H8.50001C6.67748 24.4001 5.20001 22.9227 5.20001 21.1001V10.9001ZM11.8 22.6001H23.5C24.3285 22.6001 25 21.9286 25 21.1001V10.9001C25 10.0717 24.3285 9.4001 23.5 9.4001H11.8V22.6001ZM10 9.4001V22.6001H8.50001C7.67158 22.6001 7.00001 21.9286 7.00001 21.1001V10.9001C7.00001 10.0717 7.67158 9.4001 8.50001 9.4001H10ZM18.098 12.6274C18.4695 12.9577 18.5029 13.5265 18.1727 13.898L17.1041 15.1001H21.7C22.1971 15.1001 22.6 15.503 22.6 16.0001C22.6 16.4971 22.1971 16.9001 21.7 16.9001H17.1041L18.1727 18.1021C18.5029 18.4737 18.4695 19.0426 18.098 19.3728C17.7265 19.7029 17.1575 19.6696 16.8273 19.2981L14.4273 16.5981C14.1242 16.257 14.1242 15.7432 14.4273 15.4022L16.8273 12.7022C17.1575 12.3307 17.7265 12.2972 18.098 12.6274Z"
      fill="#5E6782"
    />
  </svg>
)

export default DrawerClose
