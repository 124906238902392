import { message } from 'antd'
import http from 'services/Service'
import { type IShiftCancelReason } from 'types'

export const getAllTimeRejectReason = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/timecard-reject-reason?limit=${limit}&offset=${offset}${order && field && `&order[${field}]=${order}`}${search && `&search=${search}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getTimeCardRejectReasonById = async (id: string) => {
  try {
    const response = http.get(`/timecard-reject-reason/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addTimeCardRejectReason = async (payload: IShiftCancelReason) => {
  try {
    const response = http.post('/timecard-reject-reason', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const editTimeCardCancelReason = async (id: string, payload: IShiftCancelReason) => {
  try {
    const response = http.patch(`/timecard-reject-reason/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const deleteTimeCardCancelReason = async (id: string) => {
  try {
    const response = http.delete(`/timecard-reject-reason/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
