import { useEffect, useState } from 'react'
import { Checkbox, Form, message } from 'antd'
import { ModalClose } from 'assets/svgs'
import { checkboxOptions } from 'constants/Constant'
import { commonInputFieldValidation, commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type ICertificateSpecialties, type IDocumentModalsProps, type IFieldData, type ISelectOptionData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

import { getCertificate } from '../api'

import '../../../style/pages/ComplianceManager.scss'

const AddDocument = ({ setIsModalOpen, editId, data }: IDocumentModalsProps) => {
  const [form] = Form.useForm()
  const [error, setError] = useState({
    name: '',
    certificate: '',
    documentEssential: '',
    groupCheckbox: '',
  })
  const [checkedList, setCheckedList] = useState<string[]>([])

  useEffect(() => {
    void getCertificateDropDownOpt()
  }, [])

  useEffect(() => {
    if (editId && data) {
      form.setFieldValue('name', data.name)
      form.setFieldValue('documentEssential', data.documentEssential)
      form.setFieldValue('groupCheckbox', data.groupCheckbox)
      form.setFieldValue('certificate', data.certification)
    }
  }, [editId, data, form])

  const getCertificateDropDownOpt = async () => {
    const response = await getCertificate()
    if (response?.status === 200) {
      setCertificateOpt(response.data.data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const [certificateOpt, setCertificateOpt] = useState<ICertificateSpecialties[]>()
  const [option, setOption] = useState<ISelectOptionData[]>([])

  useEffect(() => {
    if (certificateOpt) {
      const options = certificateOpt?.map((eachCertiData: { name: string; id: string }) => ({
        value: eachCertiData.id,
        label: eachCertiData.name,
      }))
      setOption(options)
    }
  }, [certificateOpt])

  const onChange = (list: string[]) => {
    setCheckedList(list)
  }

  const handleCancel = () => {
    if (setIsModalOpen) setIsModalOpen(false)
  }

  const onFinish = (values: any) => {
    console.log('on submit data', values)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editDocument') : t('label.addNewDocument')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-8">
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="form-certi-wrapper w-full"
        >
          <>
            <div className="flex flex-col items-start gap-5  w-full add-document-form">
              <div className="flex flex-col items-start gap-5 w-full document-form">
                <div className="flex flex-col w-full">
                  <InputField
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.DOC_NAME)}
                    placeholder={t('placeholder.documentName')}
                    name="name"
                    error={error.name}
                    value={getValue('name')}
                  />
                </div>
                <FloatingLabelSelect
                  label={t('placeholder.certification')}
                  options={option}
                  name="certificate"
                  value={getValue('certificate')}
                  rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_LICENCE)}
                  error={error.certificate}
                />
                <div className="w-full flex gap- 2 flex-col">
                  <Form.Item name="documentEssential" valuePropName="checked" className="p-0 m-0" rootClassName="document-checkbox">
                    <Checkbox className="flex gap-2 para-p2 font-normal text-neutral-800">
                      {t('placeholder.documentEssential')}
                      <p className="para-p3 font-normal text-neutral-500">{t('label.instruction')}</p>
                    </Checkbox>
                  </Form.Item>

                  {error?.documentEssential && <p className="error-container">{error.documentEssential}</p>}
                </div>
                <div className="w-full flex flex-col gap-2">
                  <HeadingText classString="text-neutral-800 para-p2 font-normal" text={t('label.selectRequiredFields')} />
                  <Form.Item name="groupCheckbox" rules={commonSingleSelectValidation(VALIDATION_STRINGS.REQUIRED_FIELD)} rootClassName="checkbox-group">
                    <Checkbox.Group options={checkboxOptions} value={checkedList} onChange={onChange} />
                  </Form.Item>
                  {error?.groupCheckbox && <p className="error-container">{error.groupCheckbox}</p>}
                </div>
              </div>
            </div>
            <div className="flex items-start gap-4 w-full document-button">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  if (setIsModalOpen) setIsModalOpen(false)
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </>
        </Form>
      </div>
    </div>
  )
}

export default AddDocument
