import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Form, type MenuProps, message, Popover } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, DropDownIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { COMPLIENCE_MANAGER, DELETE_MODAL_COMPLIANCE_MANAGER_TEXT } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type ICredentialData } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import CustomTableHeader from 'components/common/CustomTableHeader/CustomTableHeader'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddDocument from './modal/AddDocument'
import AddNewCategory from './modal/AddNewCategory'
import EditCategory from './modal/EditCategory'
import { deleteCategory, deleteDocument, getAllCategory } from './api'

import '../../style/pages/ComplianceManager.scss'

const ComplianceManager = () => {
  const [visibleTables, setVisibleTables] = useState<number[]>([])

  const [categoryModalOpen, setCategoryModalOpen] = useState<boolean>(false)
  const [categoryEditModalOpen, setCategoryEditModalOpen] = useState<number | null>(null)
  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false)
  const [editDocumentId, setEditDocumentId] = useState<string>('')
  // eslint-disable-next-line
  const [editDocumentData, setEditDocumentData] = useState<any>()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [editId, setEditId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [isDeleteDocOrCategory, setIsDeleteDocOrCategory] = useState<string>('')
  const [categoryData, setCategoryData] = useState<any>(null)
  const [categoryName, setCategoryName] = useState<string>('')
  // eslint-disable-next-line
  const [selectedRowData, setSelectedRowData] = useState<any>()
  const [search, setSearch] = useState<string>('')
  const [categoryId, setCategoryId] = useState<string>('')
  const [error, setError] = useState({
    name: '',
  })
  const [addError, setAddError] = useState({
    name: '',
  })
  const [form] = Form.useForm()
  const [addForm] = Form.useForm()

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.complianceManager'),
        isBack: true,
      })
    )
  }, [dispatch, t])

  useEffect(() => {
    void handleGetAllCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleGetAllCategory = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getAllCategory(search)
    if (response?.status === 200) {
      setCategoryData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleToggle = (index: number) => {
    setVisibleTables((prevVisibleSections) => {
      if (prevVisibleSections.includes(index)) {
        return prevVisibleSections.filter((i) => i !== index)
      } else {
        return [...prevVisibleSections, index]
      }
    })
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const handleCategoryPopoverToggle = (index: number) => {
    setCategoryEditModalOpen((prevState) => (prevState === index ? null : index))
  }

  const handleDocumentPopoverToggle = (index?: string, data?: any) => {
    if (documentModalOpen) {
      setDocumentModalOpen(false)
    } else {
      setDocumentModalOpen(true)
    }
    if (index && data) {
      setEditDocumentId(index)
      setEditDocumentData(data)
    }
  }

  const handleDeleteDocument = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await deleteDocument(id)
    if (response?.data?.statusCode === 1) {
      setIsDeleteModalOpen(false)
      void handleGetAllCategory()
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteCategory = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await deleteCategory(id)
    if (response?.data?.statusCode === 1) {
      setIsDeleteModalOpen(false)
      void handleGetAllCategory()
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getMenu = (record: any): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (
            <div
              className="option-wrapper"
              onClick={() => {
                if (record?.id) handleDocumentPopoverToggle(record?.id, record)
              }}
            >
              <p className="flex items-center gap-2">
                <EditPenIcon /> <span className="activity-text font-normal text-neutral-800">Edit</span>
              </p>
            </div>
          ),
        },
        {
          key: 2,
          label: (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                if (typeof record.id === 'string') {
                  setIsDeleteDocOrCategory('document')
                  setIsDeleteModalOpen(true)
                  setDeleteId(record.id)
                  setDeleteName(record.name)
                }
              }}
            >
              <p className="flex items-center gap-2">
                <DeleteIcon className="delete-icon" /> <span className="activity-text font-normal text-error-500">Delete</span>
              </p>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: t('table.credentialsManager.credential'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 400,
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 font-normal activity-text" text={record?.name} />
      },
    },
    {
      title: COMPLIENCE_MANAGER.LICENSE,
      dataIndex: 'certificate',
      key: 'certificate',
      align: 'center',
      width: 380,
      className: 'center-align',
      sorter: (a: any, b: any) => {
        const certificatesA = a.certificate?.map((item: { name: string }) => item.name).join(', ') ?? ''
        const certificatesB = b.certificate?.map((item: { name: string }) => item.name).join(', ') ?? ''
        return certificatesA.localeCompare(certificatesB)
      },
      render: (_value, record: any) => {
        const certificates = record?.certificate?.map((item: { name: string }) => item.name).join(', ')
        return <HeadingText classString="text-neutral-800 para-p3 font-semibold" text={certificates ?? '-'} />
      },
    },
    {
      title: t('table.credentialsManager.essesential'),
      dataIndex: 'is_required',
      key: 'is_required',
      align: 'center',
      className: 'center-align',
      sorter: (a, b) => {
        const valA = a.is_required ? 'yes' : 'no'
        const valB = b.is_required ? 'yes' : 'no'
        return valA.localeCompare(valB)
      },
      render: (_value, record: any) => {
        return <HeadingText classString="text-neutral-800 para-p3 font-semibold text-center" text={record?.is_required ? 'Yes' : 'No'} />
      },
    },
    {
      title: t('table.credentialsManager.actions'),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 163,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record)}
              placement="bottomRight"
              onOpenChange={() => {
                setSelectedRowData(record)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <CustomTableHeader
        isSearch
        searchPlaceholder="Search"
        onSearchChange={(e: any) => {
          handleChange(e.target.value)
        }}
        onSearch={onSearch}
        tableButton={
          <div className="flex gap-4">
            <CustomBtn
              text="Credential Requirement"
              onClick={() => {
                navigate('/settings/compliance-manager/credential-requirement')
              }}
            />
            <Popover
              trigger={['click']}
              content={
                <AddNewCategory
                  setCategoryName={setCategoryName}
                  form={addForm}
                  error={addError}
                  setError={setAddError}
                  handleGetAllCategory={handleGetAllCategory}
                  setCategoryModalOpen={setCategoryModalOpen}
                />
              }
              placement="bottomRight"
              open={categoryModalOpen}
              onOpenChange={(open) => {
                if (!open) {
                  addForm.resetFields()

                  setCategoryModalOpen(false)

                  setError({ name: '' })
                  addForm.setFieldValue('name', '')
                }
              }}
              rootClassName="general-setting-popover"
            >
              <CustomBtn
                isIcon
                svgIcon={<PlusIcon />}
                text="Add New Category"
                type="primary"
                className="rounded-3"
                onClick={() => {
                  addForm.resetFields()
                  addForm.setFieldValue('name', '')
                  setAddError({ name: '' })
                  setCategoryModalOpen(true)
                }}
              />
            </Popover>
          </div>
        }
      />
      <div className="py-6 border bg-white pr-10 pl-10 div-wrapper">
        <p className="mb-4 text-neutral-800  mobile-heading-h4 ">Credential Manager</p>
        <div className="flex flex-col gap-4">
          {categoryData?.map((data: ICredentialData, index: number) => {
            return (
              <div key={index} className="flex flex-col gap-2 items-center w-full p-4 bg-neutral-50 rounded-4 mobile-action-icon">
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-2 items-center">
                    <div
                      onClick={() => {
                        handleToggle(index)
                      }}
                      style={{ transform: visibleTables.includes(index) ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                    >
                      <DropDownIcon width={'28'} height={'28'} className="#2A2A2B" />
                    </div>
                    <HeadingText text={data.name} classString="mobile-heading-h5 text-neutral-800 font-medium mobile-heading-text" />
                  </div>
                  <div className="flex gap-6 items-center icon-neutral-500">
                    <div>
                      <DeleteIcon
                        width={'24'}
                        height={'24'}
                        className="svg cursor-pointer"
                        onClick={() => {
                          setIsDeleteDocOrCategory('')
                          setIsDeleteModalOpen(true)
                          setDeleteId(data?.id)
                          setDeleteName(data?.name)
                        }}
                      />
                    </div>
                    <div>
                      <Popover
                        trigger={['click']}
                        content={
                          <EditCategory
                            form={form}
                            error={error}
                            setError={setError}
                            handleGetAllCategory={handleGetAllCategory}
                            setIsModalOpen={setCategoryEditModalOpen}
                            isModalOpen={categoryEditModalOpen}
                            editId={editId}
                            setEditId={setEditId}
                            name={categoryName}
                            setCategoryName={setCategoryName}
                          />
                        }
                        placement="bottomRight"
                        open={categoryEditModalOpen === index}
                        onOpenChange={(open) => {
                          if (!open) {
                            // setCategoryEditModalOpen(null)
                            form.resetFields()

                            setCategoryEditModalOpen(null)
                            setEditId('')
                            setCategoryName('')

                            setError({ name: '' })
                            form.setFieldValue('name', categoryName)
                          }
                        }}
                        rootClassName="general-setting-popover"
                      >
                        <div
                          onClick={() => {
                            setCategoryName('')
                            handleCategoryPopoverToggle(index)
                            setEditId(data?.id)
                            setCategoryName(data?.name)
                          }}
                        >
                          <EditPenIcon width={'24'} height={'24'} className="svg cursor-pointer" />
                        </div>
                      </Popover>
                    </div>
                    {visibleTables.includes(index) && (
                      <div
                        onClick={() => {
                          setEditDocumentId('')
                          setCategoryId(data?.id)
                          handleDocumentPopoverToggle()
                        }}
                      >
                        <PlusIcon width={'24'} height={'24'} className="svg cursor-pointer" />
                      </div>
                    )}
                  </div>
                </div>
                {visibleTables.includes(index) && (
                  <div className="w-full">
                    <CustomTable column={columns} data={data?.credential_document} name={data.name} className={'table-border-wrapper'} pagination={false} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>

      {documentModalOpen && (
        <CustomModal
          open={documentModalOpen}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setDocumentModalOpen(false)
          }}
          content={
            <AddDocument
              setEditId={setEditId}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              handleGetAllCategory={handleGetAllCategory}
              setIsModalOpen={setDocumentModalOpen}
              editId={editDocumentId}
              setEditDocumentId={setEditDocumentId}
            />
          }
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.CANCEL}
          okClick={() => {
            if (isDeleteDocOrCategory === 'document') {
              void handleDeleteDocument(deleteId)
            } else {
              void handleDeleteCategory(deleteId)
            }
          }}
          deleteName={deleteName}
          subHead={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default ComplianceManager
