import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message, Popover, Switch } from 'antd'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IGeneralPayload, type IGeneralSetting, type IGeneralSettingSubsection } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import AddNewReferralSource from './Modal/AddNewReferralSourc'
import { getGeneralSettings, updateSettings } from './api'

const GeneralSetting = () => {
  const dispatch = useDispatch()
  const [actionMenuOpen, setActionMenuOpen] = useState(false)
  const [settings, setSettings] = useState<IGeneralSetting[]>([])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.generalSettings'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void getSettingsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionMenuOpen])

  const onChange = (switchData: boolean, settingId: number, dataId: string) => {
    let payload = { id: '', status: '' }

    const updatedSettings = settings?.map((setting: IGeneralSetting) => {
      if (setting?.id === settingId) {
        return {
          ...setting,
          data: setting?.sub_section?.map((dataItem: IGeneralSettingSubsection) => {
            if (dataItem?.id === dataId) {
              payload = {
                id: dataId,
                status: switchData ? 'active' : 'in_active',
              }

              return { ...dataItem, status: switchData }
            }
            return dataItem
          }),
        }
      }
      return setting
    })
    void handleUpdateSetting(payload)
    setSettings(updatedSettings)
  }

  const handleUpdateSetting = async (payload: IGeneralPayload) => {
    dispatch(setGlobalLoader(true))
    const response = await updateSettings(payload)
    if (response?.data?.statusCode === 1) {
      void getSettingsData()
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getSettingsData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getGeneralSettings()
    if (response?.data?.statusCode === 1 && Array.isArray(response?.data?.data)) {
      setSettings(response?.data?.data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleTextAreaBlur = (value: string, dataId: string, settingId: number) => {
    let payload = { id: '', status: '', placeholder: '' }
    const updatedSettings = settings?.map((setting: IGeneralSetting) => {
      if (setting?.id === settingId) {
        return {
          ...setting,
          data: setting?.sub_section?.map((dataItem: IGeneralSettingSubsection) => {
            if (dataItem?.id === dataId) {
              payload = {
                id: dataId,
                status: dataItem.status,
                placeholder: value,
              }

              return { ...dataItem, placeholder: value }
            }
            return dataItem
          }),
        }
      }
      return setting
    })
    if (value) {
      void handleUpdateSetting(payload)
    }
    setSettings(updatedSettings)
  }

  return (
    <div className="max-w-1200 mx-auto">
      <div className="flex justify-end mb-6 md:flex-col">
        <div className="flex gap-6">
          <Popover
            trigger={['click']}
            content={<AddNewReferralSource setIsModalOpen={setActionMenuOpen} settings={settings} />}
            placement="bottomRight"
            open={actionMenuOpen}
            rootClassName="general-setting-popover"
          >
            <CustomBtn
              text={t('button.addReferralSource')}
              type="default"
              onClick={() => {
                setActionMenuOpen(true)
              }}
            />
          </Popover>
          {/* <CustomBtn text={t('button.saveSettings')} type="primary" /> */}
        </div>
      </div>
      <div className="flex flex-col rounded-4 justify-start bg-white aline-self">
        {settings?.map((setting: IGeneralSetting, index: number) => (
          <div className="flex w-full" key={setting?.id}>
            <div className={`table-title-wrapper ${settings.length - 1 === index ? 'border-b-0 ' : ''} `}>
              <p className="title-text">{setting?.name}</p>
            </div>
            <div className="flex flex-col w-full">
              {index === 0 && (
                <div className="flex flex-row w-full justify-between border-b-stroke">
                  <div className="field-name-wrapper">
                    <p className="field-title">{t('label.fieldName')}</p>
                  </div>
                  <div className="flex justify-center items-center p-4">
                    <p className="field-title">{t('label.displayOnForm')}</p>
                  </div>
                </div>
              )}
              {setting?.sub_section?.map((data: IGeneralSettingSubsection) => (
                <div className={`flex flex-row w-full justify-between border-b-stroke ${settings.length - 1 === index ? 'border-b-0' : ''}`} key={data.id}>
                  {data?.type !== 'text' ? (
                    <div className="field-name-wrapper">
                      <p className="field-name">{data.name}</p>
                    </div>
                  ) : (
                    <div className="p-4 w-full">
                      <CommonTextArea
                        name={data.placeholder}
                        placeholder={data.placeholder}
                        rows={3}
                        wrapperClass="general-text-area m-0"
                        onBlur={(e) => {
                          handleTextAreaBlur(e.target.value, data.id, setting.id)
                        }}
                      />
                    </div>
                  )}
                  <div className="flex justify-center items-center w-156 p-4 switch-wrapper">
                    <Switch
                      checked={data.status === 'active'}
                      onChange={(value) => {
                        onChange(value, setting.id, data.id)
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GeneralSetting
