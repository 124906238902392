import { useEffect, useState } from 'react'
import { Form, message, Radio } from 'antd'
import { ModalClose } from 'assets/svgs'
import { agencyEmployeeOption, autoAssignOption, emplyoeeTypeOption, validationuponOption } from 'constants/Constant'
import { commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type ICertificateSpecialties, type IFieldData, type IRequirementModalProps, type ISelectOptionData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import DropDown from 'components/common/Select/DropDown'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

import { getCertificate } from '../api'

import '../../../style/pages/ComplianceManager.scss'

const AddRequirement = ({ setIsModalOpen, editId, data }: IRequirementModalProps) => {
  const [form] = Form.useForm()
  const [certificateOpt, setCertificateOpt] = useState<ICertificateSpecialties[]>()
  const [credentials, setCredentials] = useState<string>()
  const [option, setOption] = useState<ISelectOptionData[]>([])
  const [error, setError] = useState({
    name: '',
    certificate: '',
    credentials: '',
    state: '',
    agencyEmployment: '',
    employmentType: '',
    autoAssign: '',
    submission: '',
    pdOrder: '',
    ltOrder: '',
    workforce: '',
  })
  const credentialsOption = [
    {
      id: '1',
      name: 'BACKGROUND CHECK / OIG',
    },
  ]

  useEffect(() => {
    if (certificateOpt) {
      const options = certificateOpt?.map((eachCertiData: { name: string; id: string }) => ({
        value: eachCertiData.id,
        label: eachCertiData.name,
      }))
      setOption(options)
    }
  }, [certificateOpt])

  useEffect(() => {
    void getCertificateDropDownOpt()
  }, [])

  useEffect(() => {
    if (editId && data) {
      setCredentials(data.credential)
      form.setFieldValue('name', data.name)
      form.setFieldValue('certificate', data.certification)
      form.setFieldValue('state', data.state)
      form.setFieldValue('agencyEmployment', data.employement)
      form.setFieldValue('employmentType', data.employmentType)
      form.setFieldValue('autoAssign', data.autoAssign)
      form.setFieldValue('submission', data.submission)
      form.setFieldValue('pdOrder', data.pdOrder)
      form.setFieldValue('ltOrder', data.ltOrder)
      form.setFieldValue('workforce', data.workForcePortal)
    }
  }, [editId, data, form])

  const getCertificateDropDownOpt = async () => {
    const response = await getCertificate()
    if (response?.status === 200) {
      setCertificateOpt(response.data.data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const handleEndTimeChange = (selectedValue: any) => {
    setCredentials(selectedValue)
  }

  const onFinish = (values: any) => {
    console.log('on submit data', values, credentials)
  }

  const handleCancel = () => {
    if (setIsModalOpen) setIsModalOpen(false)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editRequirements') : t('label.addRequirements')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        className="form-certi-wrapper"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
        initialValues={{ agencyEmployment: 'Yes', employmentType: 'Both', submission: 'None', pdOrder: 'None', ltOrder: 'None', autoAssign: 'Application Start' }}
      >
        <>
          <div className="flex flex-col items-start gap-2 w-full add-requirement-form pt-6px">
            {/* certificate and credential */}
            <div className="flex gap-1rem items-start w-full">
              <FloatingLabelSelect
                label={t('placeholder.selectCertiSpeciality')}
                options={option}
                name="certificate"
                value={getValue('certificate')}
                rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_LICENCE)}
                error={error.certificate}
              />
              <div className="w-full">
                <DropDown
                  handleChange={handleEndTimeChange}
                  name={'credentials'}
                  Options={credentialsOption}
                  placeholder={t('placeholder.credentials')}
                  optionWidth={200}
                  rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_CRED)}
                  updatedTag={credentials}
                  value={getValue('credentials')}
                />
                {error?.credentials && <p className="error-container">{error.credentials}</p>}
              </div>
            </div>
            <FloatingLabelSelect
              label={t('placeholder.selectState')}
              value={getValue('state')}
              options={[{ value: 'usa', label: 'USA' }]}
              name="state"
              rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_STATE)}
              error={error.state}
            />
            {/* agency emplyoee and emplyoee type */}
            <div className="flex gap-1rem items-center w-full">
              <div className="flex flex-col gap-3 w-full">
                <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.agencyEmployment')} />
                <Form.Item name="agencyEmployment" className="p-0 m-0">
                  <Radio.Group options={agencyEmployeeOption} />
                </Form.Item>
                {error?.agencyEmployment && <p className="error-container">{error.agencyEmployment}</p>}
              </div>
              <div className="flex flex-col gap-3 w-full">
                <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.employmentType')} />
                <Form.Item name="employmentType" className="p-0 m-0">
                  <Radio.Group options={emplyoeeTypeOption} />
                </Form.Item>
                {error?.employmentType && <p className="error-container">{error.employmentType}</p>}
              </div>
            </div>
            {/* validation upon */}
            <div className="flex gap-1rem items-center w-full">
              <div className="flex flex-col gap-2 w-full">
                <HeadingText classString="activity-text text-neutral-800 font-semi-bold" text={t('heading.validateUpon')} />
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex items-center gap-2">
                    <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.submission')} />
                    <Form.Item name="submission" className="p-0 m-0">
                      <Radio.Group options={validationuponOption} />
                    </Form.Item>
                    {error?.submission && <p className="error-container">{error.submission}</p>}
                  </div>
                  <div className="flex items-center gap-2">
                    <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.pdOrder')} />
                    <Form.Item name="pdOrder" className="p-0 m-0">
                      <Radio.Group options={validationuponOption} />
                    </Form.Item>
                    {error?.pdOrder && <p className="error-container">{error.pdOrder}</p>}
                  </div>
                  <div className="flex items-center gap-2">
                    <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.ltOrder')} />
                    <Form.Item name="ltOrder" className="p-0 m-0">
                      <Radio.Group options={validationuponOption} />
                    </Form.Item>
                    {error?.ltOrder && <p className="error-container">{error.ltOrder}</p>}
                  </div>
                </div>
              </div>
            </div>
            {/* auto assign and workforce */}
            <div className="flex gap-1rem items-center w-full">
              <div className="flex flex-col gap-3 w-full">
                <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.autoAssign')} />
                <Form.Item name="autoAssign" rules={commonSingleSelectValidation(VALIDATION_STRINGS.REQUIRED_FIELD)} className="p-0 m-0">
                  <Radio.Group options={autoAssignOption} />
                </Form.Item>
                {error?.autoAssign && <p className="error-container">{error.autoAssign}</p>}
              </div>
              {/* <div className="w-full">
                <Form.Item name="workforce" rules={workforceValidation} className="p-0 m-0 w-full role-dropdown relative">
                  <Select variant="borderless" suffixIcon={<DropDownIcon />} className="icon-class" placeholder={t('placeholder.workforce')} value={getValue('workforce')}>
                    <Option key={'None'} value={'None'} className="flex gap-1">
                      <p className="para-p2 text-neutral-800">{'None'}</p>
                    </Option>
                  </Select>
                </Form.Item>
                {error?.workforce && <p className="error-container">{error.workforce}</p>}
              </div> */}
              <FloatingLabelSelect
                label={t('placeholder.workforce')}
                value={getValue('workforce')}
                options={[{ value: 'true', label: 'True' }]}
                name="workforce"
                rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}
                error={error.workforce}
              />
            </div>
          </div>
          {/* buttons */}
          <div className="flex items-start justify-end gap-4 w-full footer-sticky">
            <CustomBtn
              text={t('button.cancel')}
              type="default"
              onClick={() => {
                if (setIsModalOpen) setIsModalOpen(false)
              }}
              className="w-160 rounded-3"
            />
            <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} type="primary" htmlType="submit" className="w-160 rounded-3" />
          </div>
        </>
      </Form>
    </div>
  )
}

export default AddRequirement
