import { message } from 'antd'
import http from 'services/Service'

export const getFacility = async (limit: number, offset: number, order: string, field: string, search: string) => {
  try {
    const response = http.get(`/admin/all-facilities?limit=${limit}&offset=${offset}${order && field && `&order[${order}]=${field}`}${search && `&search=${search}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || 'Something Went Wrong')
  }
}
