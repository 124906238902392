import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Image, message, Popover } from 'antd'
import { profile } from 'assets/imgs'
import { DownloadIcon, DrawerClose, DrawerOpen, Logo, LogOut, MyProfileIcon, SettingsIconColor, TaskManagerIcon, ThreeDotIcon } from 'assets/svgs'
import { siderMenu } from 'constants/SiderMenu'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useWindowSize } from 'hooks/useWindowSize'
import { logOut } from 'services/StaticTokenServices'
import { setLogOut } from 'store/slice/AuthSlice'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { ISideBar } from 'types'
// import { firstName, lastName, profileImage, role } from 'utils/utils'

const Sidebar = ({ collapsed, setCollapsed }: ISideBar) => {
  const windowSize = useWindowSize()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const profileData = useAppSelector((state) => state?.auth?.auth?.profileDetails)
  const role = useAppSelector((state) => state?.auth?.auth?.role?.name)

  const handleLogout = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await logOut()
      if (response?.data?.statusCode === 1) {
        dispatch(setLogOut())
        navigate(`/login`)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }

    dispatch(setGlobalLoader(false))
  }

  const handleLogoutClick = () => {
    void handleLogout()
  }

  useEffect(() => {
    setCollapsed(windowSize.width <= 950)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  const content = (
    <div>
      <div className="cursor-pointer p-4 flex gap-2 items-center">
        <span>
          <DownloadIcon className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.downloads')}</p>
      </div>
      <div className="cursor-pointer p-4 flex gap-2 items-center">
        <span>
          <SettingsIconColor className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.preferences')}</p>
      </div>
      <div className="cursor-pointer p-4 flex gap-2 items-center">
        <span>
          <TaskManagerIcon className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.taskManager')}</p>
      </div>
      <div
        className="cursor-pointer p-4 flex gap-2 items-center"
        onClick={() => {
          navigate('/my-profile')
        }}
      >
        <span>
          <MyProfileIcon className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.myProfile')}</p>
      </div>
      <div className="cursor-pointer p-4 flex gap-2 items-center border-t-stroke" onClick={handleLogoutClick}>
        <LogOut className="flex" />
        <p className="para-p1 text-error-500">{t('button.logout')}</p>
      </div>
    </div>
  )

  return (
    <div className="flex justify-between flex-col h-screen">
      <div>
        <div className={`flex ${!collapsed ? 'justify-between' : 'justify-center'}`}>
          {!collapsed && (
            <div className="flex items-center gap-1">
              <Logo />
            </div>
          )}

          {windowSize?.width >= 950 ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                setCollapsed(!collapsed)
              }}
            >
              {collapsed ? <DrawerOpen className="cursor-pointer" /> : <DrawerClose className="cursor-pointer" />}
            </div>
          ) : (
            <Logo />
          )}
        </div>

        <div className="pt-10 scroll-container-sider">
          <div className="wrapper-menu-div">
            {siderMenu.map((data) => {
              return (
                <div
                  key={data.id}
                  className={`${data?.path === location?.pathname ? 'submenu-div-selected' : ''} flex gap-2 items-center submenu-div`}
                  onClick={() => {
                    navigate(data.path)
                  }}
                >
                  <div className="flex">{data.path === location.pathname ? data.selectedIcon : data.icon}</div>
                  <p style={{ color: data.path === location.pathname ? '#220EB1' : '#494A4C' }} className={`submenu-name para-p2 font-normal ${collapsed ? 'none' : 'block'}`}>
                    {data.name}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="sider-profile-box">
        {!collapsed ? (
          <div className="flex justify-between items-center ">
            <div className="flex gap-4">
              <Image
                preview={false}
                src={profileData?.image && profileData?.base_url ? `${profileData?.base_url}${profileData?.image}` : profile}
                className="img-profile-picture object-cover"
              />
              <div className={`flex flex-col gap-1 `}>
                <p className="para-p3 font-normal text-neutral-800">{`${profileData?.first_name} ${profileData?.last_name}` || ''}</p>
                <p className="para-p4 text-neutral-500">{role}</p>
              </div>
            </div>

            <Popover content={content} placement="right">
              <ThreeDotIcon className="cursor-pointer icon" />
            </Popover>
          </div>
        ) : (
          <Popover content={content} placement="right">
            <Image
              preview={false}
              src={profileData?.image && profileData?.base_url ? `${profileData?.base_url}${profileData?.image}` : profile}
              className="object-cover img-profile-picture"
            />
          </Popover>
        )}
      </div>
    </div>
  )
}

export default Sidebar
