import { AccountingIcon, ChatsIcon, DashBoardIcon, FacilitiesIcon, ProvidersIcon, ReportsIcon, ScheduleIcon, SettingsIcon, ShiftsIcon } from 'assets/svgs'

export const siderMenu = [
  {
    id: 1,
    name: 'DashBoard',
    icon: <DashBoardIcon />,
    selectedIcon: <DashBoardIcon isActive={true} />,
    path: '/',
  },
  {
    id: 3,
    name: 'Shifts',
    icon: <ShiftsIcon />,
    selectedIcon: <ShiftsIcon isActive={true} />,
    path: '/shifts',
  },
  {
    id: 4,
    name: 'Providers',
    icon: <ProvidersIcon />,
    selectedIcon: <ProvidersIcon isActive={true} />,
    path: '/providers',
  },
  {
    id: 5,
    name: 'Facilities',
    icon: <FacilitiesIcon />,
    selectedIcon: <FacilitiesIcon isActive={true} />,
    path: '/facilities',
  },
  {
    id: 6,
    name: 'Chats',
    icon: <ChatsIcon />,
    selectedIcon: <ChatsIcon isActive={true} />,
    path: '/chats',
  },
  {
    id: 7,
    name: 'Reports',
    icon: <ReportsIcon />,
    selectedIcon: <ReportsIcon isActive={true} />,
    path: '/reports',
  },
  {
    id: 8,
    name: 'Accounting',
    icon: <AccountingIcon />,
    selectedIcon: <AccountingIcon isActive={true} />,
    path: '/accounting',
  },
  {
    id: 9,
    name: 'Settings',
    icon: <SettingsIcon />,
    selectedIcon: <SettingsIcon isActive={true} />,
    path: '/settings',
  },
]
