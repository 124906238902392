import React from 'react'

const CalenderCheckIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.8332 3.33335H14.1665V2.50002C14.1665 2.27901 14.0787 2.06704 13.9224 1.91076C13.7661 1.75448 13.5542 1.66669 13.3332 1.66669C13.1122 1.66669 12.9002 1.75448 12.7439 1.91076C12.5876 2.06704 12.4998 2.27901 12.4998 2.50002V3.33335H7.49984V2.50002C7.49984 2.27901 7.41204 2.06704 7.25576 1.91076C7.09948 1.75448 6.88752 1.66669 6.6665 1.66669C6.44549 1.66669 6.23353 1.75448 6.07725 1.91076C5.92097 2.06704 5.83317 2.27901 5.83317 2.50002V3.33335H4.1665C3.50346 3.33335 2.86758 3.59675 2.39874 4.06559C1.9299 4.53443 1.6665 5.17031 1.6665 5.83335V15.8334C1.6665 16.4964 1.9299 17.1323 2.39874 17.6011C2.86758 18.07 3.50346 18.3334 4.1665 18.3334H15.8332C16.4962 18.3334 17.1321 18.07 17.6009 17.6011C18.0698 17.1323 18.3332 16.4964 18.3332 15.8334V5.83335C18.3332 5.17031 18.0698 4.53443 17.6009 4.06559C17.1321 3.59675 16.4962 3.33335 15.8332 3.33335ZM16.6665 15.8334C16.6665 16.0544 16.5787 16.2663 16.4224 16.4226C16.2661 16.5789 16.0542 16.6667 15.8332 16.6667H4.1665C3.94549 16.6667 3.73353 16.5789 3.57725 16.4226C3.42097 16.2663 3.33317 16.0544 3.33317 15.8334V10H16.6665V15.8334ZM16.6665 8.33335H3.33317V5.83335C3.33317 5.61234 3.42097 5.40038 3.57725 5.2441C3.73353 5.08782 3.94549 5.00002 4.1665 5.00002H5.83317V5.83335C5.83317 6.05437 5.92097 6.26633 6.07725 6.42261C6.23353 6.57889 6.44549 6.66669 6.6665 6.66669C6.88752 6.66669 7.09948 6.57889 7.25576 6.42261C7.41204 6.26633 7.49984 6.05437 7.49984 5.83335V5.00002H12.4998V5.83335C12.4998 6.05437 12.5876 6.26633 12.7439 6.42261C12.9002 6.57889 13.1122 6.66669 13.3332 6.66669C13.5542 6.66669 13.7661 6.57889 13.9224 6.42261C14.0787 6.26633 14.1665 6.05437 14.1665 5.83335V5.00002H15.8332C16.0542 5.00002 16.2661 5.08782 16.4224 5.2441C16.5787 5.40038 16.6665 5.61234 16.6665 5.83335V8.33335Z"
        fill="white"
      />
      <path
        d="M8.57825 15.5951C8.65572 15.6732 8.74788 15.7352 8.84943 15.7775C8.95098 15.8198 9.0599 15.8416 9.16991 15.8416C9.27992 15.8416 9.38884 15.8198 9.49039 15.7775C9.59194 15.7352 9.68411 15.6732 9.76158 15.5951L13.0949 12.2617C13.1726 12.184 13.2342 12.0918 13.2763 11.9903C13.3183 11.8887 13.34 11.7799 13.34 11.6701C13.34 11.5602 13.3183 11.4514 13.2763 11.3498C13.2342 11.2483 13.1726 11.1561 13.0949 11.0784C13.0172 11.0007 12.925 10.9391 12.8235 10.897C12.7219 10.855 12.6131 10.8333 12.5032 10.8333C12.3934 10.8333 12.2846 10.855 12.183 10.897C12.0815 10.9391 11.9893 11.0007 11.9116 11.0784L9.16991 13.8284L8.09491 12.7451C7.93799 12.5881 7.72517 12.5 7.50325 12.5C7.28133 12.5 7.0685 12.5881 6.91158 12.7451C6.75466 12.902 6.6665 13.1148 6.6665 13.3367C6.6665 13.5586 6.75466 13.7715 6.91158 13.9284L8.57825 15.5951Z"
        fill="white"
      />
    </svg>
  )
}

export default CalenderCheckIcon
