import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, message, Switch } from 'antd'
import { ModalClose } from 'assets/svgs'
import { UsaIcon } from 'assets/svgs/index'
import { country } from 'constants/Country'
import { commonSingleSelectValidation, emailValidation, mobileValidation, nameValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { getRoleDropdown } from 'services/common/Dropdown'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IAddProfile, IFieldData, IModalProps, ISelectOptionData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

import { addContact, editContact } from '../api'

const AddUserModal = ({ setIsModalOpen, editId, contactDetails, setContactDetails }: IModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRole, setSelectedRole] = useState('')
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country_code: '',
    mobile_no: '',
    role: '',
    status: '',
    bankAc: '',
  })
  const [switchData, setSwitchData] = useState<string>('active')
  const [editForm, setEditForm] = useState({
    first_name: '',
    last_name: '',
    user_name: '',
    email: '',
    country_code: '',
    mobile_no: '',
    role: '',
    status: '',
  })
  const [roles, setRoles] = useState([])
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const usa = country.find((item) => item.code === 'US')
  const [option, setOption] = useState<ISelectOptionData[]>([])
  const [form] = Form.useForm()

  useEffect(() => {
    if (roles) {
      const options = roles?.map((eachCertiData: { name: string; id: string }) => ({
        value: eachCertiData.id,
        label: eachCertiData.name,
      }))
      setOption(options)
    }
  }, [roles])

  useEffect(() => {
    void handleGetRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (contactDetails?.role) {
      setSelectedRole(contactDetails.role.id)
      setSwitchData(contactDetails?.status)
      form.setFieldsValue({
        first_name: contactDetails?.first_name,
        last_name: contactDetails?.last_name,
        email: contactDetails?.email,
        mobile_no: contactDetails?.mobile_no,
        role: contactDetails?.role.id,
        status: contactDetails?.status,
      })
    }
  }, [contactDetails, form])

  const onFinish = (values: IAddProfile) => {
    const dialCode = usa?.dial_code
    setEditForm(values)
    const payload = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      user_name: `${new Date().getTime()}`,
      email: values?.email?.toLowerCase(),
      country_code: dialCode,
      mobile_no: values?.mobile_no,
      role: values?.role,
      status: switchData,
    }
    void handleAddMember(payload)
  }

  const handleAddMember = async (data: IAddProfile) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await editContact(editId, data)
    } else {
      response = await addContact(data)
    }
    if (response?.data?.statusCode === 1) {
      setContactDetails()
      void message.success(response?.data?.message)
      setIsModalOpen(false)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetRoles = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getRoleDropdown()
    if (response?.status === 200) {
      setRoles(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleSwitchChange = (checked: boolean) => {
    setSwitchData(checked ? 'active' : 'in_active')
  }

  const handleCancel = () => {
    setContactDetails()
    setIsModalOpen(false)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        {/* eslint-disable-next-line */}
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={(editId ?? contactDetails?.status) ? t('label.editMember') : t('label.addMember')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        className="form-certi-wrapper"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="flex gap-6">
          <div className="w-50">
            <InputField
              placeholder={t('label.profileForm.fname')}
              rules={nameValidation(VALIDATION_STRINGS.FIRST_NAME)}
              name="first_name"
              error={error.first_name}
              value={getValue('first_name')}
            />
          </div>
          <div className="w-50">
            <InputField
              error={error.last_name}
              placeholder={t('label.profileForm.lname')}
              rules={nameValidation(VALIDATION_STRINGS.LAST_NAME)}
              name="last_name"
              value={getValue('last_name')}
            />
          </div>
        </div>

        <div className="flex gap-6">
          <div className="w-50">
            <InputField placeholder={t('label.profileForm.email')} rules={emailValidation} name="email" error={error.email} value={getValue('email')} />
          </div>
          <div className="w-50">
            <div className="flex gap-2 mobile-country-code">
              <div className="country-code-wrapper w-120 h-64">
                <div className="country-flag">
                  <UsaIcon />
                </div>
                <p className="para-p2 font-normal text-neutral-800 ">{usa?.dial_code}</p>
              </div>
              <div className="flex flex-col flex-grow-1">
                <InputField
                  name="mobile_no"
                  rules={mobileValidation}
                  placeholder={t('placeholder.mobileNumber')}
                  wrapperClass="w-full flex-grow-1"
                  error={error.mobile_no}
                  value={getValue('mobile_no')}
                />
              </div>
            </div>
          </div>
        </div>

        <FloatingLabelSelect
          label={t('placeholder.selectRole')}
          options={option}
          name="role"
          rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_ROLE)}
          error={error.role}
          value={getValue('role')}
        />
        {/* <div className="w-full">
          <div className={`${!error?.bankAc ? 'mb-6' : ''}`}>
            <Form.Item name="bankAc" rules={bankAcValidation} className="p-0 m-0 w-full role-dropdown relative">
              <div className="flex">
                <Select variant="borderless" suffixIcon={null} className="" placeholder={t('placeholder.selectBankAc')}>
                  {roles.map((item: { id: string; name: string }) => {
                    return (
                      <Option key={item.id} value={item.id} className="flex gap-1">
                        <span className="flex items-center  gap-2">
                          <p className="para-p2 text-neutral-800">{item.name}</p>
                        </span>
                      </Option>
                    )
                  })}
                </Select>
                <span className="rol-dropdown-icon">
                  <DropDownIcon />
                </span>
              </div>
            </Form.Item>
          </div>
          {error?.bankAc && <p className="error-container mb-6">{error.bankAc}</p>}
        </div> */}

        <div className={`flex items-center gap-6 justify-between`}>
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.userStatus')}</span> <Switch onChange={handleSwitchChange} checked={switchData === 'active' || editForm.status === 'active'} />
          </div>
          <div className="flex gap-4">
            <CustomBtn text={t('button.cancel')} type="default" className="w-160" onClick={handleCancel} />
            {/* eslint-disable-next-line */}
            <CustomBtn text={(editId ?? contactDetails?.status) ? t('button.updateBtn') : t('button.add')} htmlType="submit" type="primary" className="w-160" />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default AddUserModal
