import { useEffect, useState } from 'react'
import { type SearchProps } from 'antd/es/input'
import { PlusIcon } from 'assets/svgs'
import { DELETE_SHIFT_CANCEL_REASONS, DELETE_SHIFT_TIMINGS, facility } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'
import type { IShiftTimeColumn } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import CustomTable from 'components/common/Table/CustomTable'

import { useColumn } from './hooks/useColumn'
import AddReason from './Modal/AddReason'
import AddTiming from './Modal/AddTiming'

const ShiftSetting = () => {
  const dispatch = useAppDispatch()

  const [, setModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [isAddNewTime, setIsAddNewTime] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string | undefined>('shift_timings')
  const [editId, setEditId] = useState<string>('')
  const [getData, setData] = useState<IShiftTimeColumn>({ id: '', name: '', start_time: '', end_time: '', status: '' })
  const [isAddNewReason, setIAddNewReason] = useState<boolean>(false)
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [sortCancelReasonTable, setSortCancelReasonTable] = useState({
    order: '',
    field: '',
  })
  const [shiftCancelData, setShiftCancelData] = useState({
    reason: '',
    description: '',
    status: '',
    user_type: '',
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const userType = facility
  const { shiftTimingColumn, shiftCancelReasonsColumn, handlePageChange, handleDeleteCancelReason, handleDeleteShiftTiming } = useColumn({
    setEditId,
    setData,
    setIsAddNewTime,
    setShiftCancelData,
    setIAddNewReason,
    paginationInfo,
    setPaginationInfo,
    sortTable,
    sortCancelReasonTable,
    userType,
    search,
    setListData,
    actionMenuOpen,
    setActionMenuOpen,
    selectedValue,
    isAddNewTime,
    isAddNewReason,
    setDeleteId,
    setDeleteName,
    setIsDeleteModalOpen,
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.shiftSetting'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const showModal = () => {
    setModalOpen(true)
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleCancelReasonTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortCancelReasonTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const TableButton =
    selectedValue === 'shift_timings' ? (
      <CustomBtn
        isIcon
        svgIcon={<PlusIcon />}
        text={t('button.addTime')}
        type="primary"
        className="rounded-3 border-0"
        onClick={() => {
          setEditId('')
          setData({ id: '', name: '', start_time: '', end_time: '', status: '' })
          setIsAddNewTime(true)
        }}
      />
    ) : (
      <CustomBtn
        isIcon
        svgIcon={<PlusIcon />}
        text={t('button.addReason')}
        type="primary"
        className="rounded-3 border-0"
        onClick={() => {
          setEditId('')
          setIAddNewReason(true)
        }}
      />
    )

  const tabData = [
    { value: 'shift_timings', label: 'Shift Timings' },
    { value: 'shift_cancel_reasons', label: 'Shift Cancel Reasons' },
  ]
  console.log('selectedValue', selectedValue)

  return (
    <div className="shift-timing-wrapper">
      <CustomTable<any>
        name={t('label.shiftTiming')}
        column={selectedValue === 'shift_timings' ? shiftTimingColumn : shiftCancelReasonsColumn}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        onChange={selectedValue === 'shift_timings' ? handleTableChange : handleCancelReasonTableChange}
        data={listData}
        isSearch
        setModalOpen={showModal}
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        searchPlaceholder={t('placeholder.search')}
        tableButton={TableButton}
        isTab
        option={tabData}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
      />
      {isAddNewTime && (
        <CustomModal
          open={isAddNewTime}
          centered
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsAddNewTime(false)
          }}
          content={<AddTiming setIsModalOpen={setIsAddNewTime} editId={editId} data={getData} />}
          width={600}
        />
      )}
      {isAddNewReason && (
        <CustomModal
          open={isAddNewReason}
          centered
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIAddNewReason(false)
          }}
          width={600}
          content={<AddReason setIsModalOpen={setIAddNewReason} editId={editId} data={shiftCancelData} />}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={selectedValue === 'shift_timings' ? DELETE_SHIFT_TIMINGS.CANCEL : DELETE_SHIFT_CANCEL_REASONS.CANCEL}
          okClick={() => {
            void (selectedValue === 'shift_timings' ? handleDeleteShiftTiming(deleteId) : handleDeleteCancelReason(deleteId))
          }}
          deleteName={deleteName}
          subHead={selectedValue === 'shift_timings' ? DELETE_SHIFT_TIMINGS.DELETE_DESC : DELETE_SHIFT_CANCEL_REASONS.DELETE_DESC}
          mainHead={selectedValue === 'shift_timings' ? DELETE_SHIFT_TIMINGS.DELETE_HEAD_LOB : DELETE_SHIFT_CANCEL_REASONS.DELETE_HEAD_LOB}
          okText={selectedValue === 'shift_timings' ? DELETE_SHIFT_TIMINGS.DELETE : DELETE_SHIFT_CANCEL_REASONS.DELETE}
        />
      )}
    </div>
  )
}

export default ShiftSetting
