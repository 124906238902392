import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Switch } from 'antd'
import { MinusIcon, PlusIcon } from 'assets/svgs'
import { schedulingWarning, settingTab } from 'constants/Constant'
import { type IFacilityPortalSetting } from 'types'

// todo :- type not given cause component is using someplace else also
const FacilityPortalSetting = ({ facilityPortalSetting, setFacilityPortalSetting }: any) => {
  const [counter, setCounter] = useState<number>(facilityPortalSetting?.cancellation_advance ?? 0)

  const handleMinusClick = () => {
    setCounter(counter > 0 ? counter - 1 : 0)
  }

  const handlePlusClick = () => {
    setCounter(Number(counter) + 1)
  }

  const handleCheckboxChange = (data: { name: string; value: string }) => {
    setFacilityPortalSetting((prevState: IFacilityPortalSetting) => {
      const updatedEntries = prevState.scheduling_warnings.includes(data.value)
        ? prevState.scheduling_warnings.filter((entry) => entry !== data.value)
        : [...prevState.scheduling_warnings, data.value]

      return {
        ...prevState,
        scheduling_warnings: updatedEntries,
      }
    })
  }

  useEffect(() => {
    setFacilityPortalSetting({ ...facilityPortalSetting, cancellation_advance: counter })
  }, [counter])

  return (
    <div className="flex items-start w-full rounded-4 border-stroke flex-col">
      <div className=" border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.ALLOW_CANCEL}</p>
        </div>
        <div className="flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch
            checked={facilityPortalSetting?.allow_cancellation}
            onChange={() => {
              setFacilityPortalSetting({ ...facilityPortalSetting, allow_cancellation: !facilityPortalSetting?.allow_cancellation })
            }}
          />
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.CANCELATION_ADVA}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <div className="counter-box">
            <Button icon={<MinusIcon />} onClick={handleMinusClick} className="counter-btn-min" />
            <div className="counter-text-wrapper">
              <p className="counter-text">{counter}</p>
            </div>
            <Button icon={<PlusIcon className="plus-icon-solid " />} onClick={handlePlusClick} className="counter-btn-plus" />
          </div>
          <p className="text-neutral-800 para-p3 font-small">{settingTab.HOURS}</p>
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.DISPLAY_PROVIDER_REQUEST}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch
            checked={facilityPortalSetting?.display_provider_request}
            onChange={() => {
              setFacilityPortalSetting({ ...facilityPortalSetting, display_provider_request: !facilityPortalSetting?.display_provider_request })
            }}
          />
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.ACCEPT_PROVIDER_REQUEST}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch
            checked={facilityPortalSetting?.accept_provider_request}
            onChange={() => {
              setFacilityPortalSetting({ ...facilityPortalSetting, accept_provider_request: !facilityPortalSetting?.accept_provider_request })
            }}
          />
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.ALLOWS_SCHEDULING_WARNING}</p>
        </div>
        <div className="flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <div className="flex flex-col item-start gap-2">
            {schedulingWarning.map((data) => {
              return (
                <div className="flex items-start justify-start gap-2" key={data.name}>
                  <Checkbox
                    onChange={() => {
                      handleCheckboxChange(data)
                    }}
                    checked={facilityPortalSetting.scheduling_warnings?.some((item: string) => item === data.value)}
                  />
                  <p className="text-neutral-700 para-p3 font-medium">{data.name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="border-b-stroke flex items-start aline-self flex-grow-1">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.SET_CLIENT_CONFO}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch
            checked={facilityPortalSetting?.client_confirmation}
            onChange={() => {
              setFacilityPortalSetting({ ...facilityPortalSetting, client_confirmation: !facilityPortalSetting?.client_confirmation })
            }}
          />
        </div>
      </div>{' '}
      <div className="flex items-center gap-2 aline-self flex-grow-1-wrapper">
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-60">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.DISPLAY_BILL_RATE}</p>
        </div>
        <div className="h-60 flex p-4 items-center gap-2 aline-self flex-grow-1 w-40">
          <Switch
            checked={facilityPortalSetting?.display_bill_rate}
            onChange={() => {
              setFacilityPortalSetting({ ...facilityPortalSetting, display_bill_rate: !facilityPortalSetting?.display_bill_rate })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default FacilityPortalSetting
