import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { message, Steps } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { addRole, setFormData } from 'store/slice/AddRoleSlice'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import type { IRoleSection, ISubSections } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

import { addRoles, editRoles, getRolesSections } from '../api'

import PermissionStep from './steps/PermissionStep'
import RoleInfo from './steps/RoleInfo'

interface StepProps {
  key: string // Adjust based on your needs
  title: string
  content: JSX.Element
}

const AddEditRole = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [current, setCurrent] = useState<number>(0)
  const [sectionData, setSectionData] = useState<IRoleSection[] | any>()
  const formData = useAppSelector(addRole)
  const topRef = useRef<HTMLDivElement>(null)

  const { editId } = useParams()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.roleManage'),
        isBack: true,
      })
    )
  }, [dispatch, t])

  useEffect(() => {
    void handleGetSection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const element = document.querySelector('.wrapper-content')
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [current])

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleGetSection = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getRolesSections()
    if (response?.status === 200) {
      setSectionData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleSubmit = () => {
    if (!formData.name) {
      void message.error(VALIDATION_ERROR.COMMON.ROLESUBMIT)
      return
    }
    void handleSubmitRoleData()
  }

  const handleSubmitRoleData = async () => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      const finalSections = formData.sections
        .map((section: ISubSections) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const filteredSubSections = section.sub_sections.filter((sub_section) => {
            return sub_section.permissions && sub_section.permissions.length > 0
          })
          return { ...section, sub_sections: filteredSubSections }
        })
        .filter((section) => section.sub_sections.length > 0)
      const data = {
        name: formData.name,
        description: formData.description,
        sections: finalSections,
      }
      response = await editRoles(editId, data)
    } else {
      response = await addRoles(formData)
    }
    if (response?.data?.statusCode === 1 || response?.status === 201) {
      navigate('/settings/user/role-management')
      const resetFormData = {
        name: '',
        description: '',
        sections: [],
      }
      void message.success(response?.data?.message)
      dispatch(setFormData(resetFormData))
    }
    dispatch(setGlobalLoader(false))
  }

  // Create a new array excluding the last element
  const modifiedSectionData = sectionData ? sectionData.slice(0, -1) : []

  const items: StepProps[] = [
    {
      key: '1',
      title: 'Role info',
      content: <RoleInfo next={next} />,
    },
    ...modifiedSectionData.map((item: IRoleSection, index: number) => {
      let title = formatTitle(item.name)
      const content = <PermissionStep next={next} prev={prev} item={item} isLast={index === modifiedSectionData.length - 1} editId={editId} />

      if (index === 6) {
        title = t('heading.accountingAndPermission')
      }

      return {
        key: item.id.toString(),
        title,
        content,
      }
    }),
  ]

  const accounting: StepProps[] = [
    {
      key: '1',
      title: 'Role info',
      content: <RoleInfo next={next} />,
    },
    ...(sectionData
      ? sectionData.map((item: IRoleSection, index: number) => {
          const isLast = index === sectionData.length - 1
          return {
            key: item.id.toString(),
            title: formatTitle(item.name),
            content: <PermissionStep next={next} prev={prev} item={item} isLast={isLast} editId={editId} />,
          }
        })
      : []),
  ]
  return (
    <div ref={topRef}>
      <div className="flex justify-between mb-6 items-center">
        <HeadingText classString="text-neutral-800 mobile-heading-h4 font-bold" text={editId ? t('heading.editRole') : t('heading.createNewRole')} />

        <div className="flex gap-6 text-neutral-700 para-p2">
          <CustomBtn
            className=""
            text={t('button.cancel')}
            onClick={() => {
              navigate('/settings/user/role-management')
              const resetFormData = {
                name: '',
                description: '',
                sections: [],
              }
              dispatch(setFormData(resetFormData))
            }}
          />
          <CustomBtn className="text-white para-p2" type="primary" text={t('button.save')} onClick={handleSubmit} />
        </div>
      </div>

      <div className="rounded-4 bg-white p-10 flex gap-6">
        <div className="p-2 border-stroke max-w-272 w-full rounded-2 h-fit">
          <Steps current={current} items={items} className="roles-wrapper" />
        </div>
        <div className="flex flex-col gap-10 w-full h-full">
          <div className="w-full h-fit flex flex-col gap-10">
            {items[current]?.content}
            {current === 7 && <div>{accounting[accounting.length - 1].content}</div>}
          </div>
          {current >= 1 && (
            <div className="flex gap-4 justify-end mt-6">
              <CustomBtn text={t('common.back')} onClick={prev} type="default" />
              {current === modifiedSectionData.length ? (
                <CustomBtn text={t('button.save')} onClick={handleSubmit} type="primary" />
              ) : (
                <CustomBtn text={t('common.next')} onClick={next} type="primary" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AddEditRole
