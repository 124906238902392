import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, type MenuProps, message } from 'antd'
import type { SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { CloseIcon, DeleteIcon, DropDownIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { agencyEmployment, autoAssignOptions, COMMON, COMPLIENCE_MANAGER, DELETE_MODAL_COMPLIANCE_MANAGER_TEXT, workForceFilterOptionFilter } from 'constants/Constant'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { getSpeciality } from 'pages/license/api'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import type { ICredentialRequirmentTableData, IDataItem, IFilterRequirement, IOptionGroup, ISelectOptionData } from 'types'
import { formatTitle } from 'utils/Helper'
import { formatQuerry } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import CustomTableHeader from 'components/common/CustomTableHeader/CustomTableHeader'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import DropDown from 'components/common/Select/DropDown'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CustomTable from 'components/common/Table/CustomTable'

import AddRequirement from './modal/AddRequirement'
import { deleteCredentialRequirements, deleteCredentialRequirementsCategory, getCertificate, getCredentialDocuments, getCredentialRequirements } from './api'

const CredentialRequirement = () => {
  //   const [selectedFilters, setSelectedFilters] = useState('')
  const [requirementModalOpen, setRequirementModalOpen] = useState<boolean>(false)
  //   const [selectedCheckboxes, setSelectedCheckboxes] = useState<Record<string, string[]>>({})
  const [editRequirementId, setEditRequirementId] = useState('')
  const [requirementData, setRequirementData] = useState<IDataItem[]>([])
  const [editRequirementData, setEditRequirementData] = useState<ICredentialRequirmentTableData | undefined>()
  const [visibleRequirementTables, setVisibleRequirementTables] = useState<number[]>([])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [isDeleteDocOrCategory, setIsDeleteDocOrCategory] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [credentialsOption, setCredentialsOption] = useState<IOptionGroup[]>([])
  const [licenceOption, setLicenceOption] = useState<ISelectOptionData[] | any>([])
  const [specialityOptions, setSpecialityOptions] = useState<ISelectOptionData[] | any>([])
  const [filterApply, setFilterApply] = useState(false)
  const initialFilters = {
    certificate: '',
    speciality: '',
    credentials_document: [],
    agency_employment: '',
    workforce_portal: '',
    auto_assign: [],
  }
  const [filters, setFilters] = useState<IFilterRequirement>({ ...initialFilters })
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.credentialRequirement'),
        isBack: true,
      })
    )
  }, [dispatch, t])

  useEffect(() => {
    if (filterApply) {
      void handleGetRequirementData()
    }
  }, [search, requirementModalOpen, filterApply])

  useEffect(() => {
    void handleGetRequirementData()
  }, [search, requirementModalOpen])

  useEffect(() => {
    void credentialDocumentData()
    void getCertificateDropDownOpt()
    void getSpecialityDropDownOpt()
  }, [])

  const handleToggleRequirement = (index: number) => {
    setVisibleRequirementTables((prevVisibleSections) => {
      if (prevVisibleSections.includes(index)) {
        return prevVisibleSections.filter((i) => i !== index)
      } else {
        return [...prevVisibleSections, index]
      }
    })
  }
  const sortDataByDateTime = (data: IDataItem[]): IDataItem[] => {
    const sortedData = data?.sort((a, b) => {
      const dateA = new Date(a?.requirement?.[0]?.created_at || 0) // Add check for undefined or empty requirement
      const dateB = new Date(b?.requirement?.[0]?.created_at || 0)
      return dateB?.getTime() - dateA?.getTime() // Sort in descending order (latest date and time first)
    })

    sortedData.forEach((item) => {
      if (item?.requirement?.length) {
        item.requirement = item?.requirement.sort((a, b) => {
          const dateA = new Date(a?.created_at || 0)
          const dateB = new Date(b?.created_at || 0)
          return dateB?.getTime() - dateA?.getTime() // Sort in descending order (latest date and time first)
        })
      }
    })

    return sortedData
  }

  const handleGetRequirementData = async () => {
    dispatch(setGlobalLoader(true))
    const params = {
      ...filters?.auto_assign?.reduce((acc: any, autoAssign: string, index) => {
        // eslint-disable-next-line
        acc[`auto_assign[${index}]`] = autoAssign
        return acc
      }, {}),
      ...filters?.credentials_document?.reduce((acc: any, credDoc: string, index) => {
        // eslint-disable-next-line
        acc[`credentials_document[${index}]`] = credDoc
        return acc
      }, {}),
      search,
      certificate: filters?.certificate,
      speciality: filters?.speciality,
      agency_employment: filters?.agency_employment,
      workforce_portal: filters?.workforce_portal,
    }
    const query = formatQuerry({ ...params })

    const response = await getCredentialRequirements(query)
    if (response?.data?.statusCode === 1) {
      setFilterApply(false)
      const sortedData = sortDataByDateTime(response?.data?.data)
      setRequirementData(sortedData)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const handleDeleteRequirementCategory = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await deleteCredentialRequirementsCategory(id)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setIsDeleteModalOpen(false)
      setDeleteId('')
      setIsDeleteDocOrCategory('')
      void handleGetRequirementData()
    }
    dispatch(setGlobalLoader(false))
  }

  const getCertificateDropDownOpt = async () => {
    const response = await getCertificate()
    if (response?.status === 200) {
      const modifiedLicence = response?.data?.data?.map((item: { id: string; name: string }) => ({
        ...item,
        value: item.id,
        label: item?.name,
      }))
      setLicenceOption(modifiedLicence)
    }
  }

  const getSpecialityDropDownOpt = async () => {
    const response = await getSpeciality()
    if (response?.status === 200) {
      const modifiedLicence = response?.data?.data?.map((item: { id: string; name: string }) => ({
        ...item,
        value: item.id,
        label: item?.name,
      }))
      setSpecialityOptions(modifiedLicence)
    }
  }

  const credentialDocumentData = async () => {
    const response = await getCredentialDocuments()
    if (response?.data?.statusCode === 1) {
      const filteredData = Object.keys(response?.data?.data).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1),
        title: key,
        options: response?.data?.data[key].map((item: { id: string; name: string }) => ({
          id: item?.id,
          name: item?.name,
        })),
      }))

      setCredentialsOption(filteredData)
    }
  }

  const handleDeleteRequirement = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await deleteCredentialRequirements(id)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setIsDeleteModalOpen(false)
      setDeleteId('')
      setIsDeleteDocOrCategory('')
      void handleGetRequirementData()
    }
    dispatch(setGlobalLoader(false))
  }

  const handleChangeCategory = (selectedValue: string) => {
    setFilters((prev: any) => ({ ...prev, credentials_document: selectedValue }))
  }

  const handleChangeAutoAssign = (selectedValue: string) => {
    setFilters((prev: any) => ({ ...prev, auto_assign: selectedValue }))
  }

  const findOptionUsingForEach = (id: string) => {
    let foundOption: any

    // Iterate over each group in the data array
    credentialsOption?.forEach((group) => {
      // Check if the option is found in the current group's options
      // eslint-disable-next-line array-callback-return
      const option = group?.options?.find((item: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        return item?.id === id
      })
      if (option) {
        foundOption = option
      }
    })
    return foundOption
  }

  const handleDeleteTag = (index: number) => {
    const updatedCredentialsTag: any = [...filters.credentials_document]
    updatedCredentialsTag.splice(index, 1)
    setFilters((prev) => ({ ...prev, credentials_document: updatedCredentialsTag }))
  }

  const handleDeleteAssignTag = (index: number) => {
    const updatedAssignTag: any = [...filters.auto_assign]
    updatedAssignTag.splice(index, 1)
    setFilters((prev) => ({ ...prev, auto_assign: updatedAssignTag }))
  }

  const filterMenu = (
    <div className="w-full flex flex-col gap-8 mt-2">
      <div className="flex flex-col gap-6px item-start w-full mt-2">
        <FloatingLabelSelect
          label={t('label.certificate')}
          options={licenceOption}
          name="certificate"
          mainClass="filter-floating-select"
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, certificate: String(e) }))
          }}
          isSearch
          searchPlaceholder={COMMON.SEARCH}
          value={filters?.certificate}
        />

        <FloatingLabelSelect
          label={t('label.speciality')}
          options={specialityOptions}
          name="speciality"
          mainClass="filter-floating-select"
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, speciality: String(e) }))
          }}
          value={filters?.speciality}
          isSearch
          searchPlaceholder={COMMON.SEARCH}
        />

        <DropDown
          handleChange={handleChangeCategory}
          name="credentials_document"
          Options={credentialsOption}
          placeholder={COMPLIENCE_MANAGER.CRED_CAT}
          optionWidth={200}
          updatedTag={filters?.credentials_document}
          mainClass="mb-6"
          value={filters?.credentials_document}
        />

        {filters?.credentials_document?.length > 0 && (
          <div className="flex flex-col gap-4 w-full mb-6">
            {filters?.credentials_document?.length > 0 && <HeadingText text={COMPLIENCE_MANAGER.CREDENTIALS} classString="para-p3 text-neutral-400" />}
            <div className="flex gap-4 w-full flex-wrap">
              {filters?.credentials_document?.map((tagId: string, index: number) => {
                const option = findOptionUsingForEach(tagId)
                return (
                  <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                    {option?.name}
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleDeleteTag(index)
                      }}
                    >
                      {<CloseIcon />}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )}

        <FloatingLabelSelect
          label={COMPLIENCE_MANAGER.AGENCY_EMP}
          options={agencyEmployment}
          name="auto_assign"
          mainClass="filter-floating-select"
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, agency_employmeCredentialnt: String(e) }))
          }}
          value={filters?.agency_employment}
        />

        <DropDown
          handleChange={handleChangeAutoAssign}
          name={'auto_assign'}
          Options={autoAssignOptions}
          placeholder={COMPLIENCE_MANAGER.AUTO_ASSIGN}
          optionWidth={200}
          mainClass="mb-6"
          updatedTag={filters?.auto_assign}
          value={filters?.auto_assign}
        />

        {filters?.auto_assign?.length > 0 && (
          <div className="flex flex-col gap-4 w-full mb-6">
            {filters?.auto_assign?.length > 0 && <HeadingText text={COMPLIENCE_MANAGER.AUTO_ASSIGN} classString="para-p3 text-neutral-400" />}
            <div className="flex gap-4 w-full flex-wrap">
              {filters?.auto_assign?.map((tagId: string, index: number) => {
                const option = autoAssignOptions.find((item) => item.id === tagId)
                return (
                  <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                    {option?.name}
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleDeleteAssignTag(index)
                      }}
                    >
                      {<CloseIcon />}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )}

        <FloatingLabelSelect
          label={COMPLIENCE_MANAGER.WORKFORCE_PORTAL}
          options={workForceFilterOptionFilter}
          name="workforce_portal"
          mainClass="filter-floating-select"
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, workforce_portal: String(e) }))
          }}
          value={filters?.workforce_portal}
        />
      </div>
    </div>
  )

  const getMenu = (record: any): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (
            <div
              className="option-wrapper"
              onClick={() => {
                if (record?.id) {
                  setEditRequirementId(record.id)
                  setRequirementModalOpen(true)
                  setEditRequirementData(record)
                }
              }}
            >
              <p className="flex items-center gap-2">
                <EditPenIcon /> <span className="activity-text font-normal text-neutral-800">Edit</span>
              </p>
            </div>
          ),
        },
        {
          key: 2,
          label: (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                if (typeof record.id === 'string') {
                  setIsDeleteDocOrCategory('requirement')
                  setIsDeleteModalOpen(true)
                  setDeleteId(record?.id)
                  setDeleteName(record?.certificate_or_speciality?.name)
                }
              }}
            >
              <p className="flex items-center gap-2">
                <DeleteIcon className="delete-icon" /> <span className="activity-text font-normal text-error-500">Delete</span>
              </p>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<ICredentialRequirmentTableData> = [
    {
      title: t('table.credentialsRequirement.certification'),
      dataIndex: ['certificate_or_speciality', 'name'],
      key: 'certificate_or_speciality',
      align: 'start',
      width: 219,
      sorter: (a, b) => {
        const certificateDataA = Array.isArray(a?.certificate) ? a?.certificate : []
        const specialityDataA = Array.isArray(a?.speciality) ? a?.speciality : []
        const certificateAndSpecialityDataA = [...certificateDataA, ...specialityDataA]
        const certificateSpecialitiesA = certificateAndSpecialityDataA?.map((certi: { name: string }) => certi?.name).join(', ')

        const certificateDataB = Array.isArray(b?.certificate) ? b?.certificate : []
        const specialityDataB = Array.isArray(b?.speciality) ? b?.speciality : []
        const certificateAndSpecialityDataB = [...certificateDataB, ...specialityDataB]
        const certificateSpecialitiesB = certificateAndSpecialityDataB?.map((certi: { name: string }) => certi?.name).join(', ')

        return certificateSpecialitiesA.localeCompare(certificateSpecialitiesB)
      },
      render: (_value, record: any) => {
        const certificateData = Array.isArray(record?.certificate) ? record?.certificate : []
        const specialityData = Array.isArray(record?.speciality) ? record?.speciality : []
        const certificateAndSpecialityData = [...certificateData, ...specialityData]

        const certificateSpecialities = certificateAndSpecialityData?.map((certi: { name: string }) => certi?.name).join(', ')
        return <HeadingText classString="text-neutral-800 font-normal activity-text" text={certificateSpecialities} />
      },
    },
    {
      title: t('table.credentialsRequirement.credential'),
      dataIndex: ['credentials_document', 'name'],
      key: 'credentials_document',
      align: 'start',
      width: 222,

      sorter: (a: any, b: any) => a.credentials_document?.name.localeCompare(b.credentials_document?.name),
    },
    {
      title: t('table.credentialsRequirement.state'),
      dataIndex: 'state',
      key: 'state',
      align: 'start',
      width: 150,
      sorter: (a, b) => a.state.localeCompare(b?.state),
    },
    {
      title: t('table.credentialsRequirement.employement'),
      dataIndex: 'employement',
      key: 'employement',
      align: 'center',
      width: 250,
      className: 'center-align',
      sorter: (a: any, b: any) => {
        const valA = a.is_required_for_agency ? 'yes' : 'no'
        const valB = b.is_required_for_agency ? 'yes' : 'no'
        return valA.localeCompare(valB)
      },
      render: (_value, record) => {
        return (
          <div className="flex flex-col gap-2">
            <HeadingText classString="text-neutral-800 font-small para-p3 flex w-full justify-start" text={record?.is_required_for_agency ? 'Yes' : 'No'} />
            <div className="flex justify-start gap-2 items-center w-full">
              <HeadingText classString="text-neutral-800 font-bold para-p3  flex justify-start" text={`${t('heading.employmentType')}: `} />
              <p className="text-neutral-800 font-small para-p3">{record?.employment_type as string}</p>
            </div>
            <HeadingText classString="text-neutral-800 font-bold para-p3 w-full flex justify-start" text={t('heading.validateUpon')} />
            <div className="flex justify-between">
              <HeadingText classString="text-neutral-800 font-small para-p3" text={t('heading.submission')} />
              <HeadingText classString="text-neutral-800 font-small para-p3" text={String(record?.submission)} />
            </div>
            <div className="flex justify-between">
              <HeadingText classString="text-neutral-800 font-small para-p3" text={t('heading.pdOrder')} />
              <HeadingText classString="text-neutral-800 font-small para-p3" text={String(record?.pd_order_fill)} />
            </div>
            <div className="flex justify-between">
              <HeadingText classString="text-neutral-800 font-small para-p3" text={t('heading.ltOrder')} />
              <HeadingText classString="text-neutral-800 font-small para-p3" text={String(record?.lt_order_fill)} />
            </div>
          </div>
        )
      },
    },
    {
      title: t('table.credentialsRequirement.autoAssign'),
      dataIndex: 'auto_assign',
      key: 'auto_assign',
      align: 'center',
      width: 199,
      className: 'center-align',
      sorter: (a: any, b: any) => a.auto_assign?.localeCompare(b.auto_assign),
      render: (_value, record: any) => {
        return <HeadingText classString="text-neutral-800 font-normal top-center-align" text={formatTitle(record?.auto_assign)} />
      },
    },
    {
      title: t('table.credentialsRequirement.workForcePortal'),
      dataIndex: 'workForcePortal',
      key: 'workForcePortal',
      align: 'start',
      width: 199,
      sorter: (a: any, b: any) => a.workforce_portal?.localeCompare(b.workforce_portal),
      render: (_value, record) => {
        return (
          <div className="flex gap-1">
            <HeadingText classString="text-neutral-800 font-medium para-p3" text={`${t('heading.enforcement')}:`} />
            <HeadingText classString="text-neutral-800 para-p3" text={record?.workforce_portal as string} />
          </div>
        )
      },
    },
    {
      title: t('table.credentialsRequirement.create'),
      dataIndex: 'create',
      key: 'create',
      align: 'start',
      width: 199,
      sorter: (a: any, b: any) => dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf(),
      render: (_value, record: any) => {
        const updateformattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY, hh:mm A')
        const createformattedDate = dayjs(record?.created_at).format('MM/DD/YYYY, hh:mm A')
        return (
          <div className="flex flex-col gap-1rem">
            <div>
              <HeadingText classString="text-neutral-800 para-p3" text={`Created on ${createformattedDate} by ${record?.created_by as string}`} />
            </div>
            <div>
              <HeadingText
                classString="text-neutral-800 para-p3"
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                text={`Modified on ${updateformattedDate} ${record?.updated_by?.trim() && `by ${record?.updated_by as string}`}`}
              />
            </div>
          </div>
        )
      },
    },
    {
      title: t('table.credentialsRequirement.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 100,
      fixed: 'right',
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <div className="flex w-full justify-center p-2">
              <Dropdown rootClassName="table-action-dropdown" trigger={['click']} menu={getMenu(record)} placement="bottomRight">
                <div className="action-cell flex items-center justify-center three-dot-icon">
                  <ThreeDotIcon className="cursor-pointer icon" />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <CustomTableHeader
        isSearch
        isFilter
        searchPlaceholder="Search"
        onSearchChange={(e: any) => {
          handleChange(e.target.value)
        }}
        onSearch={onSearch}
        filterOption={filterMenu}
        handleResetFilter={() => {
          setFilters({ ...initialFilters })
          setFilterApply(true)
        }}
        filterSelected={filters}
        handleApplyFilter={() => {
          setFilterApply(true)
        }}
        tableButton={
          <CustomBtn
            isIcon
            svgIcon={<PlusIcon />}
            text={t('button.addNewRequirement')}
            type="primary"
            className="rounded-3"
            onClick={() => {
              setEditRequirementId('')
              setRequirementModalOpen(true)
            }}
          />
        }
      />
      <div className="py-6 border bg-white pr-10 pl-10 div-wrapper">
        <p className="mb-4 text-neutral-800  mobile-heading-h4 ">Credential Manager</p>
        <div className="flex flex-col gap-4">
          {requirementData?.map((data: any, index: number) => {
            return (
              <div key={index} className="flex flex-col gap-2 items-center w-full p-4 bg-neutral-50 rounded-4">
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-2 items-center">
                    <div
                      onClick={() => {
                        handleToggleRequirement(index)
                      }}
                      style={{ transform: visibleRequirementTables.includes(index) ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                    >
                      <DropDownIcon width={'28'} height={'28'} className="#2A2A2B" />
                    </div>
                    <HeadingText text={data?.name} classString="mobile-heading-h5 text-neutral-800 font-medium mobile-heading-text" />
                  </div>
                  <div className="flex gap-6 items-center icon-neutral-500">
                    <div>
                      <DeleteIcon
                        width={'24'}
                        height={'24'}
                        className="svg cursor-pointer"
                        onClick={() => {
                          if (typeof data.id === 'string') {
                            setIsDeleteDocOrCategory('category')
                            setIsDeleteModalOpen(true)
                            setDeleteId(data?.id)
                            setDeleteName(data.name)
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {visibleRequirementTables.includes(index) && (
                  <div className="w-full">
                    <CustomTable column={columns} data={data?.requirement} name={data?.name} className={'cell-align-top table-border-wrapper'} pagination={false} search={search} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>

      {requirementModalOpen && (
        <CustomModal
          open={requirementModalOpen}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setRequirementModalOpen(false)
          }}
          content={<AddRequirement setIsModalOpen={setRequirementModalOpen} editId={editRequirementId} data={editRequirementData} />}
          width={915}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.CANCEL}
          okClick={() => {
            if (isDeleteDocOrCategory === 'requirement') {
              void handleDeleteRequirement(deleteId)
            } else {
              void handleDeleteRequirementCategory(deleteId)
            }
          }}
          deleteName={deleteName}
          subHead={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_COMPLIANCE_MANAGER_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default CredentialRequirement
