import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Form, message } from 'antd'
import { CirclePlus, DeleteIcon, DraggableDots, DropDownIcon } from 'assets/svgs'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IQuizTest } from 'types'
import { validateQuestionsForFaq } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { createTestFaqs, getAllTestFaqs } from '../api'

import '../../../style/pages/Compentancy.scss'

const QuizFaq = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [allQues, setAllQues] = useState<IQuizTest[]>([])
  const [originalData, setOriginalData] = useState<IQuizTest[]>([])
  const [visibleDetails, setVisibleDetails] = useState<boolean[]>(allQues?.map(() => false))
  const [deletedQuestionsId, setDeletedQuestionsId] = useState<string[]>([])
  const previousLength = useRef(allQues.length)

  const findLastObject = (questions: any) => {
    return questions.length > 0 ? questions[questions.length - 1] : undefined
  }

  const lastObject = findLastObject(allQues)

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.testFaq'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    void getFaqData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (allQues.length > previousLength.current) {
      const container = document.querySelector('.quiz-block')
      const lastQuestion = container?.lastElementChild

      if (lastQuestion) {
        lastQuestion.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        })
      }
    }
    previousLength.current = allQues.length
  }, [allQues])

  const getFaqData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getAllTestFaqs()
    if (response?.status === 200) {
      setAllQues(response.data.data.sort((a: { order: number }, b: { order: number }) => a.order - b.order))
      setOriginalData(response.data.data.sort((a: { order: number }, b: { order: number }) => a.order - b.order))
      setVisibleDetails(response.data.data.map(() => false))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const addQuestionAns = () => {
    if (allQues?.length === 0 || (lastObject?.question && lastObject?.answer)) {
      setVisibleDetails(allQues?.map(() => false))
      const data = {
        question: '',
        answer: '',
        order: allQues.length + 1,
      }
      setAllQues((prev) => [...prev, data])
      form.setFieldValue(`question-${allQues.length + 1}`, '')
      form.setFieldValue(`answer-${allQues.length + 1}`, '')
    } else {
      void message.error(VALIDATION_ERROR.QUIZ_NEW_QUES)
    }
  }

  const handleQueDragStart = (e: React.DragEvent<HTMLDivElement>, questionIndex: number) => {
    e.dataTransfer.setData('questionIndex', questionIndex.toString())
  }

  const handleQueDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleQueDrop = (e: React.DragEvent<HTMLDivElement>, targetQuestionIndex: number) => {
    e.preventDefault()
    const sourceQuestionIndex = parseInt(e.dataTransfer.getData('questionIndex'), 10)
    if (isNaN(sourceQuestionIndex) || sourceQuestionIndex === targetQuestionIndex) return
    const updatedQuestions = [...allQues]
    const [dragQuestion] = updatedQuestions.splice(sourceQuestionIndex, 1)
    updatedQuestions.splice(targetQuestionIndex, 0, dragQuestion)
    const reorderedQuestions = updatedQuestions.map((question, index) => {
      form.setFieldValue(`question-${index}`, question.question)
      form.setFieldValue(`answer-${index}`, question.answer)
      return {
        ...question,
        order: index + 1,
      }
    })
    setAllQues(reorderedQuestions)
  }

  const onFinish = () => {
    const validation = validateQuestionsForFaq(allQues)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    void handleSubmit(allQues)
  }

  const handleSubmit = async (data: IQuizTest[]) => {
    dispatch(setGlobalLoader(true))
    const payload = {
      ...(deletedQuestionsId?.length && { delete_faqs: deletedQuestionsId }),
      faqs: data,
    }
    const response = await createTestFaqs(payload)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      void getFaqData()
      setDeletedQuestionsId([])
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const toggleDetails = (index: number) => {
    // Check if the last object is empty (both question and answer are empty)
    if (lastObject && (lastObject.question === '' || lastObject.answer === '')) {
      void message.error(VALIDATION_ERROR.QUIZ_EDIT_QUES)
      return
    }

    // Set the form field values for the current question and answer
    form.setFieldValue(`question-${index}`, allQues[index].question)
    form.setFieldValue(`answer-${index}`, allQues[index].answer)
    // Collapse all other questions and toggle the current one
    setVisibleDetails((prevState: boolean[]) => {
      const newState = new Array(prevState.length).fill(false)
      newState[index] = !prevState[index]
      return newState
    })
  }

  const handleAddQueAns = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field: string, index: number, question?: string) => {
    const updatedQuestions = [...allQues]
    const target = e.target as HTMLInputElement | null
    updatedQuestions[index][field] = target?.value
    setAllQues(updatedQuestions)
    if (question === '') {
      setVisibleDetails((prevState: boolean[]) => {
        const newState = new Array(prevState.length).fill(false)
        newState[index] = !prevState[index]
        return newState
      })
    }
  }

  const handleDelete = async (questionIndex: number, id?: string) => {
    const updatedQuestions = [...allQues]
    updatedQuestions.splice(questionIndex, 1)
    setDeletedQuestionsId((prevId: any) => {
      if (id !== undefined && id !== null && id !== 'undefined') {
        return [...(prevId || []), id]
      }
      return prevId || []
    })

    form.setFieldValue(`question-${questionIndex}`, updatedQuestions[questionIndex]?.question)
    form.setFieldValue(`answer-${questionIndex}`, updatedQuestions[questionIndex]?.answer)
    setAllQues(updatedQuestions)
  }

  return (
    <Form form={form} onFinish={onFinish} className="flex flex-col gap-6 items-center w-full">
      <div className="flex items-center justify-end bg-white competency-addtest w-full rounded-4">
        <div className="flex items-center gap-4">
          <CustomBtn
            text={t('button.cancel')}
            className="rounded-3 w-100"
            onClick={() => {
              navigate('/settings/compentency-test')
            }}
          />
          <CustomBtn text={t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" disabled={allQues === originalData} />
        </div>
      </div>

      <div className="flex flex-col w-915 gap-6 mobile-version">
        <div className="flex justify-end items-center">
          <CustomBtn isIcon svgIcon={<CirclePlus className="svg" />} text={t('button.newQue')} className="user-Icon rounded-3" onClick={addQuestionAns} />
        </div>
        <div className="flex flex-col gap-6 quiz-block">
          {allQues?.map((question: IQuizTest, questionIndex: number) => {
            const isVisible = visibleDetails[questionIndex]
            return (
              <div
                key={questionIndex}
                className={`${isVisible || question.question === '' ? 'p-8' : 'px-8 py-4'} bg-white  rounded-3 question-container`}
                draggable
                onDragStart={(e) => {
                  handleQueDragStart(e, questionIndex)
                }}
                onDragOver={handleQueDragOver}
                onDrop={(e) => {
                  handleQueDrop(e, questionIndex)
                }}
              >
                <div className="ques-move-icon ">
                  <DraggableDots />
                </div>
                <div className="flex items-center justify-between w-full" key={questionIndex}>
                  <div className="input-container w-full">
                    {question?.question === '' || isVisible ? (
                      <InputField
                        placeholder={t('placeholder.question')}
                        name={`question-${questionIndex}`}
                        onChange={(e: any) => {
                          handleAddQueAns(e, 'question', questionIndex, question?.question)
                        }}
                        delay={20}
                      />
                    ) : (
                      <HeadingText text={question.question} classString="mobile-heading-h5 font-medium text-neutral-800 mb-6 w-full" />
                    )}
                  </div>
                  {question?.question !== '' && (
                    <div
                      className="items-center justify-center"
                      onClick={() => {
                        toggleDetails(questionIndex)
                      }}
                      style={{
                        transform: isVisible ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: 'transform 0.3s',
                        display: isVisible ? 'none' : 'flex',
                      }}
                    >
                      <DropDownIcon />
                    </div>
                  )}
                </div>
                <div className="flex-col relative" style={{ display: isVisible || question?.answer === '' ? 'flex' : 'none' }}>
                  {question?.answer === '' || isVisible ? (
                    <>
                      <CommonTextArea
                        name={`answer-${questionIndex}`}
                        placeholder={t('placeholder.answer')}
                        rows={4}
                        value={allQues[questionIndex].answer}
                        // onChange={(e: any) => {
                        //   const value = e.target.value.trim()
                        //   if (!value) {
                        //     void message.error(VALIDATION_ERROR.COMMON.ANSWER_BLANK)
                        //   } else {
                        //     handleAddQueAns(e, 'answer', questionIndex, question?.answer)
                        //   }
                        // }}
                        onChange={(e: any) => {
                          const value = e.target.value
                          // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                          void handleAddQueAns(e, 'answer', questionIndex)

                          // Show validation error if value is blank after trimming
                          if (!value.trim()) {
                            void message.error(VALIDATION_ERROR.COMMON.ANSWER_BLANK)
                          }
                        }}
                      />
                      <div className="flex justify-end cursor-pointer quiz-delete-icon">
                        <DeleteIcon
                          className="#d1293d"
                          onClick={() => {
                            void handleDelete(questionIndex, String(question?.id))
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <HeadingText text={question.answer} classString={`para-p2 font-normal text-neutral-800`} />
                      <div className="flex justify-end cursor-pointer quiz-delete-icon">
                        <DeleteIcon
                          className="#d1293d"
                          onClick={() => {
                            void handleDelete(questionIndex, String(question?.id))
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Form>
  )
}

export default QuizFaq
