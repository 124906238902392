import { message } from 'antd'
import http from 'services/Service'

import type { IAutoSchedulingData } from '../types'

export const getAutoScheduleData = async () => {
  try {
    const response = http.get('/auto-scheduling-setting')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const updateAutoScheduleData = async (id: string, payload: IAutoSchedulingData) => {
  try {
    const response = http.patch(`/auto-scheduling-setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
