import React from 'react'
import { Image } from 'antd'
import { profile } from 'assets/imgs'
import { type ImageUploadProps } from 'types'

const UploadMedia: React.FC<ImageUploadProps> = ({ imageUrl, baseUrl, handleChange, className, imgWidth, imgHeight, icon, edit, applyOverlay = false }) => {
  return (
    <div className={`${className ?? ''}`}>
      <label htmlFor="upload-button">
        {/* eslint-disable-next-line */}
        {imageUrl && baseUrl ? (
          <div className={`${applyOverlay ? 'relative' : ''}`}>
            {/* eslint-disable-next-line */}
            <Image width={imgWidth} height={imgHeight} src={`${baseUrl}${imageUrl}` || profile} alt="Uploaded Image Preview" className="upload-profile-img " preview={false} />
            {applyOverlay && <div className="absolute upload-bg-overlay flex items-center justify-center upload-profile-img">{icon}</div>}
          </div>
        ) : (
          <div className="upload-profile-img">{icon}</div>
        )}
      </label>
      {edit && <input type="file" id="upload-button" className="media-button" onChange={handleChange} accept="image/*" />}
    </div>
  )
}

export default UploadMedia
