import React, { useEffect, useState } from 'react'
import { Form, message, Radio, type RadioChangeEvent, Switch } from 'antd'
import { commonInputFieldValidation, commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IModalProps, type IShiftType } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { addShiftType, editShiftType } from '../api'

const AddNewShiftType = ({ setIsModalOpen, editId, data }: IModalProps) => {
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [value, setValue] = useState(null)
  const [error, setError] = useState({
    name: '',
    shift_type: '',
  })
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data && editId) {
      const checked = data.status === 'active'
      setStatusCheck(checked)
      form.setFieldsValue(data)
    }
  }, [data, editId, form])

  const onRadioChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  const onFinish = (values: IShiftType) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const updatedValues = { ...values, status: statusValue }
    if (editId && data) {
      void handleEditShiftType(editId, updatedValues)
    } else {
      void handleAddShiftType(updatedValues)
    }
  }

  const handleAddShiftType = async (values: IShiftType) => {
    dispatch(setGlobalLoader(true))
    const response = await addShiftType(values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditShiftType = async (id: string, values: IShiftType) => {
    dispatch(setGlobalLoader(true))
    const response = await editShiftType(id, values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (checked: boolean) => {
    setStatusCheck(checked)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={t('label.addNewShiftType')} />
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        className="add-time-content-wrapper"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <InputField
          name="name"
          rules={commonInputFieldValidation(VALIDATION_STRINGS.SHIFT_TYPE)}
          placeholder={t('placeholder.specialityName')}
          wrapperClass="w-full"
          error={error.name}
          value={getValue('name')}
        />
        <div className="shift-type-btn-wrapper">
          <p className="text-neutral-800 para-p2 font-medium">Shift Type</p>
          <div className={`w-full ${!error?.shift_type ? 'mb-6' : ''}`}>
            <Form.Item name="shift_type" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SHIFT_TYPE)}>
              <Radio.Group onChange={onRadioChange} value={value}>
                <Radio value={'per_diem'}>Per Diem</Radio>
                <Radio value={'long_term'}>Long Term</Radio>
                <Radio value={'travel'}>Travel</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          {error?.shift_type && <p className="error-container mb-6">{error.shift_type}</p>}
        </div>
        <div className="flex items-center justify-between hide-label w-full">
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.active')}</span> <Switch checked={statusCheck} onChange={onChange} />
          </div>
          <CustomBtn text={t('button.save')} htmlType="submit" type="primary" className="w-120" />
        </div>
      </Form>
    </div>
  )
}

export default AddNewShiftType
