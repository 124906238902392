import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Form } from 'antd'
import { LogoIcon } from 'assets/svgs'
import { confirmPwdValidation, passwordValidations } from 'constants/Validation'
import type { IFieldData, IResetPassword } from 'types'

import PasswordField from 'components/common/PasswordField/PasswordField'

const ResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onFinish = (values: IResetPassword) => {
    navigate('/login')
  }
  const [error, setError] = useState({
    password: '',
    confirmPassword: '',
  })
  const handleFieldsChange = (allFields: IFieldData[]) => {
    allFields.forEach((field: IFieldData) => {
      const fieldName = field.name[0]
      const fieldErrors = field.errors ?? ''
      setError((prev) => {
        return { ...prev, [fieldName]: fieldErrors[0] }
      })
    })
  }
  return (
    <div className="bg-neutral-50">
      <div className="bg-neutral-50 mx-auto max-w-400 w-full min-h-screen py-10 flex flex-col gap-10 justify-center items-center">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <p className="mobile-heading-h2 font-medium">{t('forgotPass.resetPass')}</p>
        </div>

        <div className="w-full min-w-full">
          <Form layout="vertical" onFinish={onFinish} className=" min-w-full" requiredMark={false} onFieldsChange={handleFieldsChange}>
            <PasswordField error={error.password} placeholder={t('label.login.newPass')} rules={passwordValidations} name="password" />
            <PasswordField placeholder={t('label.login.conNewPass')} rules={confirmPwdValidation} name="confirmPassword" error={error.confirmPassword} />
            <Form.Item className="mt-6">
              <Button type="primary" htmlType="submit" className="common-button">
                {t('button.login')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
