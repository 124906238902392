import { useEffect } from 'react'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, ThreeDotIcon } from 'assets/svgs'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IPaginationInfo, IShiftCancelData, IShiftCancelReason, IShiftTimeColumn, ISorting } from 'types'
import { formatTitle } from 'utils/Helper'

import HeadingText from 'components/common/HeadingText'

import { deleteShiftCancelReason, deleteShiftTime, getAllShiftCancelReason, getAllShiftTimes, updateShiftTime } from '../api'

interface IColumns {
  setEditId: React.Dispatch<React.SetStateAction<string>>
  setData: React.Dispatch<React.SetStateAction<Partial<IShiftTimeColumn>>> | any
  setIsAddNewTime: React.Dispatch<React.SetStateAction<boolean>>
  setShiftCancelData: React.Dispatch<React.SetStateAction<IShiftCancelData>>
  setIAddNewReason: React.Dispatch<React.SetStateAction<boolean>>
  paginationInfo: IPaginationInfo
  setPaginationInfo: React.Dispatch<React.SetStateAction<IPaginationInfo>>
  sortTable: ISorting
  sortCancelReasonTable: ISorting
  userType: string
  search: string
  setListData: any
  actionMenuOpen: any
  setActionMenuOpen: any
  selectedValue: string | undefined
  isAddNewTime: boolean
  isAddNewReason: boolean
  setDeleteId: React.Dispatch<React.SetStateAction<string>>
  setDeleteName: React.Dispatch<React.SetStateAction<string>>
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const useColumn = ({
  setEditId,
  setData,
  setIsAddNewTime,
  setShiftCancelData,
  setIAddNewReason,
  paginationInfo,
  setPaginationInfo,
  sortTable,
  sortCancelReasonTable,
  userType,
  search,
  setListData,
  actionMenuOpen,
  setActionMenuOpen,
  selectedValue,
  isAddNewTime,
  isAddNewReason,
  setDeleteId,
  setDeleteName,
  setIsDeleteModalOpen,
}: IColumns) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedValue === 'shift_timings' && sortTable) {
      void handleGetShiftTimingData(paginationInfo.limit, search ? 0 : paginationInfo.offset, sortTable?.order, sortTable?.field)
    } else {
      if (sortCancelReasonTable)
        void handleGetShiftCancelData(paginationInfo.limit, search ? 0 : paginationInfo.offset, sortCancelReasonTable?.order, sortCancelReasonTable?.field, userType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.offset, isAddNewTime, sortTable, sortCancelReasonTable, search, selectedValue, isAddNewReason])

  const getMenu = (record: IShiftTimeColumn, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            handleEditShiftTiming(record)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <div className=" cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      void onSwitchChange(checked, record, record?.id)
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '3',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id ?? '')
            setDeleteName(record.name)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const shiftCancelGetMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            void handleGetData(record)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id ?? '')
            setDeleteName(record.reason)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const handleEditShiftTiming = (record: IShiftTimeColumn) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, name, start_time, end_time, status } = record
    setEditId(id ?? '')
    setData({ id: '', name, start_time, end_time, status })
    setIsAddNewTime(true)
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen: any) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleGetShiftTimingData = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllShiftTimes(limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditRoleData = async (id: string, values: any) => {
    dispatch(setGlobalLoader(true))
    const response = await updateShiftTime(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetShiftTimingData(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteShiftTiming = async (id: string) => {
    const response = await deleteShiftTime(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleGetShiftTimingData(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const onSwitchChange = async (checked: boolean, data: any, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    void handleEditRoleData(id, newData)
  }

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen: any) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleGetData = async (record: IShiftCancelReason) => {
    const { id, reason, description, status } = record
    const userType = t('label.facility')
    setEditId(id ?? '')
    setShiftCancelData({ reason, description, status, user_type: userType })
    setIAddNewReason(true)
  }

  const handleGetShiftCancelData = async (limit: number, offset: number, order: string, field: string, userType: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllShiftCancelReason(limit, offset, search, order, field, userType)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteCancelReason = async (id: string) => {
    const response = await deleteShiftCancelReason(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleGetShiftCancelData(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field, userType)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    if (selectedValue === 'shift_timings' && sortTable) {
      void handleGetShiftTimingData(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
    } else {
      if (sortCancelReasonTable) void handleGetShiftCancelData(paginationInfo.limit, newOffset, sortCancelReasonTable?.order, sortCancelReasonTable?.field, userType)
    }
  }

  const shiftTimingColumn: ColumnsType<IShiftTimeColumn> = [
    {
      title: t('table.shiftTime.id'),
      dataIndex: 'shift_time_id',
      key: 'shift_time_id',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 276,
    },
    {
      title: t('table.shiftTime.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 276,
    },
    {
      title: t('table.shiftTime.startTime'),
      dataIndex: 'start_time',
      key: 'start_time',
      align: 'center',
      className: 'center-align-sortedfield',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 276,
      render: (_value, record, index) => {
        return dayjs(record?.start_time, 'HH:mm:ss').format('h:mm A')
      },
    },
    {
      title: t('table.shiftTime.endTime'),
      dataIndex: 'end_time',
      key: 'end_time',
      align: 'center',
      className: 'center-align-sortedfield',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 276,
      render: (_value, record, index) => {
        return dayjs(record?.end_time, 'HH:mm:ss').format('h:mm A')
      },
    },
    {
      title: t('table.shiftTime.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 276,
      className: 'center-align-sortedfield',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.shiftTime.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const shiftCancelReasonsColumn: ColumnsType = [
    {
      title: t('table.shiftCancelReason.reason'),
      dataIndex: 'reason',
      key: 'reason',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 460,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record.reason} />
      },
    },
    {
      title: t('table.shiftCancelReason.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 460,
    },
    {
      title: t('table.shiftCancelReason.status'),
      dataIndex: 'status',
      key: 'status',
      className: 'center-align-sortedfield',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 460,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.shiftCancelReason.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={shiftCancelGetMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return { shiftTimingColumn, shiftCancelReasonsColumn, handlePageChange, handleDeleteCancelReason, handleDeleteShiftTiming }
}
