import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message, Switch, Tabs } from 'antd'
import { useWindowSize } from 'hooks/useWindowSize'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IOptionData, type ISectionData, type ISettingsData, type ISubSectionData } from 'types'

import { getProviderById, getProviderSetting, updateById } from './api'

const ProviderProfileSettings = () => {
  const size = useWindowSize()
  const dispatch = useDispatch()
  const [settings, setSettings] = useState<ISettingsData[]>([])
  const [optionData, setOptionData] = useState<IOptionData[] | any>([])
  const [selectedTabId, setSelectedTabId] = useState('')

  useEffect(() => {
    if (settings) {
      setSelectedTabId(settings[0]?.id)
    }
  }, [settings])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.providerProfile'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void getProviderSettingsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedTabId) {
      void getSingleProviderSetting(selectedTabId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId])

  const getProviderSettingsData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getProviderSetting()
    if (response?.data?.statusCode === 1 && Array.isArray(response?.data?.data)) {
      setSettings(response?.data?.data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getSingleProviderSetting = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getProviderById(id)
    if (response?.data?.statusCode === 1) {
      setOptionData(response?.data?.data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const updateMapData = async (mapData: ISubSectionData, newStatus: string, newIsRequired: boolean) => {
    dispatch(setGlobalLoader(true))
    const updatedPayload = {
      id: mapData?.id,
      status: newStatus,
      is_required: newIsRequired,
    }
    const response = await updateById(mapData?.id, updatedPayload)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
    return {
      ...mapData,
      status: newStatus,
      is_required: newIsRequired,
    }
  }

  const onChange = async (id: string, field: string) => {
    try {
      const updatedOptionData = await Promise.all(
        optionData?.section?.map(async (item: ISectionData) => {
          return {
            ...item,
            sub_section: await Promise.all(
              item?.sub_section?.map(async (mapData: ISubSectionData) => {
                if (mapData?.id === id) {
                  let newStatus = mapData?.status
                  let newIsRequired = mapData?.is_required
                  if (field === 'active') {
                    newStatus = mapData?.status === 'active' ? 'in_active' : 'active'
                  } else if (field === 'is_required') {
                    newIsRequired = !mapData.is_required
                  }
                  return await updateMapData(mapData, newStatus, newIsRequired)
                }
                return mapData
              })
            ),
          }
        })
      )
      setOptionData(updatedOptionData)
    } catch (error) {
      console.error('Error updating data:', error)
    } finally {
      void getSingleProviderSetting(selectedTabId)
    }
  }

  return (
    <div className="setting-tab">
      {/* <div className="flex justify-end mb-6 md-flex-col">
        <CustomBtn text={t('button.saveSettings')} type="primary" />
      </div> */}
      <Tabs
        defaultActiveKey="1"
        tabPosition={size.width >= 1230 ? 'left' : 'top'}
        items={settings?.map((data: ISettingsData) => {
          const id = data.id
          return {
            label: data.name,
            key: id,
            children: (
              <div className="flex flex-col rounded-4 border-stroke">
                <div className="flex justify-end border-b-stroke">
                  <div className="provider-title-wrapper">
                    <div className="field-name-wrapper w-33">
                      <p className="provider-field-title ">{t('label.fieldName')}</p>
                    </div>
                    <div className="field-name-wrapper w-33 justify-center">
                      <p className="provider-field-title">{t('label.displayOnForm')}</p>
                    </div>
                    <div className="field-name-wrapper w-33 justify-center">
                      <p className="provider-field-title">{t('label.isFieldMandatory')}</p>
                    </div>
                  </div>
                </div>
                {optionData?.section?.map((item: ISectionData, index: number) => {
                  return (
                    <div className="flex w-full" key={item.id}>
                      <div className={`table-title-wrapper ${optionData.section.length - 1 === index ? 'border-b-0' : ''}`}>
                        <p className="title-text">{item.name}</p>
                      </div>
                      <div className={`flex flex-col w-full`}>
                        {item?.sub_section?.map((mapData: ISubSectionData) => {
                          return (
                            <div className={`flex flex-row w-full border-b-stroke ${optionData.section.length - 1 === index ? 'border-b-0' : ''}`} key={mapData.id}>
                              <div className="field-name-wrapper w-33">
                                <p className="field-name">{mapData.name}</p>
                              </div>
                              <div className="flex justify-center items-center w-33 p-4">
                                <Switch
                                  checked={mapData.status === 'active'}
                                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                  onChange={async () => {
                                    await onChange(mapData.id, 'active')
                                  }}
                                />
                              </div>
                              <div className="flex justify-center items-center w-33 p-4">
                                <Switch
                                  disabled={mapData.status !== 'active'}
                                  checked={mapData.is_required}
                                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                  onChange={async () => {
                                    await onChange(mapData.id, 'is_required')
                                  }}
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            ),
          }
        })}
        onChange={(key) => {
          setSelectedTabId(key)
        }}
      />
    </div>
  )
}

export default ProviderProfileSettings
