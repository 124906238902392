import React, { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddCategory, type ICategoryModalsProps, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { updateCategory } from '../api'

const EditCategory = ({ isModalOpen, setIsModalOpen, name, editId, setEditId, setCategoryName, handleGetAllCategory, form, error, setError }: ICategoryModalsProps) => {
  // const [form] = Form.useForm()
  // const [error, setError] = useState({
  //   name: '',
  // })
  const dispatch = useAppDispatch()

  useEffect(() => {
    form.setFieldValue('name', name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, name, isModalOpen])

  const onFinish = (values: IAddCategory) => {
    const payload = {
      name: values?.name,
    }

    void handleAddEditCategory(payload)
  }

  const handleAddEditCategory = async (payload: { name: string }) => {
    dispatch(setGlobalLoader(true))

    const response = await updateCategory(editId ?? '', payload)

    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setIsModalOpen(null)
      setEditId('')
      form.resetFields()
      handleGetAllCategory()
      form.setFieldValue('name', name)
    }
    dispatch(setGlobalLoader(false))
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={t('label.editCategory')} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start gap-5  w-full">
            <div className="flex flex-col w-full">
              <div className="h-64 aline-self">
                <InputField
                  delay={100}
                  rules={commonInputFieldValidation(VALIDATION_STRINGS.CATEGORY)}
                  placeholder={t('placeholder.categoryName')}
                  name="name"
                  value={getValue('name')}
                />
              </div>
              {error?.name && <p className="error-container">{error.name}</p>}
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  form.resetFields()
                  if (setIsModalOpen) {
                    setIsModalOpen(null)
                    setEditId('')
                    setCategoryName('')
                  }
                  setError({ name: '' })
                  form.setFieldValue('name', name) // Reset the field value to the original prop value
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={t('button.updateBtn')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default EditCategory
