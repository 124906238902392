import { message } from 'antd'
import http from 'services/Service'

export const getCertificate = async () => {
  try {
    const response = http.get('/dropdown/certificate')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
