import { Typography } from 'antd'
import { type IHeadingText } from 'types'

const HeadingText = ({ text, classString, onClick, ...props }: IHeadingText) => {
  const { Text } = Typography

  // eslint-disable-next-line react/prop-types
  return (
    <Text className={classString} onClick={onClick} {...props}>
      {text}
    </Text>
  )
}
export default HeadingText
