import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Select } from 'antd'
import { ActivityDot, DropDownIcon } from 'assets/svgs'
import type { IModalProps } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

const ActivityModal = ({ isModalOpen, setIsModalOpen }: IModalProps) => {
  const { t } = useTranslation()

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }

  const mockData = [
    {
      id: 1,
      name: 'Visited',
      value: 'Dashboard',
      time: '9:05 pm',
    },
    {
      id: 2,
      name: 'Visited',
      value: 'Shifts',
      time: '9:05 pm',
    },
    {
      id: 3,
      name: 'Created shift for',
      value: 'CNA at rocky mount care of heber on 22/12/2023 .',
      time: '9:05 pm',
    },
    {
      id: 4,
      name: 'Assigned a shift to',
      value: 'CNA at rocky mount care of heber on 22/12/2023 .',
      time: '10:10 pm',
    },
    {
      id: 5,
      name: 'Cancelled a Shift',
      value: 'Shifts',
      time: '11:00 pm',
    },
    {
      id: 6,
      name: 'Visited',
      value: 'Schedule',
      time: '11:05 pm',
    },
    {
      id: 7,
      name: 'Visited',
      value: 'Provider',
      time: '12:05 pm',
    },
    {
      id: 8,
      name: 'Approved timecard for shift',
      value: 'CNA at rocky mount care of heber on 22/12/2023 .',
      time: '12:50 pm',
    },
  ]

  return (
    <Modal
      className="activity-modal"
      open={isModalOpen}
      centered
      footer={null}
      closable={false}
      maskClosable
      onCancel={() => {
        setIsModalOpen(false)
      }}
    >
      <div className="wrapper-connect-modal">
        <div className="header-modal flex gap-6 justify-between items-center">
          <HeadingText classString="text-neutral-800 desktop-head-h5" text="Brooklyn’s Activity" />
          <Select
            defaultValue="today"
            className="w-120 h-49 select-common"
            // style={{ width: 120, height: 49 }}
            onChange={handleChange}
            suffixIcon={<DropDownIcon />}
            options={[
              { value: 'today', label: 'Today' },
              { value: 'yesterday', label: 'Yesterday' },
            ]}
          />
        </div>

        <div className="content-modal">
          <p className="para-p3 text-neutral-500 mb-6">12TH DECEMBER, 2023</p>
          <div>
            {mockData.map((item) => {
              return (
                <div key={item.id} className="flex justify-between">
                  <div>
                    <div className="flex gap-3 items-center">
                      <ActivityDot
                        stroke={item.name === 'Visited' || item.name === 'Created shift for' ? '#897AF4' : item.name === 'Cancelled a Shift' ? '#D1293D' : '#359766'}
                        fill={item.name === 'Visited' || item.name === 'Created shift for' ? '#F2F0FE' : item.name === 'Cancelled a Shift' ? '#FDF4F5' : '#F4FBF7'}
                      />
                      <p className="text-neutral-500 font-small activity-text">
                        {item.name}
                        <span className="ml-2 text-neutral-800 font-normal text-decoration-line">{item.value}</span>
                      </p>
                    </div>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <div className={`${mockData.length !== item.id && 'activity-dot'}`} />
                  </div>
                  <HeadingText text={item.time} classString="text-neutral-500 font-small para-p3" />
                </div>
              )
            })}
          </div>
        </div>

        <div className="footer-modal flex justify-end">
          <CustomBtn
            text={t('button.close')}
            onClick={() => {
              setIsModalOpen(false)
            }}
            type="primary"
            className="w-160"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ActivityModal
