import { message } from 'antd'
import http from 'services/Service'
import { type IAddRole } from 'types'

export const getRoles = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/role?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getRolesSections = async () => {
  try {
    const response = http.get('/section')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getRolesSubSection = async (id: string) => {
  try {
    const response = http.get(`/role-section-permission/section-permission/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getSingleRole = async (id: string) => {
  try {
    const response = http.get(`/role/single-role/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addRoles = async (payload: IAddRole) => {
  try {
    const response = http.post(`/role`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const deleteRoles = async (id: string) => {
  try {
    const response = http.delete(`/role/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const editRoles = async (id: string, payload: IAddRole) => {
  try {
    const response = http.patch(`/role/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const duplicateRoles = async (id: string) => {
  try {
    const response = http.post(`/role/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
