import { useEffect, useState } from 'react'
import { Form, message, Radio } from 'antd'
import { CloseIcon, ModalClose } from 'assets/svgs'
import { agencyEmployeeOption, autoAssignOption, COMPLIENCE_MANAGER, emplyoeeTypeOption, validationuponOption, WORKFORCE_PORTAL } from 'constants/Constant'
import { commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFieldData, IOptionGroup, IRequirementModalProps } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import DropDown from 'components/common/Select/DropDown'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

import { createCredentialRequirements, getCertificateAndSpeciality, getCredentialDocuments, getState, updateCredentialRequirements } from '../api'

import '../../../style/pages/ComplianceManager.scss'

const AddRequirement = ({ setIsModalOpen, editId, data }: IRequirementModalProps) => {
  const [form] = Form.useForm()
  const [credentials, setCredentials] = useState<string>('')
  const [credentialsOption, setCredentialsOption] = useState<IOptionGroup[]>([])
  const [credentialsTag, setCredentialsTag] = useState<any[]>([])
  const [certificateTag, setCertificateTag] = useState<any[]>([])
  const [stateData, setStateData] = useState<IOptionGroup[]>([])
  const [certificateAndSpecialityData, setCertificateAndSpecialityData] = useState([])
  const [error, setError] = useState({
    name: '',
    certificate_or_speciality: '',
    credentials: '',
    state: '',
    agencyEmployment: '',
    employmentType: '',
    autoAssign: '',
    submission: '',
    pdOrder: '',
    ltOrder: '',
    workforce_portal: '',
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    void handleStateData()
    void credentialDocumentData()
    void certificateSpecilityData()
  }, [])

  useEffect(() => {
    if (editId && data) {
      setCredentials(data.credential)
      form.setFieldValue('name', data.name)
      form.setFieldValue('certificate_or_speciality', data?.certificate_or_speciality?.id)
      form.setFieldValue('state', data?.state)
      form.setFieldValue('is_required_for_agency', data?.is_required_for_agency)
      form.setFieldValue('employment_type', data?.employment_type)
      form.setFieldValue('auto_assign', data?.auto_assign)
      form.setFieldValue('submission', data?.submission)
      form.setFieldValue('pd_order_fill', data?.pd_order_fill)
      form.setFieldValue('lt_order_fill', data?.lt_order_fill)
      form.setFieldValue('workforce_portal', data?.workforce_portal)
      const credDocData = [data?.credentials_document]
      const ids: any = credDocData?.map((credential: any) => credential?.id)
      const certificateData = Array.isArray(data?.certificate) ? data?.certificate : []
      const specialityData = Array.isArray(data?.speciality) ? data?.speciality : []

      const certificateAndSpecialityData = [...certificateData, ...specialityData]

      const idsCertificate = certificateAndSpecialityData?.map((certi: { id: string }) => certi?.id)

      setCertificateTag(idsCertificate)
      setCredentialsTag(ids)
      setCredentials(ids)
    }
  }, [editId, data, form])

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      credentials: credentialsTag?.length > 0 ? '' : prevError.credentials,
      certificate_or_speciality: certificateTag?.length > 0 ? '' : prevError.certificate_or_speciality,
    }))
  }, [credentialsTag, certificateTag])

  const certificateSpecilityData = async () => {
    const response = await getCertificateAndSpeciality()
    if (response?.data?.statusCode === 1) {
      const filteredData = Object.keys(response?.data?.data).map((key) => ({
        label: key === 'certificates' ? 'License' : key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the key to create the label
        title: key,
        options: response?.data?.data[key].map((item: { id: string; name: string }) => ({
          id: item?.id,
          name: item?.name,
        })),
      }))
      setCertificateAndSpecialityData(filteredData as any)
    }
  }

  const credentialDocumentData = async () => {
    const response = await getCredentialDocuments()
    if (response?.data?.statusCode === 1) {
      const filteredData = Object.keys(response?.data?.data).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1),
        title: key,
        options: response?.data?.data[key].map((item: { id: string; name: string }) => ({
          id: item?.id,
          name: item?.name,
        })),
      }))

      setCredentialsOption(filteredData)
    }
  }

  const handleStateData = async () => {
    const response = await getState()
    if (response?.data?.statusCode === 1) {
      const filteredData = response?.data?.data?.map((item: { name: string; id: string }) => ({
        label: item?.name,
        value: item?.name,
      }))
      setStateData(filteredData)
    }
  }
  const handleCertiChange = (selectedValue: string[]) => {
    setCertificateTag(selectedValue)
  }

  const handleEndTimeChange = (selectedValue: any) => {
    setCredentials(selectedValue)
    setCredentialsTag(selectedValue)
  }

  const onFinish = (values: any) => {
    const payload = {
      certificate_or_speciality: certificateTag,
      credential_document: credentials,
      state: values?.state,
      is_required_for_agency: values?.is_required_for_agency,
      employment_type: values?.employment_type,
      submission: values?.submission,
      pd_order_fill: values?.pd_order_fill,
      lt_order_fill: values?.lt_order_fill,
      auto_assign: values?.auto_assign,
      workforce_portal: values?.workforce_portal,
    }
    void handleAddEditRequirement(payload)
  }

  const handleAddEditRequirement = async (values: any) => {
    dispatch(setGlobalLoader(true))

    let response
    if (editId) {
      response = await updateCredentialRequirements(values, editId)
    } else {
      response = await createCredentialRequirements(values)
    }
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setIsModalOpen(false)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleCancel = () => {
    if (setIsModalOpen) setIsModalOpen(false)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  const findOptionUsingForEach = (id: string, data: any) => {
    let foundOption: any
    // Iterate over each group in the data array
    data?.forEach((group: any) => {
      // Check if the option is found in the current group's options
      // eslint-disable-next-line array-callback-return
      const option = group?.options?.find((item: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        return item?.id === id
      })
      if (option) {
        foundOption = option
      }
    })
    return foundOption
  }

  const handleDeleteTag = (index: number) => {
    const updatedCredentialsTag: any = [...credentialsTag]
    updatedCredentialsTag.splice(index, 1)
    setCredentialsTag(updatedCredentialsTag)
    setCredentials(updatedCredentialsTag)
  }

  const handleCertificateDeleteTag = (index: number) => {
    const updatedCertiTag: any = [...certificateTag]
    updatedCertiTag.splice(index, 1)
    setCertificateTag(updatedCertiTag)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editRequirements') : t('label.addRequirements')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        className="form-certi-wrapper"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
        initialValues={{
          is_required_for_agency: true,
          employment_type: 'both',
          submission: 'none',
          pd_order_fill: 'none',
          lt_order_fill: 'none',
          auto_assign: 'pre_hire',
        }}
      >
        <>
          <div className="flex flex-col items-start gap-2 w-full add-requirement-form pt-6px">
            {/* certificate and credential */}
            <div className="flex gap-1rem items-start w-full mb-3">
              <div className="w-full">
                <DropDown
                  handleChange={handleCertiChange}
                  name="certificate_or_speciality"
                  Options={certificateAndSpecialityData}
                  placeholder={t('placeholder.selectCertiSpeciality')}
                  optionWidth={200}
                  rules={commonSingleSelectValidation(VALIDATION_STRINGS.SLECT_CERTI_OR_SPECIALITY)}
                  updatedTag={certificateTag}
                  error={error?.certificate_or_speciality}
                  value={getValue('certificate_or_speciality')}
                />
                {certificateTag?.length > 0 && (
                  <div className="flex flex-col gap-4 w-full mb-6 mt-4">
                    {certificateTag?.length > 0 && <HeadingText text={COMPLIENCE_MANAGER.CERTI_OR_SPECIALITY} classString="para-p3 text-neutral-400" />}
                    <div className="flex gap-4 w-full flex-wrap">
                      {certificateTag?.map((tagId: string, index: number) => {
                        const option = findOptionUsingForEach(tagId, certificateAndSpecialityData)
                        return (
                          <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                            {option?.name}
                            <span
                              className="flex items-center cursor-pointer"
                              onClick={() => {
                                handleCertificateDeleteTag(index)
                              }}
                            >
                              {<CloseIcon />}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full">
                <DropDown
                  handleChange={handleEndTimeChange}
                  name={'credentials'}
                  Options={credentialsOption}
                  placeholder={t('placeholder.credentials')}
                  optionWidth={200}
                  rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_CRED)}
                  updatedTag={credentials}
                  error={error?.credentials}
                  value={getValue('credentials')}
                />
                {credentialsTag?.length > 0 && (
                  <div className="flex flex-col gap-4 w-full mb-6 mt-4">
                    {credentialsTag?.length > 0 && <HeadingText text={COMPLIENCE_MANAGER.CREDENTIALS} classString="para-p3 text-neutral-400" />}
                    <div className="flex gap-4 w-full flex-wrap">
                      {credentialsTag?.map((tagId: string, index: number) => {
                        const option = findOptionUsingForEach(tagId, credentialsOption)
                        return (
                          <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                            {option?.name}
                            <span
                              className="flex items-center cursor-pointer"
                              onClick={() => {
                                handleDeleteTag(index)
                              }}
                            >
                              {<CloseIcon />}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FloatingLabelSelect
              label={t('placeholder.selectState')}
              value={getValue('state')}
              options={stateData}
              name="state"
              rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_STATE)}
              error={error.state}
            />
            {/* agency emplyoee and emplyoee type */}
            <div className="flex gap-1rem items-center w-full">
              <div className="flex flex-col gap-3 w-full">
                <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.agencyEmployment')} />
                <Form.Item name="is_required_for_agency" className="p-0 m-0">
                  <Radio.Group options={agencyEmployeeOption} />
                </Form.Item>
                {error?.agencyEmployment && <p className="error-container">{error.agencyEmployment}</p>}
              </div>
              <div className="flex flex-col gap-3 w-full">
                <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.employmentType')} />
                <Form.Item name="employment_type" className="p-0 m-0">
                  <Radio.Group options={emplyoeeTypeOption} />
                </Form.Item>
                {error?.employmentType && <p className="error-container">{error.employmentType}</p>}
              </div>
            </div>
            {/* validation upon */}
            <div className="flex gap-1rem items-center w-full">
              <div className="flex flex-col gap-2 w-full">
                <HeadingText classString="activity-text text-neutral-800 font-semi-bold" text={t('heading.validateUpon')} />
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex items-center gap-2">
                    <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.submission')} />
                    <Form.Item name="submission" className="p-0 m-0">
                      <Radio.Group options={validationuponOption} />
                    </Form.Item>
                    {error?.submission && <p className="error-container">{error.submission}</p>}
                  </div>
                  <div className="flex items-center gap-2">
                    <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.pdOrder')} />
                    <Form.Item name="pd_order_fill" className="p-0 m-0">
                      <Radio.Group options={validationuponOption} />
                    </Form.Item>
                    {error?.pdOrder && <p className="error-container">{error.pdOrder}</p>}
                  </div>
                  <div className="flex items-center gap-2">
                    <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.ltOrder')} />
                    <Form.Item name="lt_order_fill" className="p-0 m-0">
                      <Radio.Group options={validationuponOption} />
                    </Form.Item>
                    {error?.ltOrder && <p className="error-container">{error.ltOrder}</p>}
                  </div>
                </div>
              </div>
            </div>
            {/* auto assign and workforce */}
            <div className="flex gap-1rem items-center w-full">
              <div className="flex flex-col gap-3 w-full">
                <HeadingText classString="para-p2 text-neutral-800 font-normal" text={t('heading.autoAssign')} />
                <Form.Item name="auto_assign" rules={commonSingleSelectValidation(VALIDATION_STRINGS.REQUIRED_FIELD)} className="p-0 m-0">
                  <Radio.Group options={autoAssignOption} />
                </Form.Item>
                {error?.autoAssign && <p className="error-container">{error.autoAssign}</p>}
              </div>
              {/* <div className="w-full">
                <Form.Item name="workforce" rules={workforceValidation} className="p-0 m-0 w-full role-dropdown relative">
                  <Select variant="borderless" suffixIcon={<DropDownIcon />} className="icon-class" placeholder={t('placeholder.workforce')} value={getValue('workforce')}>
                    <Option key={'None'} value={'None'} className="flex gap-1">
                      <p className="para-p2 text-neutral-800">{'None'}</p>
                    </Option>
                  </Select>
                </Form.Item>
                {error?.workforce && <p className="error-container">{error.workforce}</p>}
              </div> */}
              <FloatingLabelSelect
                label={t('placeholder.workforce')}
                value={getValue('workforce_portal')}
                options={WORKFORCE_PORTAL}
                name="workforce_portal"
                rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}
                error={error?.workforce_portal}
              />
            </div>
          </div>
          {/* buttons */}
          <div className="flex items-start justify-end gap-4 w-full footer-sticky">
            <CustomBtn
              text={t('button.cancel')}
              type="default"
              onClick={() => {
                if (setIsModalOpen) setIsModalOpen(false)
              }}
              className="w-160 rounded-3"
            />
            <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} type="primary" htmlType="submit" className="w-160 rounded-3" />
          </div>
        </>
      </Form>
    </div>
  )
}

export default AddRequirement
