import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { addRole, setFormData } from 'store/slice/AddRoleSlice'
import type { IFieldData, IRoleInfo, IRoleInfoData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

const RoleInfo = ({ next, editData }: IRoleInfo) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const formData = useAppSelector(addRole)
  const [error, setError] = useState({
    name: '',
  })

  useEffect(() => {
    if (editData) {
      dispatch(setFormData({ name: editData.name, description: editData.description }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData])

  const onFinish = (values: IRoleInfoData) => {
    dispatch(setFormData(values))
    next()
  }

  return (
    <div className="p-6 border-stroke rounded-4">
      <Form
        autoComplete="off"
        initialValues={formData}
        layout="vertical"
        className="flex flex-col"
        requiredMark={false}
        onFinish={onFinish}
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
        onValuesChange={(allValues) => {
          dispatch(setFormData(allValues))
        }}
      >
        <InputField error={error.name} rules={commonInputFieldValidation(VALIDATION_STRINGS.ROLE)} name="name" placeholder={t('placeholder.roleName')} value={formData.name} />
        <CommonTextArea name="description" placeholder={t('placeholder.desc')} rows={4} value={formData.description} />
        <div className="">
          <div className="flex gap-4 justify-end">
            <CustomBtn text={t('common.next')} type="primary" htmlType="submit" />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default RoleInfo
