import { Image } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { profile } from 'assets/imgs'
import { AwardIcon, CheckSolidIcon, CloseIcon, HospitalOutline, StethoscopeIcon } from 'assets/svgs'
import { TABLE_COLUMNS } from 'constants/Constant'

const tableData = [
  {
    id: '1',
    performanceEvaluation: 'Clinical Competence',
    oftenExceedExp: true,
    mostExp: false,
    doesNotMeetExp: false,
    na: false,
  },
  {
    id: '2',
    performanceEvaluation: 'doesNotMeetExp',
    oftenExceedExp: true,
    mostExp: false,
    doesNotMeetExp: false,
    na: false,
  },
  {
    id: '3',
    performanceEvaluation: 'Attendance & Punctuality',
    oftenExceedExp: true,
    mostExp: false,
    doesNotMeetExp: false,
    na: false,
  },
  {
    id: '4',
    performanceEvaluation: 'Good Communication Skills',
    oftenExceedExp: true,
    mostExp: false,
    doesNotMeetExp: false,
    na: false,
  },
]

const CompleteEvaluation = ({ setIsModalOpen }: any) => {
  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.PERFORMANCE,
      dataIndex: 'performanceEvaluation',
      key: 'performanceEvaluation',
      align: 'center',
      width: 230,
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.EXCEED,
      dataIndex: 'oftenExceedExp',
      key: 'oftenExceedExp',
      align: 'center',
      width: 230,
      render(value, record) {
        return <div>{record.oftenExceedExp === true ? <CheckSolidIcon /> : ''}</div>
      },
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.EXPECTATION,
      dataIndex: 'mostExp',
      key: 'mostExp',
      align: 'center',
      width: 230,
      render(value, record) {
        return <div>{record.mostExp === true ? <CheckSolidIcon /> : ''}</div>
      },
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.NOT_EXPECTED,
      dataIndex: 'doesNotMeetExp',
      key: 'doesNotMeetExp',
      align: 'center',
      width: 230,
      render(value, record) {
        return <div>{record.doesNotMeetExp === true ? <CheckSolidIcon /> : ''}</div>
      },
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.NA,
      dataIndex: 'na',
      key: 'na',
      align: 'center',
      width: 80,
      render(value, record) {
        return <div>{record.na === true ? <CheckSolidIcon /> : ''}</div>
      },
    },
  ]

  return (
    <div className="complete-evaluation-wrapper">
      <div className="evaluation-wrapper">
        <div className="evaluation-detail-wrapper">
          <div className="evaluation-detail-box">
            <div className="name-box">
              <Image src={profile} preview={false} className="evaluation-img" />
              <p className="text-neutral-800 mobile-heading-h6  font-normal">Brooklyn Simmons</p>
            </div>
            <div className="profile-detail-box">
              <div className="flex items-center gap-1">
                <AwardIcon />
                <p className="text-neutral-600 para-p3 font-normal">CNA</p>
              </div>
              <div className="flex items-center gap-1">
                <StethoscopeIcon />
                <p className="text-neutral-600 para-p3 font-normal">LTC, Rehab</p>
              </div>
            </div>
          </div>
          <div className="evaluation-detail-box">
            <div className="name-box">
              <HospitalOutline />
              <p className="text-neutral-800 para-p2  font-normal">Autumn Lane Health and Rehabilitation</p>
            </div>
            <div className="profile-detail-box">
              <div className="flex items-center gap-1">
                <p className="text-neutral-600 para-p3 font-normal">Service Period:</p>
                <p className="text-neutral-800 para-p3 font-normal">04/01/2021 - 31/03/2022</p>
              </div>
            </div>
          </div>
        </div>
        <div className="evaluation-performance-box">
          <div className="flex items-center gap-6 justify-between w-full">
            <p className="text-neutral-800 text-20 font-medium">Performance Evaluation</p>
            <div className="performance-box">
              <p className="text-success-700 desktop-heading-h5 font-semi-bold">100%</p>
              <p className="text-success-700 para-p2 font-medium">(400 out of 400)</p>
            </div>
          </div>
          <div className="table-wrapper">
            <Table columns={columns} dataSource={tableData} rootClassName="complete-evaluation-table" />
          </div>
        </div>
        <p className="footer-text">Evaluated on 05/07/2023 by E. Decker</p>
      </div>
    </div>
  )
}

export default CompleteEvaluation
