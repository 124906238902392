import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Image, Input, Radio } from 'antd'
import type { SearchProps } from 'antd/es/input'
import { profile } from 'assets/imgs/index'
import { SearchIcon } from 'assets/svgs'
import { BUTTON_STRING, SHIFTS } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { GET_FACILITY_USER } from 'services/common/Dropdown'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFacilityUser } from 'types'

import CustomBtn from 'components/common/CustomBtn'

const SetContact = ({
  setIsModalOpen,
  selectedContact,
  handleSetSelectContact,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedContact: IFacilityUser | undefined
  handleSetSelectContact: (data: IFacilityUser) => void
}) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [userList, setUserList] = useState<IFacilityUser[]>([])
  const [selectedUserId, setSelectedUserId] = useState(selectedContact?.id ?? '')
  const { id: facilityId } = useParams() ?? ''
  const dispatch = useAppDispatch()

  useEffect(() => {
    void handleGetAllUserList(searchValue)
    // eslint-disable-next-line
  }, [searchValue, facilityId])

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearchValue(value.trim().toLowerCase())
  }

  const handleChange = (value: string) => {
    if (value?.length === 0) {
      setSearchValue(value)
    }
  }

  const handleGetAllUserList = async (search: string) => {
    if (facilityId) {
      dispatch(setGlobalLoader(true))
      const response = await GET_FACILITY_USER(facilityId, search)
      if (response?.data?.statusCode === 1) {
        setUserList(response?.data?.data)
      }
      dispatch(setGlobalLoader(false))
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSearch(e.currentTarget.value)
    }
  }

  const onFinish = () => {
    const data = userList?.find((data) => data?.id === selectedUserId)
    if (data) {
      handleSetSelectContact(data)
    }
    setIsModalOpen(false)
  }

  return (
    <div className="flex flex-col items-center gap-4 pb-8">
      <Form className="flex px-4 flex-col items-center gap-4 self-stretch" onFinish={onFinish}>
        <div className="inv-content-wrapper wrapper-form-height flex flex-col items-start gap-8 self-stretch rounded-3 form-height-wrapper">
          <div className="inv-search-box-wrapper flex items-start gap-6 self-stretch">
            <Input.Search
              placeholder={SHIFTS.SEARCH_CONTACT}
              prefix={<SearchIcon />}
              allowClear
              onSearch={onSearch}
              onChange={(e) => {
                handleChange(e.target.value)
              }}
              onKeyDown={handleKeyDown}
              className="custom-searchBar searchBar-width p-0"
            />
          </div>
          <div className="flex flex-col items-start gap-6 self-stretch h-full overflow-y-scroll">
            <span className="w-full flex flex-col items-start self-stretch">
              <Radio.Group
                onChange={(e) => {
                  setSelectedUserId(e.target.value)
                }}
                value={selectedUserId}
                rootClassName="flex flex-col gap-6"
              >
                {userList.map((item) => (
                  <div className="select-nurses-container flex justify-between items-center self-stretch" key={item.id}>
                    <div className="select-content-wrapper flex justify-center items-center gap-4 ">
                      <Radio value={item.id}>
                        <div className="select-text-wrapper flex items-center gap-3">
                          <Image
                            // eslint-disable-next-line
                            src={item?.image && item?.base_url ? `${item?.base_url}${item?.image}` : profile}
                            alt="profile"
                            className="select-profile-img object-cover rounded-50"
                            preview={false}
                          />
                          <div className="text-wrapper flex flex-col items-start gap-1">
                            <p className="head-text text-neutral-800 para-p2 font-normal">
                              {item.first_name} {item.last_name}
                            </p>
                          </div>
                        </div>
                      </Radio>
                    </div>
                  </div>
                ))}
              </Radio.Group>
            </span>
          </div>
        </div>
        <div className="flex gap-4 w-full justify-end items-end">
          <CustomBtn
            type="default"
            text={BUTTON_STRING.CANCEL}
            className="w-160 h-49"
            onClick={() => {
              setIsModalOpen(false)
            }}
          />
          <CustomBtn disabled={!selectedUserId} text={BUTTON_STRING.ADD_CONTACT} type="primary" htmlType="submit" className="w-160 h-49" />
        </div>
      </Form>
    </div>
  )
}

export default SetContact
