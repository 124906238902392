import React, { useEffect, useState } from 'react'
import { CirclePlus, DraggableDots, MinusCircle } from 'assets/svgs'
import { settingTab } from 'constants/Constant'
import { getAllfacilitySettingShiftTimes } from 'pages/facilities/api'

const ShiftOptions = ({ settingData, clientSelected, setClientSelected, systemOptionData, setSystemOptionData }: any) => {
  let dragItem: HTMLDivElement | null = null
  const [dragging, setDragging] = useState(false)
  const [dragIndex, setDragIndex] = useState(-1)
  const [dropIndex, setDropIndex] = useState(-1)

  const handleAddToClientSelected = (data: { id: number; time: string }) => {
    setClientSelected((prevData: any) => {
      const isAlreadySelected = prevData.some((item: { id: number }) => item.id === data.id)
      if (isAlreadySelected) return prevData
      setSystemOptionData((prevOptions: any) => prevOptions.filter((option: any) => option.id !== data.id))
      return [...prevData, data]
    })
  }

  const handleRemoveFromClientSelected = (index: number) => {
    setClientSelected((prevData: any) => {
      const removedShift = prevData[index]
      setSystemOptionData((prevOptions: any) => {
        const isAlreadyInSystemOptions = prevOptions.some((option: any) => option.id === removedShift.id)
        if (!isAlreadyInSystemOptions) {
          return [...prevOptions, removedShift]
        }
        return prevOptions
      })
      return prevData?.slice(0, index).concat(prevData.slice(index + 1))
    })
  }

  const handleDragStart = (index: number) => {
    setDragging(true)
    setDragIndex(index)
    if (dragItem) {
      dragItem.style.transform = 'translate(0, 0)'
      dragItem.style.transition = 'none'
    }
  }

  const handleDragEnd = () => {
    setDragging(false)
    if (dragItem) {
      dragItem.style.transform = 'translate(0, 0)'
      dragItem.style.transition = 'none'
    }
  }

  const handleDragOver = (index: number) => {
    setDropIndex(index)
  }

  const handleDrop = () => {
    if (dragIndex === -1 || dropIndex === -1) return

    const newClientSelected = [...clientSelected]
    const [removed] = newClientSelected.splice(dragIndex, 1)
    newClientSelected.splice(dropIndex, 0, removed)

    setClientSelected(newClientSelected)
    setDragging(false)
    setDragIndex(-1)
    setDropIndex(-1)
  }

  const handleDragItemRef = (element: HTMLDivElement | null) => {
    dragItem = element
  }

  return (
    <div className="shift-options-wrapper flex p-10 flex-col items-start gap-6 flex-grow aline-self rounded-4 border-stroke">
      <div className="shift-option-box flex justify-between items-start aline-self gap-4">
        <div className="shift-option-selected  flex flex-col items-start gap-4 w-full">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.SYSTEM_OPTION}</p>
          {systemOptionData.map((data: any, index: number) => {
            return (
              <div className="add-shift-btn-wrapper flex w-full p-2 justify-between items-center rounded-2 border-stroke aline-self" key={data.id}>
                <p className="text-neutral-800 para-p2 font-medium">{data.time}</p>
                <CirclePlus
                  className="plus-icon-solid cursor-pointer"
                  onClick={() => {
                    handleAddToClientSelected(data)
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className="client-selected-box flex flex-col items-start gap-2 w-full">
          <p className="text-neutral-800 para-p2 font-medium">{settingTab.CLIENT_SELECTED}</p>
          {clientSelected.map((data: any, index: number) => {
            return (
              <div
                key={data.id}
                className={`client-selected-box-wrapper flex items-center gap-2 w-full justify-end  ${dragging && dragIndex === index ? 'dragging' : ''}`}
                draggable
                onDragStart={() => {
                  handleDragStart(index)
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                  handleDragOver(index)
                }}
                onDrop={(e) => {
                  e.preventDefault()
                  handleDrop()
                }}
                onDragEnd={handleDragEnd}
                ref={handleDragItemRef}
              >
                <div className="drag">
                  <DraggableDots className="cursor-pointer" />
                </div>
                <div className="client-selected-btn-wrapper w-full flex p-2 justify-between items-center rounded-2 border-stroke bg-neutral-50 aline-self">
                  <p className="text-neutral-800 para-p2 font-medium">{data.time}</p>
                  <MinusCircle
                    className="cursor-pointer"
                    onClick={() => {
                      handleRemoveFromClientSelected(index)
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ShiftOptions
