import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, message, Switch } from 'antd'
import { CloseIcon, ModalClose } from 'assets/svgs'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddNewSpecialities, type IAddNewSpecialtiesProps, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import DropDown from 'components/common/Select/DropDown'

import { addSpecialities, getCertificate, getSpecialtyById, updateSpecialties } from '../api'

const AddNewSpecialities = ({ setIsModalOpen, editId, editForm }: IAddNewSpecialtiesProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const [error, setError] = useState({
    name: '',
    abbreviation: '',
    line_of_business: '',
  })
  const [switchData, setSwitchData] = useState<boolean>(true)
  const [displayWorkforce, setDisplayWorkforce] = useState<boolean>(true)
  const [certificatesOption, setCertificatesOption] = useState<any[]>([])
  const [certificatesTag, setCertificatesTag] = useState<any[]>([])

  useEffect(() => {
    if (editId) {
      void getSingleSpecialtyData(editId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, form])

  useEffect(() => {
    void getCertificateOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCertificateOptions = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getCertificate()
    if (response?.data.statusCode === 1) {
      const certificatesOption = response?.data?.data
      const modifiedCertificates = certificatesOption.map((item: { id: string; name: string }) => ({
        ...item,
        id: item.id,
        name: item?.name,
      }))
      setCertificatesOption(modifiedCertificates)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getSingleSpecialtyData = async (editId: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getSpecialtyById(editId)
    if (response?.data.statusCode === 1) {
      const data = response.data.data
      const checked = data.status === 'active'
      setSwitchData(checked)
      const certificatesIds: string[] = data?.certificates?.map((certificates: { id: string }) => certificates.id)
      setCertificatesTag(certificatesIds)
      form.setFieldsValue({
        name: data.name,
        abbreviation: data.abbreviation,
        line_of_business: data.line_of_business_id,
        workforce_portal_alias: data.workforce_portal_alias,
      })
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleAddSpecialties = async (payload: IAddNewSpecialities) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await updateSpecialties(editId, payload)
    } else {
      response = await addSpecialities(payload)
    }
    if (response?.data?.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = (values: IAddNewSpecialities) => {
    const payload = {
      name: values.name,
      abbreviation: values.abbreviation,
      status: switchData ? 'active' : 'in_active',
      certificates: certificatesTag ?? [],
      workforce_portal_alias: values.workforce_portal_alias ?? '',
      display: displayWorkforce,
    }

    void handleAddSpecialties(payload)
  }

  const handleSwitchChange = () => {
    setSwitchData(!switchData)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleCertificatesChange = (selectedValue: any[]) => {
    setCertificatesTag(selectedValue)
  }

  const handleDeleteTag = (id: number) => {
    const updatedCertificatesTag = [...certificatesTag]
    updatedCertificatesTag.splice(id, 1)
    setCertificatesTag(updatedCertificatesTag)
  }

  const onDisplayWorkForceChange = (checked: boolean) => {
    setDisplayWorkforce(checked)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editSpeciality') : t('label.addNewSpeciality')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        className="form-certi-wrapper"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="add-speciality">
          <InputField
            error={error.name}
            placeholder={t('placeholder.specialityName')}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.SPECIALITY)}
            name="name"
            value={getValue('name')}
          />
          <InputField
            error={error.abbreviation}
            placeholder={t('placeholder.certiAbb')}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.ABBREVIATION)}
            name="abbreviation"
            value={getValue('abbreviation')}
          />
          <div className="w-full mb-6">
            <DropDown
              name="certificates"
              handleChange={handleCertificatesChange}
              Options={certificatesOption}
              placeholder={t('placeholder.certification')}
              updatedTag={certificatesTag}
              value={getValue('certificates')}
            />
          </div>
          {/* show selected option as tag */}
          {certificatesTag?.length > 0 && (
            <div className="flex flex-col gap-4 w-full mb-6">
              {certificatesTag?.length > 0 && <HeadingText text={t('placeholder.certification')} classString="para-p3 text-neutral-400" />}
              <div className="flex gap-4 w-full flex-wrap">
                {certificatesTag?.map((tagId: string, index: number) => {
                  const option = certificatesOption.find((item) => item.id === tagId)
                  return (
                    <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                      {option?.name}
                      <span
                        className="flex items-center cursor-pointer"
                        onClick={() => {
                          handleDeleteTag(index)
                        }}
                      >
                        {<CloseIcon />}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div className="flex gap-2 toggle-switch mb-6">
            <span className="para-p3 text-neutral-600">{t('heading.displayWorkforce')}</span> <Switch checked={displayWorkforce} onChange={onDisplayWorkForceChange} />
          </div>
          <InputField placeholder={t('placeholder.workforcePortalAlias')} name="workforce_portal_alias" value={getValue('workforce_portal_alias')} />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.active')}</span>
            <Switch onChange={handleSwitchChange} checked={switchData} />
          </div>
          <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="w-120" />
        </div>
      </Form>
    </div>
  )
}

export default AddNewSpecialities
