import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Tabs } from 'antd'
import { useWindowSize } from 'hooks/useWindowSize'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'
import { type ISubSectionData } from 'types'

const FaCilityGeneralSetting = () => {
  const size = useWindowSize()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.generalSettings'),
        isBack: true,
      })
    )
  }, [dispatch])

  const settings = [
    {
      key: 1,
      name: 'Schedule Settings',
      data: [
        {
          id: 1,
          name: 'Show Provider Profile Details',
          status: 'active',
        },
        {
          id: 2,
          name: 'Show Provider’s Schedule',
          status: 'active',
        },
      ],
    },
    {
      key: 2,
      name: 'Report Settings',
      data: [
        {
          id: 1,
          name: 'Show Schedule Report',
          status: 'in_active',
        },
        {
          id: 2,
          name: 'Show Provider Work History Report',
          status: 'active',
        },
      ],
    },
    {
      key: 3,
      name: 'Chat Setting',
      data: [
        {
          id: 1,
          name: 'Will be Able to Chat with All Providers',
          status: 'active',
        },
      ],
    },
    {
      key: 4,
      name: 'Time & Attendance Settings',
      data: [
        {
          id: 1,
          name: 'Can Approve the Timecards',
          status: 'active',
        },
      ],
    },
    {
      key: 5,
      name: 'Billing Settings',
      data: [
        {
          id: 1,
          name: 'Can Request More Credits',
          status: 'in_active',
        },
      ],
    },
  ]

  return (
    <div>
      {/* <div className="flex justify-end mb-6 md-flex-col">
        <CustomBtn text={t('button.saveSettings')} type="primary" />
      </div> */}
      <Tabs
        defaultActiveKey="1"
        tabPosition={size.width >= 1230 ? 'left' : 'top'}
        items={settings?.map((data: any) => {
          const id = data.key
          return {
            label: data.name,
            key: id,
            children: (
              <div className="flex flex-col rounded-4 border-stroke">
                <div className={`flex flex-col w-full`}>
                  {data.data.map((menuData: any, index: number) => {
                    return (
                      <div className={`flex flex-row w-full justify-between border-b-stroke ${index === data.data.length - 1 ? 'border-b-0' : ''}`} key={menuData.id}>
                        <div className="field-name-wrapper">
                          <p className="field-name">{menuData.name}</p>
                        </div>
                        <div className="flex justify-center items-center p-4 w-33">
                          <Switch checked={menuData.status === 'active'} />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ),
          }
        })}
      />
    </div>
  )
}

export default FaCilityGeneralSetting
