import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

export const getFacilityGeneralSetting = async (query?: string) => {
  try {
    const response = http.get(`/facility-general-setting?${query ?? ''}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateFacilityGeneralSetting = async (id: string, payload: { is_active: boolean }) => {
  try {
    const response = http.patch(`/facility-general-setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
