import React from 'react'

const UserIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="null" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0921 10.5936C13.9091 9.95086 14.5054 9.06941 14.7981 8.07193C15.0908 7.07444 15.0654 6.01053 14.7252 5.0282C14.3851 4.04587 13.7473 3.19397 12.9005 2.59102C12.0537 1.98807 11.04 1.66406 10.0004 1.66406C8.96088 1.66406 7.94719 1.98807 7.10037 2.59102C6.25355 3.19397 5.61571 4.04587 5.2756 5.0282C4.93549 6.01053 4.91002 7.07444 5.20273 8.07193C5.49543 9.06941 6.09176 9.95086 6.90876 10.5936C5.50882 11.1545 4.28733 12.0848 3.3745 13.2852C2.46167 14.4857 1.89173 15.9114 1.72542 17.4103C1.71339 17.5197 1.72302 17.6305 1.75378 17.7362C1.78454 17.8419 1.83581 17.9405 1.90468 18.0264C2.04377 18.1999 2.24608 18.311 2.46709 18.3353C2.6881 18.3596 2.90972 18.2951 3.0832 18.156C3.25667 18.017 3.36778 17.8147 3.39209 17.5936C3.57508 15.9646 4.35183 14.4602 5.57394 13.3676C6.79605 12.2751 8.37784 11.6712 10.0171 11.6712C11.6563 11.6712 13.2381 12.2751 14.4602 13.3676C15.6824 14.4602 16.4591 15.9646 16.6421 17.5936C16.6647 17.7984 16.7625 17.9875 16.9163 18.1245C17.0702 18.2615 17.2694 18.3366 17.4754 18.3353H17.5671C17.7855 18.3102 17.9852 18.1997 18.1226 18.028C18.2599 17.8563 18.3239 17.6373 18.3004 17.4186C18.1333 15.9155 17.5603 14.4861 16.6428 13.2838C15.7253 12.0815 14.4979 11.1515 13.0921 10.5936ZM10.0004 10.002C9.34115 10.002 8.69669 9.80648 8.14852 9.44021C7.60036 9.07394 7.17312 8.55334 6.92083 7.94425C6.66853 7.33517 6.60252 6.66494 6.73114 6.01834C6.85976 5.37174 7.17723 4.77779 7.6434 4.31162C8.10958 3.84544 8.70352 3.52798 9.35012 3.39936C9.99673 3.27074 10.667 3.33675 11.276 3.58904C11.8851 3.84134 12.4057 4.26858 12.772 4.81674C13.1383 5.36491 13.3338 6.00937 13.3338 6.66864C13.3338 7.5527 12.9826 8.40054 12.3574 9.02566C11.7323 9.65079 10.8845 10.002 10.0004 10.002Z"
        className={className}
      />
    </svg>
  )
}

export default UserIcon
