import { type ChangeEvent, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Grid, Select, Switch } from 'antd'
import { DropDownIcon, MinusIcon, PlusIcon } from 'assets/svgs'
import { allowedEntryType, customClockingSourceData, settingTab, TimeApprovalMethodData, UserSystemIncrementData, UserSystemRoundUpData } from 'constants/Constant'
import { commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type ITimeEntrySetting } from 'types'

import CommonTextArea from 'components/common/TextArea/CommonTextArea'

// todo :- type not given cause component is using someplace else also
const TimeAndApproval = ({ settingData, timeEntrySetting, setTimeEntrySetting }: any) => {
  const [form] = Form.useForm()
  const { xl } = Grid.useBreakpoint()
  const [counter, setCounter] = useState(settingData?.default_lunch_duration ?? 0)
  const [radius, setRadius] = useState(settingData?.geo_fence_radius ?? 0)

  useEffect(() => {
    setTimeEntrySetting({ ...timeEntrySetting, default_lunch_duration: counter })
  }, [counter])

  useEffect(() => {
    setTimeEntrySetting({ ...timeEntrySetting, geo_fence_radius: radius })
  }, [radius])

  const handleMinusClick = () => {
    setCounter(counter > 0 ? counter - 1 : 0)
  }

  const handlePlusClick = () => {
    setCounter(Number(counter) + 1)
  }
  const handleRadiusMinusClick = () => {
    setRadius(radius > 0 ? radius - 1 : 0)
  }

  const handleRadiusPlusClick = () => {
    setRadius(Number(radius) + 1)
  }

  const handleSelectChange = (value: string) => {
    setTimeEntrySetting({ ...timeEntrySetting, timecard_rounding_direction: value })
  }

  const handleTimeRoundingChange = (value: string) => {
    setTimeEntrySetting({ ...timeEntrySetting, timecard_rounding: value })
  }

  const handleClientClockingSource = (value: string) => {
    setTimeEntrySetting({ ...timeEntrySetting, clocking_source: value })
  }

  const handleTimeApprovalMethod = (value: string) => {
    setTimeEntrySetting({ ...timeEntrySetting, time_approval_method: value })
  }

  const handleCheckboxChange = (data: { name: string; value: string }) => {
    setTimeEntrySetting((prevState: ITimeEntrySetting) => {
      const updatedEntries = prevState.allowed_entries?.includes(data.value)
        ? prevState.allowed_entries?.filter((entry: string) => entry !== data.value)
        : [...prevState.allowed_entries, data.value]
      return {
        ...prevState,
        allowed_entries: updatedEntries,
      }
    })
  }

  return (
    <Form form={form} className="time-and-approval-container flex flex-col items-start flex-grow rounded-4 border-stroke overflow-x-scroll">
      <div className=" flex items-start aline-self flex-grow border-b-stroke w-full">
        {xl && (
          <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30">
            <p className="text-neutral-800 activity-text font-medium">{settingTab.TIME_CARD_SETTING}</p>
          </div>
        )}
        <div className="flex flex-col w-full">
          <div className="flex border-b-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow aline-self justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.MINUTE_ROUNDING}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex flex-col items-center justify-start w-ful">
                <div className="flex gap-4 items-center h-60 w-full">
                  <div className="flex flex-col w-full">
                    <div className={`cursor-pointer w-full `}>
                      <Form.Item name="timecard_rounding" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                        <Select
                          suffixIcon={<DropDownIcon />}
                          onChange={(value) => {
                            handleTimeRoundingChange(value)
                          }}
                          placeholder={t('placeholder.useSystemSettings')}
                          defaultValue={settingData?.timecard_rounding}
                          value={settingData?.timecard_rounding}
                          options={UserSystemIncrementData}
                          className="setting-custom-dropDown"
                        />
                      </Form.Item>
                    </div>
                    {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                  </div>
                  <p className="text-neutral-800 para-p3 font-small">{settingTab.INCREMENT}</p>
                </div>
                <div className="flex gap-4 items-center justify-start h-60 w-full">
                  <div className="flex flex-col w-full">
                    <div className={`cursor-pointer w-full `}>
                      <Form.Item name="userSystem_direction" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                        <Select
                          suffixIcon={<DropDownIcon />}
                          onChange={(value) => {
                            handleSelectChange(value)
                          }}
                          defaultValue={settingData?.timecard_rounding_direction}
                          placeholder={t('placeholder.useSystemSettings')}
                          options={UserSystemRoundUpData}
                          className="setting-custom-dropDown"
                        />
                      </Form.Item>
                    </div>
                    {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                  </div>
                  <p className="text-neutral-800 para-p3 font-small">{settingTab.DIRECTION}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex ">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow aline-self justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.DEFAULT_LUNCH_MINUTES}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start ">
              <div className="counter-box">
                <Button icon={<MinusIcon />} onClick={handleMinusClick} className="counter-btn-min" />
                <div className="counter-text-wrapper">
                  <p className="counter-text">{counter}</p>
                </div>
                <Button icon={<PlusIcon className="plus-icon-solid " />} onClick={handlePlusClick} className="counter-btn-plus" />
              </div>
            </div>
          </div>
          <div className="flex border-t-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow aline-self justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.CLIENT_CLOCKING_DATA}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex gap-4 items-center h-60">
                <div className="flex flex-col w-full">
                  <div className={`cursor-pointer w-full `}>
                    <Form.Item name="work_force_portal" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                      <Select
                        suffixIcon={<DropDownIcon />}
                        onChange={(value) => {
                          handleClientClockingSource(value)
                        }}
                        defaultValue={settingData?.clocking_source}
                        placeholder={t('placeholder.workForcePortal')}
                        options={customClockingSourceData}
                        className="setting-custom-dropDown"
                      />
                    </Form.Item>
                  </div>
                  {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                </div>
                <p className="text-neutral-800 para-p3 font-small">{settingTab.SOURCE}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start aline-self flex-grow w-full ">
        {xl && (
          <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30 ">
            <p className="text-neutral-800 activity-text font-medium">{settingTab.WORKFORCE_PORTAL}</p>
          </div>
        )}
        <div className="flex flex-col w-full">
          <div className="flex border-b-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.TIME_APPROVAL}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex gap-4 items-center h-60">
                <div className="flex flex-col w-full">
                  <div className={`cursor-pointer w-full `}>
                    <Form.Item name="e-signature" rules={commonSingleSelectValidation(VALIDATION_STRINGS.SELECT_WORKFORCE)}>
                      <Select
                        suffixIcon={<DropDownIcon />}
                        onChange={(value) => {
                          handleTimeApprovalMethod(value)
                        }}
                        defaultValue={settingData?.time_approval_method}
                        placeholder={t('placeholder.esignature')}
                        options={TimeApprovalMethodData}
                        className="setting-custom-dropDown"
                      />
                    </Form.Item>
                  </div>
                  {/* {error?.facility_type && <p className={` error-container ${error?.facility_type ? 'mb-6' : ''}`}>{error.facility_type}</p>} */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex border-b-stroke">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.ALLOWED_ENTRY_TYPE}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="flex flex-col item-start gap-2">
                {allowedEntryType.map((data) => {
                  return (
                    <div className="flex items-start justify-start gap-2" key={data.name}>
                      <Checkbox
                        onChange={() => {
                          handleCheckboxChange(data)
                        }}
                        checked={timeEntrySetting.allowed_entries?.some((item: string) => item === data.value)}
                      />
                      <p className="text-neutral-700 para-p3 font-medium">{data.name}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.CHECKED_MISSED_MEAL_FOR}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <Switch
                checked={timeEntrySetting?.check_missed_meal_break}
                onChange={() => {
                  setTimeEntrySetting({ ...timeEntrySetting, check_missed_meal_break: !timeEntrySetting?.check_missed_meal_break })
                }}
              />
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.CLOCK_IN}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <Switch
                checked={timeEntrySetting?.enforce_geo_fence}
                onChange={() => {
                  setTimeEntrySetting({ ...timeEntrySetting, enforce_geo_fence: !timeEntrySetting?.enforce_geo_fence })
                }}
              />
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.GEOFENCE_TIME_APPROVAL}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <Switch
                checked={timeEntrySetting?.geo_fence_for_approval}
                onChange={() => {
                  setTimeEntrySetting({ ...timeEntrySetting, geo_fence_for_approval: !timeEntrySetting?.geo_fence_for_approval })
                }}
              />
            </div>
          </div>
          <div className="flex border-b-stroke h-60">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.DEFAULT_RADIUS}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <div className="counter-box">
                <Button icon={<MinusIcon />} onClick={handleRadiusMinusClick} className="counter-btn-min" />
                <div className="counter-text-wrapper">
                  <p className="counter-text">{radius}</p>
                </div>
                <Button icon={<PlusIcon className="plus-icon-solid " />} onClick={handleRadiusPlusClick} className="counter-btn-plus" />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <p className="text-neutral-800 para-p2 font-medium">{settingTab.MAP_LOCATION}</p>
            </div>
            <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
              <CommonTextArea
                name="location"
                placeholder={t('placeholder.clientLocation')}
                rows={2}
                wrapperClass="m-0"
                value={timeEntrySetting?.location}
                defaultValue={timeEntrySetting?.location}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setTimeEntrySetting({ ...timeEntrySetting, location: e.target.value })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default TimeAndApproval
