import { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import usePermissionsBySectionName from 'hooks/usePermission'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type ICertificationColumn, type ICreateCertificate } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddNewLicense from './Modal/AddNewLicense'
import { deleteCertificate, editCertificate, getAllCertificate } from './api'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import { DELETE_MODAL_LICENSE_TEXT } from 'constants/Constant'

const License = () => {
  const dispatch = useAppDispatch()
  const permit = usePermissionsBySectionName('master_admin_setting')

  const [search, setSearch] = useState<string>('')
  const [isAddNewCertification, setAddNewCertification] = useState<boolean>(false)
  const [listData, setListData] = useState([])
  const [editId, setEditId] = useState<string>('')
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData.length).fill(false))
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.certification'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetCertificateList(paginationInfo.limit, search ? 0 : paginationInfo.offset, sortTable?.order, sortTable?.field)
  }, [paginationInfo.limit, paginationInfo.offset, search, sortTable, isAddNewCertification])

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleGetCertificateList = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllCertificate(limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setPaginationInfo((prev) => ({
      ...prev,
      current: 1,
    }))
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
    void handleGetCertificateList(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleDeleteCertificate = async (certiId: string) => {
    const response = await deleteCertificate(certiId)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleGetCertificateList(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const handleGetData = async (record: ICreateCertificate) => {
    const { id } = record
    setEditId(id ?? '')
    setAddNewCertification(true)
  }

  const onSwitchChange = (checked: boolean, data: ICertificationColumn, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const updatedSpeciality = data?.specialities?.map((item) => (typeof item === 'string' ? item : item.id))
    const newData = { ...data, status: newStatus, specialities: updatedSpeciality }
    void handleEditUserData(id, newData)
  }

  const handleEditUserData = async (id: string, values: ICreateCertificate) => {
    dispatch(setGlobalLoader(true))
    const response = await editCertificate(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetCertificateList(paginationInfo.limit, paginationInfo.offset, sortTable.order, sortTable.field)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            void handleGetData(record)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <div className=" cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      onSwitchChange(checked, record, record?.id)
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '3',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id)
            setDeleteName(record.name)
            handleActionMenuClick(index)
          },
          label: permit?.certification?.delete && (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<ICertificationColumn> = [
    {
      title: t('table.certification.number'),
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      width: 100,
      render: (_value, record, index) => {
        const pageNumber = paginationInfo.current
        const itemsPerPage = paginationInfo.limit
        const calculatedNumber = (pageNumber - 1) * itemsPerPage + index + 1
        return (
          <HeadingText text={search ? (index + 1 + (pageNumber - 1) * itemsPerPage).toString() : calculatedNumber.toString()} classString="para-p3 text-neutral-800 font-small" />
        )
      },
    },
    {
      title: t('table.certification.certification'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 435,
      render: (_value, record) => {
        return <HeadingText text={record.name} classString={'text-neutral-800 activity-text font-medium'} />
      },
    },
    {
      title: t('table.certification.abbreviation'),
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      align: 'start',
      width: 435,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('table.specialties.speciality'),
      dataIndex: 'speciality_count',
      key: 'speciality_count',
      align: 'start',
      width: 326,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record: any) => {
        const specialities = record?.specialities?.map((item: { name: string }) => item.name).join(', ')
        return <HeadingText classString="text-neutral-800 para-p3 font-semibold" text={specialities ?? '-'} />
      },
    },
    {
      title: t('table.certification.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 435,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.certification.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const addLicenseBtn = permit?.certification?.add && (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={t('button.addCertification')}
      onClick={() => {
        setEditId('')
        setAddNewCertification(true)
      }}
      type="primary"
    />
  )

  return (
    <div className="license-wrapper-content h-full">
      <CustomTable<ICertificationColumn>
        column={columns}
        data={listData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        name={t('label.certificate')}
        isSearch
        onSearch={onSearch}
        searchPlaceholder={t('placeholder.search')}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        tableButton={addLicenseBtn}
      />
      {isAddNewCertification && (
        <CustomModal
          open={isAddNewCertification}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setAddNewCertification(false)
          }}
          content={<AddNewLicense setIsModalOpen={setAddNewCertification} editId={editId} setSortTable={setSortTable} />}
          width={600}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_LICENSE_TEXT.CANCEL}
          okClick={() => handleDeleteCertificate(deleteId)}
          deleteName={deleteName}
          subHead={DELETE_MODAL_LICENSE_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_LICENSE_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_LICENSE_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default License
