const SelectFieldIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="4" fill="#EFEFF0" />
      <path
        d="M16.9997 9.1736C16.8123 8.98735 16.5589 8.88281 16.2947 8.88281C16.0305 8.88281 15.7771 8.98735 15.5897 9.1736L11.9997 12.7136L8.4597 9.1736C8.27234 8.98735 8.01889 8.88281 7.7547 8.88281C7.49052 8.88281 7.23707 8.98735 7.0497 9.1736C6.95598 9.26657 6.88158 9.37717 6.83081 9.49903C6.78004 9.62089 6.75391 9.75159 6.75391 9.88361C6.75391 10.0156 6.78004 10.1463 6.83081 10.2682C6.88158 10.39 6.95598 10.5006 7.0497 10.5936L11.2897 14.8336C11.3827 14.9273 11.4933 15.0017 11.6151 15.0525C11.737 15.1033 11.8677 15.1294 11.9997 15.1294C12.1317 15.1294 12.2624 15.1033 12.3843 15.0525C12.5061 15.0017 12.6167 14.9273 12.7097 14.8336L16.9997 10.5936C17.0934 10.5006 17.1678 10.39 17.2186 10.2682C17.2694 10.1463 17.2955 10.0156 17.2955 9.88361C17.2955 9.75159 17.2694 9.62089 17.2186 9.49903C17.1678 9.37717 17.0934 9.26657 16.9997 9.1736Z"
        fill="#2A2A2B"
      />
    </svg>
  )
}

export default SelectFieldIcon
