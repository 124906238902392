import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message, Switch, Tabs } from 'antd'
import { useWindowSize } from 'hooks/useWindowSize'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'
import { formatToTitleCase } from 'utils/utils'

import { getFacilityGeneralSetting, updateFacilityGeneralSetting } from './api'
import { type IFacilityGeneralSetting } from './types'

import '../../style/pages/FacilityGeneralSetting.scss'

const FacilityGeneralSetting = () => {
  const size = useWindowSize()
  const dispatch = useDispatch()
  const [data, setData] = useState<IFacilityGeneralSetting[]>([])

  // Memoizing static data
  const FACILITY_GENERAL_SETTING_TAB = useMemo(
    () => [
      { type: 'schedule', label: 'Schedule Settings', menuData: data.filter((item) => item.type === 'schedule') },
      { type: 'chat', label: 'Chat Settings', menuData: data.filter((item) => item.type === 'chat') },
      { type: 'report', label: 'Report Settings', menuData: data.filter((item) => item.type === 'report') },
      { type: 'time_attendance', label: 'Time & Attendance Settings', menuData: data.filter((item) => item.type === 'time_attendance') },
      { type: 'billing', label: 'Billing Settings', menuData: data.filter((item) => item.type === 'billing') },
    ],
    [data]
  )

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.generalSettings'),
        isBack: true,
      })
    )
  }, [dispatch])

  const handleGetFacilityGeneralSetting = async () => {
    const response = await getFacilityGeneralSetting()
    if (response?.data?.statusCode === 1) {
      setData(response?.data?.data)
    }
  }

  const handleUpdateFacilityGeneralSetting = async (id: string, isActive: boolean) => {
    const response = await updateFacilityGeneralSetting(id, { is_active: isActive })
    if (response?.data?.statusCode === 1) {
      void message?.success(response?.data?.message)
    }
  }

  useEffect(() => {
    void handleGetFacilityGeneralSetting()
  }, [])

  return (
    <div className="facility-general-setting">
      <Tabs
        defaultActiveKey="1"
        tabPosition={size.width >= 1230 ? 'left' : 'top'}
        items={FACILITY_GENERAL_SETTING_TAB?.map((tab: { label: string; type: string; menuData: IFacilityGeneralSetting[] }) => {
          return {
            label: tab?.label,
            key: tab?.type,
            children: (
              <div className={`flex flex-col rounded-4 4 ${tab?.menuData?.length > 0 ? 'border-stroke' : ''}`}>
                <div className={`flex flex-col w-full`}>
                  {tab?.menuData?.map((item, index: number) => {
                    return (
                      <div className={`flex flex-row w-full justify-between border-t-stroke ${index === 0 ? 'border-t-0' : ''}`} key={item?.id}>
                        <div className="field-name-wrapper">
                          <p className="field-name">{formatToTitleCase(item?.label)}</p>
                        </div>
                        <div className="flex justify-center items-center p-4 w-33">
                          <Switch
                            defaultChecked={item?.is_active}
                            onChange={(checked) => {
                              void handleUpdateFacilityGeneralSetting(item?.id, checked)
                            }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ),
          }
        })}
      />
    </div>
  )
}

export default FacilityGeneralSetting
