const CirclePlus = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M10.5 19.25C8.76942 19.25 7.07769 18.7368 5.63876 17.7754C4.19983 16.8139 3.07832 15.4473 2.41606 13.8485C1.75379 12.2496 1.58051 10.4903 1.91813 8.79296C2.25575 7.09563 3.08911 5.53653 4.31282 4.31282C5.53653 3.08911 7.09563 2.25575 8.79296 1.91813C10.4903 1.58051 12.2496 1.75379 13.8485 2.41606C15.4473 3.07832 16.8139 4.19983 17.7754 5.63876C18.7368 7.07769 19.25 8.76942 19.25 10.5C19.25 12.8206 18.3281 15.0462 16.6872 16.6872C15.0462 18.3281 12.8206 19.25 10.5 19.25ZM10.5 3C9.01664 3 7.5666 3.43987 6.33323 4.26398C5.09986 5.08809 4.13856 6.25943 3.57091 7.62988C3.00325 9.00032 2.85473 10.5083 3.14411 11.9632C3.4335 13.418 4.14781 14.7544 5.1967 15.8033C6.2456 16.8522 7.58197 17.5665 9.03683 17.8559C10.4917 18.1453 11.9997 17.9968 13.3701 17.4291C14.7406 16.8614 15.9119 15.9001 16.736 14.6668C17.5601 13.4334 18 11.9834 18 10.5C18 8.51088 17.2098 6.60323 15.8033 5.1967C14.3968 3.79018 12.4891 3 10.5 3Z"
        className={props.className}
      />
      <path d="M13.625 9.86328H7.375V11.1133H13.625V9.86328Z" className={props.className} />
      <path d="M11.125 7.36328H9.875V13.6383H11.125V7.36328Z" className={props.className} />
    </svg>
  )
}

export default CirclePlus
