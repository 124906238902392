import { useEffect } from 'react'
import { Form, message, Switch } from 'antd'
import { BUTTON, LOB } from 'constants/Constant'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IListOfBusiness } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { addLineOfBusiness, editLineOfBusiness } from '../api'

interface IModalProps {
  statusCheck?: boolean
  setStatusCheck?: React.Dispatch<React.SetStateAction<boolean>> | any
  setError: any
  error: any
  isModalOpen?: boolean
  editData?: any
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editId?: string
  setEditId?: any
  form: any
}

const AddBusinessList = ({ form, setIsModalOpen, editId, setEditId, editData, setStatusCheck, statusCheck, setError, error }: IModalProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    form.setFieldValue('name', editData?.name)
    setStatusCheck(editData?.status === 'active')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, editData !== null])

  useEffect(() => {
    if (!editId) setStatusCheck(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!editId])

  const onChange = (switchData: boolean) => {
    setStatusCheck(switchData)
  }

  const handleAddLineOfBusiness = async (values: IListOfBusiness) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await editLineOfBusiness(editId, values)
    } else {
      response = await addLineOfBusiness(values)
    }
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      void message.success(response?.data?.message)
      form.resetFields()
      setEditId('')
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = (values: IListOfBusiness) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const payload = { name: values?.name, status: statusValue }

    void handleAddLineOfBusiness(payload)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={editId ? LOB.UPDATE : LOB.CREATE} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start gap-5  w-full">
            <div className="flex flex-col w-full">
              <div className="h-64 aline-self">
                <InputField
                  rules={commonInputFieldValidation(VALIDATION_STRINGS.BUSINESS_NAME)}
                  placeholder={t('placeholder.businessName')}
                  name="name"
                  inputClass={''}
                  value={getValue('name')}
                />
              </div>
              {error?.name && <p className="error-container">{error.name}</p>}
            </div>
            <div className="flex items-center gap-2 mb-3">
              <p className="text-neutral-600 para-p3 font-normal">{t('label.status')}</p>
              <Switch checked={statusCheck} onChange={onChange} />
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  setIsModalOpen(false)
                  setStatusCheck(true)
                  setEditId('')
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={editId ? BUTTON.UPDATE : BUTTON.CREATE} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddBusinessList
