import type { Rule } from 'antd/es/form'

import { VALIDATION_ERROR, VALIDATION_STRINGS } from './ValidationStrings'

export const commonInputFieldValidation = (fieldName: string) => [
  {
    required: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    message: `${VALIDATION_STRINGS.INPUT_FIELD} ${fieldName}!`,
  },
]

export const commonSingleSelectValidation = (fieldName: string) => [
  {
    required: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    message: `${VALIDATION_STRINGS.SINGLE_SELECT} ${fieldName}!`,
  },
]

export const commonMultiSelectValidation = (fieldName: string) => [
  {
    required: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    message: `${VALIDATION_STRINGS.MULTI_SELECT} ${fieldName}!`,
  },
]

export const mobileValidation = [
  { required: true, message: VALIDATION_STRINGS.MOBILE_REQ },
  { pattern: /^[0-9+\s]+$/, message: VALIDATION_STRINGS.MOBILE2 },
  { min: 8, message: VALIDATION_STRINGS.MIN_NUMBER },
  { max: 14, message: VALIDATION_STRINGS.MAX_NUMBER },
]

export const nameValidation = (fieldName: string) => [
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  { required: true, message: `${VALIDATION_STRINGS.INPUT_FIELD} ${fieldName}!` },
  { pattern: /^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/, message: `${fieldName}${VALIDATION_STRINGS.NAME2}` },
]

export const emailValidation = [
  { required: true, message: VALIDATION_ERROR.COMMON.EMAIL },
  { type: 'email', message: VALIDATION_ERROR.COMMON.EMAIL2 },
]

export const passwordValidations: Rule[] = [
  { required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_NEW_PASSWORD },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/

      if (value) {
        if (value.length < 8) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.MINIMUM_LENGTH))
        }
        if (!passwordRegex.test(value)) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.INVALID_PASSWORD))
        }
      }
      await Promise.resolve()
    },
  }),
]

export const currentPwdValidation = [{ required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_CURRENT_PASSWORD }]

export const confirmPwdValidation: Rule[] = [
  { required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_CONFIRM_PASSWORD },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      if (value) {
        const newPassword = getFieldValue('newPassword')
        const password = getFieldValue('password')
        if (newPassword && newPassword !== value) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.NOT_MATCH_PASSWORD))
        }
        if (password && password !== value) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.NOT_MATCH_PASSWORD))
        }
      }
      await Promise.resolve()
    },
  }),
]

export const newPwdValidation: Rule[] = [
  { required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_NEW_PASSWORD },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).*$/

      if (value) {
        if (value.length < 8) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.MINIMUM_LENGTH))
        }
        if (!passwordRegex.test(value)) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.INVALID_PASSWORD))
        }
      }

      await Promise.resolve()
    },
  }),
]

export const selectOptionValidation = [{ required: true, message: VALIDATION_ERROR.COMMON.RADIOSELECT }]
