import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { PlusIcon } from 'assets/svgs'
import { BUTTON, DELETE_MODAL_FACILITY_PROVIDER_SETTING_TEXT } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { deleteDnrReason, deleteFlagStatus, getAllDnrReason, getAllFlagSetting } from 'services/SettingServices/FacilityProviderService'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { removeSorting } from 'utils/Helper'
import { formatQuerry } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CustomTable from 'components/common/Table/CustomTable'

import { useColumn } from './hooks/useColumn'
import AddDnrReason from './Modal/AddDnrReason'
import AddFlagStatus from './Modal/AddFlagStatus'
// import AddProviderStatus from './Modal/AddProviderStatus'

const ProviderSettings = () => {
  const [selectedValue, setSelectedValue] = useState<'flag_status' | 'dnr_reason'>('flag_status')
  const [selectValueChange, setSelectValueChange] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  // const [isProviderStatusOpen, setIsProviderStatusOpen] = useState<boolean>()
  const [isFlagStatusOpen, setIsFlagStatusOpen] = useState<boolean>(false)
  const [isAddReasonOpen, setIsAddReasonOpen] = useState<boolean>(false)
  const [listData, setListData] = useState([])
  const [refreshData, setRefreshData] = useState(false)
  const [editData, setEditData] = useState<any>()
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [search, setSearch] = useState<string>('')
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [filterValue, setFiltervalue] = useState({ type: '' })
  const [filterApply, setFilterApply] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  const showAddModal = () => {
    if (selectedValue === 'flag_status') {
      setIsFlagStatusOpen(true)
    } else {
      setIsAddReasonOpen(true)
    }
  }
  const { dnrColumns, flagColumns, handlePageChange } = useColumn({
    actionMenuOpen,
    setActionMenuOpen,
    setPaginationInfo,
    paginationInfo,
    setRefreshData,
    setEditData,
    showAddModal,
    selectedValue,
    setDeleteId,
    setDeleteName,
    setIsDeleteModalOpen,
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.provideSettings'),
        isBack: true,
      })
    )
  }, [dispatch])

  const handleGetTableData = async () => {
    try {
      dispatch(setGlobalLoader(true))
      const params = {
        limit: paginationInfo?.limit,
        offset: paginationInfo?.offset,
        ...(search && { search }),
        [`order[${String(sortTable.field)}]`]: sortTable.order,
        ...(filterApply && selectedValue === 'dnr_reason' && { reason_type: filterValue.type === 'all' ? '' : filterValue.type }),
      }
      const query = formatQuerry({ ...params })
      const response = selectedValue === 'dnr_reason' ? await getAllDnrReason(query) : await getAllFlagSetting(query)
      if (response?.data?.statusCode === 1) {
        setListData(response?.data?.data)
        setRefreshData(false)
        setFilterApply(false)
        setPaginationInfo((prev) => ({
          ...prev,
          total: response?.data?.total,
          limit: response?.data?.limit,
        }))
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setGlobalLoader(false))
    }
  }

  useEffect(() => {
    void handleGetTableData()
  }, [search, paginationInfo?.current, sortTable])

  useEffect(() => {
    if (selectedValue && selectValueChange) {
      removeSorting(setSortTable)
      if (sortTable?.field) {
        if (paginationInfo?.current !== 1) {
          handlePageChange(1)
        }
      } else {
        if (paginationInfo?.current === 1) {
          void handleGetTableData()
        } else {
          handlePageChange(1)
        }
      }
    }
  }, [selectedValue, selectValueChange])

  useEffect(() => {
    if (refreshData) {
      void handleGetTableData()
    }
    // eslint-disable-next-line
  }, [refreshData])

  useEffect(() => {
    if (filterApply) {
      void handleGetTableData()
    }
    // eslint-disable-next-line
  }, [filterApply])

  useEffect(() => {
    if (selectedValue === 'flag_status') {
      setFilterApply(false)
      setFiltervalue({ type: '' })
    }
  }, [selectedValue])

  const tabData = [
    // { value: 'provider_status', label: 'Provider Status' },
    { value: 'flag_status', label: 'Flag Status' },
    { value: 'dnr_reason', label: 'DNR Reason' },
  ]

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setPaginationInfo((prev) => ({
      ...prev,
      current: 1,
    }))
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handleDelete = async (id: string) => {
    if (id && selectedValue) {
      try {
        dispatch(setGlobalLoader(true))
        const response = selectedValue === 'dnr_reason' ? await deleteDnrReason(id) : await deleteFlagStatus(id)
        if (response?.data?.statusCode === 1) {
          setIsDeleteModalOpen(false)
          void message.success(response?.data?.message)
          const newTotal = paginationInfo.total - 1
          const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
          if (paginationInfo.current > newPage) {
            handlePageChange(newPage)
          } else {
            setRefreshData(true)
          }
        }
      } catch (err) {
        console.log(err)
        dispatch(setGlobalLoader(false))
      }
    }
  }

  useEffect(() => {
    if (!isFlagStatusOpen && !isAddReasonOpen) {
      setEditData(null)
    }
  }, [isFlagStatusOpen, isAddReasonOpen])

  const TableButton = (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={selectedValue === 'flag_status' ? BUTTON.CREATE_STATUS : BUTTON.ADD_REASON}
      type="primary"
      className="rounded-3 border-0"
      onClick={showAddModal}
    />
  )

  const FilterOptionMenu = (
    <div className="w-full flex flex-col gap-8">
      <div className="flex flex-col gap-6px item-start w-full">
        <FloatingLabelSelect
          options={[
            { value: 'all', label: 'All' },
            { value: 'clinical', label: 'Clinical' },
            { value: 'professional', label: 'Professional' },
          ]}
          value={filterValue.type}
          onChange={(value) => {
            console.log(value)
            setFiltervalue({ type: value as 'all' | 'clinical' | 'professional' })
          }}
          label={t('label.reasonType')}
          name="type"
          mainClass="mt-4"
        />
      </div>
    </div>
  )

  return (
    <div className="flex gap-6 w-full relative">
      <CustomTable
        name={selectedValue === 'flag_status' ? t('label.status') : t('label.reasons')}
        column={selectedValue === 'flag_status' ? flagColumns : dnrColumns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        data={listData}
        isSearch
        isFilter={selectedValue === 'dnr_reason'}
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        searchPlaceholder={t('placeholder.search')}
        tableButton={TableButton}
        isTab
        option={tabData}
        setSelectedValue={setSelectedValue}
        setSelectValueChange={setSelectValueChange}
        selectedValue={selectedValue}
        filterOption={FilterOptionMenu}
        filterSelected={filterValue}
        handleResetFilter={() => {
          setFiltervalue({ type: '' })
          setFilterApply(true)
        }}
        handleApplyFilter={() => {
          setFilterApply(true)
        }}
      />

      {/* {isProviderStatusOpen && (
        <CustomModal
          open={isProviderStatusOpen}
          centered
          title={t('label.createNewStatus')}
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsProviderStatusOpen(false)
          }}
          width={600}
          content={<AddProviderStatus />}
        />
      )} */}

      {isFlagStatusOpen && (
        <CustomModal
          open={isFlagStatusOpen}
          centered
          title={editData?.id ? t('label.editFlagStatus') : t('label.createNewFlagStatus')}
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsFlagStatusOpen(false)
          }}
          width={600}
          content={
            <AddFlagStatus setIsFlagStatusOpen={setIsFlagStatusOpen} setRefreshData={setRefreshData} setSortTable={setSortTable} editData={editData} setEditData={setEditData} />
          }
        />
      )}

      {isAddReasonOpen && (
        <CustomModal
          open={isAddReasonOpen}
          centered
          title={editData?.id ? t('label.editDnrReason') : t('label.createNewDnrReason')}
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsAddReasonOpen(false)
          }}
          width={600}
          content={
            <AddDnrReason setIsAddReasonOpen={setIsAddReasonOpen} setRefreshData={setRefreshData} setSortTable={setSortTable} editData={editData} setEditData={setEditData} />
          }
        />
      )}
      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_FACILITY_PROVIDER_SETTING_TEXT.CANCEL}
          okClick={() => {
            void handleDelete(deleteId)
          }}
          deleteName={deleteName}
          subHead={
            selectedValue === 'flag_status' ? DELETE_MODAL_FACILITY_PROVIDER_SETTING_TEXT.DELETE_DESC_STATUS : DELETE_MODAL_FACILITY_PROVIDER_SETTING_TEXT.DELETE_DESC_REASON
          }
          mainHead={DELETE_MODAL_FACILITY_PROVIDER_SETTING_TEXT.DELETE_HEAD}
          okText={DELETE_MODAL_FACILITY_PROVIDER_SETTING_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default ProviderSettings
