import { useEffect, useState } from 'react'
import { DatePicker, Dropdown, type MenuProps, Switch } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { BanIcon, CalenderIcon, ChatsIcon, FileOutlineIcon, HistoryIcon, ThreeDotIcon } from 'assets/svgs'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import DropDown from 'components/common/Select/DropDown'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CustomTable from 'components/common/Table/CustomTable'
import TableImage from 'components/common/Table/TableImage'

const listData = [
  {
    id: '1',
    name: 'Theresa Webb',
    email: 'Brooklynsimmons@gmail.com',
    country_code: '+1',
    mobile_no: '123 456 7890',
    recruiter: 'Bessie Cooper',
    staffingSpeciality: 'Bessie Cooper',
    referredBy: 'Bessie Cooper',
    complianceManager: 'Bessie Cooper',
    city: 'High Point',
    state: 'NC',
    zipCode: '27262',
    license: 'LVM',
    speciality: 'LTC',
    status: 'Active',
    application: 'Yes',
    verification: 'Verified',
    skill: '0',
    tests: '0',
    eDocs: '0',
    emailLoged: '2024-06-20T11:22:05.230Z',
    quickApplied: '2024-06-20T11:22:05.230Z',
    inviteSent: '2024-06-20T11:22:05.230Z',
    accSet: '2024-06-20T11:22:05.230Z',
    applexp: '2024-06-20T11:22:05.230Z',
    lastLogin: '2024-06-20T11:22:05.230Z',
    crated: '2024-06-20T11:22:05.230Z',
    available: '2024-06-20T11:22:05.230Z',
    modified: '2024-06-20T11:22:05.230Z',
    lastJournal: '1 day ago',
    workType: 'Local Contract',
  },
]

const Provider = () => {
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.providers'),
      })
    )
  }, [dispatch])

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div className="option-wrapper">
              <p className="flex items-center gap-2">
                <FileOutlineIcon />
                <span className="activity-text font-normal text-neutral-800">{t('button.viewCredential')}</span>
              </p>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <p className="flex items-center gap-2">
                <HistoryIcon />
                <span className="activity-text font-normal text-neutral-800">{t('button.workHistory')}</span>
              </p>
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div className="option-wrapper">
              <p className="flex items-center gap-2">
                <ChatsIcon />
                <span className="activity-text font-normal text-neutral-800">{t('button.chats')}</span>
              </p>
            </div>
          ),
        },
        {
          key: '4',
          label: (
            <div className="option-wrapper">
              <div className="cursor-pointer w-full" key="3">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch checked={record.status === 'active'} />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '5',
          label: (
            <div className="option-wrapper">
              <p className="flex items-center gap-2">
                <BanIcon />
                <span className="activity-text font-normal text-error-500">{t('button.terminate')}</span>
              </p>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.provider.provider'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 250,
      fixed: 'left',
      render: (_value, record) => {
        return (
          <div className="flex justify-between items-center min-w-250 gap-4">
            <TableImage name={record.name} image={undefined} email="" />
            <div className="action-cell flex items-center justify-center user-Icon">
              <CalenderIcon className="cursor-pointer " />
            </div>
          </div>
        )
      },
    },
    {
      title: t('table.provider.email'),
      dataIndex: 'email',
      key: 'email',
      align: 'start',
      width: 250,
    },
    {
      title: t('table.provider.phone'),
      dataIndex: 'mobile_no',
      key: 'mobile_no',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 136,
      render: (_value, record) => {
        return (
          <div className="flex items-center gap-1 justify-center w-max">
            <HeadingText text={record.country_code} classString="text-neutral-800 para-p3 font-normal " />
            <HeadingText text={record.mobile_no} classString="text-neutral-800 para-p3 font-normal " />
          </div>
        )
      },
    },
    {
      title: t('table.provider.city'),
      dataIndex: 'city',
      key: 'city',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 116,
      render: (_value, record) => {
        return (
          <div className="flex items-center justify-center w-max">
            <HeadingText text={record.city} classString="text-neutral-800 para-p3 font-normal " />
          </div>
        )
      },
    },
    {
      title: t('table.provider.state'),
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      width: 72,
    },
    {
      title: t('table.provider.zipCode'),
      dataIndex: 'zipCode',
      key: 'zipCode',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 118,
      render: (_value, record) => {
        return (
          <div className="flex items-start justify-start w-120 pl-5">
            <HeadingText text={record.zipCode} classString="text-neutral-800 para-p3 font-normal " />
          </div>
        )
      },
    },
    {
      title: t('table.provider.license'),
      dataIndex: 'license',
      key: 'license',
      align: 'center',
      width: 88,
    },
    {
      title: t('table.provider.speciality'),
      dataIndex: 'speciality',
      key: 'speciality',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 138,
    },
    {
      title: t('table.provider.status'),
      dataIndex: 'status',
      key: 'center',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 176,
    },
    {
      title: t('table.provider.application'),
      dataIndex: 'application',
      key: 'application',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 176,
      render: (_value, record) => {
        return (
          <div className="flex flex-col items-center justify-center w-150 gap-1">
            <HeadingText text={record.application} classString="text-neutral-800 para-p3 font-normal " />
            <HeadingText text={t('button.view')} classString="text-neutral-800 para-p3 font-small text-decoration-line " />
          </div>
        )
      },
    },
    {
      title: t('table.provider.verification'),
      dataIndex: 'verification',
      key: 'verification',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 176,
      render: (_value, record) => {
        return (
          <div className="flex items-center justify-center w-120">
            <HeadingText text={record.verification} classString="text-neutral-800 para-p3 font-normal " />
          </div>
        )
      },
    },
    {
      title: t('table.provider.skill'),
      dataIndex: 'skill',
      key: 'skill',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 100,
    },
    {
      title: t('table.provider.tests'),
      dataIndex: 'tests',
      key: 'tests',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 100,
    },
    {
      title: t('table.provider.eDocs'),
      dataIndex: 'eDocs',
      key: 'eDocs',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 100,
    },
    {
      title: t('table.provider.emailLoged'),
      dataIndex: 'emailLoged',
      key: 'emailLoged',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.emailLoged).format('MM/DD/YYYY')
        const formattedTime = dayjs(record?.emailLoged).format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
            <HeadingText classString="text-neutral-600 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.quickApplied'),
      dataIndex: 'quickApplied',
      key: 'quickApplied',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.quickApplied).format('MM/DD/YYYY')
        const formattedTime = dayjs(record?.quickApplied).format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
            <HeadingText classString="text-neutral-600 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.inviteSent'),
      dataIndex: 'inviteSent',
      key: 'inviteSent',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.inviteSent).format('MM/DD/YYYY')
        const formattedTime = dayjs(record?.inviteSent).format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
            <HeadingText classString="text-neutral-600 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.accSet'),
      dataIndex: 'accSet',
      key: 'accSet',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.accSet).format('MM/DD/YYYY')
        const formattedTime = dayjs(record?.accSet).format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
            <HeadingText classString="text-neutral-600 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.applexp'),
      dataIndex: 'applexp',
      key: 'applexp',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.applexp).format('MM/DD/YYYY')
        const formattedTime = dayjs(record?.applexp).format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
            <HeadingText classString="text-neutral-600 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.workerExp'),
      dataIndex: 'workerExp',
      key: 'workerExp',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
    },
    {
      title: t('table.provider.lastLogin'),
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.lastLogin).format('MM/DD/YYYY')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.lastCalendarView'),
      dataIndex: 'lastCalendarView',
      key: 'lastCalendarView',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 190,
    },
    {
      title: t('table.provider.lastShiftReq'),
      dataIndex: 'lastShiftReq',
      key: 'lastShiftReq',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 190,
    },
    {
      title: t('table.provider.lastShiftSchedule'),
      dataIndex: 'lastShiftSchedule',
      key: 'lastShiftSchedule',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 190,
    },
    {
      title: t('table.provider.recruiter'),
      dataIndex: 'recruiter',
      key: 'recruiter',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'start',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex items-center w-max">
            <TableImage name={record.recruiter} image={undefined} email="" />
          </div>
        )
      },
    },
    {
      title: t('table.provider.staffingSpeciality'),
      dataIndex: 'staffingSpeciality',
      key: 'staffingSpeciality',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex items-center min-w-200">
            <TableImage name={record.staffingSpeciality} image={undefined} email="" />
          </div>
        )
      },
    },
    {
      title: t('table.provider.complianceManager'),
      dataIndex: 'complianceManager',
      key: 'complianceManager',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex items-center min-w-200 ">
            <TableImage name={record.complianceManager} image={undefined} email="" />
          </div>
        )
      },
    },
    {
      title: t('table.provider.referredBy'),
      dataIndex: 'referredBy',
      key: 'referredBy',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex items-center w-max">
            <TableImage name={record.referredBy} image={undefined} email="" />
          </div>
        )
      },
    },
    {
      title: t('table.provider.crated'),
      dataIndex: 'crated',
      key: 'crated',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 120,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.crated).format('MM/DD/YYYY')
        const formattedTime = dayjs(record?.crated).format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
            <HeadingText classString="text-neutral-600 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.modified'),
      dataIndex: 'modified',
      key: 'modified',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 120,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.modified).format('MM/DD/YYYY')
        const formattedTime = dayjs(record?.modified).format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
            <HeadingText classString="text-neutral-600 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.available'),
      dataIndex: 'available',
      key: 'available',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 120,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.available).format('MM/DD/YYYY')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.hired'),
      dataIndex: 'hired',
      key: 'hired',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 100,
    },
    {
      title: t('table.provider.reHired'),
      dataIndex: 'reHired',
      key: 'reHired',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
    },
    {
      title: t('table.provider.firstContact'),
      dataIndex: 'firstContact',
      key: 'firstContact',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 160,
    },
    {
      title: t('table.provider.firstWorked'),
      dataIndex: 'firstWorked',
      key: 'firstWorked',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 123,
    },
    {
      title: t('table.provider.lastPaid'),
      dataIndex: 'lastPaid',
      key: 'lastPaid',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 156,
    },
    {
      title: t('table.provider.lastJournal'),
      dataIndex: 'lastJournal',
      key: 'lastJournal',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 196,
      render: (_value, record) => {
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={record?.lastJournal} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.calenderUp'),
      dataIndex: 'calenderUp',
      key: 'calenderUp',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 176,
    },
    {
      title: t('table.provider.workType'),
      dataIndex: 'workType',
      key: 'workType',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 134,
      render: (_value, record) => {
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={record?.workType} />
          </span>
        )
      },
    },
    {
      title: t('table.provider.tempType'),
      dataIndex: 'tempType',
      key: 'tempType',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      width: 129,
    },
    {
      title: t('table.provider.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      fixed: 'right',
      width: 176,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const VerificationBtn = <CustomBtn text={t('button.verificationRequest')} type="primary" />

  const FilterOptionMenu = (
    <div className="w-full flex flex-col gap-8">
      <div className="flex flex-col gap-6px item-start w-full">
        <p className="para-p2 text-neutral-700 font-medium">{t('placeholder.userStatus')}</p>
        <FloatingLabelSelect label={t('placeholder.selectStatus')} options={[]} name="status" mainClass="filter-floating-select" />
      </div>
      <div className="flex flex-col gap-6px item-start w-full">
        <p className="para-p2 text-neutral-700 font-medium">{t('placeholder.certification')}</p>
        <FloatingLabelSelect label={t('placeholder.selectCerti')} options={[{ label: 'SCSFDF', value: 'dvhvdfvhf' }]} name="licence" mainClass="filter-floating-select" />
      </div>
      <div className="flex flex-col gap-6px item-start w-full">
        <p className="para-p2 text-neutral-700 font-medium">{t('placeholder.specialty')}</p>
        <FloatingLabelSelect label={t('placeholder.selectSpecialty')} options={[]} name="speciality" mainClass="filter-floating-select" />
      </div>
      <div className="flex flex-col gap-6px item-start w-full">
        <p className="para-p2 text-neutral-700 font-medium">{t('placeholder.lastActive')}</p>
        <FloatingLabelSelect label={t('placeholder.select')} options={[]} name="lastActive" mainClass="filter-floating-select" />
      </div>
      <div className="flex flex-col gap-6px item-start w-full">
        <p className="para-p2 text-neutral-700 font-medium">{t('placeholder.verification')}</p>
        <FloatingLabelSelect label={t('placeholder.select')} options={[]} name="verification" mainClass="filter-floating-select" />
      </div>
      <div className="flex flex-col gap-6px item-start w-full">
        <p className="para-p2 text-neutral-700 font-medium">{t('placeholder.columns')}</p>
        <DropDown Options={[]} placeholder={t('placeholder.hideShow')} name="hide_show" mainClass="show-hide-select" />
      </div>
      <div className="flex flex-col gap-6px item-start w-full">
        <p className="para-p2 text-neutral-700 font-medium">{t('placeholder.schedule')}</p>
        <DatePicker placeholder={t('placeholder.lastSchedule')} />
        <DatePicker placeholder={t('placeholder.nextSchedule')} />
      </div>
    </div>
  )

  return (
    <div className="flex px-10 flex-col items-start gap-6 self-stretch w-full">
      <CustomTable
        className="facility-table"
        column={columns}
        data={listData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        isSearch
        isFilter
        searchPlaceholder={t('placeholder.searchProvider')}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        name={t('label.entries')}
        tableButton={VerificationBtn}
        filterOption={FilterOptionMenu}
        // setModalOpen={handleModalOpen}
      />
    </div>
  )
}

export default Provider
