export const INTERNAL_SERVER_ERROR = 500
export const UNAUTHORIZED_REQUEST = 401
export const DEFAULT_SOURCE_SECTION = 'ba01ca5f-2534-4ec2-93b2-aae83e910013'
export const CHECKED_VIEW = 'f8704615-c2ed-4647-aff1-7513d82e8ed0'
export const pathsWithSpecialStyles = [
  '/settings/compentency-test/add-test',
  '/settings/compentency-test/edit-test',
  '/settings/compentency-test/quiz-faq',
  '/settings/skill-checklist/add',
  '/settings/skill-checklist/edit',
  '/settings/compentency-test/preview',
  '/settings/compentency-test/add-test/preview',
  '/settings/compentency-test/edit-test/preview',
  '/settings/skill-checklist/add/preview',
  '/settings/skill-checklist/add/preview/subtopic',
  '/settings/skill-checklist/edit/preview',
  '/settings/skill-checklist/edit/preview/subtopic',
  '/settings/referance-form',
  '/settings/edit-form',
]

export const documentEssentialSub = 'This document will be essential to upload when provider is onboarding.'

export const credientialData = [
  {
    id: '1',
    name: 'Professional Licences',
    table: [
      {
        id: '11',
        credential: 'Nursing License - RN',
        certification: 'RN',
        essesential: 'Yes',
        name: 'Nursing License - RN',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '12',
        credential: 'CNA Certificate',
        certification: 'CNA',
        essesential: 'Yes',
        name: 'CNA Certificate',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '13',
        credential: 'LVN Certificate',
        certification: 'LVN',
        essesential: 'Yes',
        name: 'LVN Certificate',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
    ],
  },
  {
    id: '2',
    name: 'Medical Documents',
    table: [
      {
        id: '21',
        credential: 'Drug Screen',
        certification: 'CNA,CMA,RN',
        essesential: '-',
        name: 'LVN Certificate',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '22',
        credential: 'TB Test',
        certification: 'ALL',
        essesential: '-',
        name: 'LVN Certificate',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '23',
        credential: 'Covid-19 Vaccine Card',
        certification: 'ALL',
        essesential: '-',
        name: 'LVN Certificate',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
    ],
  },
  {
    id: '3',
    name: 'HR Documents',
    table: [
      {
        id: '31',
        credential: 'Experience form',
        certification: 'ALL',
        essesential: '-',
        name: 'Experience form',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '32',
        credential: 'Background Report',
        certification: 'ALL',
        essesential: '-',
        name: 'Background Report',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '33',
        credential: 'Fingerprint Background',
        certification: 'ALL',
        essesential: '-',
        name: 'Fingerprint Background',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
    ],
  },
  {
    id: '4',
    name: 'Education Documents',
    table: [
      {
        id: '41',
        credential: 'Nursing License - RN',
        certification: 'RN',
        essesential: 'Yes',
        name: 'Nursing License - RN',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '42',
        credential: 'CNA Certificate',
        certification: 'CNA',
        essesential: 'Yes',
        name: 'CNA Certificate',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
      {
        id: '43',
        credential: 'LVN Certificate',
        certification: 'LVN',
        essesential: 'Yes',
        name: 'LVN Certificate',
        documentEssential: true,
        groupCheckbox: ['expiration_date', 'documents'],
      },
    ],
  },
]

export const credientialRequirement = [
  {
    id: '1',
    name: 'BACKGROUND CHECK / OIG',
    table: [
      {
        id: '1',
        certification: 'All CNA',
        credential: 'BACKGROUND CHECK / OIG',
        state: 'GA',
        employement: 'Yes',
        employmentType: 'Employee',
        submission: 'None',
        pdOrder: 'Warn',
        ltOrder: 'Refuse',
        autoAssign: 'Application Start',
        workForcePortal: 'None',
        created_at: '09/23/2011 12:56 PM',
        updated_at: '05/02/2023 12:46 PM',
      },
    ],
  },
]

export const agencyEmployeeOption = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
]

export const emplyoeeTypeOption = [
  {
    label: 'Both',
    value: 'Both',
  },
  {
    label: 'Employee',
    value: 'Employee',
  },
  {
    label: 'Subcontractor',
    value: 'Subcontractor',
  },
]

export const autoAssignOption = [
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Application Start',
    value: 'Application Start',
  },
  {
    label: 'New Hire',
    value: 'New Hire',
  },
]

export const validationuponOption = [
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Warn',
    value: 'Warn',
  },
  {
    label: 'Refuse',
    value: 'Refuse',
  },
]

export const checkboxOptions = [
  { label: 'Expiration Date', value: 'expiration_date' },
  { label: 'Requirements Completed Date', value: 'requirements_completed_date' },
  { label: 'Documents', value: 'documents' },
  { label: 'Verification', value: 'verification' },
]

export const filterOption = [
  { filterName: 'Certifications' },
  { filterName: 'Specialties' },
  { filterName: 'Credential Category' },
  { filterName: 'Agency Employment' },
  { filterName: 'Auto Assign' },
  { filterName: 'WFP Enforcement' },
]

export const filterSubMenu: Record<
  string,
  {
    name: string[]
  }
> = {
  'Credential Category': {
    name: [
      'No Category Selection',
      'All Credential Categories',
      'CPR Level',
      'Certification',
      'HR',
      'Competency Tests',
      'Skill Checklists',
      'Employment Documents',
      'Surveys',
      'Mobile Skills',
      'Mobile Tests',
      'Mobile Edocs',
    ],
  },
  'Agency Employment': {
    name: ['Either', 'Yes', 'No'],
  },
  'Auto Assign': {
    name: ['Application Start', 'New Hire', 'None'],
  },
  'WFP Enforcement': {
    name: ['All', 'None', 'New Hire', 'Applicant', 'Worker', 'Both'],
  },
}

export const provider = 'provider'

export const facility = 'facility'

export const LINK_EXPIRY = {
  LINK_EXPIRE: 'The link is expired!',
  SORRY_TEXT: 'Sorry, this link is either expired or is used once.',
  LOGIN: 'Go To Login',
}

export const verifyFacility = {
  TYPE: 'Type',
  BEDS: 'Beds',
  EMAIL: 'Email',
  PHONE: 'Phone',
  ADDRESS: 'Address',
  BASIC_DETAIL: 'Basic Details',
  BASIC_NEXT: 'Next: Infrastructure Details',
  CREATE_FACILITY_AS: 'Creating Facility as',
  FACILITY_CLIENT: 'Facility Client',
  MASTER_CLIENT: 'Master Client',
  INFRASTRUCTURE_DETAIL: 'Infrastructure Details',
  NEXT_ADDRESS: 'Next: Address Details',
  ADDRESS_DETAIL: 'Address Details',
  FLOOR_DETAILS: 'Floor Details',
  GENERAL_INSTRUCTION: 'General Instructions',
  NEXT_GENERAL_SETTINGS: 'Next: General Settings',
  GENERAL_SETTINGS: 'General Settings',
  DEFAULT_ORDER_TYPE: 'Default Order Type',
  NEXT_FLOOR: 'Next: Floor Settings',
  FLOOR: 'Floor Settings',
  NEXT_SHIFT: 'Next: Shift Options',
  SHIFT: 'Shift Options',
  NEXT_TIME_ENTRY: 'Next: Time Entry & Approval',
  TIME_ENTRY: 'Time Entry & Approval',
  NEXT_FACILITY: 'Next: Facility Portal Settings',
  FACILITY: 'Facility Portal Settings',
  NEXT_INVOICE: 'Next: Invoice Settings',
  INVOICE: 'Invoice Settings',
  PREVIOUS: 'Previous',
}

export const settingTab = {
  SYSTEM_DEFAULT: 'System Default',
  PER_DIEM: 'Per Diem',
  LONG_TERM_CONT: 'Long Term Contract',
  TRAVEL_ASSIGN: 'Travel Assignments',
  OTHER_SETTINGS: 'Other Settings',
  WORK_COME_CODE: 'Work Comp Code',
  OR: 'OR',
  CLIENT_SPECIAL_FLOOR: 'Client Specific Floor Options',
  ASSOCIATE_WITH_SPEC: 'Associate floors with specialties',
  ASSOCIATE_WITH_SPEC_SUB:
    'Check this box if you want specialties below to associate with the appropriate Floor on the Order form. If you do not check this box, all floors below will show for any specialties that you select on the order form.',
  INVOICE_SHOULD_BE_PAID: 'Invoice Should Be Paid Within',
  DAYS: 'Days',
  ALLOW_CANCEL: 'Allow Cancellations',
  CANCELATION_ADVA: 'Minimum Cancellation Advance',
  HOURS: 'Hours',
  DISPLAY_PROVIDER_REQUEST: 'Display Provider Requests',
  ACCEPT_PROVIDER_REQUEST: 'Accept Provider Requests',
  ALLOWS_SCHEDULING_WARNING: 'Allowed Scheduling Warnings For Approval',
  SET_CLIENT_CONFO: 'Set Client Confirmation when Accepting Requests',
  DISPLAY_BILL_RATE: 'Display Bill Rate',
  SYSTEM_OPTION: 'System Options',
  CLIENT_SELECTED: 'Client Selected Shifts',
  TIME_CARD_SETTING: 'Timecard Settings',
  MINUTE_ROUNDING: 'Time Card Minute Rounding',
  INCREMENT: 'Increment',
  DIRECTION: 'Direction',
  USE_SYSTEM_SETTING: 'Use System Settings',
  WORKFORCE_PORTAL: 'Workforce Portal Time Entry Settings',
  CLIENT_CLOCKING_DATA: 'Client Clocking Data',
  SOURCE: 'Source',
  TIME_APPROVAL: 'Time Approval Method',
  ALLOWED_ENTRY_TYPE: 'Allowed Entry Types',
  CHECKED_MISSED_MEAL_FOR: 'Check for missed meal break',
  CLOCK_IN: 'Enforce Geofence When Clocking In & Out',
  GEOFENCE_TIME_APPROVAL: 'Enforce Geofence for Time Approval',
  DEFAULT_RADIUS: 'Default Geofence Radius',
  MAP_LOCATION: 'Client Map Location',
  LOCATION_DESCRIPTION: '5050 Kimball Bridge Rd Johns Creek GA 30005',
}

export const schedulingWarning = [
  { name: 'Compliance', checked: true },
  { name: 'Orientation', checked: true },
  { name: 'Double Booking', checked: true },
  { name: 'Overtime', checked: false },
  { name: 'Double Shift', checked: false },
  { name: 'Scheduling', checked: false },
  { name: 'Weekend Threshold', checked: false },
]

export const allowedEntryType = [
  { name: 'Regular', checked: true },
  { name: 'Meal break', checked: false },
  { name: 'Other break', checked: true },
  { name: 'Call back', checked: false },
  { name: 'On call', checked: false },
]

export const certificateBg = [
  { color: '#1517D1', background: '#ECEDFD' },
  { color: '#220EB1', background: '#F2F0FE' },
  { color: '#FF6C2D', background: '#FFEAE0' },
  { color: '#FB8600', background: '#FFEFDC' },
  { color: '#510E9A', background: '#F7F1FE' },
]

export const durationType = [
  {
    value: 'day',
    label: 'Days',
  },
  {
    value: 'week',
    label: 'Weeks',
  },
  {
    value: 'month',
    label: 'Months',
  },
  {
    value: 'year',
    label: 'Years',
  },
]

export const DELETE_MODAL_COMPLIANCE_MANAGER_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Compliance Settings?',
  DELETE_DESC: 'Are you sure you want to delete this compliance settings',
}

export const DELETE_MODAL_LICENSE_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'License Settings?',
  DELETE_DESC: 'Are you sure you want to delete this license',
}

export const DELETE_MODAL_SPECIALTIES_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Specialties Settings?',
  DELETE_DESC: 'Are you sure you want to delete this specialty',
}

export const DELETE_MODAL_ROLE_MANAGEMENT_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Role Management Settings?',
  DELETE_DESC: 'Are you sure you want to delete this role',
}

export const DELETE_FACILITY_VERIFICATION_REJECTION_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Facility Verification Rejection Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this facility verification rejection reason',
}

export const DELETE_PROVIDER_VERIFICATION_REJECTION_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Provider Verification Rejection Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this provider verification rejection reason',
}

export const DELETE_SHIFT_TYPE = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Shift Type?',
  DELETE_DESC: 'Are you sure you want to delete this shift type',
}

export const DELETE_SHIFT_TIMINGS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Shift Timings?',
  DELETE_DESC: 'Are you sure you want to delete this shift timing',
}

export const DELETE_REJECTED_TIMECARD_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Reject Timecard Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this reject timecard reason',
}

export const DELETE_COMPETENCY_TEST_SETTINGS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Competency Test Settings?',
  DELETE_DESC: 'Are you sure you want to delete this competency test settings',
}

export const DELETE_SKILLS_CHECKLIST_SETTINGS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Skills Checklist Settings?',
  DELETE_DESC: 'Are you sure you want to delete this skills checklist settings',
}

export const DELETE_SHIFT_CANCEL_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Shift Cancel Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this shift cancel reason',
}

export const DELETE_MODAL_REFERENCE_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Reference Form Settings?',
  DELETE_DESC: 'Are you sure you want to delete this reference form settings',
}

export const DELETE_MODAL_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Delete Line of Business?',
  DELETE_DESC: 'Are you sure you want to delete this line of business',
}

export const BUTTON = {
  UPDATE: 'Update',
  CREATE: 'Create',
  SAVE: 'Save',
  CREATE_STATUS: 'Create Status',
  ADD_REASON: 'Add Reason',
}

export const LOB = {
  CREATE: 'Add Line Of Business',
  UPDATE: 'Update Line Of Business',
}
