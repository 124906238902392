import React from 'react'
import { Button, Modal } from 'antd'

import './DeleteCancelModal.scss'

interface IDeleteModal {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  mainHead?: string
  subHead?: string
  cancelText: string
  okClick: () => void
  okText: string
  deleteName?: string
}

const DeleteCancelModal = ({ isModalOpen, setIsModalOpen, mainHead, subHead, cancelText, okClick, okText, deleteName }: IDeleteModal) => {
  return (
    <Modal
      open={isModalOpen}
      centered
      maskClosable
      onCancel={() => {
        setIsModalOpen(false)
      }}
      closable={false}
      closeIcon={null}
      footer={null}
      className="delete-modal"
    >
      <p className="font-semi-bold text-neutral-800 mb-6 border-b-stroke px-6 py-4 delete-heading">{mainHead}</p>
      <p className="font-medium para-p1 text-neutral-700 mb-6 px-6">
        {subHead}
        {deleteName && `“${deleteName}”`} {subHead ? '?' : ''}
      </p>

      <div className="flex justify-end w-full gap-4 px-6">
        <Button
          onClick={() => {
            setIsModalOpen(false)
          }}
          className="common-btn cancel-button"
        >
          {cancelText}
        </Button>
        <Button onClick={okClick} type="primary" className="common-btn delete-btn">
          {okText}
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteCancelModal
