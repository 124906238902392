import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { HomeIcon, SeperatorBreadcrumbs } from 'assets/svgs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectBreadcrumbData, setBreadcrumbMap } from 'store/slice/BreadcrumbSlice'
import { resolveBreadcrumbLabel } from 'utils/Helper'

const breadcrumbAbout = {
  '/': 'Home',
  '/settings': 'Settings',
  '/settings/compentency-test': 'Competency Test Settings',
  '/settings/compentency-test/quiz-faq': 'Test FAQs',
  '/settings/skill-checklist': 'Skill Checklist',
  '/settings/compentency-test/add-test': 'Create Test',
  '/settings/compentency-test/edit-test': 'Edit Test',
  '/settings/compentency-test/preview': 'Preview Test',
  '/settings/compentency-test/add-test/preview': 'Preview Test',
  '/settings/compentency-test/edit-test/preview': 'Preview Test',
  '/settings/skill-checklist/add': 'Create Checklist',
  '/settings/skill-checklist/edit': 'Edit Checklist',
  '/settings/shift-reject-reason': 'Shift Reject Reason',
  '/settings/provider-profile': 'Provider Profile Settings',
  '/settings/general-settings': 'General Settings',
  '/settings/facility-general-settings': 'Facility General Settings',
  '/settings/shifts-settings': 'Shift Settings',
  '/settings/facility-provider-setting': 'Facility Provider Settings',
  '/settings/time-and-attendance': 'Reject Timecard Reason',
  '/settings/document-categories': 'Document Settings',
  '/settings/line-of-business': 'Line of Business',
  '/settings/license': 'License',
  '/settings/specialties': 'Specialties',
  '/settings/shift-type': 'Shift Type',
  '/settings/provider-verification-rejection': 'Provider Verification Rejection',
  '/settings/facility-verification-rejection': 'Facility Verification Rejection Reason',
  '/settings/compliance-manager': 'Compliance Manager',
  '/settings/user': 'User',
  '/settings/user/role-management': 'Role Management',
  '/settings/user/role-management/create-role': 'Create New Role',
  '/settings/user/role-management/edit-role': 'Edit Role',
  '/settings/auto-schedule': 'Auto Schedule Settings',
  '/facilities': 'Facilities',
  '/my-profile': 'Profile',
  '/settings/skill-checklist/add/preview': 'Preview',
  '/settings/skill-checklist/add/preview/subtopic': 'Assessment/Patient Care',
  '/settings/skill-checklist/edit/preview': 'Preview',
  '/settings/skill-checklist/edit/preview/subtopic': 'Assessment/Patient Care',
  '/settings/skill-checklist/preview': 'Preview',
  '/settings/skill-checklist/preview/subtopic': 'Assessment/Patient Care',
  '/settings/referance-form-setting': 'Reference Form Settings',
  '/settings/referance-form-setting/add-form': 'Create New Form',
  '/settings/referance-form-setting/edit-form': 'Edit Form',
  '/settings/referance-form-setting/referance-form-preview': 'Referance form preview',
  '/settings/referance-form-setting/add-form/referance-form-preview': 'Referance form preview',
  '/settings/referance-form-setting/edit-form/referance-form-preview': 'Referance form preview',
}

const Breadcrumbs: React.FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const breadcrumbMap = useAppSelector(selectBreadcrumbData)

  useEffect(() => {
    dispatch(setBreadcrumbMap(breadcrumbAbout))
  }, [dispatch])

  const pathSnippets = location.pathname.split('/').filter((i) => i)

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      if (!breadcrumbMap[url]) return null

      const { url: resolvedUrl, label } = resolveBreadcrumbLabel(url, location, breadcrumbMap)

      return (
        <Breadcrumb.Item key={resolvedUrl}>
          <Link to={resolvedUrl}>{label}</Link>
        </Breadcrumb.Item>
      )
    })
    .filter((item): item is React.ReactElement => item !== null) // Filter out null values

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeIcon />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems)

  return (
    <Breadcrumb
      className="mt-4 mb-4"
      separator={
        <div className="flex justify-center items-center h-full">
          <SeperatorBreadcrumbs />
        </div>
      }
    >
      {breadcrumbItems}
    </Breadcrumb>
  )
}

export default Breadcrumbs
