import { useState } from 'react'
import { Form } from 'antd'
import { commonInputFieldValidation, commonSingleSelectValidation, mobileValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

const customData = [{ label: 'ICU', value: 'ICU' }]
const AddFloor = ({ isModalOpen }: any) => {
  const [error, setError] = useState({
    floor_name: '',
    beds: '',
    order_contact: '',
    speciality: '',
    billToClient: '',
    po: '',
    costCenter: '',
    phone: '',
  })
  const [form] = Form.useForm()

  const handelCancelClick = () => {
    isModalOpen(false)
  }

  const onFinish = (values: any) => {
    console.log(values)
    isModalOpen(false)
  }

  return (
    <Form
      className="add-floor-wrapper"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="grid grid-cols-2 gap-4 w-full ">
        <div className="flex flex-col w-full">
          <InputField
            placeholder={t('placeholder.floorName')}
            name="floor_name"
            wrapperClass="w-full"
            error={error.floor_name}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.FLOOR_NAME)}
          />
        </div>
        <div className="flex flex-col w-full">
          <InputField placeholder={t('placeholder.beds')} name="beds" wrapperClass="w-full" error={error.beds} rules={commonInputFieldValidation(VALIDATION_STRINGS.BEDS)} />
        </div>
      </div>
      <div className={`grid grid-cols-2 gap-4 w-full ${error.speciality || error.order_contact ? '' : 'mb-6'}`}>
        <div className="flex flex-col w-full">
          <FloatingLabelSelect
            label={t('placeholder.speciality')}
            options={customData}
            name="speciality"
            error={error.speciality}
            rules={commonSingleSelectValidation('speciality')}
          />
        </div>
        <div className="flex flex-col w-full">
          <FloatingLabelSelect
            label={t('placeholder.orderContact')}
            options={customData}
            name="order_contact"
            error={error.order_contact}
            rules={commonSingleSelectValidation('contact order')}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex flex-col w-full">
          <FloatingLabelSelect
            label={t('placeholder.billToClient')}
            options={customData}
            name="billToClient"
            error={error.billToClient}
            rules={commonSingleSelectValidation('client bill')}
          />
        </div>
        <div className="flex flex-col w-full">
          <InputField placeholder={t('placeholder.po')} name="po" wrapperClass="w-full" error={error.po} rules={commonInputFieldValidation(VALIDATION_STRINGS.PO)} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full ">
        <div className="flex flex-col w-full">
          <InputField
            placeholder={t('placeholder.costCenter')}
            name="costCenter"
            wrapperClass="w-full"
            error={error.costCenter}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.COST_CENTER)}
          />
        </div>
        <div className="flex flex-col w-full">
          <InputField placeholder={t('placeholder.phone')} name="phone" wrapperClass="w-full" error={error.phone} rules={mobileValidation} />
        </div>
      </div>
      <CommonTextArea name="description" placeholder={t('placeholder.description')} rows={4} />
      <div className="flex  w-full justify-end items-center gap-4">
        <CustomBtn text={t('button.cancel')} type="default" className="w-160 rounded-3" onClick={handelCancelClick} />
        <CustomBtn text={t('button.save')} type="primary" htmlType="submit" className="w-160 border-0 rounded-3" />
      </div>
    </Form>
  )
}

export default AddFloor
