import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dropdown, type MenuProps, message } from 'antd'
import type { SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { CloseIcon, DatePickerIcon, EditPenIcon, PlusIcon, RefreshIcon, RequestIcon, ThreeDotIcon, TimeCardIcon } from 'assets/svgs'
import { BUTTON_STRING, COMMON, SHIFTS } from 'constants/Constant'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useAppDispatch } from 'hooks/reduxHooks'
import { GET_CERTIFICATE, GET_SPECIALITY } from 'services/common/Dropdown'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFacility, type IShiftFilter } from 'types'
import { formatTitle } from 'utils/Helper'
import { formatQuerry } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import CustomDatePickerField from 'components/common/DatePicker/CustomDatePicker'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import CustomMultiSelect from 'components/common/Select/CustomMultiSelect'
import CustomSelect from 'components/common/Select/CustomSelect'
import CustomTable from 'components/common/Table/CustomTable'

import { GET_SHIFTS } from '../../api'

import CancelShift from './CancelShift'
import CreateShift from './CreateShift'
import DetailShift from './DetailShift'

dayjs.extend(customParseFormat)

const Shifts = () => {
  const [isCancel, setIsCancel] = useState<boolean>(false)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [shiftData, setShiftData] = useState([])
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [isCreateShiftModalOpen, setIsCreateShiftModalOpen] = useState<boolean>(false)
  const [rowId, setRowId] = useState<string>('')
  const [tabId, setTabId] = useState<string>('')
  const [limit, setLimit] = useState(10)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isOpenCancelModal, setIsCancelModal] = useState<boolean>(false)
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  // eslint-disable-next-line
  const [selectedRowData, setSelectedRowData] = useState<any>()
  const { id: facilityId } = useParams()
  const [selectedValue, setSelectedValue] = useState<string | undefined>()
  const [search, setSearch] = useState<string>('')
  const dispatch = useAppDispatch()
  const [filterApply, setFilterApply] = useState(false)
  const [specialityData, setSpecialityData] = useState<IFacility[]>([])
  const [specialityOriginData, setSpecialityOriginData] = useState<IFacility[]>([])
  const [certificateData, setCertificateData] = useState<IFacility[]>([])
  const [certificateOriginData, setCertificateOriginData] = useState<IFacility[]>([])
  const initialFilters = {
    status: [],
    certificate: '',
    speciality: '',
    from_date: '',
    to_date: '',
    columns: ['shift_date', 'shift_time', 'license', 'specialties', 'status', 'request'],
    shift_id_from: '',
    shift_id_to: '',
  }
  const [filters, setFilters] = useState<IShiftFilter>({ ...initialFilters })
  useEffect(() => {
    setTotal(0)
    setCurrent(1)
    setLimit(10)
  }, [facilityId])

  useEffect(() => {
    void handleGetShiftData(current, limit, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line
  }, [current, limit, sortTable, facilityId, search, isCancel, !isCancel, isCreateShiftModalOpen, isDetailModalOpen, !isEdit])

  useEffect(() => {
    if (filterApply) {
      void handleGetShiftData(current, limit, sortTable?.order, sortTable?.field)
    }
    // eslint-disable-next-line
  }, [filterApply])

  const handleGetShiftData = async (page: number, pageSize: number, order: string, field: string) => {
    if (facilityId) {
      dispatch(setGlobalLoader(true))
      try {
        const params = {
          ...filters?.status?.reduce((acc: any, status: string, index) => {
            // eslint-disable-next-line
            acc[`status[${index}]`] = status
            return acc
          }, {}),
          [`certificate[0]`]: filters?.certificate,
          [`speciality[0]`]: filters?.speciality,
          from_date: filters?.from_date,
          to_date: filters?.to_date,
          shift_id_from: filters?.shift_id_from,
          shift_id_to: filters?.shift_id_to,
        }
        const query = formatQuerry({ ...params })
        const response = await GET_SHIFTS(facilityId, page, pageSize, search, order, field, query)
        if (response?.data?.statusCode === 1) {
          setShiftData(response?.data?.data)
          setTotal(response?.data?.total)
          setFilterApply(false)
        }
      } catch (error: any) {
        void message.error(error)
      }
      dispatch(setGlobalLoader(false))
    }
  }

  const handleCancelClick = (id: string) => {
    setIsCancelModal(true)
    setRowId(id)
  }

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const handleDetailModal = (id: string) => {
    setRowId(id)
    setIsDetailModalOpen(true)
  }

  const getMenu = (record: any): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: [SHIFT_STATUS.REQUESTED, SHIFT_STATUS.SCHEDULE, SHIFT_STATUS.UNSUBMITTED, SHIFT_STATUS.ON_GOING, SHIFT_STATUS.VOID].includes(record.status ?? '') && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setIsDetailModalOpen(true)
                setRowId(record?.id)
                setTabId('4')
              }}
            >
              <p className="flex items-center gap-2">
                <RequestIcon />{' '}
                <span className="mobile-heading-h6 font-normal text-neutral-800">
                  {[SHIFT_STATUS.UNSUBMITTED, SHIFT_STATUS.ON_GOING, SHIFT_STATUS.VOID].includes(record.status) ? SHIFTS.VIEW_DETAILS : SHIFTS.VIEW_REQUEST}
                </span>
              </p>
            </div>
          ),
        },
        {
          label: ![
            SHIFT_STATUS.OPEN,
            SHIFT_STATUS.REQUESTED,
            SHIFT_STATUS.UNCONFIRMED,
            SHIFT_STATUS.AUTO_SCHEDULING,
            SHIFT_STATUS.SCHEDULE,
            SHIFT_STATUS.UNSUBMITTED,
            SHIFT_STATUS.ON_GOING,
            SHIFT_STATUS.VOID,
          ].includes(record.status) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setRowId(record?.id)
                setIsCreateShiftModalOpen(true)
              }}
            >
              <p className="flex items-center gap-2">
                <RefreshIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.REPOST}</span>{' '}
              </p>
            </div>
          ),
          key: 2,
        },
        {
          label: record.status === SHIFT_STATUS.COMPLETED && (
            <div className="p-4 cursor-pointer w-full">
              <p className="flex items-center gap-2">
                <TimeCardIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.VIEW_TIMECARD}</span>
              </p>
            </div>
          ),
          key: 3,
        },
        {
          label: ![
            SHIFT_STATUS.CANCELLED,
            SHIFT_STATUS.COMPLETED,
            SHIFT_STATUS.UNCONFIRMED,
            SHIFT_STATUS.AUTO_SCHEDULING,
            SHIFT_STATUS.UNSUBMITTED,
            SHIFT_STATUS.ON_GOING,
            SHIFT_STATUS.VOID,
          ].includes(record.status) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setIsEdit(true)
                setIsDetailModalOpen(true)
                setRowId(record?.id)
              }}
            >
              <p className="flex items-center gap-2">
                <EditPenIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.EDIT_SHIFT}</span>
              </p>
            </div>
          ),
          key: 4,
        },
        {
          label: ![SHIFT_STATUS.CANCELLED, SHIFT_STATUS.COMPLETED, SHIFT_STATUS.UNSUBMITTED, SHIFT_STATUS.ON_GOING, SHIFT_STATUS.VOID].includes(record.status) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                handleCancelClick(record?.id)
              }}
            >
              <p className="flex items-center gap-2">
                <CloseIcon /> <span className="para-p1 font-normal text-neutral-800">{BUTTON_STRING.CANCEL_SHIFT}</span>
              </p>
            </div>
          ),
          key: 5,
        },
      ],
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setCurrent(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType = [
    {
      title: SHIFTS.TABLE.SHIFT_ID,
      dataIndex: 'shift_id',
      key: 's.shift_id',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
    },
    {
      title: SHIFTS.TABLE.SHIFT_DATE,
      dataIndex: 'start_date',
      key: 's.start_date',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      render: (_value, record) => {
        return <p>{dayjs(record?.start_date).format('MM/DD/YYYY')}</p>
      },
      hidden: !filters?.columns?.includes('shift_date'),
    },
    {
      title: SHIFTS.TABLE.SHIFT_TIME,
      dataIndex: 's.start_time',
      key: 's.start_time',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 350,
      className: 'center-align',
      render: (_value, record) => {
        // eslint-disable-next-line
        return <p className="status-text">{`${dayjs(record?.start_time, 'HH:mm:ss').format('h:mm A')} - ${dayjs(record?.end_time, 'HH:mm:ss').format('h:mm A')}`}</p>
      },
      hidden: !filters?.columns?.includes('shift_time'),
    },
    {
      title: SHIFTS.TABLE.LICENSE,
      dataIndex: 'c.name',
      key: 'c.name',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      hidden: !filters?.columns?.includes('license'),
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText
              classString="status"
              style={{ background: record?.certificate?.background_color, color: record?.certificate?.text_color }}
              text={record?.certificate?.name}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.SPECIALTIES,
      dataIndex: 'sp.name',
      key: 'sp.name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 200,
      className: 'center-align',
      hidden: !filters?.columns?.includes('specialties'),
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText style={{ background: record?.speciality?.background_color, color: record?.speciality?.text_color }} classString="status" text={record?.speciality?.name} />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.STATUS,
      dataIndex: 's.status',
      key: 's.status',
      align: 'center',
      width: 250,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      hidden: !filters?.columns?.includes('status'),
      render: (_value, record) => {
        return (
          <div className="justify-center flex">
            <HeadingText
              classString={`common-tags ${record.status === SHIFT_STATUS.OPEN ? 'tertiary' : record.status === SHIFT_STATUS.CANCELLED ? 'error' : record.status === SHIFT_STATUS.COMPLETED ? 'neutral-tag' : record.status === SHIFT_STATUS.SCHEDULE ? 'success' : record?.status === SHIFT_STATUS.VOID ? 'void' : 'warning'}`}
              text={formatTitle(record?.status)}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.NO_OF_REQUEST,
      dataIndex: 'total_requests',
      key: 'total_requests',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      align: 'center',
      width: 200,
      className: 'center-align',
      hidden: !filters?.columns?.includes('request'),
    },
    {
      title: COMMON.ACTION,
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 120,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <Dropdown
              overlayClassName="action-menu-dropdown"
              menu={getMenu(record)}
              trigger={['click']}
              onOpenChange={() => {
                setSelectedRowData(record)
              }}
            >
              <div className="three-dot-icon flex  border-action-menu action-cell">
                <ThreeDotIcon className="cursor-pointer svg" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]
  const handleCreateShift = () => {
    setIsCreateShiftModalOpen(true)
  }

  const tableButton = <CustomBtn isIcon svgIcon={<PlusIcon />} text={SHIFTS.CREATE_SHIFT} onClick={handleCreateShift} type="primary" className="rounded-3 border-0" />

  const tabData = [
    { value: 'per_diem', label: 'Per Diem' },
    { value: 'long_term', label: 'Long Term' },
  ]

  // Filters api and dropdown
  const getSpeciality = async () => {
    try {
      const response = await GET_SPECIALITY()
      if (response?.status === 200) {
        setSpecialityData(response?.data?.data)
        setSpecialityOriginData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getCertificate = async () => {
    try {
      const response = await GET_CERTIFICATE()
      if (response?.status === 200) {
        setCertificateData(response?.data?.data)
        setCertificateOriginData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  useEffect(() => {
    void getSpeciality()
    void getCertificate()
  }, [])

  const checkDateFilterValid = useMemo(() => {
    if (filters?.from_date && filters?.to_date && !dayjs(filters?.from_date)?.isAfter(dayjs(filters?.to_date))) {
      return true
    } else if (!filters?.from_date && !filters?.to_date) {
      return true
    } else {
      return false
    }
  }, [filters])

  const checkShiftIdFilterValid = useMemo(() => {
    if (filters?.shift_id_from && filters?.shift_id_to && Number(filters?.shift_id_from) < Number(filters?.shift_id_to)) {
      return true
    } else if (!filters?.shift_id_from && !filters?.shift_id_to) {
      return true
    } else {
      return false
    }
  }, [filters])

  const handleSearchLicense = (search: string) => {
    if (search !== '') {
      const filteredData = certificateData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setCertificateData(filteredData)
      return
    }
    setCertificateData(certificateOriginData)
  }

  const handleSearchSpeciality = (search: string) => {
    if (search !== '') {
      const filteredData = specialityData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setSpecialityData(filteredData)
      return
    }
    setSpecialityData(specialityOriginData)
  }

  const FilterOptionMenu = (
    <div className="w-full flex flex-col gap-8 mt-2">
      <div className="flex flex-col gap-6px item-start w-full">
        <CustomMultiSelect
          name="status"
          handleChange={(e: any) => {
            setFilters((prev) => ({ ...prev, status: e as string[] }))
          }}
          Options={[
            { value: 'open', name: 'Open' },
            { value: 'requested', name: 'Requested' },
            { value: 'scheduled', name: 'Scheduled' },
            { value: 'ongoing', name: 'Ongoing' },
            { value: 'running_late', name: 'Running Late' },
            { value: 'unconfirmed', name: 'Unconfirmed' },
            { value: 'cancelled', name: 'Cancelled' },
            { value: 'completed', name: 'Completed' },
            { value: 'void', name: 'Void' },
          ]}
          placeholder={COMMON.STATUS}
          value={filters?.status}
          updatedTag={filters?.status}
        />
        <CustomSelect
          label={COMMON.LICENSE}
          options={certificateData?.map((item: { name: string; id: string }) => ({
            label: item?.name,
            value: item?.id,
          }))}
          value={filters?.certificate}
          isSearch
          searchPlaceholder={COMMON.SEARCH_LICENCE}
          name="license"
          searchData={handleSearchLicense}
          allowClear={true}
          onChange={(value) => {
            setFilters((prev) => ({ ...prev, certificate: value }))
          }}
          wrapperClass="mt-4"
        />
        <CustomSelect
          label={COMMON.SPECIALITY}
          options={specialityData?.map((item: { name: string; id: string }) => ({
            label: item?.name,
            value: item?.id,
          }))}
          value={filters?.speciality}
          isSearch
          searchPlaceholder={COMMON.SEARCH_SPECIALITY}
          name="speciality"
          searchData={handleSearchSpeciality}
          onChange={(value) => {
            setFilters((prev) => ({ ...prev, speciality: value }))
          }}
          allowClear={true}
        />

        <CustomMultiSelect
          name="columns"
          handleChange={(e: any) => {
            setFilters((prev) => ({ ...prev, columns: e as string[] }))
          }}
          Options={[
            { name: 'Shift Date', value: 'shift_date' },
            { name: 'Shift Time', value: 'shift_time' },
            { name: 'License', value: 'license' },
            { name: 'Specialties', value: 'specialties' },
            { name: 'Status', value: 'status' },
            { name: 'Request', value: 'request' },
          ]}
          placeholder={COMMON.COLUMNS}
          updatedTag={filters?.columns}
          value={filters?.columns}
        />
        <InputField
          wrapperClass="mt-6"
          placeholder={COMMON.SHIFT_ID_FROM}
          name="shift_id_from"
          type="number"
          value={filters?.shift_id_from}
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, shift_id_from: e?.target?.value }))
          }}
          error={filters?.shift_id_to && !filters?.shift_id_from ? VALIDATION_ERROR.SHIFT_ID : ''}
          onKeyDown={(e) => {
            if (e?.key === 'e' || e.key === 'E') {
              e?.preventDefault()
            }
          }}
        />
        <InputField
          placeholder={COMMON.SHIFT_ID_TO}
          name="shift_id_to"
          type="number"
          value={filters?.shift_id_to}
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, shift_id_to: e?.target?.value }))
          }}
          onKeyDown={(e) => {
            if (e?.key === 'e' || e.key === 'E') {
              e?.preventDefault()
            }
          }}
          error={
            filters?.shift_id_from
              ? filters?.shift_id_to && !filters?.shift_id_from
                ? VALIDATION_ERROR.SHIFT_ID
                : !checkShiftIdFilterValid
                  ? VALIDATION_ERROR.VALID_SHIFT_ID
                  : ''
              : ''
          }
        />
        <CustomDatePickerField
          name="from_date"
          placeholder="From Date"
          icon={<DatePickerIcon />}
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, from_date: e ? dayjs(e)?.format('YYYY-MM-DD') : '' }))
          }}
          value={filters?.from_date}
          error={filters?.to_date && !filters?.from_date ? VALIDATION_ERROR.DATE : ''}
        />
        <CustomDatePickerField
          name="to_date"
          placeholder="To Date"
          icon={<DatePickerIcon />}
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, to_date: e ? dayjs(e)?.format('YYYY-MM-DD') : '' }))
          }}
          value={filters?.to_date}
          error={filters?.from_date ? (filters?.from_date && !filters?.to_date ? VALIDATION_ERROR.DATE : !checkDateFilterValid ? VALIDATION_ERROR.DATE_RANGE : '') : ''}
        />
      </div>
    </div>
  )

  return (
    <div>
      <CustomTable
        option={tabData}
        isSearch
        isFilter
        searchPlaceholder={COMMON.SEARCH_SHIFT}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        tableButton={tableButton}
        column={columns}
        setModalOpen={handleDetailModal}
        data={shiftData}
        total={total}
        current={current}
        limit={limit}
        onChangePage={handleChangePage}
        className="shift-table"
        wrapperClass="table-top-border-radius-0"
        onChange={handleTableChange}
        name={SHIFTS.SHIFTS}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        onSearch={onSearch}
        filterOption={FilterOptionMenu}
        handleResetFilter={() => {
          setFilters({ ...initialFilters })
          setFilterApply(true)
        }}
        handleApplyFilter={() => {
          setFilterApply(true)
        }}
        filterSelected={filters as any}
        isFiltersValid={checkDateFilterValid && checkShiftIdFilterValid}
      />

      {isDetailModalOpen && (
        <DetailShift
          setTabId={setTabId}
          tabId={tabId}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isCancel={isCancel}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          setIsCancel={setIsCancel}
          id={rowId}
          setRowId={setRowId}
          isDetailShiftOpen={isDetailModalOpen}
          setIsDetailShiftOpen={setIsDetailModalOpen}
        />
      )}
      {isOpenCancelModal && (
        <CustomModal
          onCancel={() => {
            setIsCancelModal(false)
          }}
          footer={null}
          closable={false}
          width={600}
          open={isOpenCancelModal}
          title={SHIFTS.CANCEL_SHIFT}
          content={<CancelShift id={rowId} isCancel={isCancel} setIsCancel={setIsCancel} setIsModalOpen={setIsCancelModal} />}
        />
      )}
      {isCreateShiftModalOpen && (
        <CreateShift rowId={rowId} setRowId={setRowId} isCreateShiftModalOpen={isCreateShiftModalOpen} setIsCreateShiftModalOpen={setIsCreateShiftModalOpen} />
      )}
    </div>
  )
}

export default Shifts
