import { useEffect } from 'react'
import { Form, message, Switch } from 'antd'
import { myFacilityText } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type FieldRowProps, type IFacilityProfileProps, type IFormField, type ISection } from 'types'

import { updateMyFacilitySettings } from '../api'

const FacilityProfile = ({ myFacilitySettingsData, setIsSettingUpdated }: IFacilityProfileProps) => {
  const dispatch = useAppDispatch()

  const myFacilityContactDetailsData = myFacilitySettingsData?.filter((it: ISection) => it.section === 'contact_details')?.[0]?.form_fields
  const myFacilityGeneralInstructionsData = myFacilitySettingsData?.filter((it: ISection) => it.section === 'general_instructions')?.[0]?.form_fields
  const myFacilityInfrastructureData = myFacilitySettingsData?.filter((it: ISection) => it.section === 'infrastructure')?.[0]?.form_fields

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: 'My Facility Settings',
      })
    )
  }, [dispatch])

  const dataMapping = {
    contact: myFacilityContactDetailsData,
    general: myFacilityGeneralInstructionsData,
    infrastructure: myFacilityInfrastructureData,
  }

  const getValuesForSwitch = (value: string, type: keyof typeof dataMapping) => {
    const data = dataMapping[type]
    return data?.find((item) => item.key === value)
  }

  const handleUpdateDisplayOnForm = async (text: string, type: keyof typeof dataMapping) => {
    dispatch(setGlobalLoader(true))
    const valuesToUpdate = getValuesForSwitch(text, type)
    if (valuesToUpdate) {
      const id = valuesToUpdate?.id
      const data = {
        status: valuesToUpdate?.status === 'active' ? 'in_active' : 'active',
      }
      const response = await updateMyFacilitySettings(id, data)

      if (response?.data.statusCode === 1) {
        void message.success(response?.data?.message)
        setIsSettingUpdated(true)
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const handleUpdateIsMandatory = async (text: string, type: keyof typeof dataMapping) => {
    dispatch(setGlobalLoader(true))
    const valuesToUpdate = getValuesForSwitch(text, type)
    if (valuesToUpdate?.status === 'active') {
      const id = valuesToUpdate?.id
      const data = {
        status: valuesToUpdate?.status,
        is_required: !valuesToUpdate?.is_required,
      }
      const response = await updateMyFacilitySettings(id, data)
      if (response?.data.statusCode === 1) {
        void message.success(response?.data?.message)
        setIsSettingUpdated(true)
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const FieldRow = ({ label, fieldKey, section, onUpdateDisplay, onUpdateMandatory, getValues }: FieldRowProps) => {
    const values = getValues(fieldKey, section)
    return (
      <div className="flex border-b-stroke">
        <div className="w-full flex p-4 items-center gap-2 justify-start aline-self flex-grow justify-start">
          <p className="text-neutral-800 para-p2 font-normal">{label}</p>
        </div>
        <div className="w-full flex p-4 items-center gap-2 justify-center aline-self flex-grow justify-center">
          <Switch
            onChange={() => {
              void onUpdateDisplay(fieldKey, section)
            }}
            checked={values?.status === 'active'}
          />
        </div>
        <div className="w-full flex p-4 items-center gap-2 justify-center aline-self flex-grow justify-center">
          <Switch
            onChange={() => {
              void onUpdateMandatory(fieldKey, section)
            }}
            checked={values?.is_required}
            disabled={values?.status !== 'active'}
          />
        </div>
      </div>
    )
  }

  return (
    <Form className="time-and-approval-container flex flex-col items-start flex-grow rounded-4 border-stroke  ">
      <div className="w-full flex justify-end border-b-stroke">
        <div className="my-facility-title-wrapper">
          <div className="field-name-wrapper w-33">
            <p className="provider-field-title ">{myFacilityText.FIELD_NAME}</p>
          </div>
          <div className="field-name-wrapper w-33 justify-center">
            <p className="provider-field-title">{myFacilityText.DISPLAY_ON_FORM}</p>
          </div>
          <div className="field-name-wrapper w-33 justify-center">
            <p className="provider-field-title">{myFacilityText.IS_FIELD_MANDATORY}</p>
          </div>
        </div>
      </div>
      <div className=" flex items-start aline-self flex-grow border-b-stroke w-full">
        <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30">
          <p className="text-neutral-800 activity-text font-medium">{myFacilityText.CONTACT_DETAILS}</p>
        </div>
        <div className="flex flex-col w-70">
          {myFacilityContactDetailsData?.map(({ name, key, section = 'contact' }: IFormField) => (
            <FieldRow
              key={key}
              label={name}
              fieldKey={key}
              section={section}
              onUpdateDisplay={handleUpdateDisplayOnForm}
              onUpdateMandatory={handleUpdateIsMandatory}
              getValues={getValuesForSwitch}
            />
          ))}
        </div>
      </div>
      <div className=" flex items-start aline-self flex-grow border-b-stroke w-full">
        <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30">
          <p className="text-neutral-800 activity-text font-medium">{myFacilityText.GENERAL_INSTRUCTIONS}</p>
        </div>
        <div className="flex flex-col w-70">
          {myFacilityGeneralInstructionsData?.map(({ name, key, section = 'general' }: IFormField) => (
            <FieldRow
              key={key}
              label={name}
              fieldKey={key}
              section={section}
              onUpdateDisplay={handleUpdateDisplayOnForm}
              onUpdateMandatory={handleUpdateIsMandatory}
              getValues={getValuesForSwitch}
            />
          ))}
        </div>
      </div>
      <div className=" flex items-start aline-self flex-grow w-full mb-2">
        <div className="flex p-4 items-center gap-2 justify-center aline-self flex-grow border-r-stroke w-30">
          <p className="text-neutral-800 activity-text font-medium">{myFacilityText.INFRASTRUCTURE}</p>
        </div>
        <div className="flex flex-col w-70">
          {myFacilityInfrastructureData?.map(({ name, key, section = 'infrastructure' }: IFormField) => (
            <FieldRow
              key={key}
              label={name}
              fieldKey={key}
              section={section}
              onUpdateDisplay={handleUpdateDisplayOnForm}
              onUpdateMandatory={handleUpdateIsMandatory}
              getValues={getValuesForSwitch}
            />
          ))}
        </div>
      </div>
    </Form>
  )
}

export default FacilityProfile
