import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { Form, message, Radio, type RadioChangeEvent, Switch } from 'antd'
import { FlagColorSelect } from 'assets/svgs'
import { FLAG_COLORS } from 'constants/Constant'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { addFlagStatus, editFlagSetting } from 'services/SettingServices/FacilityProviderService'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddFlagStatusPayload, type IFieldData } from 'types'
import { handleFieldsChange, removeSorting } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

export interface IModal {
  setIsFlagStatusOpen: Dispatch<SetStateAction<boolean>>
  setRefreshData: Dispatch<SetStateAction<boolean>>
  setSortTable?: React.Dispatch<React.SetStateAction<{ order: string; field: string }>>
  setEditData: Dispatch<SetStateAction<any>>
  editData: any
}

const AddFlagStatus = ({ setIsFlagStatusOpen, setRefreshData, setSortTable, setEditData, editData }: IModal) => {
  const [form] = Form.useForm()
  const [currentSelectedColor, setCurrentSelectedColor] = useState('')
  const [status, setStatus] = useState(true)
  const dispatch = useAppDispatch()
  const [error, setError] = useState({
    name: '',
  })

  const handleColorChange = (e: RadioChangeEvent) => {
    setCurrentSelectedColor(e.target.value)
  }

  useEffect(() => {
    const color = form.getFieldValue('color')
    if (color) setCurrentSelectedColor(color)
    // eslint-disable-next-line
  }, [form])

  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({ ...editData })
      setCurrentSelectedColor(editData?.color)
      setStatus(editData?.status === 'active')
    }
    // eslint-disable-next-line
  }, [editData])

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          ...values,
          status: status ? 'active' : 'in_active',
        }
        void handleCreateFlagStatus(payload)
      })
      .catch((errorInfo) => {
        console.log('Validate Failed:', errorInfo)
      })
  }

  const handleCreateFlagStatus = async (values: IAddFlagStatusPayload) => {
    try {
      dispatch(setGlobalLoader(true))
      const response = editData?.id ? await editFlagSetting(editData?.id, values) : await addFlagStatus(values)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        setIsFlagStatusOpen(false)
        setEditData(null)
        form.resetFields()
        if (!editData?.id) {
          setSortTable && removeSorting(setSortTable)
        } else {
          setRefreshData(true)
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setGlobalLoader(false))
    }
  }
  return (
    <Form
      className="add-provider-status-wrapper"
      form={form}
      initialValues={{ color: FLAG_COLORS[0] }}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <InputField
        error={error.name}
        disabled={editData?.is_default}
        placeholder={t('placeholder.statusName')}
        name="name"
        rules={commonInputFieldValidation(VALIDATION_STRINGS.FLAG_NAME)}
        delay={100}
      />
      <CommonTextArea disabled={editData?.is_default} name="description" placeholder={t('placeholder.desc')} rows={4} formItemClass="mb-0" />
      <div className="badge-color-wrapper">
        <p className="text-neutral-800 para-p2 font-medium">{t('label.flagColor')}</p>
        <div className="badge-wrapper">
          <Form.Item name="color">
            <Radio.Group className="badge-radio-button" onChange={handleColorChange}>
              {FLAG_COLORS?.map((color) => (
                <Radio value={color} key={color} style={{ background: color }}>
                  <div className="custom-radio flex items-center justify-center">{currentSelectedColor === color && <FlagColorSelect />}</div>
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <div className="add-provider-footer mt-6">
        <div className="flex gap-2 toggle-switch items-center">
          <span>{t('label.active')}</span>
          <Switch
            disabled={editData?.is_default}
            checked={status}
            onChange={(checked) => {
              setStatus(checked)
            }}
          />
        </div>
        <CustomBtn text={editData?.id ? t('button.edit') : t('button.add')} htmlType="submit" type="primary" className="w-120" onClick={handleSubmit} />
      </div>
    </Form>
  )
}

export default AddFlagStatus
