import { useState } from 'react'
import { Form, Progress } from 'antd'
import { CloseIcon } from 'assets/svgs'
import { verifyFacility } from 'constants/Constant'
import { commonInputFieldValidation, commonSingleSelectValidation, emailValidation, mobileValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import FacilityPortalSetting from '../facilityProfile/components/settings/components/FacilityPortalSetting'
import Floors from '../facilityProfile/components/settings/components/Floors'
import GeneralSettings from '../facilityProfile/components/settings/components/GeneralSettings'
import InvoiceSettings from '../facilityProfile/components/settings/components/InvoiceSettings'
import ShiftOptions from '../facilityProfile/components/settings/components/ShiftOptions'
import TimeAndApproval from '../facilityProfile/components/settings/components/TimeAndApproval'

const facilityData = [
  {
    value: 'Autumn Lane Health and Rehabilitation',
    label: 'Autumn Lane Health and Rehabilitation',
  },
]

const stateData = [
  {
    value: 'Gujarat',
    label: 'Gujarat',
  },
  {
    label: 'California',
    value: 'California',
  },
]
const countryData = [
  {
    label: 'India',
    value: 'India',
  },
  {
    label: 'USA',
    value: 'USA',
  },
]

const headTexts = [
  verifyFacility.BASIC_DETAIL,
  verifyFacility.INFRASTRUCTURE_DETAIL,
  verifyFacility.ADDRESS_DETAIL,
  verifyFacility.GENERAL_SETTINGS,
  verifyFacility.FLOOR,
  verifyFacility.SHIFT,
  verifyFacility.TIME_ENTRY,
  verifyFacility.FACILITY,
  verifyFacility.INVOICE,
]

const subText = [
  verifyFacility.BASIC_NEXT,
  verifyFacility.NEXT_ADDRESS,
  verifyFacility.NEXT_GENERAL_SETTINGS,
  verifyFacility.NEXT_FLOOR,
  verifyFacility.NEXT_SHIFT,
  verifyFacility.NEXT_TIME_ENTRY,
  verifyFacility.NEXT_FACILITY,
  verifyFacility.NEXT_INVOICE,
]

const AddFacility = ({ setIsOpen }: any) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [error, setError] = useState({
    facility: '',
    associate_with_master: '',
    facility_name: '',
    facility_email: '',
    phone: '',
    no_of_beds: '',
    facility_type: '',
    street_address: '',
    apartment: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
  })

  const [form] = Form.useForm()

  const handleCancelClick = () => {
    setIsOpen(false)
  }

  const handleBackClick = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleSkipClick = () => {
    setCurrentStep(currentStep + 1)
  }

  const onFinish = (values: any) => {
    const payload = {
      facility: values.facility,
      associate_with_master: values?.associate_with_master,
      facility_name: values?.facility_name,
      facility_email: values?.facility_email,
      phone: values?.phone,
      no_of_beds: values?.no_of_beds,
      facility_type: values?.facility_type,
      instruction_provider: values?.instruction_provider,
      street_address: values?.street_address,
      apartment: values?.apartment,
      zipCode: values?.zipCode,
      city: values?.city,
      state: values?.state,
      country: values?.country,
    }
    console.log(payload)
    // eslint-disable-next-line no-lone-blocks
    {
      currentStep !== 9 && setCurrentStep(currentStep + 1)
    }
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="add-facility-container flex w-full pb-10 flex-col items-center gap-10 rounded-4 bg-white">
      <div className="flex py-4 px-6 items-center w-full justify-between gap-6 aline-self border-b-stroke">
        <p className="text-neutral-800 mobile-heading-h4 font-semi-bold">Add New Facility</p>
        <div className="close-btn flex p-2 flex-col justify-center items-center rounded-2 box-shadow" onClick={handleCancelClick}>
          <CloseIcon />
        </div>
      </div>
      <div className="facility-detail-wrapper flex flex-col gap-10 w-full items-center ">
        <div className="flex w-full p-4 justify-between items-center rounded-2 bg-primary-50">
          <div className="flex flex-col items-start gap-4">
            <p className="tet-neutral-800 font-semi-bold mobile-heading-h2">{headTexts[currentStep - 1]}</p>
            {currentStep !== 9 && <p className="tet-neutral-600 para-p2  font-normal">{subText[currentStep - 1]}</p>}
          </div>
          <Progress type="circle" percent={(currentStep / 9) * 100} format={(percent) => `${currentStep}/9`} rootClassName="progress-bar-facility" />
        </div>
        <Form
          className="facility-form w-full items-center flex flex-col"
          form={form}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
        >
          {currentStep === 1 && (
            <>
              <FloatingLabelSelect
                label={t('placeholder.associateWithMaster')}
                options={facilityData}
                name="associate_with_master"
                rules={commonSingleSelectValidation(VALIDATION_STRINGS.ASSOSIATE_MASTER)}
                error={error.associate_with_master}
                value={getValue('associate_with_master')}
              />
              <div className="flex flex-col w-full">
                <InputField
                  placeholder={t('placeholder.nameOfFacility')}
                  name="facility_name"
                  wrapperClass="w-full"
                  error={error.facility_name}
                  rules={commonInputFieldValidation(VALIDATION_STRINGS.FACILITY_NAME)}
                  value={getValue('facility_name')}
                />
              </div>
              <div className="grid grid-cols-2 gap-6 w-full">
                <div className="flex flex-col w-full">
                  <InputField
                    placeholder={t('placeholder.facilityEmail')}
                    name="facility_email"
                    wrapperClass="w-full"
                    rules={emailValidation}
                    error={error.facility_email}
                    value={getValue('facility_email')}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <InputField placeholder={t('placeholder.phone')} name="phone" wrapperClass="w-full" error={error.phone} rules={mobileValidation} value={getValue('phone')} />
                </div>
              </div>
              <div className="flex w-full justify-end pt-4">
                <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className="grid grid-cols-2 w-full gap-6">
                <div className="flex flex-col w-full">
                  <InputField
                    placeholder={t('placeholder.noOfBeds')}
                    name="no_of_beds"
                    wrapperClass="w-full"
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.NUMBER_OF_BED)}
                    error={error.no_of_beds}
                    value={getValue('no_of_beds')}
                  />
                </div>
                <FloatingLabelSelect
                  label={t('placeholder.facilityType')}
                  options={facilityData}
                  name="facility_type"
                  rules={commonSingleSelectValidation('facility type')}
                  error={error.facility_type}
                  labelClass="left-0"
                  value={getValue('facility_type')}
                />
              </div>
              <div className={`flex flex-col justify-start items-start gap-2 w-full`}>
                <p className="text-neutral-800 para-p2 font-medium">{verifyFacility.GENERAL_INSTRUCTION}</p>
                <CommonTextArea name="instruction_provider" placeholder={t('placeholder.instructionProvider')} rows={4} value={getValue('instruction_provider')} />
              </div>
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <div className="grid grid-cols-2 gap-6 w-full">
                <div className="flex flex-col w-full">
                  <InputField
                    placeholder={t('placeholder.streetAddress')}
                    name="street_address"
                    wrapperClass="w-full"
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.STREET_ADDRESS)}
                    error={error.street_address}
                    value={getValue('street_address')}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <InputField
                    placeholder={t('placeholder.apartmentBuilding')}
                    name="apartment"
                    wrapperClass="w-full"
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.STREET_ADDRESS)}
                    error={error.apartment}
                    value={getValue('apartment')}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6 w-full">
                <div className="flex flex-col w-full">
                  <InputField
                    placeholder={t('placeholder.zipCode')}
                    name="zipCode"
                    wrapperClass="w-full"
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.ZIP_CODE)}
                    error={error.zipCode}
                    value={getValue('zipcode')}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <InputField
                    placeholder={t('placeholder.townCity')}
                    name="city"
                    wrapperClass="w-full"
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.CITY)}
                    error={error.city}
                    value={getValue('city')}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6 w-full">
                <FloatingLabelSelect label={t('placeholder.state')} options={stateData} name="state" error={error.state} labelClass="label-float" value={getValue('state')} />
                <FloatingLabelSelect
                  label={t('placeholder.country')}
                  options={countryData}
                  name="country"
                  error={error.country}
                  labelClass="country-float"
                  value={getValue('country')}
                />
              </div>
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
              </div>
            </>
          )}
          {currentStep === 4 && (
            <span className="w-full">
              <GeneralSettings mainClass="border-0 p-0" />
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <div className="flex gap-4">
                  <CustomBtn text={t('button.skip')} type="default" className="w-160" onClick={handleSkipClick} />
                  <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
                </div>
              </div>
            </span>
          )}
          {currentStep === 5 && (
            <span className="w-full">
              <Floors />
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <div className="flex gap-4">
                  <CustomBtn text={t('button.skip')} type="default" className="w-160" onClick={handleSkipClick} />
                  <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
                </div>
              </div>
            </span>
          )}
          {currentStep === 6 && (
            <span className="w-full">
              <ShiftOptions />
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
              </div>
            </span>
          )}
          {currentStep === 7 && (
            <span className="w-full">
              <TimeAndApproval />
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <div className="flex gap-4">
                  <CustomBtn text={t('button.skip')} type="default" className="w-160" onClick={handleSkipClick} />
                  <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
                </div>
              </div>
            </span>
          )}
          {currentStep === 8 && (
            <span className="w-full">
              <FacilityPortalSetting />
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <div className="flex gap-4">
                  <CustomBtn text={t('button.skip')} type="default" className="w-160" onClick={handleSkipClick} />
                  <CustomBtn text={t('button.next')} type="primary" htmlType="submit" className="w-160" />
                </div>
              </div>
            </span>
          )}
          {currentStep === 9 && (
            <span className="w-full">
              <InvoiceSettings />
              <div className="flex w-full justify-between gap-4 pt-4 items-center">
                <span className="text-primary-500 para-p2 font-medium cursor-pointer" onClick={handleBackClick}>
                  {verifyFacility.PREVIOUS}
                </span>
                <CustomBtn text={t('button.save')} type="primary" htmlType="submit" className="w-160" />
              </div>
            </span>
          )}
        </Form>
      </div>
    </div>
  )
}

export default AddFacility
