import { useState } from 'react'
import { Checkbox, Form } from 'antd'
import { documentEssentialSub } from 'constants/Constant'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type IFieldData, type IModalProps } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

const AddNewDocument = ({ setIsModalOpen }: IModalProps) => {
  const [isEssential, setIsEssential] = useState(false)
  const [form] = Form.useForm()
  const [error, setError] = useState({
    documentName: '',
  })

  const onFinish = (values: any) => {
    const essential = isEssential ? 'Yes' : 'No'
    const updatedValues = { ...values, essentialDocument: essential }
    console.log(updatedValues)
    setIsModalOpen(false)
  }

  const onChange = () => {
    setIsEssential(!isEssential)
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={t('label.addNewDocument')} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start gap-5  w-full">
            <div className="flex flex-col w-full">
              <div className=" h-64 aline-self">
                <InputField rules={commonInputFieldValidation(VALIDATION_STRINGS.DOC_NAME)} placeholder={t('placeholder.documentName')} name="documentName" inputClass={''} />
              </div>
              {error?.documentName && <p className="error-container">{error.documentName}</p>}
            </div>
            <div className="flex items-start gap-2 aline-self">
              <Checkbox checked={isEssential} onChange={onChange} />
              <div className="flex flex-col justify-center items-start gap-1">
                <p className="text-neutral-800 para-p2 font-normal">{t('label.essentialDocument')}</p>
                <p className="text-neutral-500 para-p3 font-normal">{documentEssentialSub}</p>
              </div>
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  setIsModalOpen(false)
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddNewDocument
