import React, { useEffect, useState } from 'react'
import { Form, message, Radio, Switch } from 'antd'
import { badgeData } from 'constants/Constant'
import { commonInputFieldValidation, commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IGetStatusOptionColumn } from 'types'
import { handleFieldsChange, removeSorting } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { addStatusOption, updateStatusOption } from '../api'

import '../../../style/pages/StatusOptions.scss'

export interface IModalProps {
  isModalOpen?: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editId?: string
  setEditId?: any
  data?: IGetStatusOptionColumn | undefined
  settings?: any
  editData?: any
  setSortTable?: React.Dispatch<React.SetStateAction<{ order: string; field: string }>>
  selectedValue: string
}

const AddStatusOptions = ({ setIsModalOpen, editId, data, setSortTable, selectedValue }: IModalProps) => {
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [error, setError] = useState({
    name: '',
    status_for: '',
    text_color: '',
  })
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data && editId) {
      const checked = data.status === 'active'
      setStatusCheck(checked)
      form.setFieldsValue(data)
      form.setFieldValue('text_color', data.background_color)
    }
  }, [data, editId, form])

  const onFinish = (values: any) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const selectedBadge = badgeData.find(({ value }) => value === values.text_color)
    const updatedValues = { ...values, status: statusValue, background_color: selectedBadge?.style?.backgroundColor ?? '', text_color: selectedBadge?.style?.color }

    void handleUpdateAddStatus(updatedValues)
  }

  const handleUpdateAddStatus = async (values: IGetStatusOptionColumn) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await updateStatusOption(editId, values)
    } else {
      response = await addStatusOption(values)
    }
    if (response?.data?.statusCode === 1) {
      setIsModalOpen(false)
      setSortTable && removeSorting(setSortTable)
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (checked: boolean) => {
    setStatusCheck(checked)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper status-section">
      <div className="header-wrapper-certi">
        {editId && data ? (
          <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={t('label.editStatus')} />
        ) : (
          <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={t('label.createNewStatus')} />
        )}
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        className="add-time-content-wrapper"
        initialValues={{
          status_for: selectedValue,
          text_color: badgeData[0]?.value,
        }}
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="status-option-form w-full flex flex-col gap-6">
          <div>
            <InputField
              name="name"
              rules={commonInputFieldValidation(VALIDATION_STRINGS.STATUS_NAME)}
              placeholder={t('placeholder.statusName')}
              wrapperClass="w-full m-0"
              error={error.name}
              value={getValue('name')}
            />
          </div>
          <div className="status-wrapper">
            <p className="para-p2 text-neutral-800 font-medium">{t('label.statusFor')}</p>
            <div className="flex items-start gap-4 w-full mb-3">
              <Form.Item name="status_for" rules={commonSingleSelectValidation(VALIDATION_STRINGS.STATUS_FOR)} className="w-full">
                <Radio.Group rootClassName="status-radio-button">
                  <Radio value={'provider'} className="radio-placeholder">
                    {t('label.provider')}
                  </Radio>
                  <Radio value={'facility'} className="radio-placeholder">
                    {t('label.client')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {error?.status_for && <p className="error-container">{error.status_for}</p>}
          </div>
          <CommonTextArea name="description" placeholder={t('placeholder.desc')} rows={4} value={getValue('description')} wrapperClass={'m-0'} />
          <div className="badge-color-wrapper mb-2">
            <p className="para-p2 text-neutral-800 font-medium">{t('label.badgeColor')}</p>{' '}
            <div className="badge-wrapper">
              <Form.Item name="text_color" rules={commonSingleSelectValidation(VALIDATION_STRINGS.BADGE_COLOR)}>
                <Radio.Group options={badgeData} rootClassName="badge-radio-button" />
              </Form.Item>
            </div>
            {error?.text_color && <p className="error-container">{error.text_color}</p>}
          </div>
        </div>
        <div className="flex items-center justify-between hide-label w-full mt-6">
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.active')}</span> <Switch checked={statusCheck} onChange={onChange} />
          </div>
          <CustomBtn text={editId ? t('button.updateBtn') : t('button.add')} htmlType="submit" type="primary" className="w-160" />
        </div>
      </Form>
    </div>
  )
}

export default AddStatusOptions
