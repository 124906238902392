import { message } from 'antd'
import axios from 'axios'
import { UNAUTHORIZED_REQUEST } from 'constants/Constant'

export const mediaUpload = async (media: FormData) => {
  const config = {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // timeout: 5000,
  }
  try {
    const response = axios.post('/upload/image', media, config)
    return await response
  } catch (error: any) {
    void message.error(error?.response?.data?.message)
    if (error?.response.status === UNAUTHORIZED_REQUEST) {
      localStorage.clear()
      window.location.reload()
    }
  }
}
