import React, { useEffect, useRef, useState } from 'react'
import { Button, message } from 'antd'
import CloseIcon from 'assets/svgs/CloseIcon'
import MyFacilityUploadLogo from 'assets/svgs/MyFacilityUploadLogo'
import { MAX_FILE_SIZE_MB, myFacilityText } from 'constants/Constant'
import { mediaUpload } from 'services/FormDataServices'
import { type IAccessSiteLogoProps } from 'types'
import { validateFileSize } from 'utils/utils'

const AccessSiteLogo = ({ siteAndTheme, siteAndThemeUploadData, setSiteAndThemeUploadData, setIsLogoOrThemeUpdated }: IAccessSiteLogoProps) => {
  const [preview, setPreview] = useState<string | null>(null)
  const [dragging, setDragging] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleMediaChange = async (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    setIsLogoOrThemeUpdated(true)
    const target = e.target as HTMLInputElement
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      if (!file.type.startsWith('image/')) {
        return
      }

      const validSize = validateFileSize(file, MAX_FILE_SIZE_MB)
      if (!validSize) {
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result as string)
      }
      reader.readAsDataURL(file)

      const imgformData = new FormData()
      imgformData.append('image', file)
      imgformData.append('folder', 'admin')
      const response = await mediaUpload(imgformData)
      if (response?.data?.statusCode === 1) {
        setSiteAndThemeUploadData({
          ...siteAndThemeUploadData,
          image: response?.data?.data?.image,
        })
        void message.success(response?.data?.message)
      }
    }
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = () => {
    setDragging(false)
  }

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
    void handleMediaChange(e)
  }

  const handleLogoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    if (siteAndTheme?.image !== '') {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setPreview(`${siteAndTheme?.base_url}${siteAndTheme?.image}`)
    }
  }, [siteAndTheme])
  return (
    <>
      <div>
        {preview ? (
          <div className="access-logo-inner-container">
            <div className="logo-image-wrapper">
              <span
                className="my-facility-cross-icon h-[28] w-[28] absolute"
                onClick={() => {
                  setPreview(null)
                }}
              >
                <CloseIcon />
              </span>
            </div>
            <img src={preview} alt="Uploaded Logo Preview" className="uploaded-logo" />
            {preview && (
              <div style={{ paddingLeft: '130px' }} className="w-44 flex justify-end">
                <Button onClick={handleLogoClick} className="common-btn upload-button font-medium">
                  {myFacilityText.REUPLOAD}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`access-logo-container ${dragging ? 'dragging' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => {
              void handleDrop(e)
            }}
            onClick={handleLogoClick}
          >
            <p>
              <MyFacilityUploadLogo />
            </p>
            <div className="logo-text">{myFacilityText.LOGO_TEXT_UPPER}</div>
            <div className="logo-text">{myFacilityText.LOGO_TEXT_LOWER}</div>
          </div>
        )}
      </div>

      {/* Hidden File Upload Input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          void handleMediaChange(e)
        }}
      />
    </>
  )
}

export default AccessSiteLogo
