import React from 'react'

const MinusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.834 9.16602H4.16732C3.9463 9.16602 3.73434 9.25381 3.57806 9.41009C3.42178 9.56637 3.33398 9.77833 3.33398 9.99935C3.33398 10.2204 3.42178 10.4323 3.57806 10.5886C3.73434 10.7449 3.9463 10.8327 4.16732 10.8327H15.834C16.055 10.8327 16.267 10.7449 16.4232 10.5886C16.5795 10.4323 16.6673 10.2204 16.6673 9.99935C16.6673 9.77833 16.5795 9.56637 16.4232 9.41009C16.267 9.25381 16.055 9.16602 15.834 9.16602Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default MinusIcon
