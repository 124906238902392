import {
  AccountingIcon,
  ChatsIcon,
  DashBoardIcon,
  FacilitiesIcon,
  ProvidersIcon,
  ReportsIcon,
  ScheduleIcon,
  SettingsIcon,
  SettingsIconColor,
  ShiftsIcon,
} from './siderMenuIcons/SiderMenuIcons'
import ActivityDot from './ActivityDot'
import BanIcon from './BanIcon'
import CalenderCheckIcon from './CalenderCheckIcon'
import CalenderIcon from './CalenderIcon'
import CameraIcon from './CameraIcon'
import ChatIcon from './ChatsIcon'
import CheckBoxIcon from './CheckBoxIcon'
import CheckCircleOutline from './CheckCircleOutline'
import CheckIcon from './CheckIcon'
import CirclePlus from './CirclePlus'
import ClockDropDownIcon from './ClockDropDownIcon'
import CloseIcon from './CloseIcon'
import DeleteIcon from './DeleteIcon'
import DoubleCheckIcon from './DoubleCheckIcon'
import DownloadIcon from './DownloadIcon'
import DraggableDots from './DraggableDots'
import DrawerClose from './DrawerClose'
import DrawerOpen from './DrawerOpen'
import DropDownIcon from './DropDownIcon'
import DuplicateIcon from './DuplicateIcon'
import Edit from './Edit'
import EditPenIcon from './EditPenIcon'
import EmptySearchIcon from './EmptySearchIcon'
import EnvolepUploadIcon from './EnvolepUploadIcon'
import FileOutlineIcon from './FileOutlineIcon'
import Filter from './Filter'
import FilterIcon from './FilterIcon'
import ForgotPasswordIcon from './ForgotPasswordIcon'
import GmailLogo from './GmailLogo'
import GreenFlag from './GreenFlag'
import HeaderBack from './HeaderBack'
import HistoryIcon from './HistoryIcon'
import HomeIcon from './HomeIcon'
import HospitalSolid from './HospitalSolid'
import LinkExpiryIcon from './LinkExpiryIcon'
import Logo from './Logo'
import LogoIcon from './LogoIcon'
import LogOut from './LogOut'
import MinusCircle from './MinusCircle'
import MinusIcon from './MinusIcon'
import ModalClose from './ModalClose'
import MultipleChoiceIcon from './MultipleChoiceIcon'
import MyProfileIcon from './MyProfileIcon'
import NotificationIcon from './NotificationIcon'
import OutlookLogo from './OutlookLogo'
import PaginationLeft from './PaginationLeft'
import PaginationRight from './PaginationRight'
import PasswordInvisibleIcon from './PasswordInvisibleIcon'
import PasswordVisibleIcon from './PasswordVisibleIcon'
import PlusIcon from './PlusIcon'
import SearchIcon from './SearchIcon'
import SelectFieldIcon from './SelectFieldIcon'
import SeperatorBreadcrumbs from './SeperatorBreadcrumbs'
import Settings from './Setting'
import SupportRequest from './SupportRequest'
import TaskManagerIcon from './TaskManagerIcon'
import TextAreaIcon from './TextAreaIcon'
import ThreeDotIcon from './ThreeDotIcon'
import TimeLineIcon from './TimeLineIcon'
import UsaIcon from './UsaIcon'
import UserIcon from './UserIcon'

export {
  AccountingIcon,
  ActivityDot,
  BanIcon,
  CalenderCheckIcon,
  CalenderIcon,
  CameraIcon,
  ChatIcon,
  ChatsIcon,
  CheckBoxIcon,
  CheckCircleOutline,
  CheckIcon,
  CirclePlus,
  ClockDropDownIcon,
  CloseIcon,
  DashBoardIcon,
  DeleteIcon,
  DoubleCheckIcon,
  DownloadIcon,
  DraggableDots,
  DrawerClose,
  DrawerOpen,
  DropDownIcon,
  DuplicateIcon,
  Edit,
  EditPenIcon,
  EmptySearchIcon,
  EnvolepUploadIcon,
  FacilitiesIcon,
  FileOutlineIcon,
  Filter,
  FilterIcon,
  ForgotPasswordIcon,
  GmailLogo,
  GreenFlag,
  HeaderBack,
  HistoryIcon,
  HomeIcon,
  HospitalSolid,
  LinkExpiryIcon,
  Logo,
  LogoIcon,
  LogOut,
  MinusCircle,
  MinusIcon,
  ModalClose,
  MultipleChoiceIcon,
  MyProfileIcon,
  NotificationIcon,
  OutlookLogo,
  PaginationLeft,
  PaginationRight,
  PasswordInvisibleIcon,
  PasswordVisibleIcon,
  PlusIcon,
  ProvidersIcon,
  ReportsIcon,
  ScheduleIcon,
  SearchIcon,
  SelectFieldIcon,
  SeperatorBreadcrumbs,
  Settings,
  SettingsIcon,
  SettingsIconColor,
  ShiftsIcon,
  SupportRequest,
  TaskManagerIcon,
  TextAreaIcon,
  ThreeDotIcon,
  TimeLineIcon,
  UsaIcon,
  UserIcon,
}
