import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'antd'
import { CHECKED_VIEW } from 'constants/Constant'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { getRolesSubSection } from 'pages/roleManagement/api'
import { addRole, setFormData } from 'store/slice/AddRoleSlice'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IRolePermission, IRoleSubSection, ISubSectionRolePermission, ISubSections } from 'types'
import { formatTitle } from 'utils/Helper'

import HeadingText from 'components/common/HeadingText'

const PermissionStep = ({ next, prev, item, isLast, editId }: IRolePermission) => {
  const [subSectionPermissions, setSubSectionPermissions] = useState<ISubSectionRolePermission>()
  const [checkedState, setCheckedState] = useState<ISubSections>()

  const dispatch = useAppDispatch()
  const formData = useAppSelector(addRole)
  const { t } = useTranslation()

  useEffect(() => {
    void handleGetSubSection(item?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  useEffect(() => {
    const existingSections: ISubSections[] = formData.sections ? [...formData.sections] : []
    const sectionIndex = existingSections.findIndex((section) => section.section_id === checkedState?.section_id)

    if (sectionIndex >= 0) {
      if (checkedState && JSON.stringify(existingSections[sectionIndex]) !== JSON.stringify(checkedState)) {
        existingSections[sectionIndex] = checkedState
        dispatch(setFormData({ ...formData, sections: existingSections }))
      }
    } else if (checkedState) {
      existingSections.push(checkedState)
      dispatch(setFormData({ ...formData, sections: existingSections }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedState])

  const handleGetSubSection = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getRolesSubSection(id)
    if (response?.status === 200) {
      setSubSectionPermissions(response?.data?.data)
      const data: ISubSections = {
        section_id: '',
        sub_sections: [],
      }
      if (response.data.data?.id) {
        data.section_id = response.data.data.id
      }
      response?.data?.data?.permissions.forEach((subSection: IRoleSubSection) => {
        const subSectionId = subSection.id
        const newSubSection = {
          sub_section_id: subSectionId,
          permissions: subSection.permission.map((perm) => ({
            permission_id: perm.id,
            has_access: false,
          })),
        }
        data.sub_sections.push(newSubSection)
      })
      if (formData.sections) {
        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        const matchingSection: any = formData.sections.find((section: ISubSections) => section.section_id === data.section_id)
        if (matchingSection) {
          if (matchingSection.sub_sections && matchingSection.sub_sections.length > 0) {
            const subSectionWithPermissions = matchingSection.sub_sections.find(
              (subSection: any) => subSection.permissions !== undefined && subSection.permissions !== null && subSection.permissions.length !== 0
            )
            if (subSectionWithPermissions) {
              setCheckedState(matchingSection)
            } else {
              setCheckedState(data)
            }
          } else {
            setCheckedState(data)
          }
        } else {
          // Handle case when no matching section is found
          setCheckedState(data)
        }
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const handleCheckboxChange = (subSectionId: string, permissionId: string, checked: boolean) => {
    setCheckedState((prevState: ISubSections | undefined) => {
      if (!prevState) return prevState
      const newState: ISubSections = {
        ...prevState,
        sub_sections: prevState.sub_sections.map((subSection) => {
          if (subSection.sub_section_id === subSectionId) {
            return {
              ...subSection,
              permissions: subSection.permissions.map((perm) => {
                if (perm.permission_id === permissionId) {
                  return {
                    ...perm,
                    has_access: checked,
                  }
                } else if (permissionId === CHECKED_VIEW && !checked) {
                  // If "View" permission is unchecked, uncheck all other permissions
                  return {
                    ...perm,
                    has_access: false,
                  }
                } else if (perm.permission_id === CHECKED_VIEW) {
                  // Ensuring "View" permission is automatically selected when any other permission is selected
                  return {
                    ...perm,
                    has_access: true,
                  }
                }
                return perm
              }),
            }
          }
          return subSection
        }),
      }

      return newState
    })
  }

  const handleCheckAll = () => {
    setCheckedState((prevState) => {
      if (!prevState) return prevState
      const newState = {
        ...prevState,
        sub_sections: prevState.sub_sections.map((subSection) => ({
          ...subSection,
          permissions: subSection.permissions.map((perm) => ({
            ...perm,
            has_access: true,
          })),
        })),
      }
      return newState
    })
  }

  const handleUncheckAll = () => {
    setCheckedState((prevState) => {
      if (!prevState) return prevState
      const newState = {
        ...prevState,
        sub_sections: prevState.sub_sections.map((subSection) => ({
          ...subSection,
          permissions: subSection.permissions.map((perm) => ({
            ...perm,
            has_access: false,
          })),
        })),
      }
      return newState
    })
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        {subSectionPermissions?.name && <HeadingText text={formatTitle(subSectionPermissions?.name)} classString="mobile-heading-h5 text-neutral-800 font-medium" />}
        <div className="flex gap-6">
          <HeadingText text={t('button.check')} classString="para-p3 text-primary-400 cursor-pointer " onClick={handleCheckAll} />
          <HeadingText text={t('button.uncheck')} classString="para-p3 text-primary-400 cursor-pointer" onClick={handleUncheckAll} />
        </div>
      </div>

      <div className="border-stroke rounded-4">
        {subSectionPermissions?.permissions?.map((permission, index) => {
          return (
            <div className={`flex justify-between p-4 border-b-stroke ${index === subSectionPermissions?.permissions.length - 1 ? 'border-b-0' : ''}`} key={permission.sub_section}>
              <div className="w-50">
                <HeadingText text={formatTitle(permission.sub_section)} classString="para-p2 text-neutral-800" />
              </div>
              <div className="w-50 flex gap-8 flex-wrap">
                {permission?.permission?.map((perm, index) => {
                  return (
                    <div key={`${perm?.id}${index}`}>
                      <Checkbox
                        checked={
                          checkedState?.sub_sections
                            ?.find((subSection) => subSection.sub_section_id === permission.id)
                            ?.permissions?.find((permItem) => permItem.permission_id === perm.id)?.has_access ?? false
                        }
                        onChange={(e) => {
                          handleCheckboxChange(permission.id, perm.id, e.target.checked)
                        }}
                        className="permission-checkbox"
                      >
                        {formatTitle(perm?.name)}
                      </Checkbox>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PermissionStep
