import { useEffect, useState } from 'react'
import { Button, message } from 'antd'
import { MinusIcon, PlusIcon } from 'assets/svgs'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'

import CustomBtn from 'components/common/CustomBtn'

import { getAutoScheduleData, updateAutoScheduleData } from './api'
import type { IAutoSchedulingData } from './types'

const settings = [
  {
    id: 1,
    name: 'Shift running late trigger settings',
    sub_section: [
      {
        id: 'provider_radius',
        name: 'Provider should be in this set (miles) distance radius before starting the shift',
        counterText: 'Miles',
      },
      {
        id: 'running_late_ai_time',
        name: 'AI will start searching for new providers to fill the shift if the scheduled/assigned provider fails to reach the set distance radius within the set time limit.',
        counterText: 'Hour',
      },
      {
        id: 'check_distance_time',
        name: 'Configure trigger time before shift for distance check',
        counterText: 'Hour',
      },
    ],
  },
  {
    id: 2,
    name: 'Facility cancel shift settings',
    sub_section: [
      {
        id: 'facility_cancel_time',
        name: 'Enter the number of hours before the shift start time when a facility can cancel the shift.',
        counterText: 'Hour',
      },
    ],
  },
  {
    id: 3,
    name: 'Request expire settings for running late and cancel shift settings',
    sub_section: [
      {
        id: 'cancel_request_expiry',
        name: 'Enter the number of minutes a shift request cancel remains valid before it expires.',
        counterText: 'Mins',
      },
      {
        id: 'running_late_request_expiry',
        name: 'Enter the number of minutes a shift request running late remains valid before it expires.',
        counterText: 'Mins',
      },
    ],
  },
  {
    id: 4,
    name: 'Send another request settings for shift fall under more than a week',
    sub_section: [
      {
        id: 'send_another_request',
        name: 'Define the time in hours for when another request can be sent if the shift start date is more than a week away.',
        counterText: 'Hour',
        subCounterText: 'Mins',
      },
    ],
  },
  {
    id: 5,
    name: 'Shift settings for open orders',
    sub_section: [
      {
        id: 'post_shift_to_open',
        name: 'Enter the number of hours before the shift start time when the system will post the shift into open order if not scheduled by AI.',
        counterText: 'Hour',
        subCounterText: 'Mins',
      },
    ],
  },
  {
    id: 6,
    name: 'Bulk Request Duration Option',
    sub_section: [
      {
        id: 'bulk_scheduling_duration',
        name: 'Configure the duration for bulk requests, allowing you to specify how long before scheduling the system will consider bulk requests for processing.',
        counterText: 'Days',
      },
    ],
  },
]

const AutoSchedule = () => {
  const [autoSchedulingData, setAutoSchedulingData] = useState<IAutoSchedulingData>()
  const [stringState, setStringState] = useState<Record<string, string>>({})
  const [counters, setCounters] = useState(() => {
    const initialCounters: Record<string, number> = {}
    settings.forEach((setting) => {
      if (setting.sub_section) {
        setting.sub_section.forEach((data) => {
          initialCounters[data.id] = 0
        })
      }
    })
    return initialCounters
  })
  const [subCounter, setSubCounter] = useState(() => {
    const initialCounters: Record<string, number> = {}
    settings.forEach((setting) => {
      if (setting.sub_section) {
        setting.sub_section.forEach((data) => {
          initialCounters[data.id] = 0
        })
      }
    })
    return initialCounters
  })
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.autoSchedule'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetAutoSchedulingData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleIncrement = (id: string, isHourIncrement = false) => {
    if (id === 'send_another_request' || id === 'post_shift_to_open') {
      setSubCounter((prevCounters) => {
        const currentMinutes = prevCounters[id] % 60
        const currentHours = Math.floor(prevCounters[id] / 60)

        let newSubCounter: any
        if (isHourIncrement) {
          newSubCounter = currentHours * 60 + (currentMinutes + 1)
        } else {
          const newMinutes = currentMinutes
          const newHours = currentHours + 1
          newSubCounter = newHours * 60 + newMinutes
        }

        setStringState((prevState) => ({
          ...prevState,
          [id]: Math.floor(newSubCounter / 60).toString(),
          [id + '_minutes']: (newSubCounter % 60).toString(),
        }))

        return {
          ...prevCounters,
          [id]: newSubCounter,
        }
      })
    } else {
      const incrementValue = getIncrementValue(id)
      setCounters((prevCounters) => {
        const newCounter = prevCounters[id] + incrementValue

        setStringState((prevState) => ({
          ...prevState,
          [id]: newCounter.toString(),
        }))

        return {
          ...prevCounters,
          [id]: newCounter,
        }
      })
    }
  }

  const handleGetAutoSchedulingData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getAutoScheduleData()
    if (response?.data?.statusCode === 1) {
      const data = response?.data?.data
      setAutoSchedulingData(response?.data?.data)
      const updatedCounters: Record<string, number> = {}
      settings.forEach((setting) => {
        if (setting.sub_section) {
          setting.sub_section.forEach((subSection) => {
            let value = data[subSection.id]
            if (subSection.counterText === 'Hour') {
              value = value / 60
            }
            updatedCounters[subSection.id] = value
          })
        }
      })
      setCounters(updatedCounters)
      setSubCounter(data)
    }
    dispatch(setGlobalLoader(false))
  }

  const getIncrementValue = (id: string) => {
    for (const setting of settings) {
      if (setting.sub_section) {
        for (const subSection of setting.sub_section) {
          if (subSection.id === id) {
            return subSection.counterText === 'Hour' ? 0.5 : 1
          }
        }
      }
    }
    return 1
  }

  const handleMinusClick = (id: string, isHourDecrement = false) => {
    if (id === 'send_another_request' || id === 'post_shift_to_open') {
      setSubCounter((prevCounters) => {
        const currentMinutes = prevCounters[id] % 60
        const currentHours = Math.floor(prevCounters[id] / 60)

        let newSubCounter: any
        if (!isHourDecrement) {
          newSubCounter = Math.max((currentHours - 1) * 60 + currentMinutes, 0)
        } else {
          let newMinutes = currentMinutes - 1
          let newHours = currentHours
          if (newMinutes < 0) {
            newHours = Math.max(currentHours - 1, 0)
            newMinutes = 59
          }
          if (newHours === 0 && newMinutes < 1) {
            newMinutes = 1
          }
          newSubCounter = newHours * 60 + newMinutes
        }
        setStringState((prevState) => ({
          ...prevState,
          [id]: Math.floor(newSubCounter / 60).toString(),
          [id + '_minutes']: (newSubCounter % 60).toString(),
        }))

        return {
          ...prevCounters,
          [id]: newSubCounter,
        }
      })
    } else {
      const decrementValue = getIncrementValue(id)
      setCounters((prevCounters) => {
        const newValue = Math.max(prevCounters[id] - decrementValue, 0)

        setStringState((prevState) => ({
          ...prevState,
          [id]: newValue.toString(),
        }))

        return {
          ...prevCounters,
          [id]: newValue,
        }
      })
    }
  }

  const isObjectValid = (obj: Record<string, number | string>) => {
    return Object.values(obj).every((value) => value !== null && value !== undefined && value !== '')
  }

  const onHandleSave = async () => {
    if (!isObjectValid(counters) || !isObjectValid(subCounter) || !isObjectValid(stringState)) {
      void message.error(VALIDATION_ERROR.COMMON.NOBLANKDATA)
      return
    }
    dispatch(setGlobalLoader(true))
    const convertToMinutes = (value: number) => {
      return Math.floor(value / 60) * 60 + (value % 60)
    }
    const payload = {
      provider_radius: counters?.provider_radius,
      running_late_ai_time: counters?.running_late_ai_time * 60,
      check_distance_time: counters?.check_distance_time * 60,
      facility_cancel_time: counters?.facility_cancel_time * 60,
      cancel_request_expiry: counters?.cancel_request_expiry,
      running_late_request_expiry: counters?.running_late_request_expiry,
      send_another_request: convertToMinutes(subCounter.send_another_request),
      post_shift_to_open: convertToMinutes(subCounter.post_shift_to_open),
      bulk_scheduling_duration: counters?.bulk_scheduling_duration,
    }
    const response = await updateAutoScheduleData(autoSchedulingData?.id as string, payload)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <div className="flex flex-col gap-6 auto-schedule-container w-full items-end mx-auto">
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <CustomBtn text={t('button.save')} htmlType="submit" type="primary" className="w-160" onClick={onHandleSave} />
      <div className="flex flex-col rounded-4 justify-start bg-white aline-self">
        {settings.map((setting, index) => (
          <div className="flex w-full" key={setting.id}>
            <div className={`table-title-wrapper ${settings.length - 1 === index ? 'border-b-0 ' : ''} `}>
              <p className="text-neutral-800 para-p1 text-center font-medium">{setting.name}</p>
            </div>
            <div className="flex flex-col w-full">
              {setting?.sub_section?.map((data: { name: string; id: string; counterText: string; subCounterText?: string }, index: number) => {
                return (
                  <div className={`flex flex-row w-full justify-between border-b-stroke ${settings.length - 1 === index ? 'border-b-0' : ''}`} key={data.id}>
                    <div className="field-name-wrapper">
                      <p className="text-neutral-800 para-p2 font-medium">{data.name}</p>
                    </div>
                    <div className="flex flex-col justify-center items-center p-4 switch-wrapper">
                      <div className="h-60 flex p-4 items-center gap-2 align-self flex-grow-1 w-240px">
                        <div className="counter-box">
                          <Button
                            icon={<MinusIcon />}
                            onClick={() => {
                              handleMinusClick(data.id, !data.subCounterText)
                            }}
                            disabled={
                              data.id === 'send_another_request' || data.id === 'post_shift_to_open'
                                ? (stringState[data.id] === '0' && stringState[data.id + '_minutes'] === '1') || subCounter[data.id] === 1
                                : stringState[data.id] === '0' || stringState[data.id + '_minutes'] === '0' || subCounter[data.id] === 0
                            }
                            className="counter-btn-min"
                          />
                          <div className="counter-text-wrapper">
                            <input
                              className="counter-text"
                              value={
                                // eslint-disable-next-line
                                data.subCounterText
                                  ? (stringState[data.id] ?? Math.floor(subCounter[data.id] / 60).toString())
                                  : (stringState[data.id] ?? counters[data.id].toString())
                              }
                              onChange={(e) => {
                                const value = e.target.value
                                const isDecimalAllowed = data.id === 'running_late_ai_time' || data.id === 'check_distance_time' || data.id === 'facility_cancel_time'

                                const regex = isDecimalAllowed ? /^-?\d*\.?\d*$/ : /^-?\d*$/

                                if (!regex.test(value)) return

                                setStringState((prevState) => ({
                                  ...prevState,
                                  [data.id]: value,
                                }))

                                const numericValue = parseFloat(value)
                                if (!isNaN(numericValue)) {
                                  if (data.subCounterText) {
                                    setSubCounter((prevState) => ({
                                      ...prevState,
                                      [data.id]: numericValue * 60,
                                    }))
                                  } else {
                                    setCounters((prevState) => ({
                                      ...prevState,
                                      [data.id]: numericValue,
                                    }))
                                  }
                                }
                              }}
                            />
                          </div>
                          <Button
                            icon={<PlusIcon className="plus-icon-solid " />}
                            onClick={() => {
                              handleIncrement(data.id, !data.subCounterText)
                            }}
                            className="counter-btn-plus"
                          />
                        </div>
                        <p className="text-neutral-800 para-p2 font-medium">{data.counterText}</p>
                      </div>
                      {data.subCounterText && (
                        <div className="h-60 flex p-4 items-center gap-2 align-self flex-grow-1 w-240px">
                          <div className="counter-box">
                            <Button
                              icon={<MinusIcon />}
                              onClick={() => {
                                handleMinusClick(data.id, true)
                              }}
                              disabled={
                                data.id === 'send_another_request' || data.id === 'post_shift_to_open'
                                  ? (stringState[data.id] === '0' && stringState[data.id + '_minutes'] === '1') || subCounter[data.id] === 1
                                  : stringState[data.id] === '0' || stringState[data.id + '_minutes'] === '0' || subCounter[data.id] === 0
                              }
                              className="counter-btn-min"
                            />
                            <div className="counter-text-wrapper">
                              <input
                                className="counter-text"
                                value={stringState[data.id + '_minutes'] ?? (subCounter[data.id] % 60).toString()}
                                onChange={(e) => {
                                  const value = Number(e.target.value) === 0 && Math.floor(subCounter[data.id] / 60) === 0 ? '1' : e.target.value
                                  const regex = /^-?\d*$/

                                  if (!regex.test(value)) return

                                  setStringState((prevState) => ({
                                    ...prevState,
                                    [data.id + '_minutes']: value,
                                  }))

                                  if (!isNaN(Number(value))) {
                                    setSubCounter((prevState) => {
                                      const minutes = Math.min(Math.max(Number(value) || 0, 0), 59) // Ensure minutes are within the 0-59 range
                                      const hours = Math.floor(prevState[data.id] / 60)

                                      // Set minutes to 1 if hours is 0 and minutes is 0
                                      const finalMinutes = hours === 0 && minutes === 0 ? 1 : minutes

                                      return {
                                        ...prevState,
                                        [data.id]: hours * 60 + finalMinutes,
                                      }
                                    })
                                  }
                                }}
                              />
                            </div>
                            <Button
                              icon={<PlusIcon className="plus-icon-solid " />}
                              onClick={() => {
                                handleIncrement(data.id, true)
                              }}
                              className="counter-btn-plus"
                            />
                          </div>
                          <p className="text-neutral-800 para-p2 font-medium">{data.subCounterText}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AutoSchedule
