import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import type { ICompetencyTestQue, IFieldData, IQuizTest, ISkillChecklistQuestion } from 'types'

export const formatTitle = (name: string): string => name?.replace(/_/g, ' ')?.replace(/\b\w/g, (char) => char?.toUpperCase())

export const handleFieldsChange = (allFields: IFieldData[], setError: React.Dispatch<React.SetStateAction<any>>) => {
  allFields.forEach((field: IFieldData) => {
    const fieldName = field.name[0]
    const fieldErrors = field.errors ?? []
    setError((prev: any) => ({
      ...prev,
      [fieldName]: fieldErrors.length > 0 ? fieldErrors[0] : '',
    }))
  })
}

// validate skill checklist json before submit
export const validateJSON = (data: any[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(data) || data.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.NOBLANKDATA }
  }

  for (const module of data) {
    if (!module.name || typeof module.name !== 'string' || module.name.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_WITH_SUBMODULE }
    }
    if (typeof module.order !== 'number') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_WITH_SUBMODULE }
    }
    if (!Array.isArray(module.skill_checklist_sub_module) || module.skill_checklist_sub_module.length === 0) {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_SUBMODULE }
    }

    for (const subModule of module.skill_checklist_sub_module) {
      if (!subModule.name || typeof subModule.name !== 'string' || subModule.name.trim() === '') {
        return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
      }
      if (!Array.isArray(subModule.skill_checklist_question) || subModule.skill_checklist_question.length === 0) {
        return { isValid: false, error: VALIDATION_ERROR.NO_QUE_SUBMODULE }
      }

      for (const question of subModule.skill_checklist_question) {
        if (!question.question || typeof question.question !== 'string' || question.question.trim() === '') {
          return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
        }
        if (typeof question.order !== 'number') {
          return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
        }
      }
    }
  }

  return { isValid: true, error: null }
}

// validate compentency json before submit
export const validateQuestionsForCompentancy = (questions: ICompetencyTestQue[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
  }
  for (const question of questions) {
    if (
      !question.question ||
      !question.order ||
      !Array.isArray(question.competency_test_option) ||
      question.competency_test_option.length === 1 ||
      question.competency_test_option.length === 0
    ) {
      return { isValid: false, error: VALIDATION_ERROR.BLANK_QUE_OPT }
    }
    let hasAnswer = false
    for (const option of question.competency_test_option) {
      if (!option.option || !option.order) {
        return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
      }
      if (option.is_answer) {
        hasAnswer = true
      }
    }
    if (!hasAnswer) {
      return { isValid: false, error: VALIDATION_ERROR.OPT_SELECTED }
    }
  }
  return { isValid: true, error: null }
}

export const validateQuestionsForCompentancyPreview = (questions: ICompetencyTestQue[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
  }

  for (const question of questions) {
    if (!question.question || !question.order || !Array.isArray(question.competency_test_option) || question.competency_test_option.length === 0) {
      return { isValid: false, error: VALIDATION_ERROR.BLANK_QUE_OPT_ONE }
    }

    for (const option of question.competency_test_option) {
      if (!option.option || !option.order) {
        return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
      }
    }
  }

  return { isValid: true, error: null }
}

// validate faq json before submit
export const validateQuestionsForFaq = (questions: IQuizTest[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.NOBLANKDATA }
  }
  for (const q of questions) {
    if (!q.question || typeof q.question !== 'string' || q.question.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_ANS }
    }
    if (!q.answer || typeof q.answer !== 'string' || q.answer.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_ANS }
    }
    if (typeof q.order !== 'number') {
      return { isValid: false, error: VALIDATION_ERROR.ENTER_QUE_ANS }
    }
  }
  return { isValid: true, error: null }
}

// add deleted id state for compentency
export const addCompentencyDeletedData = (updatedValues: any, deleteQueIds: string[], deleteOptionIds: string[]) => {
  const newData = { ...updatedValues }
  if (deleteQueIds && deleteQueIds.length > 0) {
    newData.delete_question = deleteQueIds
  }
  if (deleteOptionIds && deleteOptionIds.length > 0) {
    newData.delete_option = deleteOptionIds
  }
  return newData
}

// validation for sub module question
export const validateQuestionsForSubModule = (questions: ISkillChecklistQuestion[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
  }
  for (const q of questions) {
    if (!q.question || typeof q.question !== 'string' || q.question.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.COMMON.ENTERONEQUE }
    }
  }
  return { isValid: true, error: null }
}

// add deleted id state for skill check list
export const addSkillChecklistDeletedData = (updatedValues: any, deleteModule: string[], deleteQuestion: string[], deleteSubModule: string[]) => {
  const newData = { ...updatedValues }

  if (deleteModule && deleteModule.length > 0) {
    newData.delete_module = deleteModule
  }
  if (deleteSubModule && deleteSubModule.length > 0) {
    newData.delete_sub_module = deleteSubModule
  }
  if (deleteQuestion && deleteQuestion.length > 0) {
    newData.delete_question = deleteQuestion
  }
  return newData
}

// helper function for set dynamic string and id for breadcrumb label
export const resolveBreadcrumbLabel = (url: string, location: any, breadcrumbMap: Record<string, string>): { url: string; label: string } => {
  let label = breadcrumbMap[url]

  if (url === '/settings/skill-checklist/edit') {
    const getData = JSON.parse(localStorage.getItem('skillState') ?? 'null') as { editId?: string } | null
    const editId = getData?.editId ?? ''
    return { url: `${url}/${editId}`, label }
  }

  if (url === '/settings/skill-checklist/preview' || url === '/settings/skill-checklist/edit/preview' || url === '/settings/skill-checklist/add/preview') {
    const getData = JSON.parse(localStorage.getItem('skillState') ?? 'null') as { name?: string } | null
    const name = location.state?.record?.name ?? getData?.name ?? ''
    label = ` ${String(name)}`
  }

  if (
    url === '/settings/skill-checklist/preview/subtopic' ||
    url === '/settings/skill-checklist/edit/preview/subtopic' ||
    url === '/settings/skill-checklist/add/preview/subtopic'
  ) {
    const name = location.state?.subModuleName ?? ''
    label = ` ${String(name)}`
  }

  if (url === '/settings/compentency-test/add-test/preview' || url === '/settings/compentency-test/edit-test/preview') {
    const getData = JSON.parse(localStorage.getItem('compentancyState') ?? 'null') as { name?: string } | null
    const name = location.state?.record?.name ?? getData?.name ?? ''
    label = ` ${String(name)}`
  }

  if (url === '/settings/compentency-test/edit-test') {
    const getData = JSON.parse(localStorage.getItem('compentancyState') ?? 'null') as { editId?: string } | null
    const editId = getData?.editId ?? ''
    url += `/${editId}`
  }

  if (url === '/settings/user/role-management/edit-role') {
    const getData = localStorage.getItem('editRoleId') ?? ''
    url += `/${getData}`
  }

  if (url === '/settings/referance-form-setting/add-form/referance-form-preview' || url === '/settings/referance-form-setting/edit-form/referance-form-preview') {
    const getData = JSON.parse(localStorage.getItem('referanceForm') ?? 'null') as { name?: string } | null
    const name = location.state?.record?.name ?? getData?.name ?? ''
    label = ` ${String(name)}`
  }

  if (url === '/settings/referance-form-setting/edit-form') {
    const getData = JSON.parse(localStorage.getItem('referanceForm') ?? 'null') as { editId?: string } | null
    const editId = getData?.editId ?? ''
    url += `/${editId}`
  }

  return { url, label }
}

// add deleted id for referance form
export const referanceFormDeletedData = (updatedValues: any, deleteQueIds: string[], deleteOptionIds: string[]) => {
  const newData = { ...updatedValues }
  if (deleteQueIds && deleteQueIds.length > 0) {
    newData.delete_question = deleteQueIds
  }
  if (deleteOptionIds && deleteOptionIds.length > 0) {
    newData.delete_option = deleteOptionIds
  }
  return newData
}

// validate json for referance form
export const validateReferanceFormJSON = (data: any[]): { isValid: boolean; error: string | null } => {
  if (!Array.isArray(data) || data.length === 0) {
    return { isValid: false, error: VALIDATION_ERROR.NOBLANK_FORM_DATA }
  }

  for (const module of data) {
    if (!module.name || typeof module.name !== 'string' || module.name.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.FORM_FIELD_NAME }
    }

    if (!module.option_type || typeof module.option_type !== 'string' || module.option_type.trim() === '') {
      return { isValid: false, error: VALIDATION_ERROR.FORM_OPTION_TYPE }
    }

    if ((module.option_type === 'multiple_choice' || module.option_type === 'dropdown') && (!Array.isArray(module.options) || module.options.length === 0)) {
      return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
    }

    if (module.options && Array.isArray(module.options)) {
      for (const option of module.options) {
        if (!option.name || typeof option.name !== 'string' || option.name.trim() === '') {
          return { isValid: false, error: VALIDATION_ERROR.BLANK_OPT }
        }
      }
    }
  }

  return { isValid: true, error: null }
}
