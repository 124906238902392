export const ADMIN_CONTACT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'in_active',
  INVITED: 'invited',
}

export const CERTIFICATION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'in_active',
  INVITED: 'Invited',
}
export const SPECIALITIES_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INVITED: 'invited',
}

export const SKILLCHECKLIST_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INVITED: 'invited',
}

export const PROVIDER_VERIFICATION = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INVITED: 'invited',
}

export const PROVIDER_FACILITY_VERIFICATION = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INVITED: 'invited',
}

export const SHIFT_STATUS = {
  OPEN: 'open',
  CANCELLED: 'cancelled',
  REQUESTED: 'requested',
  UNCONFIRMED: 'unconfirmed',
  UNSUBMITTED: 'un_submitted',
  SCHEDULE: 'scheduled',
  COMPLETED: 'completed',
  AUTO_SCHEDULING: 'auto_scheduling',
  ON_GOING: 'ongoing',
  VOID: 'void',
}

export const SHIFT_INVITATION_STATUS = {
  WITHDRAWN: 'withdrawn',
  ACCEPTED: 'accepted',
  INVITED: 'invited',
  UNSEEN: 'unseen',
  REJECTED: 'rejected',
}

export const SHIFT_INVITATION_STATUS_COLOR: any = {
  withdrawn: { bgColor: `#fdf4f5`, color: `#d1293d` },
  rejected: { bgColor: `#fdf4f5`, color: `#d1293d` },
  invited: { bgColor: `#F2F0FE`, color: `#220EB1` },
  unseen: { bgColor: `#FFF8F0`, color: `#FB8600` },
}

export const PERIOD_STATUS = {
  EVENING: 'evening',
  DAY: 'day',
  NIGHT: 'night',
  FLEXIBLE: 'flexible',
}
