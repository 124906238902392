import { Form, Radio, Switch } from 'antd'
import { t } from 'i18next'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

const badgeData = [
  {
    value: 'success',
    label: 'Clinical',
  },
  {
    value: 'primary',
    label: 'Professional',
  },
]

const AddDnrReason = () => {
  // const [value, setValue] = useState(1)

  // const onChange = (e: RadioChangeEvent) => {
  //   console.log('radio checked', e.target.value)
  //   setValue(e.target.value)
  // }
  return (
    <Form className="add-provider-status-wrapper">
      <InputField placeholder={t('placeholder.statusName')} name="status_name" wrapperClass="w-full" />
      <div className="badge-color-wrapper mb-6">
        <p className="text-neutral-800 para-p2 font-medium">Badge Color</p>
        <div className="badge-wrapper">
          <Form.Item name="repeat-btn" valuePropName="checked">
            <Radio.Group options={badgeData} rootClassName="dnr-radio-button" />
          </Form.Item>
        </div>
      </div>
      <CommonTextArea name="description" placeholder={t('placeholder.desc')} rows={4} />
      <div className="add-provider-footer">
        <div className="flex gap-2 toggle-switch">
          <span>{t('label.active')}</span> <Switch checked={true} />
        </div>
        <CustomBtn text={t('button.add')} htmlType="submit" type="primary" className="w-120" />
      </div>
    </Form>
  )
}

export default AddDnrReason
