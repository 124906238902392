import { message } from 'antd'
import http from 'services/Service'

export const getProviderSetting = async () => {
  try {
    const response = http.get('/provider-profile-setting')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getProviderById = async (id: string) => {
  try {
    const response = http.get(`/provider-profile-setting/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const updateById = async (updateId: string, payload: any) => {
  try {
    const response = http.patch(`/provider-profile-setting/${updateId}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
