import { message } from 'antd'
import http from 'services/Service'
import { type IEditFloorApiData, type IFloor, type updateSettingApiData } from 'types'

export const getFacility = async (limit: number, offset: number, order: string, field: string, search: string) => {
  try {
    const response = http.get(`/admin/all-facilities?limit=${limit}&offset=${offset}${order && field && `&order[${order}]=${field}`}${search && `&search=${search}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || 'Something Went Wrong')
  }
}

export const createFloor = async (payload: IFloor) => {
  try {
    const response = http.post('/floor-detail', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getAllFloorData = async (facilityId: string | undefined, limit: number, offset: number) => {
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const response = http.get(`/floor-detail/all/${facilityId}?limit=${limit}&offset=${offset}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || 'Something Went Wrong')
  }
}

export const getSpecialities = async () => {
  try {
    const response = http.get(`/dropdown/speciality`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || 'Something Went Wrong')
  }
}

export const getOrderAndClientContact = async (id: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const response = http.get(`/dropdown/facility-user/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || 'Something Went Wrong')
  }
}

export const deleteFloorDetail = async (id: string) => {
  try {
    const response = http.delete(`/floor-detail/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const editFloorDetail = async (id: string, payload: IEditFloorApiData) => {
  try {
    const response = http.patch(`/floor-detail/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getFacilitySettings = async (facilityId: number) => {
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const response = http.get(`/facility/setting/${facilityId}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || 'Something Went Wrong')
  }
}

export const updateGeneralSettings = async (facilityId: string, payload: updateSettingApiData) => {
  try {
    const response = http.patch(`/facility/setting/${facilityId}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getAllfacilitySettingShiftTimes = async () => {
  try {
    const response = http.get(`/facility-shift-setting`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
