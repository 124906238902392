import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, message } from 'antd'
import { DropDownIcon } from 'assets/svgs'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type ICreateChecklistData, type ISkillChecklistQuestion, type ISkillChecklistSubModule } from 'types'
import { addSkillChecklistDeletedData, validateJSON } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

import { addChecklist, editChecklist } from '../api'

import '../../../style/pages/SkillChecklist.scss'

const PreviewSubtopic = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()

  const [data, setData] = useState({
    subModuleName: '',
    subModuleQues: [],
    listPreview: false,
    editId: '',
  })
  const [visibleDetails, setVisibleDetails] = useState<boolean[]>(data.subModuleQues?.map(() => false))

  useEffect(() => {
    console.log('location.state', location.state)
    dispatch(
      changeHeaderTitle({
        pageTitle: location.state?.listPreview ? t('pageTitle.preview') : t('pageTitle.livePreview'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (location.state) {
      const data = location.state
      setData(data)
    }
  }, [location])

  const onFinish = () => {
    const stateString = localStorage.getItem('skillState')
    const data = stateString ? JSON.parse(stateString) : {}
    if (data.skill_checklist_module.length === 0) {
      void message.error(VALIDATION_ERROR.COMMON.ENTERONEQUE)
      return
    }
    const validation = validateJSON(data.skill_checklist_module)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const newstatus = data.status === true ? 'active' : 'in_active'
    const updatedValues = { name: data.name, certificate: data.certificate_id, status: newstatus, skill_checklist_module: data.skill_checklist_module }
    if (data.editId) {
      const newData = addSkillChecklistDeletedData(updatedValues, data.delete_module, data.delete_question, data.delete_sub_module)
      void handleEditCheckList(data.editId, newData)
    } else {
      void handleAddCheckList(updatedValues)
    }
  }

  const handleAddCheckList = async (payload: ICreateChecklistData) => {
    dispatch(setGlobalLoader(true))
    const response = await addChecklist(payload)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/skill-checklist')
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditCheckList = async (id: string, values: ICreateChecklistData) => {
    dispatch(setGlobalLoader(true))
    const response = await editChecklist(id, values)
    if (response?.status === 200) {
      void message.success(response?.data?.message)
      navigate('/settings/skill-checklist')
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const toggleDetails = (index: number) => {
    setVisibleDetails((prevState) => {
      const newState = new Array(prevState.length).fill(false)
      newState[index] = !prevState[index] ? true : !prevState[index]
      return newState
    })
  }

  return (
    <>
      <Form form={form} onFinish={onFinish} className="flex flex-col gap-6 items-center w-full addedit-checklist">
        <div
          className="rounded-4 flex items-center justify-between bg-white w-full skillchecklist-preview content-header-spacing"
          style={{ paddingTop: location.state.listPreview ? '25px' : '', paddingBottom: location.state.listPreview ? '25px' : '' }}
        >
          <div className="flex gap-8 items-center justify-between skill-heading">
            <HeadingText classString="text-neutral-800 mobile-heading-h6 font-medium skill-text" text={data.subModuleName} />
            <HeadingText classString="text-neutral-800 mobile-heading-h6 font-medium skill-text" text={`Topics: ${data.subModuleQues.length ?? 0}`} />
          </div>
          <div className="flex items-center justify-end gap-4">
            {!location.state.listPreview && (
              <CustomBtn text={data.editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" />
            )}
          </div>
        </div>
        <div className="flex flex-col w-955 px-10 w-full gap-6">
          <div className="flex flex-col gap-6">
            {data.subModuleQues?.map((question: ISkillChecklistSubModule, index: number) => {
              const isVisible = visibleDetails[index]
              return (
                <div key={index} className="bg-white rounded-3 question-container">
                  <div className="flex flex-col gap-6 p-8 bg-white rounded-3">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-6">
                        <div
                          className="flex items-center"
                          onClick={() => {
                            toggleDetails(index)
                          }}
                          style={{ transform: isVisible ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                        >
                          <DropDownIcon width={24} height={24} />
                        </div>
                        <HeadingText classString="w-full mobile-heading-h5 font-medium p-0 m-0" text={question.name} />
                      </div>
                      <div className="data-number para-p4 text-primary-500 rounded-2 item-center preview-count">{question.skill_checklist_question.length ?? 0}</div>
                    </div>
                    <div style={{ display: isVisible ? 'block' : 'none' }}>
                      <div className="flex flex-col sub-module">
                        {question.skill_checklist_question.map((question: ISkillChecklistQuestion, subModuleIndex: number) => {
                          return (
                            <div key={subModuleIndex} className="flex flex-col items-center justify-between divider">
                              <div className="flex flex-col gap-3 w-full">
                                <HeadingText classString="para-p2 font-medium text-neutral-800" text={question.question} />
                                <div className="grid grid-cols-4 gap-8 skill-rating">
                                  {[...Array(4)].map((_, index) => {
                                    return (
                                      <div key={index} className="flex flex-col gap-2 justify-center items-center rounded-2 border-neutral-200 subtopic-rating w-full">
                                        {index}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Form>
    </>
  )
}

export default PreviewSubtopic
