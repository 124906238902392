import { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, message } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_SHIFT_TYPE } from 'constants/Constant'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IShiftType } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddNewShiftType from './Modal/AddNewShiftType'
import { deleteShiftType, getAllSpecialityTypes } from './api'

const ShiftTypes = () => {
  const [typeData, setTypeData] = useState<IShiftType[]>([])
  const [editId, setEditId] = useState<string>('')
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(typeData?.length).fill(false))
  const [isAddNewShift, setIsAddNewShift] = useState<boolean>(false)
  const [isShiftTypeDeleted, setIsShiftTypeDeleted] = useState(false)
  const [shiftTypeData, setShiftTypeData] = useState({
    name: '',
    shift_type: '',
    status: '',
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.shiftTypes'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetShiftType()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!isAddNewShift, isShiftTypeDeleted])

  const handleGetData = async (record: IShiftType) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, name, shift_type, status } = record
    setEditId(id ?? '')
    setShiftTypeData({ name, shift_type, status })
    setIsAddNewShift(true)
  }

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleGetShiftType = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getAllSpecialityTypes()
    if (response?.status === 200) {
      setTypeData(response?.data?.data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteShiftType = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await deleteShiftType(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      setIsShiftTypeDeleted(true)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteConfirmModal = (id: string) => {
    void handleDeleteShiftType(id)
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: 1,
          onClick: () => {
            if (record.id !== undefined) {
              void handleGetData(record)
            }
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: 2,
          onClick: () => {
            if (record.status !== 'invited') {
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              setIsDeleteModalOpen(true)
              setDeleteId(record.id ?? '')
              setDeleteName(record.name)
            }
            handleActionMenuClick(index)
            setIsShiftTypeDeleted(false)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.shiftType.shiftTypeName'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 1009,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record.name} />
      },
    },
    {
      title: t('table.shiftType.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 300,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.shiftCancelReason.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 163,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className="shift-type-wrapper flex p-0 flex-col items-start gap-12 aline-self">
      <div className=" flex flex-col items-start gap-6 aline-self">
        <div className=" flex justify-between items-center aline-self">
          <p className="text-neutral-800 font-medium mobile-heading-h5">{t('heading.preDiemShiftType')}</p>
          <CustomBtn
            isIcon
            svgIcon={<PlusIcon />}
            text={t('button.addNew')}
            type="primary"
            className="rounded-3 border-0"
            onClick={() => {
              setEditId('')
              setIsAddNewShift(true)
            }}
          />
        </div>
        <div className="table-wrapper flex p-6 flex-col items-start gap-6 aline-self rounded-3 bg-white settings-box-shadow">
          <CustomTable name={t('label.shiftTypes')} column={columns} data={typeData?.filter((item) => item.shift_type === 'per_diem')} />
        </div>
      </div>
      <div className=" flex flex-col items-start gap-6 aline-self">
        <div className=" flex justify-between items-center aline-self">
          <p className="text-neutral-800 font-medium mobile-heading-h5">{t('heading.longTermShiftType')}</p>
        </div>
        <div className="table-wrapper flex p-6 flex-col items-start gap-6 aline-self rounded-3 bg-white settings-box-shadow">
          <CustomTable name={t('label.shiftTypes')} column={columns} data={typeData?.filter((item) => item.shift_type === 'long_term')} />
        </div>
      </div>
      <div className=" flex flex-col items-start gap-6 aline-self">
        <div className=" flex justify-between items-center aline-self">
          <p className="text-neutral-800 font-medium mobile-heading-h5">{t('heading.travelShiftType')}</p>
        </div>
        <div className="table-wrapper flex p-6 flex-col items-start gap-6 aline-self rounded-3 bg-white settings-box-shadow">
          <CustomTable name={t('label.shiftTypes')} column={columns} data={typeData?.filter((item) => item.shift_type === 'travel')} />
        </div>
      </div>
      {isAddNewShift && (
        <CustomModal
          open={isAddNewShift}
          centered
          footer={null}
          closable={true}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setIsAddNewShift(false)
          }}
          width={600}
          content={<AddNewShiftType setIsModalOpen={setIsAddNewShift} editId={editId} data={shiftTypeData} />}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_SHIFT_TYPE.CANCEL}
          okClick={() => {
            handleDeleteConfirmModal(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_SHIFT_TYPE.DELETE_DESC}
          mainHead={DELETE_SHIFT_TYPE.DELETE_HEAD_LOB}
          okText={DELETE_SHIFT_TYPE.DELETE}
        />
      )}
    </div>
  )
}

export default ShiftTypes
