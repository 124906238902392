import { useState } from 'react'
import { Form, message } from 'antd'
import { facilityProfile } from 'assets/imgs'
import { CalenderIcon, CameraIcon, EditPenIcon } from 'assets/svgs'
import { MAX_FILE_SIZE_MB } from 'constants/Constant'
import { commonInputFieldValidation, commonSingleSelectValidation, emailValidation, mobileValidation } from 'constants/Validation'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { mediaUpload } from 'services/FormDataServices'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'
import { validateFileSize } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomDatePickerField from 'components/common/DatePicker/CustomDatePicker'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import UploadMedia from 'components/common/UploadFile'

const DetailDataOne = [
  {
    id: 1,
    name: 'Contact Info',
    data: [
      {
        id: 1,
        head: 'CONTACT NO.',
        sub: '+1 123 456 7890',
      },
      {
        id: 2,
        head: 'EMAIL',
        sub: 'heberhealth@gmail.com',
      },
      {
        id: 3,
        head: 'ADDRESS',
        sub: '3825 Brownley DrMacon, GA 31206',
      },
      {
        id: 4,
        head: 'WEBSITE',
        sub: 'www.rockycare.com',
      },
    ],
  },
  {
    id: 2,
    name: 'Infrastructure',
    data: [
      {
        id: 1,
        head: 'TYPE',
        sub: 'Facility',
      },
      {
        id: 2,
        head: 'NUMBER OF BEDS',
        sub: '120',
      },
      {
        id: 3,
        head: 'FACILITY TYPE',
        sub: 'Nursing Home',
      },
    ],
  },
  {
    id: 3,
    name: 'Rate Groups',
    data: [
      {
        id: 1,
        head: 'CLIENT DEFAULT',
        sub: 'Standard Rates',
        supSub: 'Holiday Rates',
      },
      {
        id: 2,
        head: 'PER DIEM',
        sub: '-',
      },
      {
        id: 3,
        head: 'CONTRACT',
        sub: '-',
      },
      {
        id: 4,
        head: 'TRAVEL',
        sub: '-',
      },
    ],
  },
  {
    id: 4,
    name: 'Referral',
    data: [
      {
        id: 1,
        head: 'REFERRAL SOURCE',
        sub: '-',
      },
      {
        id: 2,
        head: 'Referred By',
        sub: 'Catherine James',
      },
    ],
  },
]
const DetailData = [
  {
    id: 1,
    name: 'Client Management',
    data: [
      {
        id: 1,
        head: 'STAFFING SPECIALIST',
        sub: 'Cristy Holloway',
      },
      {
        id: 2,
        head: 'SALES REP/ACCT MANAGER',
        sub: 'Not Assigned',
      },
      {
        id: 3,
        head: 'BILLING SPECIALIST',
        sub: 'Not Assigned',
      },
      {
        id: 4,
        head: 'CONTRACT DATE',
        sub: '07/30/2023',
      },
      {
        id: 5,
        head: 'NEW CONTRACT DATE',
        sub: '-',
      },
    ],
  },
  {
    id: 2,
    name: 'General Instructions',
    data: [
      {
        id: 1,
        head: 'FIRST SHIFT:',
        sub: '-',
      },
      {
        id: 2,
        head: 'ORIENTATION',
        sub: 'Arrive 10 Minutes early',
      },
      {
        id: 3,
        head: 'SHIFT DESCRIPTION',
        sub: '8 Hour shifts',
      },
      {
        id: 4,
        head: 'LUNCH/BREAKS',
        sub: '30 minute lunch required',
      },
    ],
  },
]

const Detail = () => {
  const [imageLink, setImageLink] = useState<string>('')
  const [isEdit, setIsEdit] = useState(false)
  const [isImageLoading, setImageLoading] = useState(false)

  const [formData, setFormData] = useState({ image: '' })
  const [error, setError] = useState({
    email: '',
    phone: '',
    website: '',
    street_address: '',
    apartment_building: '',
    zipCode: '',
    town: '',
    state: '',
    country: '',
    facility_type: '',
    no_of_bed: '',
    staffingSpecialist: '',
    salesRep: '',
    billingSpecialist: '',
    contract_date: '',
    orientation: '',
    shiftDescription: '',
    lunch: '',
    timeKipping: '',
  })

  const [form] = Form.useForm()

  const handleEditClick = () => {
    setIsEdit(!isEdit)
  }

  const onFinish = (values: any) => {
    setIsEdit(!isEdit)
  }

  const handleMediaChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (!file.type.startsWith('image/')) {
        setError((prev) => {
          return { ...prev, image: VALIDATION_ERROR.IMAGE.ONLYIMG }
        })
        return
      }

      const validSize = validateFileSize(file, MAX_FILE_SIZE_MB)
      if (!validSize) {
        return
      }

      setImageLoading(true)
      const imgformData = new FormData()
      imgformData.append('image', file)
      imgformData.append('folder', 'admin')
      const response = await mediaUpload(imgformData)
      if (response?.data?.statusCode === 1) {
        setImageLink(response?.data?.data?.image)
        setFormData({
          ...formData,
          image: response?.data?.data?.image,
        })
        void message.success(response?.data?.message)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
      setImageLoading(false)
    }
  }

  return (
    <div className="tab-detail-wrapper flex py-10 justify-center items-start aline-self rounded-4 bg-white box-shadow ">
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
        className="tab-detail-container relative flex w-full flex-col items-start gap-12 "
      >
        <div className="flex items-center gap-4">
          {!isEdit ? (
            <img src={facilityProfile} height={100} width={100} alt="profile" />
          ) : (
            <Form.Item name="image">
              <UploadMedia
                className="flex justify-center items-center "
                icon={<CameraIcon />}
                imgHeight={100}
                imgWidth={100}
                imageUrl={formData?.image}
                baseUrl={process.env.REACT_APP_S3_URL}
                edit={isEdit}
                applyOverlay={true}
                handleChange={(e) => {
                  void handleMediaChange(e)
                }}
              />
            </Form.Item>
          )}
          <p className="text-neutral-800 mobile-heading-h3 font-medium">Rocky Mtn. Care of Heber</p>
          {isEdit ? (
            <CustomBtn text={t('button.saveBtn')} type="default" htmlType="submit" className="user-Icon profile-edit" />
          ) : (
            <CustomBtn
              isIcon
              svgIcon={<EditPenIcon className="svg" />}
              text={t('button.editProfile')}
              type="default"
              className="user-Icon profile-edit"
              onClick={handleEditClick}
            />
          )}
        </div>
        <div className="facility-detail-wrapper grid grid-cols-2 justify-center items-start gap-10 aline-self ">
          {!isEdit ? (
            <>
              <div className="flex flex-col items-center gap-10">
                {DetailDataOne.map((data, index: number) => {
                  return (
                    <div className="flex flex-col items-start rounded-4 border-stroke bg-white overflow-hidden aline-self box-shadow" key={data.id}>
                      <div className=" flex py-2 px-4 items-center gap-6 aline-self bg-primary-50  justify-between">
                        <p className="text-neutral-800 mobile-heading-h5 font-medium ">{data.name}</p>
                        {DetailDataOne.length - 2 === index && <EditPenIcon />}
                      </div>
                      <div className="flex p-4 flex-col item-start gap-4 rounded-4">
                        {data.data.map((item) => {
                          return (
                            <div className="flex flex-col items-start gap-2 aline-self" key={item.id}>
                              <p className=" text-neutral-800 para-p2">{item.head}</p>
                              <div className="flex gap-2">
                                <p className={` text-neutral-800 mobile-heading-h6 ${item.supSub ? 'text-decoration-line' : ''}`}>{item.sub}</p>
                                {item.supSub && <p className={` text-neutral-800 mobile-heading-h6 ${item.supSub ? 'text-decoration-line' : ''}`}>{item?.supSub}</p>}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="flex flex-col items-center gap-10">
                {DetailData.map((data) => {
                  return (
                    <div className="flex flex-col items-start rounded-4 border-stroke bg-white overflow-hidden aline-self box-shadow" key={data.id}>
                      <div className=" flex py-2 px-4 items-center gap-6 aline-self bg-primary-50">
                        <p className=" text-neutral-800 mobile-heading-h5 font-medium ">{data.name}</p>
                      </div>
                      <div className="box-content-wrapper flex p-4 flex-col item-start gap-4 rounded-4">
                        {data.data.map((item) => {
                          return (
                            <div className="flex flex-col items-start gap-2 aline-self" key={item.id}>
                              <p className=" text-neutral-800 para-p2">{item.head}</p>
                              <div className="flex gap-2">
                                <p className="text-neutral-800 mobile-heading-h6">{item.sub}</p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-center gap-10 w-full">
                <div className="flex flex-col items-start gap-6 w-full">
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{t('heading.contactInfo')}</p>
                  <div className="flex flex-col items-start w-full">
                    <InputField name="email" placeholder={t('placeholder.email')} rules={emailValidation} error={error.email} />
                    <InputField name="phone" placeholder={t('placeholder.contactNumber')} rules={mobileValidation} error={error.phone} />
                    <InputField name="website" placeholder={t('placeholder.website')} rules={commonInputFieldValidation('your website name')} error={error.website} />
                  </div>
                </div>
                <div className="flex flex-col items-start gap-6 w-full">
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{t('heading.address')}</p>
                  <div className="flex flex-col items-start w-full">
                    <InputField
                      name="street_address"
                      placeholder={t('placeholder.streetAddress')}
                      rules={commonInputFieldValidation('your street address')}
                      error={error.street_address}
                    />
                    <InputField
                      name="apartment_building"
                      placeholder={t('placeholder.apartmentBuilding')}
                      rules={commonInputFieldValidation('your apartment name/no ')}
                      error={error.apartment_building}
                    />
                    <InputField name="zipCode" placeholder={t('placeholder.zipCode')} rules={commonInputFieldValidation('zip code')} error={error.zipCode} />
                    <InputField name="town" placeholder={t('placeholder.townCity')} rules={commonInputFieldValidation('town/city name')} />
                    <FloatingLabelSelect
                      label={t('placeholder.state')}
                      options={[{ label: 'abc', value: 'abc' }]}
                      name="state"
                      rules={commonSingleSelectValidation('state')}
                      error={error.state}
                    />
                    <FloatingLabelSelect
                      label={t('placeholder.country')}
                      options={[{ label: 'abc', value: 'abc' }]}
                      name="country"
                      rules={commonSingleSelectValidation('country')}
                      error={error.country}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start gap-6 w-full">
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{t('heading.infrastructure')}</p>
                  <div className="flex flex-col items-start w-full">
                    <FloatingLabelSelect
                      label={t('placeholder.facilityType')}
                      options={[{ label: 'abc', value: 'abc' }]}
                      name="facility_type"
                      rules={commonSingleSelectValidation('facility type')}
                      error={error.facility_type}
                    />
                    <InputField name="no_of_bed" error={error.no_of_bed} placeholder={t('placeholder.noOfBeds')} rules={commonInputFieldValidation('no of beds')} />
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-10 w-full">
                <div className="flex flex-col items-start gap-6 w-full">
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{t('heading.clientManage')}</p>
                  <div className="flex flex-col items-start w-full">
                    <InputField
                      name="staffingSpecialist"
                      error={error.staffingSpecialist}
                      placeholder={t('placeholder.staffingSpecialist')}
                      rules={commonInputFieldValidation('staffing Specialist')}
                    />
                    <InputField name="salesRep" error={error.salesRep} placeholder={t('placeholder.salesRep')} rules={commonInputFieldValidation('acct manager name')} />
                    <InputField
                      name="billingSpecialist"
                      error={error.billingSpecialist}
                      placeholder={t('placeholder.billingSpecialist')}
                      rules={commonInputFieldValidation('Billing Specialist')}
                    />
                    <CustomDatePickerField
                      name="contract_date"
                      placeholder={t('placeholder.contractDate')}
                      rules={commonSingleSelectValidation('contract date')}
                      suffixIcon={<CalenderIcon />}
                      mainClass="contract-date"
                      error={error.contract_date}
                    />
                    <CustomDatePickerField name="new_contract_date" placeholder={t('placeholder.newContractDate')} mainClass="contract-date" />
                  </div>
                </div>
                <div className="flex flex-col items-start gap-6 w-full">
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{t('heading.generalInstruction')}</p>
                  <div className="flex flex-col items-start w-full">
                    <InputField name="firstShift" placeholder={t('placeholder.firstShift')} />
                    <InputField name="orientation" error={error.orientation} placeholder={t('placeholder.orientation')} rules={commonInputFieldValidation('orientation')} />
                    <InputField
                      name="shiftDescription"
                      error={error.shiftDescription}
                      placeholder={t('placeholder.shiftDescription')}
                      rules={commonInputFieldValidation('shift description')}
                    />
                    <InputField name="lunch" placeholder={t('placeholder.lunch')} error={error.lunch} rules={commonInputFieldValidation('lunch breck duration')} />
                    <InputField name="dressCode" placeholder={t('placeholder.dressCode')} />
                    <InputField name="parkingInstruction" placeholder={t('placeholder.parkingInstruction')} />
                    <InputField name="doorLock" placeholder={t('placeholder.doorLock')} />
                    <InputField name="timeKipping" error={error.timeKipping} placeholder={t('placeholder.timeKipping')} rules={commonInputFieldValidation('timeKipping')} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Form>
    </div>
  )
}

export default Detail
