import React, { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, Popover } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, DropDownIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddNewCategory from './Modal/AddNewCategory'
import AddNewDocument from './Modal/AddNewDocument'

const listData = [
  {
    id: 1,
    name: 'Contracts',
    table: [
      {
        id: '326599',
        essential: 'Yes',
        document: 'Nursing License - RN',
      },
    ],
  },
  {
    id: 2,
    name: 'Agreements',
    table: [
      {
        id: '65698965',
        essential: '-',
        document: 'Drug Screen',
      },
    ],
  },
  {
    id: 3,
    name: 'Other Document',
    table: [
      {
        id: '131654',
        essential: '-',
        document: 'Drug Screen',
      },
    ],
  },
]

const Document = () => {
  const [addNewCategory, setAddNewCategory] = useState(false)
  const [addNewDocuments, setAddNewDocuments] = useState(Array(listData.length).fill(false))
  const [showTables, setShowTables] = useState(Array(listData.length).fill(false))
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData.length).fill(false))
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.document'),
        isBack: true,
      })
    )
  }, [dispatch])

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleClick = (index: number) => {
    setShowTables((prevShowTables) => {
      const newShowTables = prevShowTables.map((show, idx) => (idx === index ? !show : false))
      return newShowTables
    })
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.document.document'),
      dataIndex: 'document',
      key: 'document',
      align: 'start',
      width: 865,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record.document} />
      },
    },
    {
      title: t('table.document.essential'),
      dataIndex: 'essential',
      key: 'essential',
      align: 'start',
      width: 380,
    },
    {
      title: t('table.shiftCancelReason.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 163,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]
  return (
    <div className="document-setting-wrapper">
      <div className="flex flex-col items-start gap-4 w-full">
        <div className="flex justify-between items-center w-full">
          <p className="text-neutral-800 desktop-head-h5 font-medium">{t('label.document')}</p>
          <Popover
            trigger={['click']}
            content={<AddNewCategory setIsModalOpen={setAddNewCategory} />}
            placement="bottomRight"
            open={addNewCategory}
            rootClassName="general-setting-popover"
          >
            <CustomBtn
              isIcon
              svgIcon={<PlusIcon />}
              text={t('button.addNewCategory')}
              type="primary"
              className="rounded-3 border-0"
              onClick={() => {
                setAddNewCategory(true)
              }}
            />
          </Popover>
        </div>
        {listData.map((data, index) => {
          return (
            <div className="table-wrapper" key={data.id}>
              <div className="flex justify-between items-center aline-self cursor-pointer">
                <div
                  className="flex items-center gap-2"
                  onClick={() => {
                    handleClick(index)
                  }}
                >
                  <div
                    className="flex items-center justify-end"
                    style={{ transform: showTables[index] === true ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                  >
                    <DropDownIcon />
                  </div>
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{data.name}</p>
                </div>
                <div className="flex items-center gap-6">
                  <DeleteIcon className="neutral-icon" />
                  <EditPenIcon className="neutral-icon" />
                  <Popover
                    trigger={['click']}
                    content={
                      <AddNewDocument
                        setIsModalOpen={(open) => {
                          setAddNewDocuments((prev) => [...prev.slice(0, index), open, ...prev.slice(index + 1)])
                        }}
                      />
                    }
                    placement="bottomRight"
                    open={addNewDocuments[index]}
                    rootClassName="general-setting-popover"
                  >
                    <PlusIcon
                      className="neutral-icon"
                      onClick={() => {
                        setAddNewDocuments((prev) => [...prev.slice(0, index), true, ...prev.slice(index + 1)])
                      }}
                    />
                  </Popover>
                </div>
              </div>
              {showTables[index] && <CustomTable column={columns} data={data.table} />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Document
