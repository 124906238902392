import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { confirmPwdValidation, currentPwdValidation, newPwdValidation } from 'constants/Validation'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IChangePwd, type IFieldData, type IPasswordForm } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import PasswordField from 'components/common/PasswordField/PasswordField'

import { changePassword } from '../api'

const ChangePwdForm = ({ onChange, onOk, email }: IPasswordForm) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [error, setError] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const handleFormFinish = (values: IChangePwd) => {
    if (email) {
      void handleUpdatePassword(values)
    }
  }

  const handleUpdatePassword = async (values: IChangePwd) => {
    const newValue = { email, old_password: values.oldPassword, new_password: values.newPassword }
    dispatch(setGlobalLoader(true))
    const response = await changePassword(newValue)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      if (onOk) {
        onOk()
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      className="flex flex-col change-password-modal"
      requiredMark={false}
      onFinish={handleFormFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div>
        <PasswordField placeholder={t('label.profileForm.currentPass')} rules={currentPwdValidation} error={error.oldPassword} name="oldPassword" inputClass={' password-input'} />
      </div>
      <div>
        <PasswordField placeholder={t('label.profileForm.newPass')} inputClass={' password-input'} rules={newPwdValidation} error={error.newPassword} name="newPassword" />
      </div>
      <div>
        <PasswordField
          placeholder={t('label.profileForm.conNewPass')}
          rules={confirmPwdValidation}
          name="confirmPassword"
          error={error.confirmPassword}
          inputClass={' password-input'}
        />
      </div>
      <div className="footer-modal flex items-end justify-end">
        <CustomBtn text={t('button.updateBtn')} type="primary" htmlType="submit" className="w-160 rounded-3 border-0" />
      </div>
    </Form>
  )
}

export default ChangePwdForm
