import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { type IAddCategory, type ICategoryModalsProps, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

const AddNewCategory = ({ setActionMenu, setIsModalOpen, data, editId }: ICategoryModalsProps) => {
  const [form] = Form.useForm()
  const [error, setError] = useState({
    name: '',
  })

  useEffect(() => {
    if (editId && data) {
      console.log('data', data)
      form.setFieldValue('name', data.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, data])

  const onFinish = (values: IAddCategory) => {
    console.log('on submit data', values)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={editId ? t('label.editCategory') : t('label.addNewCategory')} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start gap-5  w-full">
            <div className="flex flex-col w-full">
              <div className="h-64 aline-self">
                <InputField rules={commonInputFieldValidation(VALIDATION_STRINGS.CATEGORY)} placeholder={t('placeholder.categoryName')} name="name" value={getValue('name')} />
              </div>
              {error?.name && <p className="error-container">{error.name}</p>}
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  if (setActionMenu) setActionMenu(false)
                  if (setIsModalOpen) setIsModalOpen(null)
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddNewCategory
