import { Modal } from 'antd'
import { type ICustomModal } from 'types'

const CustomModal = ({ title, open, onOk, onCancel, okText, cancelText, content, closable, showCancelBtn, footer, centered, classStrings, width }: ICustomModal) => {
  return (
    <Modal
      title={title}
      open={open}
      centered={centered}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      footer={footer}
      closable={closable}
      maskClosable
      cancelText={cancelText}
      cancelButtonProps={{ style: { display: showCancelBtn } }}
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      className={`custom-modal ${classStrings}`}
      width={width}
    >
      {content}
    </Modal>
  )
}
export default CustomModal
