import React, { useEffect, useState } from 'react'
import { Form, Radio } from 'antd'
import { settingTab, verifyFacility } from 'constants/Constant'
import { selectOptionValidation } from 'constants/Validation'
import { t } from 'i18next'
import { type IGeneralSettingProps } from 'types'

import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

const GeneralSettings = ({ mainClass, defaultOrderTypeData, workCompCodeData, setDefaultOrderType, workCompCode, setWorkCompCode }: IGeneralSettingProps) => {
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>(defaultOrderTypeData ?? 'per_diem')

  const customData = [
    {
      label: workCompCodeData ?? '',
      value: workCompCodeData ?? '',
    },
  ]

  useEffect(() => {
    setDefaultOrderType?.(selectedRadioValue)
  }, [selectedRadioValue])

  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <div className={`general-settings-container flex p-10 flex-col items-center gap-6 rounded-4 border-stroke ${mainClass} `}>
      <Form className="general-setting-wrapper flex justify-between items-start aline-self gap-4">
        <div className="order-type-wrapper flex w-full flex-col items-start gap-4">
          <p className="text-neutral-800 activity-text font-medium">{verifyFacility.DEFAULT_ORDER_TYPE}</p>
          <div className={`cursor-pointer w-full MB-6`}>
            <Form.Item name="facility" className="w-full" rules={selectOptionValidation}>
              <Radio.Group
                onChange={(e) => {
                  setSelectedRadioValue(e.target.value)
                }}
                defaultValue={selectedRadioValue}
                rootClassName="setting-general-radio flex flex-col items-start gap-4 w-full aline-self"
              >
                <Radio value={settingTab.PER_DIEM_VALUE}>{settingTab.PER_DIEM}</Radio>
                <Radio value={settingTab.LONG_TERM_CONT_VALUE}>{settingTab.LONG_TERM_CONT}</Radio>
                <Radio value={settingTab.TRAVEL_ASSIGN_VALUE}>{settingTab.TRAVEL_ASSIGN}</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          {/* {error?.facility && <p className={` error-container ${error?.facility ? 'mb-6' : ''}`}>{error.facility}</p>} */}
        </div>
        <div className="order-type-wrapper w-full">
          <p className="text-neutral-800 activity-text font-medium pb-4">{settingTab.OTHER_SETTINGS}</p>
          <div className="flex flex-col justify-center items-start gap-2 aline-self w-full">
            <p className="text-neutral-800 para-p2 font-medium">{settingTab.WORK_COME_CODE}</p>
            <div className="flex items-start gap-4 aline-self w-full ">
              <div className="flex flex-col w-full">
                <FloatingLabelSelect label={t('placeholder.facilityType')} options={customData} name="custom" labelClass="facility-float" />
              </div>
              <p className="text-neutral-800 para-p3 font-medium pt-22">{settingTab.OR}</p>
              <div className="flex flex-col w-full">
                <InputField
                  onChange={(e) => {
                    if (setWorkCompCode) {
                      setWorkCompCode(e.target.value)
                    }
                  }}
                  value={workCompCode}
                  defaultValue={workCompCode}
                  placeholder={t('placeholder.enterFloorName')}
                  name="floor_name"
                  wrapperClass="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default GeneralSettings
