// builtin
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import AdminContacts from 'pages/adminContacts/AdminContacts'
import AdminSettings from 'pages/AdminSettings'
import AdminUserRegister from 'pages/authflow/AdminUserRegister'
import ForgotPassword from 'pages/authflow/ForgotPassword'
import LinkExpiry from 'pages/authflow/LinkExpiry'
import Login from 'pages/authflow/Login'
import ResetPassword from 'pages/authflow/ResetPassword'
import AutoSchedule from 'pages/autoSchedule/AutoSchedule'
import AddEditTest from 'pages/competencyTest/addEditTest/AddEditTest'
import CompetencyTest from 'pages/competencyTest/CompetencyTest'
import PreviewTest from 'pages/competencyTest/preview/PreviewTest'
import QuizFaq from 'pages/competencyTest/quizFaq/quizFaq'
import ComplianceManager from 'pages/complianceManager/ComplianceManager'
import CredentialRequirement from 'pages/complianceManager/CredentialRequirement'
import DashBoard from 'pages/DashBoard'
import Document from 'pages/document/Document'
import DocumentCategoryForAdmin from 'pages/documentAdminSetting'
import VerificationRequest from 'pages/facilities/components/VerificationRequest'
import Facility from 'pages/facilities/Facility'
import ProviderProfile from 'pages/facilities/facilityProfile/components/Provider/ProviderProfile'
import FacilityProfile from 'pages/facilities/facilityProfile/FacilityProfile'
import FacilityGeneralSetting from 'pages/facilityGeneralSetting/FacilityGeneralSetting'
import ProviderSetting from 'pages/facilityProviderSetting/ProviderSetting'
import FacilityVerificationRejection from 'pages/facilityVerificationRejection/FacilityVerificationRejection'
import GeneralSetting from 'pages/generalSetting/GeneralSetting'
import License from 'pages/license/License'
import LineOfBusiness from 'pages/lineOfBusiness/LineOfBusiness'
import MyFacility from 'pages/myFacility/MyFacility'
import MyProfile from 'pages/myProfile/MyProfile'
import Provider from 'pages/provider'
import ProviderProfileSettings from 'pages/providerProfile/ProviderProfile'
import ProviderVerificationRejection from 'pages/providerVerificationRejection/ProviderVerificationRejection'
import AddEditReferanceForm from 'pages/referanceForm/AddEditReferanceForm'
import ReferanceForm from 'pages/referanceForm/ReferanceForm'
import ReferanceFormPreview from 'pages/referanceForm/ReferanceFormPreview'
import AddEditRole from 'pages/roleManagement/addEditRole/AddEditRole'
import RoleManagement from 'pages/roleManagement/RoleManagement'
import ScheduleAndRequest from 'pages/schedule-and-request/ScheduleAndRequest'
import ShiftCancelReasonProvider from 'pages/shiftCancelReasonProvider/ShiftCancelReasonProvider'
import ShiftSetting from 'pages/shiftSetting/ShiftSetting'
import ShiftTypes from 'pages/shiftTypes/ShiftTypes'
import AddEditCheckList from 'pages/skillCheckList/addEditChecklist/AddEditCheckList'
import PreviewCheckList from 'pages/skillCheckList/preview/PreviewChecklist'
import PreviewSubtopic from 'pages/skillCheckList/preview/PreviewSubtopic'
import SkillCheckList from 'pages/skillCheckList/SkillCheckList'
import Specialties from 'pages/specialty/Specialties'
import StatusOption from 'pages/statusOption'
import TimeAndAttendance from 'pages/timeAndAttendance/TimeAndAttendance'
import TimeEntryApproval from 'pages/timeEntryApproval/TimeEntryApproval'

import NoAccess from 'components/NoAccess'

import PrivateRoute from './PrivateRoutes'
import PublicRoute from './PublicRoutes'

const AppRoutes = () => {
  const { hasPermission } = useAuth()

  const permissionRoute = (element: React.ReactNode, requiredPermissions: string, subSection: string, path?: string, index?: boolean) => {
    return hasPermission(requiredPermissions, subSection, path) ? <Route index={index} path={path} element={element} /> : <Route path={'/'} element={<NoAccess />} />
  }
  return (
    <Routes>
      {/* public routes */}
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/admin-user-register" element={<AdminUserRegister />} />
        <Route path="/link-expiry" element={<LinkExpiry />} />
      </Route>

      {/* private routes */}
      <Route element={<PrivateRoute />}>
        {permissionRoute(<DashBoard />, '', '', '/', true)}
        {permissionRoute(<MyProfile />, '', '', '/my-profile')}
        {permissionRoute(<AdminSettings />, '', '', '/settings')}
        {permissionRoute(<AdminContacts />, '', '', '/settings/user')}
        {permissionRoute(<RoleManagement />, '', '', '/settings/user/role-management')}
        {permissionRoute(<AddEditRole />, '', '', '/settings/user/role-management/create-role')}
        {permissionRoute(<AddEditRole />, '', '', '/settings/user/role-management/edit-role/:editId')}
        {permissionRoute(<License />, 'master_admin_setting', 'certification', '/settings/license')}
        {permissionRoute(<Specialties />, 'master_admin_setting', 'specialty', '/settings/specialties')}
        {permissionRoute(<GeneralSetting />, '', '', '/settings/general-settings')}
        {permissionRoute(<TimeEntryApproval />, '', '', '/settings/time-entry-approval')}
        {permissionRoute(<ScheduleAndRequest />, '', '', '/settings/schedule-and-request')}
        {permissionRoute(<ProviderProfileSettings />, '', '', '/settings/provider-profile')}
        {permissionRoute(<ShiftSetting />, '', '', '/settings/shifts-settings')}
        {permissionRoute(<ShiftTypes />, '', '', '/settings/shift-type')}
        {permissionRoute(<SkillCheckList />, '', '', '/settings/skill-checklist')}
        {permissionRoute(<AddEditCheckList />, '', '', '/settings/skill-checklist/add')}
        {permissionRoute(<AddEditCheckList />, '', '', '/settings/skill-checklist/edit/:editId')}
        {permissionRoute(<PreviewCheckList />, '', '', '/settings/skill-checklist/add/preview')}
        {permissionRoute(<PreviewSubtopic />, '', '', '/settings/skill-checklist/add/preview/subtopic')}
        {permissionRoute(<PreviewCheckList />, '', '', '/settings/skill-checklist/edit/preview')}
        {permissionRoute(<PreviewSubtopic />, '', '', '/settings/skill-checklist/edit/preview/subtopic')}
        {permissionRoute(<PreviewCheckList />, '', '', '/settings/skill-checklist/preview')}
        {permissionRoute(<PreviewSubtopic />, '', '', '/settings/skill-checklist/preview/subtopic')}
        {permissionRoute(<FacilityGeneralSetting />, '', '', '/settings/facility-general-settings')}
        {permissionRoute(<ProviderSetting />, '', '', '/settings/facility-provider-setting')}
        {permissionRoute(<CompetencyTest />, '', '', '/settings/compentency-test')}
        {permissionRoute(<AddEditTest />, '', '', '/settings/compentency-test/add-test')}
        {permissionRoute(<AddEditTest />, '', '', '/settings/compentency-test/edit-test/:editId')}
        {permissionRoute(<PreviewTest />, '', '', '/settings/compentency-test/preview')}
        {permissionRoute(<PreviewTest />, '', '', '/settings/compentency-test/add-test/preview')}
        {permissionRoute(<PreviewTest />, '', '', '/settings/compentency-test/edit-test/preview')}
        {permissionRoute(<PreviewTest />, '', '', '/settings/compentency-test/preview')}
        {permissionRoute(<QuizFaq />, '', '', '/settings/compentency-test/quiz-faq')}
        {permissionRoute(<ProviderVerificationRejection />, '', '', '/settings/provider-verification-rejection')}
        {permissionRoute(<TimeAndAttendance />, '', '', '/settings/time-and-attendance')}
        {permissionRoute(<MyFacility />, '', '', '/settings/my-facility')}
        {permissionRoute(<Document />, '', '', '/settings/document-categories')}
        {permissionRoute(<LineOfBusiness />, '', '', '/settings/line-of-business')}
        {permissionRoute(<FacilityVerificationRejection />, '', '', '/settings/facility-verification-rejection')}
        {permissionRoute(<ComplianceManager />, '', '', '/settings/compliance-manager')}
        {permissionRoute(<CredentialRequirement />, '', '', '/settings/compliance-manager/credential-requirement')}
        {permissionRoute(<ShiftCancelReasonProvider />, '', '', '/settings/shift-reject-reason')}
        {permissionRoute(<Facility />, '', '', '/facilities')}
        {permissionRoute(<FacilityProfile />, '', '', '/facilities/:id')}
        {permissionRoute(<ProviderProfile />, '', '', '/facilities/:id/provider-profile')}
        {permissionRoute(<VerificationRequest />, '', '', '/facilities/verification-request')}
        {permissionRoute(<AutoSchedule />, '', '', '/settings/auto-schedule')}
        {permissionRoute(<Provider />, '', '', '/providers')}
        {permissionRoute(<ReferanceForm />, '', '', '/settings/referance-form-setting')}
        {permissionRoute(<AddEditReferanceForm />, '', '', '/settings/referance-form-setting/add-form')}
        {permissionRoute(<AddEditReferanceForm />, '', '', '/settings/referance-form-setting/edit-form/:editId')}
        {permissionRoute(<ReferanceFormPreview />, '', '', '/settings/referance-form-setting/referance-form-preview')}
        {permissionRoute(<ReferanceFormPreview />, '', '', '/settings/referance-form-setting/add-form/referance-form-preview')}
        {permissionRoute(<ReferanceFormPreview />, '', '', '/settings/referance-form-setting/edit-form/referance-form-preview')}
        {permissionRoute(<StatusOption />, '', '', '/settings/status-option')}
        {permissionRoute(<DocumentCategoryForAdmin />, '', '', '/settings/admin-document-category')}
      </Route>

      {/* page not found */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default AppRoutes
