/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Image, message, Spin } from 'antd'
import { profile } from 'assets/imgs'
import { CameraIcon, Edit } from 'assets/svgs'
import { MAX_FILE_SIZE_MB } from 'constants/Constant'
import { emailValidation, mobileValidation, nameValidation } from 'constants/Validation'
import { VALIDATION_ERROR, VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import ChangePwdForm from 'pages/myProfile/modal/ChangePwdForm'
import { mediaUpload } from 'services/FormDataServices'
import { setProfileData } from 'store/slice/AuthSlice'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IProfileFormDataType } from 'types'
import { handleFieldsChange } from 'utils/Helper'
import { validateFileSize } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import UploadMedia from 'components/common/UploadFile'

import { editProfileData, getProfileData } from './api'

import '../../style/pages/MyProfile.scss'

const MyProfile = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isEdit, setEditData] = useState<boolean>(false)
  const [openModal, setModalOpen] = useState<boolean>(false)
  const [imageResponse, setImageResponse] = useState()
  const [formData, setFormData] = useState<IProfileFormDataType>({
    image: '',
    full_name: '',
    first_name: '',
    last_name: '',
    role: '',
    email: '',
    user_name: '',
    mobile_no: '',
    base_url: '',
  })
  const [error, setError] = useState({
    image: '',
    first_name: '',
    last_name: '',
    role: '',
    email: '',
    mobile_no: '',
  })
  const [isImageLoading, setImageLoading] = useState(false)
  const form = Form.useForm()[0]

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.profile'),
      })
    )
  }, [dispatch, t])

  useEffect(() => {
    void handleGetProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  const showModal = () => {
    setModalOpen(true)
  }

  const hideModal = () => {
    setModalOpen(false)
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const newValues = { ...values, image: imageResponse }
        void handleUpdateProfile(newValues)
      })
      .catch((errorInfo) => {
        if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
          console.error(errorInfo.errorFields[0].errors[0])
        } else {
          console.error('Validation failed')
        }
      })
  }

  const handleUpdateProfile = async (values: IProfileFormDataType) => {
    dispatch(setGlobalLoader(true))
    const response = await editProfileData(values)
    if (response?.data.statusCode === 1) {
      setEditData(false)
      const profileData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile_no: values.mobile_no,
        image: imageResponse,
        base_url: formData?.base_url,
      }
      dispatch(setProfileData(profileData))
      void message.success(response?.data?.message)
      void handleGetProfile()
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetProfile = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getProfileData()
    if (response?.data?.statusCode === 1) {
      const resData = {
        full_name: String(response?.data?.data.first_name) + ' ' + String(response?.data?.data.last_name),
        first_name: response?.data?.data.first_name,
        last_name: response?.data?.data.last_name,
        role: response?.data?.data.role.name,
        email: response?.data?.data.email,
        user_name: response?.data?.data.user_name,
        mobile_no: response?.data?.data.mobile_no,
        image: response?.data?.data?.image,
        base_url: response?.data?.data?.base_url,
      }
      setImageResponse(response?.data?.data.image)
      setFormData(resData)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleMediaChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (!file.type.startsWith('image/')) {
        setError((prev) => {
          return { ...prev, image: VALIDATION_ERROR.IMAGE.ONLYIMG }
        })
        return
      }

      const validSize = validateFileSize(file, MAX_FILE_SIZE_MB)
      if (!validSize) {
        return
      }

      setImageLoading(true)
      const imgformData = new FormData()
      imgformData.append('image', file)
      imgformData.append('folder', 'admin')
      const response = await mediaUpload(imgformData)
      if (response?.data?.statusCode === 1) {
        setImageResponse(response?.data?.data?.image)
        setFormData({
          ...formData,
          image: response?.data?.data?.image,
        })
        void message.success(response?.data?.message)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
      setImageLoading(false)
    }
  }

  return (
    <div className="container user-profile-container shadow">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        className="flex justify-center items-center user-form-tag"
        requiredMark={false}
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="flex flex-col items-center gap-16 user-profile-form">
          <div className="flex flex-col">
            <div className="flex items-start user-avtar">
              <HeadingText text={t('profile.avatar')} classString="mobile-heading-h3 font-medium text-neutral-800 user-text" />
              {isEdit ? (
                isImageLoading ? (
                  <div className="w-200 h-200 border-stroke rounded-full flex items-center justify-center">
                    <Spin size="large" />
                  </div>
                ) : (
                  <UploadMedia
                    className="flex justify-center items-center"
                    icon={<CameraIcon />}
                    imgHeight={200}
                    imgWidth={200}
                    handleChange={(e) => {
                      dispatch(setGlobalLoader(true))
                      void handleMediaChange(e)
                      dispatch(setGlobalLoader(false))
                    }}
                    imageUrl={formData?.image}
                    baseUrl={formData?.base_url}
                    edit={isEdit}
                    applyOverlay={true}
                  />
                )
              ) : (
                <Image
                  width={200}
                  height={200}
                  preview={false}
                  src={formData?.image && formData?.base_url ? `${formData.base_url}${formData.image}` : profile}
                  className="rounded-100 object-cover"
                />
              )}
              {isEdit ? (
                <CustomBtn isIcon={false} text={t('button.saveBtn')} onClick={handleSubmit} />
              ) : (
                <CustomBtn
                  className="user-Icon"
                  isIcon={true}
                  svgIcon={<Edit className="svg" />}
                  text={t('button.editBtn')}
                  onClick={() => {
                    setEditData(true)
                  }}
                />
              )}
            </div>
            {error.image && isEdit && <p className="error-container flex justify-center">{error.image}</p>}
          </div>
          <div className="flex justify-center items-start user-detail">
            <HeadingText text={t('profile.details')} classString="mobile-heading-h3 font-medium text-neutral-800 user-text" />
            <div className="flex flex-col justify-center user-form">
              {isEdit ? (
                <div className="w-full">
                  <div className={`flex edit-name gap-6 h-64 ${error.last_name || error.first_name ? '' : 'mb-6'} `}>
                    <div className="flex w-50">
                      <InputField
                        placeholder={t('label.profileForm.fname')}
                        disabled={!isEdit}
                        rules={nameValidation(VALIDATION_STRINGS.FIRST_NAME)}
                        name="first_name"
                        inputClass="form-name"
                        labelClass="profile-label"
                        textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                        value={formData.first_name}
                      />
                    </div>
                    <div className="flex w-50">
                      <InputField
                        placeholder={t('label.profileForm.lname')}
                        disabled={!isEdit}
                        rules={nameValidation(VALIDATION_STRINGS.LAST_NAME)}
                        inputClass="form-name"
                        labelClass="profile-label"
                        textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                        name="last_name"
                        value={formData.last_name}
                      />
                    </div>
                  </div>

                  {(error.last_name || error.first_name) && isEdit ? (
                    <div className="flex gap-6 edit-name">
                      <div className="flex items-center w-50">
                        <p className="error-container mb-6">{error.first_name}</p>
                      </div>
                      <div className="flex items-center w-50">
                        <p className="error-container mb-6">{error.last_name}</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="">
                  <InputField
                    placeholder={t('label.profileForm.fullName')}
                    disabled
                    labelClass="profile-label"
                    textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                    name="full_name"
                    value={formData.full_name}
                  />
                </div>
              )}
              {isEdit ? null : (
                <div>
                  <InputField
                    placeholder={t('label.profileForm.role')}
                    disabled={!isEdit}
                    name="role"
                    labelClass="profile-label"
                    textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                    error={error.role}
                    value={formData.role}
                  />
                </div>
              )}
              <div>
                <InputField
                  placeholder={t('label.profileForm.email')}
                  rules={emailValidation}
                  disabled
                  labelClass="profile-label"
                  textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                  name="email"
                  error={error.email}
                  value={formData.email}
                />
              </div>
              <div>
                <InputField
                  placeholder={t('label.profileForm.mobile')}
                  disabled={!isEdit}
                  rules={mobileValidation}
                  labelClass="profile-label"
                  textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                  name="mobile_no"
                  error={error.mobile_no}
                  value={formData.mobile_no}
                />
              </div>
              {isEdit && (
                <div className="flex justify-end">
                  <CustomBtn isIcon={false} text={t('button.chnPwdBtn')} onClick={showModal} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
      {openModal && (
        <CustomModal
          open={openModal}
          onCancel={hideModal}
          title={t('profile.modalTitle')}
          showCancelBtn="none"
          footer={null}
          content={<ChangePwdForm email={formData?.email} onOk={hideModal} />}
        />
      )}
    </div>
  )
}

export default MyProfile
