import {
  AccountingIcon,
  ChatsIcon,
  DashBoardIcon,
  FacilitiesIcon,
  ProvidersIcon,
  ReportsIcon,
  ScheduleIcon,
  SettingsIcon,
  SettingsIconColor,
  ShiftsIcon,
} from './siderMenuIcons/SiderMenuIcons'
import ActivityClockIcon from './ActivityClockIcon'
import ActivityDot from './ActivityDot'
import AwardIcon from './AwardIcon'
import BanIcon from './BanIcon'
import BlackFlag from './BlackFlag'
import BuildingIcon from './BuildingIcon'
import CalendarOutline from './CalendarOutline'
import CalenderCheckIcon from './CalenderCheckIcon'
import CalenderCounterMinus from './CalenderCounterMinus'
import CalenderCounterPlus from './CalenderCounterPlus'
import CalenderIcon from './CalenderIcon'
import CalenderSelectedDate from './CalenderSelectedDate'
import CameraIcon from './CameraIcon'
import ChatProvider from './ChatProvider'
import ChatIcon from './ChatsIcon'
import CheckBoxIcon from './CheckBoxIcon'
import CheckCircleOutline from './CheckCircleOutline'
import CheckIcon from './CheckIcon'
import CheckSolidIcon from './CheckSolidIcon'
import CirclePlus from './CirclePlus'
import ClockDropDownIcon from './ClockDropDownIcon'
import { ClockIcon } from './ClockIcon'
import CloseIcon from './CloseIcon'
import CupIcon from './CupIcon'
import DatePickerIcon from './DatePickerIcon'
import DeleteIcon from './DeleteIcon'
import DoubleCheckIcon from './DoubleCheckIcon'
import DownIcon from './DownIcon'
import DownloadIcon from './DownloadIcon'
import DownloadPdf from './DownloadPdf'
import DraggableDots from './DraggableDots'
import DrawerClose from './DrawerClose'
import DrawerOpen from './DrawerOpen'
import DropDownIcon from './DropDownIcon'
import DuplicateIcon from './DuplicateIcon'
import Edit from './Edit'
import EditPenIcon from './EditPenIcon'
import EmptySearchIcon from './EmptySearchIcon'
import EnvolepUploadIcon from './EnvolepUploadIcon'
import FacilityIcon from './FacilityIcon'
import FileIcon from './FileIcon'
import FileOutlineIcon from './FileOutlineIcon'
import Filter from './Filter'
import FilterIcon from './FilterIcon'
import FlagColorSelect from './FlagColorSelect'
import FlagIcon from './FlagIcon'
import ForgotPasswordIcon from './ForgotPasswordIcon'
import GmailLogo from './GmailLogo'
import GreenFlag from './GreenFlag'
import HeaderBack from './HeaderBack'
import HistoryIcon from './HistoryIcon'
import HomeIcon from './HomeIcon'
import HospitalOutline from './HospitalOutline'
import HospitalSolid from './HospitalSolid'
import InfoIcon from './InfoIcon'
import LinkExpiryIcon from './LinkExpiryIcon'
import LocationIcon from './LocationIcon'
import Logo from './Logo'
import LogoIcon from './LogoIcon'
import LogOut from './LogOut'
import MinusCircle from './MinusCircle'
import MinusIcon from './MinusIcon'
import ModalClose from './ModalClose'
import MultipleChoiceIcon from './MultipleChoiceIcon'
import MyProfileIcon from './MyProfileIcon'
import NextIcon from './NextIcon'
import NotificationIcon from './NotificationIcon'
import OutlookLogo from './OutlookLogo'
import PaginationLeft from './PaginationLeft'
import PaginationRight from './PaginationRight'
import ParagraphIcon from './ParagraphIcon'
import PasswordInvisibleIcon from './PasswordInvisibleIcon'
import PasswordVisibleIcon from './PasswordVisibleIcon'
import PlusIcon from './PlusIcon'
import PlusIconSolid from './PlusIconSolid'
import PrevIcon from './PrevIcon'
import ProviderProfileBadge from './ProviderProfileBadge'
import RefreshIcon from './RefreshIcon'
import RequestIcon from './RequestIcon'
import SearchIcon from './SearchIcon'
import SelectDownIcon from './SelectDownIcon'
import SelectFieldIcon from './SelectFieldIcon'
import SelectIcon from './SelectIcon'
import SeperatorBreadcrumbs from './SeperatorBreadcrumbs'
import Settings from './Setting'
import { EveningIcon, FlexibleIcon, MoonIcon, SunIcon } from './ShiftPeriodIcons'
import StethoscopeIcon from './StethoscopeIcon'
import SupportRequest from './SupportRequest'
import TaskManagerIcon from './TaskManagerIcon'
import TextAreaIcon from './TextAreaIcon'
import ThreeDotIcon from './ThreeDotIcon'
import TimeCardIcon from './TimeCardIcon'
import TimeLineIcon from './TimeLineIcon'
import TimePickerIcon from './TimePickerIcon'
import UploadDoc from './UploadDoc'
import UsaIcon from './UsaIcon'
import UserIcon from './UserIcon'
import UserPlusIcon from './UserPlusIcon'

export {
  AccountingIcon,
  ActivityClockIcon,
  ActivityDot,
  AwardIcon,
  BanIcon,
  BlackFlag,
  BuildingIcon,
  CalendarOutline,
  CalenderCheckIcon,
  CalenderCounterMinus,
  CalenderCounterPlus,
  CalenderIcon,
  CalenderSelectedDate,
  CameraIcon,
  ChatIcon,
  ChatProvider,
  ChatsIcon,
  CheckBoxIcon,
  CheckCircleOutline,
  CheckIcon,
  CheckSolidIcon,
  CirclePlus,
  ClockDropDownIcon,
  ClockIcon,
  CloseIcon,
  CupIcon,
  DashBoardIcon,
  DatePickerIcon,
  DeleteIcon,
  DoubleCheckIcon,
  DownIcon,
  DownloadIcon,
  DownloadPdf,
  DraggableDots,
  DrawerClose,
  DrawerOpen,
  DropDownIcon,
  DuplicateIcon,
  Edit,
  EditPenIcon,
  EmptySearchIcon,
  EnvolepUploadIcon,
  EveningIcon,
  FacilitiesIcon,
  FacilityIcon,
  FileIcon,
  FileOutlineIcon,
  Filter,
  FilterIcon,
  FlagColorSelect,
  FlagIcon,
  FlexibleIcon,
  ForgotPasswordIcon,
  GmailLogo,
  GreenFlag,
  HeaderBack,
  HistoryIcon,
  HomeIcon,
  HospitalOutline,
  HospitalSolid,
  InfoIcon,
  LinkExpiryIcon,
  LocationIcon,
  Logo,
  LogoIcon,
  LogOut,
  MinusCircle,
  MinusIcon,
  ModalClose,
  MoonIcon,
  MultipleChoiceIcon,
  MyProfileIcon,
  NextIcon,
  NotificationIcon,
  OutlookLogo,
  PaginationLeft,
  PaginationRight,
  ParagraphIcon,
  PasswordInvisibleIcon,
  PasswordVisibleIcon,
  PlusIcon,
  PlusIconSolid,
  PrevIcon,
  ProviderProfileBadge,
  ProvidersIcon,
  RefreshIcon,
  ReportsIcon,
  RequestIcon,
  ScheduleIcon,
  SearchIcon,
  SelectDownIcon,
  SelectFieldIcon,
  SelectIcon,
  SeperatorBreadcrumbs,
  Settings,
  SettingsIcon,
  SettingsIconColor,
  ShiftsIcon,
  StethoscopeIcon,
  SunIcon,
  SupportRequest,
  TaskManagerIcon,
  TextAreaIcon,
  ThreeDotIcon,
  TimeCardIcon,
  TimeLineIcon,
  TimePickerIcon,
  UploadDoc,
  UsaIcon,
  UserIcon,
  UserPlusIcon,
}
