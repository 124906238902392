import React, { useEffect, useState } from 'react'
import { Dropdown, Form, type MenuProps, message, Popover } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, DropDownIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_CATEGORY_FACILITY_SETTING, DELETE_DOCUMENT_FACILITY_SETTING } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IDocumentAdminSetting } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddNewCategory from './modal/AddNewCategory'
import AddNewDocument from './modal/AddNewDocument'
import { getFindAllCategoryAndDocument, removeCategory, removeDocument } from './api'

const Document = () => {
  const dispatch = useAppDispatch()
  const [listData, setListData] = useState<IDocumentAdminSetting[]>([])
  const [addNewCategory, setAddNewCategory] = useState<boolean>(false)
  const [editCategory, setEditCategory] = useState<number | null>(null)
  const [showTables, setShowTables] = useState<number[]>([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData.length).fill(false))
  const [isDeleteCategory, setIsDeleteCategory] = useState<boolean>(false)
  const [isDeleteDocument, setIsDeleteDocument] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [addEditDocumentModal, setaddEditDocumentModal] = useState<boolean>(false)
  const [editId, setEditId] = useState('')
  const [editData, setEditData] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const [error, setError] = useState({
    name: '',
  })
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.document'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetAllCategoryAndDocument()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewCategory, editCategory, addEditDocumentModal])

  const handleGetAllCategoryAndDocument = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getFindAllCategoryAndDocument()
    if (response?.status === 200) {
      setListData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }
  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleClick = (index: number) => {
    setShowTables((prevVisibleSections) => {
      if (prevVisibleSections.includes(index)) {
        return prevVisibleSections.filter((i) => i !== index)
      } else {
        return [...prevVisibleSections, index]
      }
    })
  }

  const handleDeleteCategory = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await removeCategory(id)
    if (response?.status === 200) {
      setIsDeleteCategory(false)
      void message.success(response?.data?.message)
      void handleGetAllCategoryAndDocument()
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteDocument = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await removeDocument(id)
    if (response?.status === 200) {
      setIsDeleteDocument(false)
      void message.success(response?.data?.message)
      void handleGetAllCategoryAndDocument()
    }
    dispatch(setGlobalLoader(false))
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
          onClick: () => {
            setaddEditDocumentModal(true)
            setEditId(record.id)
            setEditData(record)
            setCategoryId(record?.categoryId)
          },
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
          onClick: () => {
            setIsDeleteDocument(true)
            setDeleteId(record.id)
            setDeleteName(record.name)
          },
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.document.document'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 889,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record?.name} />
      },
    },
    {
      title: t('table.document.essential'),
      dataIndex: 'essential',
      key: 'essential',
      align: 'center',
      width: 380,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record?.is_required === true ? 'Yes' : 'No'} />
      },
    },
    {
      title: t('table.shiftCancelReason.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 163,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className="document-setting-wrapper">
      <div className="flex flex-col items-start gap-4 w-full">
        <div className="flex justify-between items-center w-full">
          <p className="text-neutral-800 desktop-head-h5 font-medium">{t('label.document')}</p>
          <Popover
            trigger={['click']}
            content={<AddNewCategory error={error} setError={setError} form={form} setIsModalOpen={setAddNewCategory} />}
            placement="bottomRight"
            open={addNewCategory}
            onOpenChange={(open) => {
              if (!open) {
                setAddNewCategory(false)
                setError({ name: '' })
                form.resetFields()
              }
            }}
            rootClassName="general-setting-popover"
          >
            <CustomBtn
              isIcon
              svgIcon={<PlusIcon />}
              text={t('button.addNewCategory')}
              type="primary"
              className="rounded-3 border-0"
              onClick={() => {
                setAddNewCategory(true)
              }}
            />
          </Popover>
        </div>
        {listData?.map((data, index) => {
          return (
            <div className="table-wrapper" key={data.id}>
              <div className="flex justify-between items-center aline-self cursor-pointer">
                <div
                  className="flex items-center gap-2 w-full"
                  onClick={() => {
                    handleClick(index)
                  }}
                >
                  <div
                    className="flex items-center justify-end"
                    style={{ transform: showTables.includes(index) ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                  >
                    <DropDownIcon />
                  </div>
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{data.name}</p>
                </div>
                <div className="flex items-center gap-6">
                  <DeleteIcon
                    className="neutral-icon"
                    onClick={() => {
                      setIsDeleteCategory(true)
                      setDeleteId(data.id)
                      setDeleteName(data.name)
                    }}
                  />
                  <Popover
                    trigger={['click']}
                    content={<AddNewCategory error={error} setError={setError} form={form} setEditModalOpen={setEditCategory} editId={data.id} data={data} />}
                    placement="bottomRight"
                    open={editCategory === index}
                    onOpenChange={(open) => {
                      if (!open) {
                        setEditCategory(null)
                        setError({ name: '' })
                        form.resetFields()
                      }
                    }}
                    rootClassName="general-setting-popover"
                  >
                    <EditPenIcon
                      className="neutral-icon"
                      onClick={() => {
                        setEditCategory((prevState) => (prevState === index ? null : index))
                      }}
                    />
                  </Popover>
                  {showTables.includes(index) && (
                    <PlusIcon
                      className="neutral-icon"
                      onClick={() => {
                        setEditId('')
                        setEditData([])
                        setCategoryId(data.id)
                        setaddEditDocumentModal(true)
                      }}
                    />
                  )}
                </div>
              </div>
              {showTables.includes(index) && <CustomTable column={columns} data={data.facility_document.map((doc) => ({ ...doc, categoryId: data.id }))} pagination={false} />}
            </div>
          )
        })}
      </div>

      {isDeleteCategory && (
        <DeleteCancelModal
          isModalOpen={isDeleteCategory}
          setIsModalOpen={setIsDeleteCategory}
          cancelText={DELETE_CATEGORY_FACILITY_SETTING.CANCEL}
          okClick={() => {
            void handleDeleteCategory(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_CATEGORY_FACILITY_SETTING.DELETE_DESC}
          mainHead={DELETE_CATEGORY_FACILITY_SETTING.DELETE_HEAD_LOB}
          okText={DELETE_CATEGORY_FACILITY_SETTING.DELETE}
        />
      )}
      {isDeleteDocument && (
        <DeleteCancelModal
          isModalOpen={isDeleteDocument}
          setIsModalOpen={setIsDeleteDocument}
          cancelText={DELETE_DOCUMENT_FACILITY_SETTING.CANCEL}
          okClick={() => {
            void handleDeleteDocument(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_DOCUMENT_FACILITY_SETTING.DELETE_DESC}
          mainHead={DELETE_DOCUMENT_FACILITY_SETTING.DELETE_HEAD_LOB}
          okText={DELETE_DOCUMENT_FACILITY_SETTING.DELETE}
        />
      )}
      {addEditDocumentModal && (
        <CustomModal
          open={addEditDocumentModal}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setEditId('')
            setEditData([])
            setaddEditDocumentModal(false)
          }}
          content={<AddNewDocument setEditModalOpen={setaddEditDocumentModal} categoryId={categoryId} editId={editId} data={editData} />}
        />
      )}
    </div>
  )
}

export default Document
