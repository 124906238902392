import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import { type IAddDocumentAdmin, type IDocumentCategory } from 'types'

export const getFindAllCategoryAndDocument = async () => {
  try {
    const response = http.get(`/facility-document`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createNewCategory = async (payload: IDocumentCategory) => {
  try {
    const response = http.post(`/facility-document/category`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editCategory = async (id: string, payload: IDocumentCategory) => {
  try {
    const response = http.patch(`/facility-document/category/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const removeCategory = async (id: string) => {
  try {
    const response = http.delete(`/facility-document/category/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createNewDocument = async (payload: IDocumentCategory) => {
  try {
    const response = http.post(`/facility-document`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editDocument = async (id: string, payload: IAddDocumentAdmin) => {
  try {
    const response = http.patch(`/facility-document/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const removeDocument = async (id: string) => {
  try {
    const response = http.delete(`/facility-document/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
