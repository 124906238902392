import React from 'react'

const PlusIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width ?? '20'} height={props.height ?? '20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.8334 9.16536H10.8334V4.16536C10.8334 3.66536 10.5 3.33203 10 3.33203C9.50004 3.33203 9.16671 3.66536 9.16671 4.16536V9.16536H4.16671C3.66671 9.16536 3.33337 9.4987 3.33337 9.9987C3.33337 10.4987 3.66671 10.832 4.16671 10.832H9.16671V15.832C9.16671 16.332 9.50004 16.6654 10 16.6654C10.5 16.6654 10.8334 16.332 10.8334 15.832V10.832H15.8334C16.3334 10.832 16.6667 10.4987 16.6667 9.9987C16.6667 9.4987 16.3334 9.16536 15.8334 9.16536Z"
        fill={props.className ?? 'white'}
      />
    </svg>
  )
}

export default PlusIcon
