import { useEffect } from 'react'
import { Form, message } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IDocumentCategory, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { createNewCategory, editCategory } from '../api'

const AddNewCategory = ({
  setIsModalOpen,
  setEditModalOpen,
  editId,
  data,
  form,
  error,
  setError,
}: {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setEditModalOpen?: React.Dispatch<React.SetStateAction<number | null>>
  editId?: string
  data?: any
  form: any
  error: { name: string }
  setError: any
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (editId && data) {
      form.setFieldValue('name', data.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, data])

  const onFinish = (values: IDocumentCategory) => {
    void handleSubmit(values, editId ?? '')
  }

  const handleSubmit = async (values: IDocumentCategory, editId: string) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await editCategory(editId, values)
    } else {
      response = await createNewCategory(values)
    }
    if (response?.data.statusCode === 1) {
      if (setIsModalOpen) setIsModalOpen(false)
      if (setEditModalOpen) setEditModalOpen(null)
      void message.success(response?.data?.message)
      form.resetFields()
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={editId ? t('label.editCategory') : t('label.addNewCategory')} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start gap-5  w-full">
            <div className="flex flex-col w-full">
              <div className={`h-64 aline-self ${error?.name ? 'mb-6' : ''}`}>
                <InputField
                  delay={100}
                  rules={commonInputFieldValidation(VALIDATION_STRINGS.CATEGORY)}
                  placeholder={t('placeholder.categoryName')}
                  name="name"
                  error={error?.name}
                  value={form.getFieldValue('name')}
                />
              </div>
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  form.resetFields()
                  setError({ name: '' })
                  if (setIsModalOpen) setIsModalOpen(false)
                  if (setEditModalOpen) setEditModalOpen(null)
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={editId ? t('button.update') : t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddNewCategory
